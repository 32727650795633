import { combineReducers } from '@reduxjs/toolkit'

import { alertsReducer as alerts } from './alerts/alertsReducer'
import {
  applicationReducer as application,
  savedLocationStateReducer as savedLocationState,
  onboardingTypeReducer as onboardingType,
} from './application/applicationReducer'
import { authReducer as auth } from './auth/authReducer'
import { unauthenticatedUserReducer as unauthenticatedUser } from './unauthenticatedUser/unauthenticatedUserReducer'
import {
  customerReducer as customer,
  launchDarklyCustomerReducer as launchDarklyCustomer,
} from './customer/customerReducer'
import { transferDetailsReducer as transferDetails } from './transferDetails/transferDetailsReducer'
import { apiValidationsErrorsReducer as apiValidationErrors } from './apiValidationErrors/apiValidationErrorsReducer'
import {
  debitCardDepositDetailsReducer as debitCardDepositDetails,
  debitCardDepositAttemptNumberReducer as debitCardDepositAttemptNumber,
} from './debitCardDepositDetails/debitCardDepositDetailsReducer'
import { segmentTrackingReducer as segmentTracking } from './segmentTracking/segmentTrackingReducer'

export default combineReducers({
  alerts,
  application,
  auth,
  unauthenticatedUser,
  customer,
  transferDetails,
  apiValidationErrors,
  debitCardDepositDetails,
  launchDarklyCustomer,
  savedLocationState,
  onboardingType,
  debitCardDepositAttemptNumber,
  segmentTracking,
})
