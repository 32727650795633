import { useEffect, useState } from 'react'

import { store, purgeStore } from '@redux/store'

import { IGNORE_IDLE_USER } from '@common/constants'
import { isAuthenticated } from './appUtils'
import { staticRoutes } from '@routing/routes'

const useIdleUserTimeout = ({ maxIdleTime = 900000 }) => {
  const [isUserAuthenticated, setUserIsAuthenticated] = useState(false)

  useEffect(() => {
    if (!IGNORE_IDLE_USER) {
      store.subscribe(() => {
        const state = store.getState()
        const hasToken = isAuthenticated(state)
        setUserIsAuthenticated(hasToken)
      })
    }
  }, [])

  useEffect(() => {
    let timeout
    const events = [
      'load',
      'mousemove',
      'mousedown',
      'click',
      'scroll',
      'keydown',
      'touchstart',
      'touchmove',
    ]
    const hasEventListeners = !IGNORE_IDLE_USER && isUserAuthenticated

    const resetTimer = () => {
      clearTimeout(timeout)
      timeout = setTimeout(handleTimeout, maxIdleTime)
    }

    if (hasEventListeners) {
      events.forEach(name => window.addEventListener(name, resetTimer, true))
    }

    return () => {
      if (hasEventListeners) {
        events.forEach(name => window.removeEventListener(name, resetTimer))
        clearTimeout(timeout)
      }
    }
  }, [isUserAuthenticated, maxIdleTime])

  const handleTimeout = () => {
    // If user is logged in but has been idle for longer than the alotted time period, then
    // show the session expired message, sign the user out, and redirect them to the sign in page
    window.sessionStorage.setItem('showSessionExpiredMessage', true)
    purgeStore({ redirectPath: staticRoutes.signIn.pathname })
  }
}

export default useIdleUserTimeout
