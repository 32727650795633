import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { usePlaidLink } from 'react-plaid-link'
import PropTypes from 'prop-types'
import { useLocation, useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import LoadingContainer from '@shared/components/loadingContainer/LoadingContainer'
import Button from '@shared/components/button/Button'

import { usePlaidLinkConfigCallbacks } from '@common/utils'
import {
  PLAID_LINKING_STATUSES,
  PLAID_OAUTH_REDIRECT_URL,
  PLAID_OAUTH_REDIRECT_SESSION_VARIABLES,
} from '@common/constants'
import { store } from '@redux/store'
import { setLocationState } from '@redux/application/applicationActions'
import { useLazyQuery, gql } from '@services/serviceUtils'

import styling from './plaidLink.module.scss'

const plaidNewLinkTokenQuery = gql`
  query PlaidTokenForNewLink($redirectUri: String) {
    plaidTokenForNewLink(redirectUri: $redirectUri) {
      linkToken
    }
  }
`

const plaidUpdateLinkTokenQuery = gql`
  query PlaidTokenForExistingLink($accountId: ID!, $redirectUri: String) {
    plaidTokenForExistingLink(accountId: $accountId, redirectUri: $redirectUri) {
      linkToken
    }
  }
`

/**
 * Displays the Plaid Link button and iFrame
 */
const PlaidLinkSdk = ({
  token,
  onBeforeSuccess,
  onAfterSuccess,
  setOpenFunction,
  accountId,
  openOnLoad,
  receivedRedirectUri,
}) => {
  const [hasOpenedOnLoad, setHasOpenedOnLoad] = useState(false)

  const isNewLink = !accountId

  const plaidLinkConfigCallbacks = usePlaidLinkConfigCallbacks({
    onBeforeSuccess,
    onAfterSuccess,
  })

  const {
    open, // Calling open will display the Consent Pane view
    ready, // Will be 'true' once the pane is ready to be opened
    error, // Populated if Plaid fails to load the Link JS
  } = usePlaidLink({
    token,
    receivedRedirectUri,
    onExit: plaidLinkConfigCallbacks.onExit,
    onEvent: plaidLinkConfigCallbacks.onEvent,
    onSuccess: isNewLink
      ? plaidLinkConfigCallbacks.onCreateSuccess
      : (_publicToken, metadata) => plaidLinkConfigCallbacks.onUpdateSuccess(accountId, metadata),
  })

  useEffect(() => {
    if (setOpenFunction && open && ready) {
      /* This must be wrapped in a function because of React's functional updates in `useState`:
         https://reactjs.org/docs/hooks-reference.html#functional-updates
      */
      setOpenFunction(() => open)
    }
  }, [ready, setOpenFunction, open])

  // Open the SDK as soon as it's ready after loading (but only once)
  useEffect(() => {
    if (openOnLoad && !hasOpenedOnLoad && open && ready) {
      setHasOpenedOnLoad(true)
      open()
    }
  }, [ready, hasOpenedOnLoad, openOnLoad, open])

  return (
    <LoadingContainer loading={false} error={error} errorMessage='Error loading Plaid Link'>
      {/* Don't render a loader or content, this is just here to nicely display an error message */}
      {null}
    </LoadingContainer>
  )
}

const getSavedToken = () =>
  window.sessionStorage.getItem(PLAID_OAUTH_REDIRECT_SESSION_VARIABLES.PLAID_LINK_TOKEN) || null

const getTokenQuery = isNewLink => (isNewLink ? plaidNewLinkTokenQuery : plaidUpdateLinkTokenQuery)

const useTokenQuery = ({ isNewLink }) => {
  const [tokenQuery, setTokenQuery] = useState(getTokenQuery(isNewLink))

  useEffect(() => {
    setTokenQuery(getTokenQuery(isNewLink))
  }, [isNewLink])

  return tokenQuery
}

const getQueryVariables = ({ redirectUri, accountId, isNewLink }) => {
  const queryVariables = {
    redirectUri,
  }

  if (!isNewLink) {
    queryVariables.accountId = accountId
  }

  return queryVariables
}

const useQueryVariables = ({ redirectUri, accountId, isNewLink }) => {
  const [queryVariables, setQueryVariables] = useState(
    getQueryVariables({ redirectUri, accountId, isNewLink })
  )

  useEffect(() => {
    setQueryVariables({ redirectUri, accountId, isNewLink })
  }, [redirectUri, accountId, isNewLink])

  return queryVariables
}

const getNewToken = ({ isNewLink, plaidLinkTokenData }) =>
  isNewLink
    ? plaidLinkTokenData?.plaidTokenForNewLink?.linkToken
    : plaidLinkTokenData?.plaidTokenForExistingLink?.linkToken

const getIsContinuingOAuthProcess = ({ savedToken, location }) => !!(savedToken && location?.search)

const useReceivedRedirectUri = ({ redirectUri, location, isContinuingOAuthProcess }) => {
  return useMemo(() => {
    return isContinuingOAuthProcess ? `${redirectUri}${location?.search}` : undefined
  }, [isContinuingOAuthProcess, location, redirectUri])
}

const useTokenSessionStorage = ({ newToken, location }) => {
  // Set the new token value in sessionStorage
  useEffect(() => {
    if (newToken) {
      window.sessionStorage.setItem(
        PLAID_OAUTH_REDIRECT_SESSION_VARIABLES.PLAID_LINK_TOKEN,
        newToken
      )
    }
  }, [newToken])
}

const getShouldGetPlaidLinkToken = ({ linkingStatus, isContinuingOAuthProcess }) =>
  linkingStatus !== PLAID_LINKING_STATUSES.VERIFIED &&
  linkingStatus !== PLAID_LINKING_STATUSES.PENDING_VERIFICATION &&
  !isContinuingOAuthProcess

const usePlaidLinkSdk = ({
  accountId,
  linkingStatus,
  onBeforeSuccess,
  onAfterSuccess,
  openOnLoad,
}) => {
  const location = useLocation()
  const navigate = useNavigate()

  const { savedLocationState } = store?.getState()

  useEffect(() => {
    store.dispatch(setLocationState(location?.state))
  }, [location?.state])

  const [plaidLinkProcessing, setPlaidLinkProcessing] = useState(false)
  const [openFunction, setOpenFunction] = useState(null)
  const [savedToken] = useState(getSavedToken())

  const isNewLink = !accountId
  const redirectUri = `${PLAID_OAUTH_REDIRECT_URL}${location?.pathname}`
  const query = useTokenQuery({ isNewLink })

  // Fetch token for updating an existing link or creating a new link
  const [
    getPlaidLinkToken,
    { data: plaidLinkTokenData, loading: plaidLinkTokenLoading },
  ] = useLazyQuery(query, {
    variables: useQueryVariables({ redirectUri, accountId, isNewLink }),
    fetchPolicy: 'no-cache',
  })

  const newToken = getNewToken({ isNewLink, plaidLinkTokenData })
  const isContinuingOAuthProcess = getIsContinuingOAuthProcess({ savedToken, location })
  const shouldGetPlaidLinkToken = getShouldGetPlaidLinkToken({
    linkingStatus,
    isContinuingOAuthProcess,
  })

  // This property is for ensuring that the user is redirected to the appropriate portion of the linking process once the SDK opens
  const receivedRedirectUri = useReceivedRedirectUri({
    redirectUri,
    location,
    isContinuingOAuthProcess,
  })

  // Remove the plaidLinkToken sessionStorage value when the component is unmounted
  useEffect(() => {
    return () => {
      window.sessionStorage.removeItem(PLAID_OAUTH_REDIRECT_SESSION_VARIABLES.PLAID_LINK_TOKEN)
    }
  }, [])

  // Updates the token in session storage
  useTokenSessionStorage({ newToken, location })

  // Only retrieve a Plaid Link token if an action can be taken
  useEffect(() => {
    if (shouldGetPlaidLinkToken) {
      getPlaidLinkToken()
    }
  }, [shouldGetPlaidLinkToken, getPlaidLinkToken])

  const handleBeforeSuccess = useCallback(() => {
    setPlaidLinkProcessing(true)
    onBeforeSuccess && onBeforeSuccess()
  }, [onBeforeSuccess])

  const handleAfterSuccess = useCallback(() => {
    setPlaidLinkProcessing(false)
    onAfterSuccess && onAfterSuccess()

    // After successfully adding Plaid account, return user to general transfer page; remove location.search criteria
    navigate(location.pathname, { state: savedLocationState })
  }, [location, onAfterSuccess, navigate, savedLocationState])

  const token = newToken || savedToken
  const shouldOpenOnLoad = isContinuingOAuthProcess || openOnLoad

  const PlaidLinkSdkComponent = useMemo(
    () => props =>
      token ? (
        <PlaidLinkSdk
          accountId={accountId}
          token={token}
          openOnLoad={shouldOpenOnLoad}
          setOpenFunction={setOpenFunction}
          onBeforeSuccess={handleBeforeSuccess}
          onAfterSuccess={handleAfterSuccess}
          receivedRedirectUri={receivedRedirectUri}
          {...props}
        />
      ) : null,
    [
      accountId,
      handleAfterSuccess,
      handleBeforeSuccess,
      token,
      shouldOpenOnLoad,
      receivedRedirectUri,
    ]
  )

  return {
    processing: plaidLinkProcessing,
    loading: plaidLinkTokenLoading,
    shouldGetPlaidLinkToken,
    open: openFunction,
    PlaidLinkSdk: PlaidLinkSdkComponent,
  }
}

const PlaidLink = ({
  className,
  displayText,
  onBeforeSuccess,
  onAfterSuccess,
  accountId,
  linkingStatus,
  openOnLoad = false,
  onClick,
  isLoading = false,
  ...rest
}) => {
  const {
    processing,
    loading,
    open,
    shouldGetPlaidLinkToken,
    PlaidLinkSdk: PlaidLinkSdkComponent,
  } = usePlaidLinkSdk({ accountId, linkingStatus, onBeforeSuccess, onAfterSuccess, openOnLoad })

  const buttonText = displayText || (
    <>
      <div className={styling.plus}>&#43;</div>Add External Account
    </>
  )

  return (
    <Button
      className={classNames(styling['plaid-action-button'], className)}
      onClick={onClick || open}
      isLoading={isLoading || (shouldGetPlaidLinkToken && (loading || processing))}
      disabled={!(onClick || open)}
      data-cy='plaid-link-action-button'
      {...rest}
    >
      {buttonText}
      <PlaidLinkSdkComponent />
    </Button>
  )
}

PlaidLink.propTypes = {
  /**
   * Any additional class names to pass to the button element.
   */
  className: PropTypes.any,

  /**
   * onBeforeSuccess executes before the Plaid Link `onSuccess` callback begins execution. Useful
   * for updating some loader state for example.
   */
  onBeforeSuccess: PropTypes.func,

  /**
   * onAfterSuccess executes after the Plaid Link `onSuccess` callback fully executes. Useful for
   * updating some loader state for example.
   */
  onAfterSuccess: PropTypes.func,

  /**
   * onClick function to perform when button is clicked.
   * Note: Passing a function here will override the default implmentation
   */
  onClick: PropTypes.func,

  /**
   * accountId can be passed to initiate the 'update' flow for Plaid Link, e.g. to fix existing
   * linked accounts that are in error state. When undefined, an 'add' button will be present so
   * that a new Plaid Link item can be created.
   */
  accountId: PropTypes.string,

  /**
   * displayText is the text of the button
   * Note: When passed this will overwrite the default button text
   */
  displayText: PropTypes.node,

  /**
   * linkingStatus must be passed when accountId is passed so that the component knows what
   * potential actions are available (e.g. to fix or verify a linked account).
   */
  linkingStatus: PropTypes.string,

  /**
   * Automatically open the Plaid Link SDK once it's available after mount. Default is false.
   */
  openOnLoad: PropTypes.bool,

  /**
   * Loading state for button. Default is false.
   */
  isLoading: PropTypes.bool,
}

export default PlaidLink
export { usePlaidLinkSdk }
