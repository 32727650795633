import React, { useEffect, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'
import { ReactComponent as PencilIcon } from '@common/images/icons/pencil.svg'

import Button from '@shared/components/button/Button'

import {
  useCheckpointNavigation,
  trackEvent,
  trackPage,
  SEGMENT_EVENTS,
  SEGMENT_SOURCE_DETAILS,
  SEGMENT_PAGE_NAMES,
} from '@common/utils'
import { staticRoutes } from '@routing/routes'
import { DEPLOY_ENV } from '@common/constants'

import styling from './createAccountReview.module.scss'

const CreateAccountReview = ({ data, submitApplication, submitting, isResubmitting = false }) => {
  const navigate = useNavigate()
  const { pathname, state } = useLocation()

  const userIsResubmitting = useMemo(() => isResubmitting || state?.isResubmitting, [
    isResubmitting,
    state?.isResubmitting,
  ])

  useEffect(() => {
    if (userIsResubmitting && !state?.isResubmitting) {
      navigate(pathname, {
        state: {
          ...state,
          isResubmitting: true,
          shouldSetupDirectDeposit: true,
        },
        replace: true,
      })
    }
  }, [userIsResubmitting, navigate, pathname, state])

  useCheckpointNavigation({
    checkpoints: data?.checkpoints,
    applicationStatus: data?.application?.customerApplicationStatus,
    isResubmitting: userIsResubmitting,
  })

  const {
    firstName,
    lastName,
    deliveryAddress,
    buildingNumber,
    locality,
    subdivisions,
    postalCode,
  } = data?.application
  const { birthDate, ssn } = data?.review

  // Track registration confirmation page visits
  useEffect(() => {
    trackPage({ name: SEGMENT_PAGE_NAMES.REGISTRATION_CONFIRM })
  }, [])

  const fullName = useMemo(() => `${firstName} ${lastName}`, [firstName, lastName])

  const accountInfo = useMemo(
    () => [
      {
        title: 'Legal Name',
        value: fullName,
        segmentSourceDetail: SEGMENT_SOURCE_DETAILS.EDIT_NAME,
        editRoute: staticRoutes.createAccountName.pathname,
        dataCy: 'name',
      },
      {
        title: 'Date of Birth',
        value: birthDate,
        segmentSourceDetail: SEGMENT_SOURCE_DETAILS.EDIT_DATE_OF_BIRTH,
        editRoute: staticRoutes.createAccountBirthday.pathname,
        dataCy: 'birthdate',
      },
      {
        title: 'Social Security Number',
        value: ssn,
        segmentSourceDetail: SEGMENT_SOURCE_DETAILS.EDIT_SOCIAL_SECURITY,
        editRoute: staticRoutes.createAccountSSN.pathname,
        dataCy: 'ssn',
      },
      {
        title: 'Permanent Address',
        value: (
          <>
            {deliveryAddress}
            <br />
            {buildingNumber && (
              <>
                {buildingNumber}
                <br />
              </>
            )}
            {locality}, {subdivisions && subdivisions[0]} {postalCode}
          </>
        ),
        segmentSourceDetail: SEGMENT_SOURCE_DETAILS.EDIT_ADDRESS,
        editRoute: staticRoutes.createAccountAddress.pathname,
        resubmissionRoute: staticRoutes.addressResubmissionEntry.pathname,
        dataCy: 'address',
      },
    ],
    [fullName, birthDate, ssn, deliveryAddress, buildingNumber, locality, subdivisions, postalCode]
  )

  const allowedResubmissionFields = ['address']
  if (DEPLOY_ENV === 'dev') {
    allowedResubmissionFields.push('name')
  }

  const getAccountInfo = () =>
    accountInfo.map(info => {
      return (
        <li key={info.title}>
          <p>
            <span className={styling.title}>{info.title}:</span>
            <span className={styling.value}>{info.value}</span>
          </p>
          {(!userIsResubmitting ||
            (userIsResubmitting && allowedResubmissionFields.includes(info.dataCy))) && (
            <Button
              className='edit-button'
              disabled={submitting}
              ghost
              onClick={() => {
                trackEvent({
                  event: SEGMENT_EVENTS.registrationButtonClicked({
                    sourceDetail: info.segmentSourceDetail,
                  }),
                })
                if (userIsResubmitting) {
                  navigate(info.resubmissionRoute || info.editRoute, {
                    state: {
                      ...state,
                      isResubmitting: true,
                    },
                  })
                } else {
                  navigate(info.editRoute, { state: { ...state, isEditing: true } })
                }
              }}
              size='sm'
              data-cy={`create-account-review-edit-${info.dataCy}-button`}
            >
              <PencilIcon />
            </Button>
          )}
        </li>
      )
    })

  return (
    <div className='white-card-container' data-cy='authenticated-loaded-view'>
      <GreenwoodLogo className='logo' />
      <h1 id='review-step-header'>Review your information</h1>

      <div className='create-account-content-wrapper'>
        <p>
          Please ensure you have provided all of the correct information before submitting your
          application to Greenwood.
        </p>

        <ul className={styling['account-info']}>{getAccountInfo()}</ul>

        <p>
          By applying for an account through Greenwood, you authorize the use of your information in
          accordance with Greenwood Privacy Policy and Coastal Community Bank Privacy Policy.
        </p>
        <Button
          className='full-width-card-button'
          data-cy='create-account-review-agree-button'
          isLoading={submitting}
          onClick={() => submitApplication({})}
        >
          SUBMIT
        </Button>
      </div>
    </div>
  )
}

export default CreateAccountReview
