import React, { useMemo } from 'react'
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import MembershipView from './MembershipView'
import AboutMyMembership from './AboutMyMembership'
import ChangeMyMembership from './ChangeMyMembership'
import PaymentMethod from './PaymentMethod'
import Hero from '@common/components/hero/Hero'
import AccountMembership from './account/AccountMembership'
import Tabs from '@shared/components/tabs/Tabs'
import ProtectedRoute from '@routing/ProtectedRoute'

import { useTabRouting } from '@common/utils'
import { getTabOptions } from './membershipUtils'
import { staticRoutes } from '@routing/routes'

import styling from '../settings/settings.module.scss'

const Membership = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const applicationStatus = useSelector(state => state.application?.customerApplicationStatus)
  const customer = useSelector(state => state.customer)
  const hasPremium = useSelector(state => state.customer?.hasPremium)
  const hasElevate = useSelector(state => state.customer?.hasElevate)
  const hasSubscription = useSelector(state => state.customer?.hasSubscription)
  const firstName = customer?.firstName || ''
  const lastName = customer?.lastName || ''
  const userFullName = `${firstName} ${lastName}`

  const tabOptions = useMemo(() => getTabOptions({ applicationStatus, hasSubscription }), [
    applicationStatus,
    hasSubscription,
  ])

  const { activeTab } = useTabRouting({
    defaultActiveTab: tabOptions[0],
    location,
    tabOptions,
    tabsEnabled: true,
  })

  const handleTabChange = tab => {
    if (tab) {
      navigate(tab.pathname)
    }
  }

  const defaultPath = useMemo(
    () =>
      applicationStatus === 'PASS' || hasSubscription
        ? staticRoutes.membershipDetails.pathname
        : staticRoutes.membershipAccount.pathname,
    [applicationStatus, hasSubscription]
  )

  return (
    <>
      <Hero heading={userFullName} hasPremium={hasPremium} hasElevate={hasElevate} />

      <div className='page-content-container white-bg'>
        <div className='row'>
          <Tabs
            defaultActiveTab={activeTab}
            options={tabOptions}
            onChangeCallback={handleTabChange}
          />
          <div className={styling['tabs-content-container']}>
            <Routes>
              <Route
                path={staticRoutes.membershipDetails.relativePathname}
                element={<ProtectedRoute component={MembershipView} />}
              />
              <Route
                path={staticRoutes.membershipAboutMyMembership.relativePathname}
                element={<ProtectedRoute component={AboutMyMembership} />}
              />
              <Route
                path={staticRoutes.membershipChangeMyMembership.relativePathname}
                element={<ProtectedRoute component={ChangeMyMembership} />}
              />
              <Route
                path={staticRoutes.membershipPaymentMethod.relativePathname}
                element={<ProtectedRoute component={PaymentMethod} />}
              />
              <Route
                path={staticRoutes.membershipAccount.relativePathname}
                element={<ProtectedRoute component={AccountMembership} />}
              />
              <Route path='*' element={<Navigate to={defaultPath} />} />
            </Routes>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  )
}

Membership.propTypes = {
  userFullName: PropTypes.string,
}

export default Membership
