import { SET_AUTH, SET_SESSION_ID, SET_CHALLENGE_TYPE, SET_TOKEN } from './authActions'

const initialState = {
  sessionId: null,
  challengeType: null,
  token: {},
}

export const authReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_AUTH:
      return action.payload
    case SET_CHALLENGE_TYPE:
      return {
        ...state,
        challengeType: action.payload,
      }
    case SET_SESSION_ID:
      return {
        ...state,
        sessionId: action.payload,
      }
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      }
    default:
      return state
  }
}
