import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { useElements, useStripe, PaymentElement } from '@stripe/react-stripe-js'
import classNames from 'classnames'

import Button from '@shared/components/button/Button'
import ExternalLink from '@common/components/link/ExternalLink'

import {
  getStripeDisclaimerText,
  STRIPE_PAYMENT_STATUSES,
  STRIPE_INTENT_TYPES,
  ONBOARDING_FLOW_TYPES,
  BANK_DISCLAIMER,
} from '@common/constants'
import { ALERT_TYPES, DEFAULT_ALERT_DISMISS_DELAY } from '@shared/constants/uiConstants'
import { addAlertAction } from '@redux/alerts/alertsActions'
import { setEmailAction } from '@redux/unauthenticatedUser/unauthenticatedUserActions'
import { setOnboardingFlowTypeAction } from '@redux/application/applicationActions'
import { staticRoutes } from '@routing/routes'
import { gql, useMutation } from '@services/serviceUtils'
import {
  BOOTSTRAP_SITEMAP_RESOURCES,
  SEGMENT_EVENTS,
  SEGMENT_PAGE_NAMES,
  getBootstrapSitemapResource,
  trackEvent,
  trackPage,
} from '@common/utils'

import styling from './stripePaymentForm.module.scss'

const updateSubscriptionPaymentStatusMutation = gql`
  mutation UpdateSubscriptionPaymentStatus(
    $subscriptionPaymentStatusUpdateDTOInput: SubscriptionPaymentStatusUpdateDTOInput!
  ) {
    updateSubscriptionPaymentStatus(
      subscriptionPaymentStatusUpdateDTOInput: $subscriptionPaymentStatusUpdateDTOInput
    ) {
      success
    }
  }
`

const updatePaymentMethodForSubscriptionMutation = gql`
  mutation UpdatePaymentMethodForSubscription(
    $paymentMethodInputDTOInput: PaymentMethodInputDTOInput!
    $subId: ID!
  ) {
    updatePaymentMethodForSubscription(
      paymentMethodInputDTOInput: $paymentMethodInputDTOInput
      subId: $subId
    )
  }
`

const StripePaymentForm = ({
  membershipType,
  buttonText = 'Confirm Payment',
  isUpdatingMethod = false,
  subscriptionId,
  handleSuccess,
  showStripePrivacyPolicyLink = true,
  className,
  sessionType = STRIPE_INTENT_TYPES.PAYMENT,
  setPaymentFormLoaded,
  isElevateMembershipOnlyPayment = false,
  isNonBankingMembershipPayment = false,
  trackingSource,
}) => {
  const navigate = useNavigate()
  const { state = {} } = useLocation()
  const dispatch = useDispatch()

  const stripe = useStripe()
  const elements = useElements()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const [submitUpdateSubscriptionPaymentStatusRequest] = useMutation(
    updateSubscriptionPaymentStatusMutation
  )
  const [updatePaymentMethodForSubscription, { loading: updatingPaymentMethod }] = useMutation(
    updatePaymentMethodForSubscriptionMutation
  )

  const options = {
    terms: {
      card: 'never',
    },
  }

  const stripePrivacyPolicyDetails = getBootstrapSitemapResource(
    BOOTSTRAP_SITEMAP_RESOURCES.STRIPE_PRIVACY_POLICY
  )

  const isNotUpgradeUpdate = useMemo(() => {
    return isUpdatingMethod && !state?.isUpgrading
  }, [isUpdatingMethod, state?.isUpgrading])

  const segmentPageName = useMemo(() => {
    let pageName

    if (isNotUpgradeUpdate) {
      pageName = SEGMENT_PAGE_NAMES.STRIPE_PAYMENT
    }

    return pageName
  }, [isNotUpgradeUpdate])

  useEffect(() => {
    if (segmentPageName) {
      trackPage({ name: segmentPageName })
    }
  }, [segmentPageName])

  const trackStripePrivacyPolicyClick = () => {
    const trackingDetails = {}
    const source = segmentPageName || trackingSource

    if (state?.isOnboarding) {
      trackingDetails.productType = membershipType
    } else if (source) {
      trackingDetails.source = source
    }

    trackEvent({
      event: SEGMENT_EVENTS.productPaymentStripePrivacyPolicyButtonClicked(trackingDetails),
    })
  }

  const showErrorAlert = error => {
    const actionType = isUpdatingMethod ? 'update' : 'payment'
    const message = `${error?.message ||
      `Your ${actionType} has failed.`} Please verify your information and try again.`

    dispatch(
      addAlertAction({
        autoDismissDelay: DEFAULT_ALERT_DISMISS_DELAY,
        text: message,
      })
    )
  }

  const updateSubscriptionStatus = async ({ paymentSessionId, paymentStatus }) => {
    await submitUpdateSubscriptionPaymentStatusRequest({
      variables: {
        subscriptionPaymentStatusUpdateDTOInput: {
          paymentSessionId,
          paymentStatus,
        },
      },
    })
  }

  const handleSubmitPayment = async () => {
    if (state?.isOnboarding) {
      trackEvent({
        event: SEGMENT_EVENTS.productPaymentSubscribeButtonClicked({
          productType: membershipType,
        }),
      })
    }

    if (isNonBankingMembershipPayment) {
      trackEvent({
        event: SEGMENT_EVENTS.nonBankingProductConfirmPaymentButtonClicked({
          userEmail: state?.userEmail,
          source: trackingSource,
        }),
      })
    }

    // Submit the payment for the subscription to Stripe
    await stripe
      .confirmPayment({
        elements,
        redirect: 'if_required',
      })
      .then(result => {
        setIsSubmitting(false)

        const { error, paymentIntent } = result

        // Payment Intent is only set if the payment method was confirmed
        if (paymentIntent) {
          if (!isNonBankingMembershipPayment) {
            switch (paymentIntent.status) {
              case STRIPE_PAYMENT_STATUSES.SUCCEEDED:
              case STRIPE_PAYMENT_STATUSES.PROCESSING: {
                const paymentStatus =
                  paymentIntent.status === STRIPE_PAYMENT_STATUSES.SUCCEEDED
                    ? 'SUCCESSFUL'
                    : paymentIntent.status
                updateSubscriptionStatus({ paymentSessionId: paymentIntent.id, paymentStatus })

                navigate(staticRoutes.membershipUpgradeConfirmation.pathname, {
                  state: {
                    fundingFlow: state?.fundingFlow,
                    productType: state?.product?.productType,
                    shouldSetupDirectDeposit: state?.shouldSetupDirectDeposit,
                    isUpgradeSuccessful: paymentIntent.status === STRIPE_PAYMENT_STATUSES.SUCCEEDED,
                    isUpgrading: state?.isUpgrading,
                    cancelUpgradePathname: state?.cancelUpgradePathname,
                  },
                })

                break
              }
              default: {
                updateSubscriptionStatus({
                  paymentSessionId: paymentIntent.id,
                  paymentStatus: paymentIntent.status,
                })
                break
              }
            }
          } else {
            // what to do for successful payment if user is paying for an Elevate membership without banking
            switch (paymentIntent.status) {
              case STRIPE_PAYMENT_STATUSES.SUCCEEDED: {
                if (isElevateMembershipOnlyPayment) {
                  dispatch(setOnboardingFlowTypeAction(ONBOARDING_FLOW_TYPES.ELEVATE))
                  dispatch(setEmailAction({ email: state?.userEmail }))

                  // Send an Elevate specific registration complete event for marketing conversion tracking
                  trackEvent({ event: SEGMENT_EVENTS.ELEVATE_REGISTRATION_COMPLETED })

                  dispatch(
                    addAlertAction({
                      dismissible: false,
                      autoDismissDelay: DEFAULT_ALERT_DISMISS_DELAY,
                      type: ALERT_TYPES.SUCCESS,
                      text: 'Payment successful. Welcome to Elevate!',
                      className: styling['success-alert'],
                    })
                  )

                  navigate(staticRoutes.signUpPassword.pathname, {
                    state: {
                      isElevateCustomer: true,
                      shouldSetupDirectDeposit: true,
                    },
                  })
                } else {
                  navigate(`${state?.paymentPathname}-success`, { state })
                }
                break
              }
              default:
                break
            }
          }
        }

        // If there was an error with the payment or Stripe failed to authorize the payment,
        // show an error and allow the user to attempt the payment again
        if (error || paymentIntent?.status === STRIPE_PAYMENT_STATUSES.REQUIRES_PAYMENT_METHOD) {
          showErrorAlert(error)
        }
      })
  }

  const handleUpdatePaymentMethod = async () => {
    if (isNotUpgradeUpdate) {
      trackEvent({ event: SEGMENT_EVENTS.STRIPE_PAYMENT_UPGRADE_BUTTON_CLICK })
    }

    // Submit the updated payment method information to Stripe
    await stripe
      .confirmSetup({
        elements,
        redirect: 'if_required',
      })
      .then(async result => {
        setIsSubmitting(false)

        const { error, setupIntent } = result

        if (setupIntent?.status === STRIPE_PAYMENT_STATUSES.SUCCEEDED) {
          const paymentMethodId = setupIntent?.payment_method

          const updateMethodResponse = await updatePaymentMethodForSubscription({
            variables: {
              paymentMethodInputDTOInput: {
                paymentMethodId,
              },
              subId: subscriptionId,
            },
          })

          if (updateMethodResponse?.data) {
            dispatch(
              addAlertAction({
                autoDismissDelay: DEFAULT_ALERT_DISMISS_DELAY,
                type: ALERT_TYPES.SUCCESS,
                text: 'Successfully updated your payment method.',
              })
            )

            handleSuccess && handleSuccess()
          }
        }

        if (error || setupIntent?.status === STRIPE_PAYMENT_STATUSES.REQUIRES_PAYMENT_METHOD) {
          showErrorAlert(error)
        }
      })
  }

  const handleSubmit = event => {
    event.preventDefault()
    setIsSubmitting(true)

    if (sessionType === STRIPE_INTENT_TYPES.PAYMENT) {
      handleSubmitPayment()
    } else {
      handleUpdatePaymentMethod()
    }
  }

  return (
    <form
      onSubmit={handleSubmit}
      className={classNames(styling['stripe-payment-form'], className)}
    >
      <PaymentElement
        options={options}
        onReady={() => {
          setIsLoading(false)
          if (setPaymentFormLoaded) {
            setPaymentFormLoaded()
          }
        }}
      />
      {!isLoading && (
        <>
          <p className='disclaimer-text'>{getStripeDisclaimerText(buttonText)}</p>
          <div className='buttons-container'>
            <Button
              type='submit'
              isLoading={isSubmitting || updatingPaymentMethod}
              disabled={isSubmitting || updatingPaymentMethod}
              styleType='primary'
              data-cy='stripe-payment-button'
            >
              {buttonText}
            </Button>
          </div>
          <p className={classNames('disclaimer-text', styling['bank-disclaimer'])}><sup>1</sup>{BANK_DISCLAIMER}</p>
          {showStripePrivacyPolicyLink && (
            <div>
              <ExternalLink
                to={stripePrivacyPolicyDetails.url}
                className='underlined-link bold'
                clickListener={trackStripePrivacyPolicyClick}
              >
                {stripePrivacyPolicyDetails?.title.value}
              </ExternalLink>
            </div>
          )}
        </>
      )}
    </form>
  )
}

StripePaymentForm.propTypes = {
  membershipType: PropTypes.string,
  buttonText: PropTypes.string,
  isUpdatingMethod: PropTypes.bool,
  subscriptionId: PropTypes.string,
  handleSuccess: PropTypes.func,
  showStripePrivacyPolicyLink: PropTypes.bool,
  className: PropTypes.string,
  sessionType: PropTypes.string,
  setPaymentFormLoaded: PropTypes.func,
}

export default StripePaymentForm
