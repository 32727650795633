import { staticRoutes } from '@routing/routes'

export const getTabOptions = ({ applicationStatus, hasSubscription }) => {
  const tabs = []

  if (applicationStatus === 'PASS' || hasSubscription) {
    tabs.push({
      label: 'Membership',
      dataCy: 'membership-tab',
      enabled: true,
      pathname: staticRoutes.membershipDetails.pathname,
    })
  }

  tabs.push({
    label: 'Personal Information',
    dataCy: 'personal-information-tab',
    enabled: true,
    pathname: staticRoutes.membershipAccount.pathname,
  })

  return tabs
}
