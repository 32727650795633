import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import Loading from '@shared/components/Loading'
import LoadingContainer from '@shared/components/loadingContainer/LoadingContainer'
import FormCheckbox from '@shared/components/formCheckbox/FormCheckbox'
import { ReactComponent as LockedIcon } from '@shared/images/icons/locked.svg'
import { staticRoutes } from '@routing/routes'
import { CARD_UPDATE_STATUSES } from '@common/constants'

import styling from './lockCard.module.scss'

const LockCard = ({
  last4,
  cardIsLocked,
  loading,
  error,
  updateCardStatus,
  updatingCardStatus,
}) => {
  const toggleLock = () => {
    updateCardStatus({
      variables: {
        updatedCardStatus: {
          cardUpdateStatus: cardIsLocked
            ? CARD_UPDATE_STATUSES.ACTIVE
            : CARD_UPDATE_STATUSES.BLOCKED,
        },
      },
    })
  }

  const headerTitle = cardIsLocked ? 'Unlock Your Card' : 'Lock Your Card'
  const lockStatusIndicatorText = cardIsLocked ? 'Locked' : 'Unlocked'

  return last4 ? (
    <>
      <h2 data-cy='lock-unlock-tab-title'>{headerTitle}</h2>
      <LoadingContainer error={error} errorMessage='Error loading card.' loading={loading}>
        <div className={styling.account}>
          <p className={styling['card-title']}>
            Debit Card (...{last4}) {cardIsLocked && <LockedIcon />}
          </p>
          <table className={classNames('simple-table', [styling['lock-table']])}>
            <tbody>
              <tr>
                <th scope='row'>
                  <span id='status-indicator'>{lockStatusIndicatorText}</span>
                </th>
                <td className={styling['checkbox-column']}>
                  <Loading
                    className={classNames('row-loader', { invisible: !updatingCardStatus })}
                  />
                  <FormCheckbox
                    className={styling['lock-checkbox']}
                    name='toggle'
                    id='lock-toggle'
                    type='checkbox'
                    toggle
                    onChange={toggleLock}
                    checked={cardIsLocked}
                    disabled={updatingCardStatus}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </LoadingContainer>
    </>
  ) : (
    <div className={styling['lock-cant-find']}>
      <h6>Can’t find your card?</h6>
      <p>
        Lock your card to instantly block new purchases and ATM withdrawals.
        <br />
        If you think your card was stolen or the card information was put at risk, please request a
        new card or{' '}
        <Link className='underlined-link bold' to={staticRoutes.settingsHelp.pathname}>
          Contact Us
        </Link>{' '}
        immediately.
      </p>
    </div>
  )
}

LockCard.propTypes = {
  /**
   *  Last 4 digits of the card number
   */
  last4: PropTypes.string,

  /**
   * Locked Card status
   */
  cardIsLocked: PropTypes.bool,

  /**
   * Loading status
   */
  loading: PropTypes.bool,

  /**
   * Load Error status or error object
   */
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),

  /**
   * Function to call to update the card status
   */
  updateCardStatus: PropTypes.func.isRequired,

  /**
   * Whether or not card status is updating
   */
  updatingCardStatus: PropTypes.bool,
}

export default LockCard
