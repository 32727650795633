import { CTA_TYPES } from '@common/constants'

export const getDisplayAddress = ({
  buildingNumber,
  deliveryAddress,
  locality,
  subdivisions = [],
  postalCode,
} = {}) => {
  const _buildingNumber = buildingNumber ? ` ${buildingNumber}` : ''
  const _deliveryAddress = deliveryAddress ? `${deliveryAddress}` : ''
  const _locality = locality ? `${locality}, ` : ''
  const _subdivisions = subdivisions?.length > 0 ? `${subdivisions.join(', ')}, ` : ''
  const _postalCode = postalCode || ''

  const displayAddress = `${_deliveryAddress}${_buildingNumber}, ${_locality}${_subdivisions}${_postalCode}`

  // Clean up the trailing comma if there is one
  return displayAddress.replace(/, $/, '')
}

export const getDisplayName = ({ firstName = '', lastName = '' } = {}) => {
  return `${firstName} ${lastName}`.trim()
}

export const isCompleteCheckpoint = ({ checkpoints, checkpoint = '' } = {}) => {
  if (checkpoints?.values) {
    for (const value of checkpoints.values) {
      if (value === checkpoint) {
        return true
      }
    }
  }
  return false
}

/**
 * Returns array of products the customer is subscribed
 */
export const getCustomerProductSubscriptions = (products = []) => {
  // Get the human readable substring of product name
  const getProductDisplayName = str => {
    // Get the substring before an underscore if exists
    const strippedName = str.indexOf('_') >= 0 ? str.substring(0, str.indexOf('_')) : str

    let displayName = `${strippedName[0]}${strippedName.substring(1).toLowerCase()}`
    if (displayName.toLowerCase() === 'basic') {
      displayName = 'Standard'
    }

    return displayName
  }

  // Filter active products depending on boolean value
  //   Add display name for convenience
  return (
    products
      ?.filter(product => product.alreadyAvailableToCustomer)
      ?.map(p => ({ displayName: getProductDisplayName(p.productType), ...p })) || []
  )
}

/**
 * Returns product data for requested product subscription type or null
 */
export const getProductByType = ({ products = [], productType = '' }) => {
  return products?.find(product => product.productType === productType) || null
}

/**
 * Returns a flag to indicate whether customer has requested account type
 */
export const hasAccountType = ({ accounts = [], accountType = '' }) => {
  return accounts?.some(account => account.accountType === accountType) || false
}

/**
 * Returns the account if the customer has an account that includes the requested account title
 */
export const getAccountByTitle = ({ accounts = [], accountTitle = '' }) => {
  return accounts?.find(account => account.title.toLowerCase().includes(accountTitle.toLowerCase()))
}

/**
 * Returns a flag to indicate that the specified CTA should be displayed
 */
export const showCta = ({ ctas = [], ctaType = '' }) => {
  return ctas?.some(cta => cta.callToActionType === ctaType) || false
}

/**
 * Determines if any of the given CTAs is the SELECT_DDA_TYPE CTA
 */
export const hasSelectDdaTypeCta = (ctas, ctaType = CTA_TYPES.SELECT_DDA_TYPE) =>
  !!ctas?.find(cta => cta.callToActionType === ctaType) || false

export const hasUpgradeToPremiumAccountTypeCta = ctas =>
  !!ctas?.find(cta => cta.callToActionType === CTA_TYPES.UPGRADE_TO_PREMIUM_ACCOUNT) || false

export const hasUpgradeDdaAccountCta = ctas =>
  !!ctas?.find(cta => cta.callToActionType === CTA_TYPES.UPGRADE_DDA_ACCOUNT) || false
