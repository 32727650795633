import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import MaskedInput from 'react-text-mask'
import * as Yup from 'yup'
import { sub } from 'date-fns'
import { useDispatch } from 'react-redux'

import Button from '@shared/components/button/Button'
import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'
import FormInput from '@shared/components/formInput/FormInput'

import { shortMonthDayYearToIso8601Date } from '@shared/utils'
import {
  SEGMENT_EVENTS,
  SEGMENT_PAGE_NAMES,
  trackPage,
  trackEvent,
  useCheckpointNavigation,
  SEGMENT_SOURCE_DETAILS,
  parseDateString,
} from '@common/utils'
import ReduxField from '@common/components/reduxField/ReduxField'
import { removeAlertsAction } from '@redux/alerts/alertsActions'

const todaysDate = new Date()
const maxBirthDate = sub(todaysDate, { years: 18 })
const birthdayValidationErrorMessage = 'You must be at least 18 years of age.'

const birthdateValidationSchema = Yup.object().shape({
  birthDate: Yup.date()
    .transform(parseDateString)
    .typeError('Invalid Date')
    .max(maxBirthDate, birthdayValidationErrorMessage)
    .required('Required'),
})

const fullValidatorForSchema = (schema, handleError) => async values => {
  return await schema
    .validate(values, { abortEarly: false })
    .then(() => ({}))
    .catch(async validationError => {
      const errors = {
        birthDate: validationError.message,
      }

      await handleError(validationError.message)

      return errors
    })
}

const CreateAccountBirthday = ({ updateApplication, data }) => {
  const [focusedInputSourceDetail, setFocusedInputSourceDetail] = useState(null)
  const [isUnderageEventTracked, setIsUnderageEventTracked] = useState(false)
  const dispatch = useDispatch()

  const { proceedToNextCheckpoint } = useCheckpointNavigation({
    checkpoints: data?.checkpoints,
    applicationStatus: data?.application?.customerApplicationStatus,
  })

  // Track birthday registration page visits
  useEffect(() => {
    trackPage({ name: SEGMENT_PAGE_NAMES.REGISTRATION_BIRTHDAY })
  }, [])

  // Checks if the underage error occurrs. If so, track the event
  const reportUnderageEvent = message => {
    if (!isUnderageEventTracked && message === birthdayValidationErrorMessage) {
      trackEvent({ event: SEGMENT_EVENTS.USER_DISQUALIFIED })
      setIsUnderageEventTracked(true)
    }
  }

  const handleSubmit = async (values, actions) => {
    /* If there is still a focused input, make sure to track the event since onBlur is not executed
       when the form submits */
    dispatch(removeAlertsAction())

    if (focusedInputSourceDetail) {
      trackEvent({
        event: SEGMENT_EVENTS.registrationFormFieldEntry({
          sourceDetail: focusedInputSourceDetail,
        }),
      })

      setFocusedInputSourceDetail(null)
    }

    const response = await updateApplication({
      birthDate: { value: shortMonthDayYearToIso8601Date(values?.birthDate) },
    })
    if (response) {
      proceedToNextCheckpoint()
    }

    actions.setSubmitting(false)
  }

  return (
    <div className='white-card-container' data-cy='authenticated-loaded-view'>
      <GreenwoodLogo className='logo' />
      <h1>Date of birth</h1>
      <p>This should match the date on your ID. Must be 18 years old or older.</p>
      <Formik
        initialValues={{
          birthDate: '',
        }}
        onSubmit={handleSubmit}
        validate={fullValidatorForSchema(birthdateValidationSchema, reportUnderageEvent)}
      >
        {({ errors, handleBlur, handleChange, isSubmitting, touched, values }) => (
          <Form data-cy='birth-date-form'>
            <MaskedInput
              disabled={isSubmitting}
              mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
              onChange={handleChange}
              onFocus={() => {
                setFocusedInputSourceDetail(SEGMENT_SOURCE_DETAILS.BIRTHDAY)
              }}
              onBlur={params => {
                handleBlur(params)

                // Track any time the user leaves the birthday field during registration
                trackEvent({
                  event: SEGMENT_EVENTS.registrationFormFieldEntry({
                    sourceDetail: SEGMENT_SOURCE_DETAILS.BIRTHDAY,
                  }),
                })

                setFocusedInputSourceDetail(null)
              }}
              placeholder='MM/DD/YYYY'
              autoFocus
              value={values.birthDate}
              data-cy='create-account-birthday-birthdate-input'
              render={(ref, props) => (
                <ReduxField
                  as={FormInput}
                  name='birthDate'
                  type='text'
                  label='Date of Birth'
                  autoComplete='bday'
                  required
                  showRequiredAsterisk={false}
                  invalid={errors.birthDate && touched.birthDate}
                  errorText={errors.birthDate}
                  disabled={isSubmitting}
                  inputMode='numeric'
                  innerRef={ref}
                  {...props}
                  defaultValue={undefined}
                />
              )}
            />
            <Button type='submit' isLoading={isSubmitting}>
              NEXT
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  )
}

CreateAccountBirthday.propTypes = {
  /**
   * Function used to update the application object
   */
  updateApplication: PropTypes.func,

  /**
   * Error data
   */
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),

  /**
   * The form data
   */
  data: PropTypes.object,

  /**
   * Loading indicator when the data is initially fetched
   */
  loading: PropTypes.bool,
}

export default CreateAccountBirthday
