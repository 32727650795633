import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import classNames from 'classnames'
import { Formik } from 'formik'
import { useDispatch } from 'react-redux'
import { useFlags } from 'launchdarkly-react-client-sdk'

import LoadingContainer from '@shared/components/loadingContainer/LoadingContainer'
import NewUserForm from './forms/NewUserForm'
import Button from '@shared/components/button/Button'

import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'

import {
  FUNDING_FLOWS,
  PRODUCT_TYPES,
  MEMBERSHIP_CARD_TYPES,
  PRODUCT_DETAILS,
  CTA_TYPES,
} from '@common/constants'
import { staticRoutes } from '@routing/routes'
import { gql, useQuery, useLazyQuery, useMutation } from '@services/serviceUtils'
import usePreventBrowserBackClick from '@common/utils/usePreventBrowserBackClick'
import useAccountApprovedTracking from '@common/utils/useAccountApprovedTracking'
import {
  hasSelectDdaTypeCta,
  hasUpgradeDdaAccountCta,
  SEGMENT_PAGE_NAMES,
  SEGMENT_EVENTS,
  trackPage,
  trackEvent,
} from '@common/utils'
import {
  ALERT_TYPES,
  DEFAULT_ALERT_DISMISS_DELAY,
} from '@shared/constants/uiConstants'
import { addAlertAction } from '@redux/alerts/alertsActions'
import { setCustomerAction } from '@redux/customer/customerActions'

import styling from './createAccountSelectTier.module.scss'

const productsQuery = gql`
  query Products {
    products {
      id
      productType
      benefits {
        benefits
        category
      }
      agreements {
        id
        url
        title
      }
      fee {
        amount {
          amount
        }
        frequency
      }
      feeOptions {
        amount {
          amount
        }
        frequency
        key
      }
    }
    ctas {
      callToActionType
    }
  }
`

const accountSummaryQuery = gql`
  query AccountSummary {
    accountSummary {
      accountType
    }
  }
`

const subscriptionsQuery = gql`
  query Subscriptions {
    subscriptions {
      id
    }
  }
`

const paymentMethodForSubscriptionQuery = gql`
  query PaymentMethodForSubscription($subId: ID!) {
    paymentMethodForSubscription(subId: $subId) {
      id
    }
  }
`

const openDdaMutation = gql`
  mutation OpenDDA($openDDARequestDTOInput: OpenDDARequestDTOInput!) {
    openDDA(openDDARequestDTOInput: $openDDARequestDTOInput) {
      id
      paymentDetails {
        paymentSessionId
        paymentSessionType
      }
    }
  }
`

const upgradeDdaMutation = gql`
  mutation UpgradeDDA($upgradeDDARequestDTOInput: UpgradeDDARequestDTOInput!) {
    upgradeDDA(upgradeDDARequestDTOInput: $upgradeDDARequestDTOInput) {
      id
      paymentDetails {
        paymentSessionId
        paymentSessionType
      }
    }
  }
`

const premiumProducts = [PRODUCT_TYPES.premium, PRODUCT_TYPES.premiumPlus]

const CreateAccountSelectTier = () => {
  const [product, setProduct] = useState({})
  const [subText, setSubText] = useState()
  const [isMonthlyTerm, setIsMonthlyTerm] = useState(true)
  const [fee, setFee] = useState()
  const [cardType, setCardType] = useState()
  const [cardImage, setCardImage] = useState(undefined)

  const dispatch = useDispatch()

  const navigate = useNavigate()
  const { state = {} } = useLocation()
  const { isUpgrading = false, fundingFlow = '', shouldSetupDirectDeposit = false } = state || {}

  usePreventBrowserBackClick(isUpgrading)
  useAccountApprovedTracking({ fundingFlow, shouldSetupDirectDeposit })

  const { webOnboardingPremiumProductOnly } = useFlags()

  useEffect(() => {
    trackPage({ name: SEGMENT_PAGE_NAMES.SELECT_MEMBERSHIP_TYPE })
  }, [])

  const { data: productsData, loading: productsLoading, error: productsError } = useQuery(
    productsQuery,
    {
      fetchPolicy: 'no-cache',
    }
  )

  const ctaTypes = useMemo(() => productsData?.ctas?.map(cta => cta?.callToActionType), [
    productsData?.ctas,
  ])

  const [
    getCustomerAccountType,
    {
      data: accountSummaryData,
      loading: accountSummaryLoading,
      error: accountSummaryError,
      refetch: refetchCustomerAccountType,
    },
  ] = useLazyQuery(accountSummaryQuery, {
    fetchPolicy: 'no-cache',
  })
  const [submitOpenDda, { loading: openDdaLoading, error: submitOpenDdaError }] = useMutation(
    openDdaMutation
  )
  const { data: subscriptionsData } = useQuery(subscriptionsQuery, { fetchPolicy: 'no-cache' })
  const subscriptionId = useMemo(() => subscriptionsData?.subscriptions[0]?.id, [
    subscriptionsData?.subscriptions,
  ])

  const [getPaymentMethod, { data: paymentMethodData }] = useLazyQuery(
    paymentMethodForSubscriptionQuery,
    {
      variables: { subId: subscriptionId },
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
    }
  )
  const [submitUpgradeDda, { loading: upgradeDdaLoading, error: upgradeDdaError }] = useMutation(
    upgradeDdaMutation
  )

  useEffect(() => {
    if (isUpgrading) {
      getCustomerAccountType()

      if (subscriptionId) {
        getPaymentMethod()
      }
    }
  }, [
    isUpgrading,
    getCustomerAccountType,
    subscriptionId,
    getPaymentMethod,
  ])

  const productType = useMemo(() => product?.productType, [product])

  const productsToRemove = useMemo(() => {
    const products = [
      PRODUCT_TYPES.savings,
      PRODUCT_TYPES.premium,
      PRODUCT_TYPES.elevateCard,
      PRODUCT_TYPES.basic,
    ]

    if ((isUpgrading || webOnboardingPremiumProductOnly) && !products.includes(PRODUCT_TYPES.basic)) {
      products.push(PRODUCT_TYPES.basic)
    }

    return products
  }, [
    isUpgrading,
    webOnboardingPremiumProductOnly,
  ])

  const productList = useMemo(() => {
    return productsData?.products.filter(
      product => !productsToRemove.includes(product?.productType)
    )
  }, [
    productsData?.products,
    productsToRemove,
  ])

  const termPeriod = useMemo(() => (
    isMonthlyTerm ? 'MONTHLY' : 'YEARLY'
  ), [isMonthlyTerm])

  const feeOptions = useMemo(() => {
    const options = product?.feeOptions

    if (options?.length) {
      setIsMonthlyTerm(options[0]?.key?.includes('MONTHLY'))
    }

    return options
  }, [product?.feeOptions])

  const feeKey = useMemo(() => productType?.replace('DDA', termPeriod), [productType, termPeriod])

  useEffect(() => {
    trackEvent({
      event: SEGMENT_EVENTS.changeMembershipTypeButtonClicked({
        sourceDetail: MEMBERSHIP_CARD_TYPES[productType],
      }),
    })
  }, [productType])

  useEffect(() => {
    const hasPremiumProduct = productList?.find(
      product => product.productType === PRODUCT_TYPES.premiumPlus
    )

    if (productList?.length) {
      if (hasPremiumProduct && isUpgrading) {
        setProduct(productList.find(product => product.productType === PRODUCT_TYPES.premiumPlus))
      } else {
        setProduct(productList[0])
      }
    }
  }, [productList, isUpgrading])

  useEffect(() => {
    if (productType) {
      setCardType(PRODUCT_DETAILS[productType]?.cardHeader || '')

      if (fee) {
        setSubText(`${fee ? `Just ${fee} after your 30-day free trial` : ''}`)
      } else {
        setSubText('Access great benefits at no membership cost')
      }

      const newCardImage = PRODUCT_DETAILS[productType]?.cardImage
      setCardImage(newCardImage || undefined)
    }
  },
  [
    productType,
    isUpgrading,
    fee,
  ])

  useEffect(() => {
    const feeInfo = feeOptions?.find(fee => fee.key.includes(termPeriod))

    // if fee info exists, set it in state so that it changes on the UI
    // otherwise, set it to null
    if (feeInfo) {
      const newFee = `$${feeInfo?.amount?.amount.toLocaleString()}${feeInfo?.frequency}`
      setFee(newFee)
    } else {
      setFee(null)
    }
  }, [feeOptions, termPeriod])

  const canSelectProduct = useMemo(
    () =>
      ctaTypes?.includes(CTA_TYPES.SELECT_DDA_TYPE) ||
      (ctaTypes?.includes(CTA_TYPES.UPGRADE_DDA_ACCOUNT) && accountSummaryData?.accountSummary?.accountType === PRODUCT_TYPES.basic),
    [ctaTypes, accountSummaryData?.accountSummary?.accountType]
  )

  const isBasic = useMemo(() => productType === PRODUCT_TYPES.basic, [productType])

  const error =
  !!productsError || !!submitOpenDdaError || !!upgradeDdaError || !!accountSummaryError
  const errorMessage = useMemo(() => {
    let message = ''
    if (productsError) {
      message = 'Error loading product information'
    } else if (submitOpenDdaError) {
      message = 'Error setting up DDA account'
    } else if (upgradeDdaError) {
      message = 'Error upgrading DDA account'
    } else if (accountSummaryError) {
      message = 'Error loading account details'
    }
    return message
  }, [productsError, submitOpenDdaError, upgradeDdaError, accountSummaryError])

  const getUpdatedLocation = () => (
    <Navigate to={staticRoutes.dashboard.pathname} replace={true} />
  )

  const getSubmitButton = useCallback(({ text, isSubmitting }) => (
    <Button
      type='submit'
      isLoading={openDdaLoading || upgradeDdaLoading || isSubmitting}
      className='additional-button'
    >
      {text}
    </Button>
  ), [openDdaLoading, upgradeDdaLoading])

  const navigateToPaymentPage = pageState => {
    navigate(staticRoutes.membershipAddPaymentMethod.pathname, { state: { ...pageState } })
  }

  const navigateToSuccessPage = () => {
    navigate(staticRoutes.membershipUpgradeConfirmation.pathname, {
      state: {
        fundingFlow,
        shouldSetupDirectDeposit: !isUpgrading,
        productType,
        isUpgrading,
        cancelUpgradePathname: state?.cancelUpgradePathname,
      },
    })
  }

  const displaySubmissionErrorAndReroute = useCallback(() => {
    dispatch(
      addAlertAction({
        dismissible: false,
        autoDismissDelay: DEFAULT_ALERT_DISMISS_DELAY,
        type: ALERT_TYPES.SUCCESS,
        text: 'Your previous selection has already been saved successfully',
      })
    )

    navigate(staticRoutes.dashboard.pathname)
  }, [dispatch, navigate])

  const handleBasicProductClick = async () => {
    /* Check if the user is allowed to open a DDA; it's possible the user arrived here via the
       browser's back button navigation */
    const { agreements, id: productId } = product

    const result = await submitOpenDda({
      variables: {
        openDDARequestDTOInput: {
          agreementIds: agreements?.map(agreement => agreement.id),
          productId,
        },
      },
    })

    // On successful basic DDA submission, navigate to basic card inbound screen
    if (result?.data?.openDDA?.id) {
      navigate(staticRoutes.basicCardInbound.pathname, {
        state: {
          fundingFlow: FUNDING_FLOWS.BASIC,
          shouldSetupDirectDeposit: state?.shouldSetupDirectDeposit,
        },
      })
    }
  }

  const handleSubmit = async () => {
    trackEvent({ event: SEGMENT_EVENTS.SELECT_MEMBERSHIP_TYPE_CONTINUE_BUTTON_CLICK })

    const ddaRequestInput = {
      agreementIds: product?.agreements?.map(agreement => agreement.id),
      productId: product?.id,
      feeKey,
    }

    if (hasSelectDdaTypeCta(productsData?.ctas)) {
      if (isBasic) {
        handleBasicProductClick()
      } else {
        const flow =
          productType === PRODUCT_TYPES.elevateCard
            ? FUNDING_FLOWS.ONBOARDING_ELEVATED_CARD
            : FUNDING_FLOWS.ONBOARDING_PREMIUM
        const fundingFlow = state?.fundingFlow || flow

        const result = await submitOpenDda({
          variables: { openDDARequestDTOInput: ddaRequestInput },
        })

        const { paymentDetails, id } = result?.data?.openDDA || {}

        if (paymentDetails) {
          navigateToPaymentPage({
            fundingFlow,
            shouldSetupDirectDeposit: true,
            paymentDetails,
            product,
            feeKey,
            isOnboarding: true,
          })
        } else if (id) {
          navigateToSuccessPage()
        }
      }
    } else if (hasUpgradeDdaAccountCta(productsData?.ctas)) {
      const pageState = {
        product,
        feeKey,
        isUpgrading,
        fundingFlow: state?.fundingFlow,
        subscriptionId,
        cancelUpgradePathname: state?.cancelUpgradePathname,
      }

      // Call upgrade here for users without payment information on file
      if (!paymentMethodData) {
        const result = await submitUpgradeDda({
          variables: { upgradeDDARequestDTOInput: ddaRequestInput },
        })

        const { paymentDetails = null, id = '' } = result?.data?.upgradeDDA

        if (paymentDetails) {
          pageState.paymentDetails = paymentDetails

          navigateToPaymentPage(pageState)
        } else if (id) {
          const { data } = await refetchCustomerAccountType()

          if (data?.accountSummary?.accountType) {
            if (premiumProducts.includes(data.accountSummary.accountType)) {
              dispatch(setCustomerAction({ hasPremium: true }))
            } else if (data.accountSummary.accountType === PRODUCT_TYPES.elevateCard) {
              dispatch(setCustomerAction({ hasElevate: true }))
            }
          }

          navigateToSuccessPage()
        }
      } else {
        // Otherwise notify the user that they already selected a DDA type
        displaySubmissionErrorAndReroute()
      }
    }
  }

  const containerClasses = classNames(
    'create-account-black-page',
    styling['create-account-select-tier-container'],
    styling['v2-select-tier-container']
  )

  return (
    <>
      <div className={containerClasses}>
        <GreenwoodLogo className='logo' />
        <LoadingContainer
          loading={productsLoading || accountSummaryLoading}
          error={error}
          errorMessage={errorMessage}
        >
          {!canSelectProduct && getUpdatedLocation()}
          <Formik
            initialValues={{
              isMonthlyTerm,
            }}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => {
              return (
                <NewUserForm
                  subText={subText}
                  isSubmitting={isSubmitting}
                  product={product}
                  getSubmitButton={getSubmitButton}
                  cardType={cardType}
                  isMonthlyTerm={isMonthlyTerm}
                  cardImage={cardImage}
                  hasFee={!!fee}
                />
              )
            }}
          </Formik>
        </LoadingContainer>
      </div>
    </>
  )
}

export default CreateAccountSelectTier
