import React from 'react'
import classNames from 'classnames'

import { ReactComponent as Chevron } from '@shared/images/icons/chevron.svg'

import ExternalLink from '@common/components/link/ExternalLink'

import {
  getBootstrapSitemapResource,
  BOOTSTRAP_SITEMAP_RESOURCES,
  getBootstrapValueByKey,
  BOOTSTRAP_KEYS,
  SEGMENT_EVENTS,
  trackEvent,
  SEGMENT_SOURCE_DETAILS,
} from '@common/utils'

import styling from './helpSupportTable.module.scss'

const HelpSupportTable = ({ className, segmentSource }) => {
  const faq = getBootstrapSitemapResource(BOOTSTRAP_SITEMAP_RESOURCES.FAQ)
  const contactOptions = getBootstrapValueByKey(BOOTSTRAP_KEYS.SUPPORT)?.contact

  const helpSupportTableClasses = classNames('simple-table', styling['support-table'], className)

  return (
    <table className={helpSupportTableClasses}>
      <tbody>
        <tr
          className='link-row'
          onClick={() => {
            if (segmentSource) {
              trackEvent({
                event: SEGMENT_EVENTS.HELP_SUPPORT_BUTTON_CLICK({
                  source: segmentSource,
                  sourceDetail: SEGMENT_SOURCE_DETAILS.FAQS,
                }),
              })
            }
            window.open(faq.url)
          }}
          data-cy='faq-link'
        >
          <th scope='row' colSpan={2}>
            <span className={styling['faq-title']}>{faq.title.value}</span>
          </th>
          <td className='row-chevron'>
            <Chevron />
          </td>
        </tr>
        {contactOptions.map(contactOption => {
          let displayValue = contactOption?.valueTwo
          if (contactOption?.method === 'email') {
            displayValue = (
              <ExternalLink
                to={`mailto:${contactOption?.value}`}
                clickListener={() => {
                  if (segmentSource) {
                    trackEvent({
                      event: SEGMENT_EVENTS.HELP_SUPPORT_BUTTON_CLICK({
                        source: segmentSource,
                        sourceDetail: SEGMENT_SOURCE_DETAILS.EMAIL_US,
                      }),
                    })
                  }
                }}
              >
                {contactOption?.value}
              </ExternalLink>
            )
          } else if (contactOption?.method === 'phone') {
            displayValue = (
              <a
                href={`tel:${contactOption?.value}`}
                onClick={() => {
                  if (segmentSource) {
                    trackEvent({
                      event: SEGMENT_EVENTS.HELP_SUPPORT_BUTTON_CLICK({
                        source: segmentSource,
                        sourceDetail: SEGMENT_SOURCE_DETAILS.CALL_US,
                      }),
                    })
                  }
                }}
              >
                {contactOption?.value}
              </a>
            )
          }

          return (
            <tr
              key={contactOption?.value}
              data-cy={`contact-${contactOption?.method}`}
              className={styling['contact-option']}
            >
              <th scope='row' colSpan={2}>
                <span>{contactOption?.title?.find(item => item.lang === 'en-us')?.value}</span>
              </th>
              <td>{displayValue}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default HelpSupportTable
