const ROUTE_AUTH_REQUIREMENTS = {
  NONE: 'NONE',
  AUTHENTICATED: 'AUTHENTICATED',
  NOT_AUTHENTICATED: 'NOT_AUTHENTICATED',
}

/**
 * A set of non-parameterized routes. Each property value is a string.
 * Property definitions:
 *
 *   - requiresAuth: When true, the user must be logged in to be able
 *     to access this route. When false, there must not be a logged
 *     in user to be able to access this route. When not defined, the
 *     auth check is not performed. Used by ProtectedRoute.
 *
 *   - title: Used by RootContainer to set the page title.
 *
 *   - showHeaderFooter: Used by ViewContainer and RootContainer to
 *     show or hide the app header and footer.
 *
 *   - useWhiteFooterBg: Used by RootContainer to toggle the footer
 *     background color.
 */
const staticRoutes = {
  /* Dashboard and splash screen */

  dashboard: {
    pathname: '/',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    title: 'Dashboard',
  },

  splash: {
    pathname: '/',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.NOT_AUTHENTICATED,
    showHeaderFooter: false,
    title: 'Home',
  },

  elevate: {
    pathname: '/elevate',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.NONE,
    showHeaderFooter: false,
    title: 'Home',
  },

  elevateOnly: {
    pathname: '/elevate-only',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.NONE,
    showHeaderFooter: false,
    title: 'Home',
  },

  elevatePremiumOnly: {
    pathname: '/elevate-premium',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.NONE,
    showHeaderFooter: false,
    title: 'Home',
  },

  premiumOnly: {
    pathname: '/premium-only',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.NONE,
    showHeaderFooter: false,
    title: 'Home',
  },

  /* Authentication and security */

  signIn: {
    pathname: '/sign-in',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.NONE,
    showHeaderFooter: false,
    title: 'Sign in',
  },

  signOut: {
    pathname: '/sign-out',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.NONE,
    showHeaderFooter: false,
    title: 'Sign out',
  },

  recoverProvideEmail: {
    pathname: '/provide-email',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.NONE,
    showHeaderFooter: false,
    title: 'Provide email',
  },

  recoverProvidePassword: {
    pathname: '/forgot-password',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.NONE,
    showHeaderFooter: false,
    title: 'Forgot password',
  },

  coreExchangeSignIn: {
    pathname: '/ce-sign-in',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.NONE,
    showHeaderFooter: false,
    title: 'Core Exchange sign in',
  },

  /* MFA */

  chooseVerificationMethod: {
    pathname: '/choose-verification-method',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.NONE,
    showHeaderFooter: false,
    title: 'Choose verification method',
  },

  submitVerificationCode: {
    pathname: '/submit-verification-code',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.NONE,
    showHeaderFooter: false,
    title: 'Submit verification code',
  },

  /* Sign up and auth (shared, depending on state) */

  providePhone: {
    pathname: '/provide-phone',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.NONE,
    showHeaderFooter: false,
    title: 'Provide phone',
  },

  verifyEmail: {
    pathname: '/verify-email',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.NONE,
    showHeaderFooter: false,
    title: 'Verify email',
  },

  verifyPhone: {
    pathname: '/verify-phone',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.NONE,
    showHeaderFooter: false,
    title: 'Verify phone',
  },

  /* Sign up */

  signUp: {
    pathname: '/sign-up/*',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.NOT_AUTHENTICATED,
    showHeaderFooter: false,
    title: 'Sign up',
  },

  elevateSignUp: {
    pathname: '/elevate/sign-up/*',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.NOT_AUTHENTICATED,
    showHeaderFooter: false,
    title: 'Sign up',
  },

  elevateOnlySignUp: {
    pathname: '/elevate-only/sign-up/*',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.NOT_AUTHENTICATED,
    showHeaderFooter: false,
    title: 'Sign up',
  },

  elevatePremiumOnlySignUp: {
    pathname: '/elevate-premium/sign-up/*',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.NOT_AUTHENTICATED,
    showHeaderFooter: false,
    title: 'Sign up',
  },

  premiumOnlySignUp: {
    pathname: '/premium-only/sign-up/*',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.NOT_AUTHENTICATED,
    showHeaderFooter: false,
    title: 'Sign up',
  },

  signUpEmail: {
    pathname: '/sign-up/email',
    relativePathname: 'email',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.NOT_AUTHENTICATED,
    showHeaderFooter: false,
    title: 'Provide email',
  },

  signUpPassword: {
    pathname: '/sign-up/password',
    relativePathname: 'password',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.NOT_AUTHENTICATED,
    showHeaderFooter: false,
    title: 'Create password',
  },
  // here
  addressResubmission: {
    pathname: '/address-resubmission/*',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    showHeaderFooter: false,
    title: 'Address resubmission',
  },

  addressResubmissionEntry: {
    pathname: '/address-resubmission/entry',
    relativePathname: 'entry',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    showHeaderFooter: false,
    title: 'Address resubmission entry',
  },

  addressResubmissionReview: {
    pathname: '/address-resubmission/review',
    relativePathname: 'review',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    showHeaderFooter: false,
    title: 'Address resubmission review',
  },

  /* Bank account creation */

  createAccount: {
    pathname: '/create-account/*',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    showHeaderFooter: false,
    title: 'Create account',
  },

  createAccountName: {
    pathname: '/create-account/name',
    relativePathname: 'name',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    showHeaderFooter: false,
    title: 'Provide name',
  },

  createAccountBirthday: {
    pathname: '/create-account/birthday',
    relativePathname: 'birthday',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    showHeaderFooter: false,
    title: 'Provide birthday',
  },

  createAccountSSN: {
    pathname: '/create-account/ssn',
    relativePathname: 'ssn',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    showHeaderFooter: false,
    title: 'Provide SSN',
  },

  createAccountAddress: {
    pathname: '/create-account/address',
    relativePathname: 'address',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    showHeaderFooter: false,
    title: 'Provide address',
  },

  createAccountAgreements: {
    pathname: '/create-account/agreements',
    relativePathname: 'agreements',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    showHeaderFooter: false,
    title: 'Agreements',
  },

  createAccountReview: {
    pathname: '/create-account/review',
    relativePathname: 'review',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    showHeaderFooter: false,
    title: 'Review information',
  },

  createAccountProcessing: {
    pathname: '/create-account/processing',
    relativePathname: 'processing',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    showHeaderFooter: false,
    title: 'Application processing',
  },

  // createAccountApproved: {
  //   pathname: '/create-account/approved',
  //   requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
  //   showHeaderFooter: false,
  //   title: 'Application approved',
  // },

  createAccountInReview: {
    pathname: '/create-account/in-review',
    relativePathname: 'in-review',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    showHeaderFooter: false,
    title: 'Application in review',
  },

  documentSubmit: {
    pathname: '/document-submit',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    showHeaderFooter: false,
    title: 'Document submit',
  },

  /* Bank account info */

  myAccount: {
    pathname: '/my-account',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    title: 'My account',
  },

  myCard: {
    pathname: '/my-card/*',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    title: 'My card',
  },

  myCardReportCard: {
    pathname: '/my-card/report-card',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    title: 'Report card',
  },

  myCardLockCard: {
    pathname: '/my-card/lock-card',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    title: 'Lock card',
  },

  cardActivated: {
    pathname: '/card-activated',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    showHeaderFooter: false,
    title: 'Card activated',
  },

  selectAccountTier: {
    pathname: '/select-tier',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    showHeaderFooter: false,
    title: 'Select account tier',
  },

  premiumAgreements: {
    pathname: '/premium-agreements',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    showHeaderFooter: false,
    title: 'Premium agreements',
  },

  membershipAddPaymentMethod: {
    pathname: '/membership-add-payment-method',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    showHeaderFooter: false,
    title: 'Membership add payment method',
  },

  membershipUpgradeConfirmation: {
    pathname: '/membership-upgrade-confirmation',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    showHeaderFooter: false,
    title: 'Membership upgrade confirmation',
  },

  /* Move Money */

  moveMoney: {
    pathname: '/move-money/*',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    useWhiteFooterBg: true,
    title: 'Move money',
  },

  moveMoneyAddMoney: {
    pathname: '/move-money/add-money',
    relativePathname: 'add-money',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    title: 'Add Money',
  },

  moveMoneySendMoney: {
    pathname: '/move-money/send-money',
    relativePathname: 'send-money',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    title: 'Send Money',
  },

  payBills: {
    pathname: '/pay-bills',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    showHeaderFooter: false,
    title: 'Pay bills',
  },

  directDepositSetup: {
    pathname: '/direct-deposit-setup',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    showHeaderFooter: false,
    title: 'Set up direct deposit',
  },

  directDepositSelection: {
    pathname: '/direct-deposit-selection',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    showHeaderFooter: false,
    title: 'Set up direct deposit',
  },

  directDepositFormSent: {
    pathname: '/direct-deposit-form-sent',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    showHeaderFooter: false,
    title: 'Direct deposit form sent',
  },

  directDepositFormSendFailed: {
    pathname: '/direct-deposit-form-send-failed',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    showHeaderFooter: false,
    title: 'Direct deposit form send failed',
  },

  /* Giving */

  startGiving: {
    pathname: '/start-giving',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    showHeaderFooter: false,
    useWhiteFooterBg: true,
    title: 'Start giving',
  },

  giving: {
    pathname: '/giving',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    useWhiteFooterBg: true,
    title: 'Giving',
  },

  /* Transfer */

  transfer: {
    pathname: '/transfer/*',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    title: 'Transfer',
    showHeaderFooter: false,
  },

  transferForm: {
    pathname: '/transfer/form',
    relativePathname: 'form',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    title: 'Transfer',
    showHeaderFooter: false,
  },

  transferReview: {
    pathname: '/transfer/review',
    relativePathname: 'review',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    title: 'Transfer review',
    showHeaderFooter: false,
  },

  transferSuccess: {
    pathname: '/transfer/success',
    relativePathname: 'success',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    title: 'Transfer success',
    showHeaderFooter: false,
  },

  transferProcessing: {
    pathname: '/transfer-processing',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    title: 'Transfer processing',
    showHeaderFooter: false,
  },

  transferFailed: {
    pathname: '/transfer-failed',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    title: 'Transfer failed',
    showHeaderFooter: false,
  },

  wireTransfer: {
    pathname: '/wire-transfer',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    title: 'Wire Transfer',
    showHeaderFooter: false,
  },

  wireTransferSuccess: {
    pathname: '/wire-transfer-success',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    title: 'Wire transfer success ',
    showHeaderFooter: false,
  },

  wireTransferFormSendFailed: {
    pathname: '/wire-transfer-form-send-failed',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    showHeaderFooter: false,
    title: 'Wire Transfer form send failed',
  },

  /* Debit Card Deposit */

  debitCardDeposit: {
    pathname: '/debit-card-deposit/*',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    title: 'Debit card deposit',
    showHeaderFooter: false,
  },

  debitCardDepositForm: {
    pathname: '/debit-card-deposit/form',
    relativePathname: 'form',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    title: 'Debit card deposit',
    showHeaderFooter: false,
  },

  debitCardDepositReview: {
    pathname: '/debit-card-deposit/review',
    relativePathname: 'review',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    title: 'Debit card deposit review',
    showHeaderFooter: false,
  },

  debitCardDepositSuccess: {
    pathname: '/debit-card-deposit/success',
    relativePathname: 'success',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    title: 'Debit card deposit success',
    showHeaderFooter: false,
  },

  /* Fund Account */

  addMoney: {
    pathname: '/add-money',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    title: 'Add money',
    showHeaderFooter: false,
  },

  premiumPayment: {
    pathname: '/confirm-premium-payment',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    title: 'Confirm payment',
    showHeaderFooter: false,
  },

  accountsForFunding: {
    pathname: '/accounts-for-funding',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    title: 'Choose account',
    showHeaderFooter: false,
  },

  /* Card Inbound */

  premiumCardInbound: {
    pathname: '/premium-card-sent',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    title: 'Your premium card',
    showHeaderFooter: false,
  },

  basicCardInbound: {
    pathname: '/basic-card-sent',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    title: 'Your basic card',
    showHeaderFooter: false,
  },

  /* Create Savings Account */

  createSavingsAccount: {
    pathname: '/create-savings-account',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    showHeaderFooter: false,
    title: 'Create savings account',
  },

  createSavingsAccountAgreements: {
    pathname: '/create-savings-account-agreements',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    showHeaderFooter: false,
    title: 'Savings account agreements',
  },

  createSavingsAccountApproved: {
    pathname: '/create-savings-account-approved',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    showHeaderFooter: false,
    title: 'Savings account approved',
  },

  /* Membership */

  membership: {
    pathname: '/membership/*',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    useWhiteFooterBg: true,
    title: 'Membership',
  },
  membershipDetails: {
    pathname: '/membership/details',
    relativePathname: 'details',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    useWhiteFooterBg: true,
    title: 'Membership details',
  },
  membershipAboutMyMembership: {
    pathname: '/membership/about-my-membership',
    relativePathname: 'about-my-membership',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    useWhiteFooterBg: true,
    title: 'About my membership',
  },

  membershipChangeMyMembership: {
    pathname: '/membership/change-my-membership',
    relativePathname: 'change-my-membership',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    useWhiteFooterBg: true,
    title: 'Change my membership',
  },

  membershipPaymentMethod: {
    pathname: '/membership/payment-method',
    relativePathname: 'payment-method',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    useWhiteFooterBg: true,
    title: 'Payment method',
  },

  membershipAccount: {
    pathname: '/membership/account',
    relativePathname: 'account',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    useWhiteFooterBg: true,
    title: 'Account settings',
  },

  /* Settings */

  settings: {
    pathname: '/settings/*',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    useWhiteFooterBg: true,
    title: 'Settings',
  },

  settingsDocuments: {
    pathname: '/settings/documents',
    relativePathname: 'documents',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    useWhiteFooterBg: true,
    title: 'Statements',
  },

  settingsHelp: {
    pathname: '/settings/help-support',
    relativePathname: 'help-support',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    useWhiteFooterBg: true,
    title: 'Help & support',
  },

  settingsNotifications: {
    pathname: '/settings/notifications',
    relativePathname: 'notifications',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    useWhiteFooterBg: true,
    title: 'Notification settings',
  },

  settingsPrivacySecurity: {
    pathname: '/settings/privacy-security',
    relativePathname: 'privacy-security',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    useWhiteFooterBg: true,
    title: 'Security',
  },

  settingsPrivacySecurityVerificationCodes: {
    pathname: '/settings/privacy-security/verification-codes',
    relativePathname: 'verification-codes',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    useWhiteFooterBg: true,
    title: 'Verification codes',
  },

  settingsAgreementsDisclosures: {
    pathname: '/settings/agreements-disclosures',
    relativePathname: 'agreements-disclosures',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    useWhiteFooterBg: true,
    title: 'Agreements & disclosures',
  },
  settingsLinkedAccounts: {
    pathname: '/settings/linked-accounts',
    relativePathname: 'linked-accounts/*',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    useWhiteFooterBg: true,
    title: 'Linked accounts/cards',
  },

  settingsLinkedAccountsLinkedAccountDetails: {
    pathname: '/settings/linked-accounts/linked-account',
    relativePathname: 'linked-account',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    useWhiteFooterBg: true,
    title: 'Linked account details',
  },

  replacementCardSent: {
    pathname: '/replacement-card-sent',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    showHeaderFooter: false,
    title: 'Replacement card sent',
  },

  /* Add Debit Card */

  addDebitCard: {
    pathname: '/add-debit-card',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    showHeaderFooter: false,
    title: 'Add debit card',
  },

  /* Non-Banking Checkout */

  /* Account Editing */

  verifyPassword: {
    pathname: '/verify-password',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    showHeaderFooter: false,
    title: 'Verify password',
  },

  editPhoneNumber: {
    pathname: '/edit-phone-number',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    showHeaderFooter: false,
    title: 'Edit phone number',
  },

  editEmailAddress: {
    pathname: '/edit-email-address',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    showHeaderFooter: false,
    title: 'Edit email address',
  },

  /* Investing */

  invest: {
    pathname: '/invest',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    showHeaderFooter: false,
    title: 'Invest',
  },

  investDiscovery: {
    pathname: '/invest-discovery',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    showHeaderFooter: false,
    title: 'Invest discovery',
  },

  investPortfolio: {
    pathname: '/invest-portfolio',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    showHeaderFooter: false,
    title: 'Invest portfolio',
  },

  /* Community */

  community: {
    pathname: '/community/*',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    showHeaderFooter: true,
    title: 'Community',
  },

  communityInvest: {
    pathname: '/community/invest',
    relativePathname: 'invest',
    requiresAuth: ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED,
    showHeaderFooter: true,
    title: 'Community invest',
  },
}

/**
 * A set of parameterized routes. Each property value is a function
 * that returns a string
 */
const dynamicRoutes = {}

export { staticRoutes, dynamicRoutes, ROUTE_AUTH_REQUIREMENTS }
