import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { Elements } from '@stripe/react-stripe-js'

import Button from '@shared/components/button/Button'
import LoadingContainer from '@shared/components/loadingContainer/LoadingContainer'
import Modal from '@shared/components/modal/Modal'
import ModalHeader from '@shared/components/modal/ModalHeader'
import ModalBody from '@shared/components/modal/ModalBody'
import StripePaymentForm from '@common/components/stripePayment/StripePaymentForm'
import { ReactComponent as DebitCardIcon } from '@common/images/icons/debit-card.svg'

import { SEGMENT_PAGE_NAMES, SEGMENT_EVENTS, trackPage, trackEvent } from '@common/utils'
import { gql, useQuery, useLazyQuery } from '@services/serviceUtils'
import { stripePromise } from '@common/constants'

import styling from './paymentMethod.module.scss'

const subscriptionsQuery = gql`
  query Subscriptions {
    subscriptions {
      id
    }
  }
`

const paymentMethodForSubscriptionQuery = gql`
  query PaymentMethodForSubscription($subId: ID!) {
    paymentMethodForSubscription(subId: $subId) {
      maskedNumber
      network
    }
  }
`

const paymentMethodSessionQuery = gql`
  query PaymentMethodSession {
    paymentMethodSession {
      paymentSessionId
      paymentSessionType
    }
  }
`

const PaymentMethod = () => {
  const navigate = useNavigate()

  const [showUpdatePaymentMethodModal, toggleUpdatePaymentMethodModal] = useState(false)
  const [isUpdateComplete, setIsUpdateComplete] = useState(false)

  useEffect(() => {
    trackPage({ name: SEGMENT_PAGE_NAMES.PAYMENT_METHOD })
  }, [])

  const {
    data: subscriptionsData,
    loading: subscriptionsLoading,
    error: subscriptionsError,
  } = useQuery(subscriptionsQuery, { fetchPolicy: 'no-cache' })
  const subscriptionId = useMemo(() => subscriptionsData?.subscriptions[0]?.id, [
    subscriptionsData?.subscriptions,
  ])

  const [
    getPaymentMethod,
    {
      data: paymentMethodData,
      loading: paymentMethodLoading,
      error: paymentMethodError,
      refetch: refetchPaymentMethod,
    },
  ] = useLazyQuery(paymentMethodForSubscriptionQuery, {
    variables: { subId: subscriptionId },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })
  const isLoading = subscriptionsLoading || paymentMethodLoading
  const hasError = subscriptionsError || paymentMethodError

  useEffect(() => {
    if (isUpdateComplete) {
      refetchPaymentMethod()
      setIsUpdateComplete(false)
    }
  }, [isUpdateComplete, refetchPaymentMethod])

  const [
    getPaymentMethodSessionToken,
    {
      data: paymentMethodSessionData,
      loading: paymentMethodSessionLoading,
      error: paymentMethodSessionError,
    },
  ] = useLazyQuery(paymentMethodSessionQuery, { fetchPolicy: 'no-cache' })

  const options = useMemo(() => {
    return {
      clientSecret: paymentMethodSessionData?.paymentMethodSession?.paymentSessionId,
      appearance: {
        variables: {
          colorPrimary: '#1f9081',
          colorDanger: '#dc3519',
          borderRadius: '12px',
          colorText: '#191919',
          fontSizeBase: '12px',
          fontLineHeight: '18px',
          spacingUnit: '5px',
          spacingGridRow: '18px',
          spacingGridColumn: '7px',
        },
      },
    }
  }, [paymentMethodSessionData?.paymentMethodSession?.paymentSessionId])

  useEffect(() => {
    if (subscriptionId) {
      getPaymentMethod()
    }
  }, [subscriptionId, getPaymentMethod])

  const handleUpdatePaymentMethod = () => {
    trackEvent({ event: SEGMENT_EVENTS.PAYMENT_METHOD_UPDATE_METHOD_BUTTON_CLICK })
    getPaymentMethodSessionToken()
    toggleUpdatePaymentMethodModal(true)
  }

  const handleBackButtonClick = () => {
    navigate(-1)
  }

  const paymentMethodActionType = paymentMethodData?.paymentMethodForSubscription ? 'Update' : 'Add'

  return (
    <>
      <div
        className={classNames(
          'membership-view-content',
          'membership-view-container',
          styling['payment-method-container']
        )}
      >
        <h2 data-cy='membership-settings-header'>Payment Method</h2>
        <p className='subtext'>
          Make changes to your payment method on file for your membership billings.
        </p>
        <LoadingContainer
          loading={isLoading}
          error={hasError}
          errorMessage='Error loading payment method'
        >
          {paymentMethodData?.paymentMethodForSubscription ? (
            <table className={classNames('simple-table', 'payment-method-table')}>
              <tbody>
                <tr>
                  <th>
                    <div className='payment-method-details'>
                      <DebitCardIcon data-cy='payment-method-card-icon' />
                      <div>
                        <span>{paymentMethodData?.paymentMethodForSubscription?.network}</span>
                        <span data-cy='payment-method-last-four-digits-container'>
                          (...{paymentMethodData?.paymentMethodForSubscription?.maskedNumber})
                        </span>
                      </div>
                    </div>
                  </th>
                </tr>
              </tbody>
            </table>
          ) : null}
        </LoadingContainer>
        <div className='actions'>
          {!isLoading && !hasError && (
            <Button onClick={handleUpdatePaymentMethod} data-cy='payment-method-add-update-button'>
              {`${paymentMethodActionType}${
                paymentMethodActionType === 'Add' ? ' Payment' : ''
              } Method`}
            </Button>
          )}
          <Button onClick={handleBackButtonClick} outline>
            Back
          </Button>
        </div>
      </div>
      <Modal
        centered
        size='md'
        open={showUpdatePaymentMethodModal}
        toggleModal={() => toggleUpdatePaymentMethodModal(!showUpdatePaymentMethodModal)}
        className={styling['update-payment-method-modal']}
      >
        <ModalHeader withCloseButton closeModal={() => toggleUpdatePaymentMethodModal(false)}>
          <h4>{paymentMethodActionType} Payment Method</h4>
        </ModalHeader>
        <ModalBody>
          <LoadingContainer
            loading={paymentMethodSessionLoading}
            error={paymentMethodSessionError}
            errorMessage='Error loading update payment method form'
          >
            <Elements stripe={stripePromise} options={options}>
              <StripePaymentForm
                buttonText={paymentMethodActionType}
                isUpdatingMethod
                subscriptionId={subscriptionId}
                handleSuccess={() => {
                  toggleUpdatePaymentMethodModal(false)
                  setIsUpdateComplete(true)
                }}
                sessionType={paymentMethodSessionData?.paymentMethodSession?.paymentSessionType}
              />
            </Elements>
          </LoadingContainer>
        </ModalBody>
      </Modal>
    </>
  )
}

export default PaymentMethod
