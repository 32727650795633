import React, { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { gql, useQuery } from '@services/serviceUtils'
import { usePlaidLinkSdk } from '@common/components/plaidLink/PlaidLink'
import Button from '@shared/components/button/Button'
import LinksTable from '@common/components/link/LinksTable'
import useFundingFlowRouteGuard from '@common/utils/useFundingFlowRouteGuard'
import { ADD_DEBIT_CARD_FLOW_TYPES, FUNDING_FLOWS } from '@common/constants'
import { staticRoutes } from '@routing/routes'

import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'
import { ReactComponent as InstantDepositIcon } from '@common/images/icons/instant-deposit.svg'
import { ReactComponent as LinkIcon } from '@common/images/icons/link.svg'

import { setTransferDetailsAction } from '@redux/transferDetails/transferDetailsActions'

import styling from './accountsForFunding.module.scss'

const REQUIRED_FUNDING_FLOWS = [FUNDING_FLOWS.BASIC]

const plaidAccountsQuery = gql`
  query PlaidAccounts {
    plaidAccounts {
      id
      balanceWhenLinked {
        amount
      }
      mask
      title
    }
  }
`

const AccountsForFunding = () => {
  const dispatch = useDispatch()
  const savedLocationState = useSelector(state => state.savedLocationState)
  const {
    state: {
      fundingFlow: stateFundingFlow,
      shouldSetupDirectDeposit,
    } = {},
  } = useLocation()

  const navigate = useNavigate()

  const { webDebitCardTabapay } = useFlags()

  const fundingFlow = stateFundingFlow || savedLocationState?.fundingFlow

  const {
    data: plaidAccountsData,
    loading: plaidAccountsLoading,
    error: plaidAccountsError,
    refetch: refetchPlaidAccounts,
  } = useQuery(plaidAccountsQuery, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })

  /* Prevents navigation to this view without containging the requisite funding flows in the
     router's state */
  useFundingFlowRouteGuard({ requiredFundingFlows: REQUIRED_FUNDING_FLOWS })

  const handleAfterPlaidSuccess = useCallback(async () => {
    const { data: refetchPlaidAccountsData } = await refetchPlaidAccounts()

    /* Check if Plaid accounts are now available to use. If so, transition to the `Transfer Money`
       screen. */
    if ((refetchPlaidAccountsData?.plaidAccounts?.length || 0) > 0) {
      // Set up the transfer information
      dispatch(
        setTransferDetailsAction({
          fromAccount: refetchPlaidAccountsData.plaidAccounts[0],
        })
      )

      navigate(staticRoutes.transfer.pathname, {
        state: { fundingFlow, shouldSetupDirectDeposit, ...savedLocationState },
      })
    }
  }, [
    dispatch,
    refetchPlaidAccounts,
    fundingFlow,
    navigate,
    shouldSetupDirectDeposit,
    savedLocationState,
  ])

  const {
    processing: plaidLinkProcessing,
    loading: plaidLinkLoading,
    open: openPlaidLinkSdk,
    PlaidLinkSdk,
  } = usePlaidLinkSdk({
    onAfterSuccess: handleAfterPlaidSuccess,
  })

  const handleInstantDepositClick = () => {
    navigate(staticRoutes.debitCardDeposit.pathname, {
      state: {
        fundingFlow,
        shouldSetupDirectDeposit,
        addDebitCardFlow: ADD_DEBIT_CARD_FLOW_TYPES.BEFORE_FUNDING,
      },
    })
  }

  const handleAddMoneyClick = () => {
    if ((plaidAccountsData?.plaidAccounts?.length || 0) < 1) {
      // When there are no plaid linked accounts, open the Plaid SDK.
      openPlaidLinkSdk()
    } else {
      /* Otherwise et up the transfer information then navigate directly to the `Transfer Money`
         screen. */
      dispatch(
        setTransferDetailsAction({
          fromAccount: plaidAccountsData.plaidAccounts[0],
        })
      )

      navigate(staticRoutes.transfer.pathname, {
        state: { fundingFlow, shouldSetupDirectDeposit },
      })
    }
  }

  const handleCancelClick = () => {
    /* Navigates to add money when premium onboarding and resets funding flow state, to the direct desposit
       screen if required, or to the dashboard in any other case */
    if (fundingFlow === FUNDING_FLOWS.ONBOARDING_PREMIUM) {
      navigate(staticRoutes.addMoney.pathname, {
        state: {
          fundingFlow,
          shouldSetupDirectDeposit,
          ...savedLocationState,
        },
      })
    } else if (shouldSetupDirectDeposit || savedLocationState?.shouldSetupDirectDeposit) {
      /* This state flag is used explicitly, since the user might have visited this flow from a
         dashboard CTA, rather than through onboarding */
      navigate(staticRoutes.directDepositSetup.pathname, { state: { fundingFlow } })
    } else {
      navigate(staticRoutes.dashboard.pathname)
    }
  }

  const fundingOptionRows = [
    {
      heading: 'Add Money from Other Banks',
      subtext: 'Available in 2-4 business days (Linking to business accounts not allowed)',
      icon: <LinkIcon />,
      loading: plaidLinkProcessing || plaidLinkLoading || plaidAccountsLoading,
      disabled: !!plaidAccountsError,
      onClick: handleAddMoneyClick,
      dataCy: 'add-money-from-other-banks-link',
    },
  ]

  if (webDebitCardTabapay) {
    fundingOptionRows.unshift({
      heading: 'Instant Card Deposit',
      subtext: 'Funds available within minutes',
      icon: <InstantDepositIcon />,
      onClick: handleInstantDepositClick,
      dataCy: 'add-money-instant-deposit-link',
    })
  }

  return (
    <div className={classNames('white-card-container', styling['funding-view-container'])}>
      <GreenwoodLogo className='logo' />
      <h1>Fund your Spending Account</h1>
      <p>Select an option to fund your account.</p>
      <LinksTable rowsData={fundingOptionRows} />
      <PlaidLinkSdk />
      <Button
        ghost
        onClick={handleCancelClick}
        className='additional-button'
        data-cy='cancel-funding-button'
      >
        Cancel
      </Button>
    </div>
  )
}

export default AccountsForFunding
