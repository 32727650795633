import React, { useCallback, useEffect, useMemo } from 'react'
import { Navigate, Routes, Route, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import ProtectedRoute from '@routing/ProtectedRoute'
import LoadingContainer from '@shared/components/loadingContainer/LoadingContainer'
import CreateAccountName from './CreateAccountName'
import CreateAccountAddress from './createAccountAddress/CreateAccountAddress'
import CreateAccountAgreements from './createAccountAgreements/CreateAccountAgreements'
import CreateAccountBirthday from './CreateAccountBirthday'
import CreateAccountInReview from './CreateAccountInReview'
import CreateAccountProcessing from './CreateAccountProcessing'
import CreateAccountReview from './createAccountReview/CreateAccountReview'
import CreateAccountSSN from './CreateAccountSSN'

import { gql, useQuery } from '@services/serviceUtils'
import { staticRoutes } from '@routing/routes'
import { setOnboardingFlowTypeAction } from '@redux/application/applicationActions'
import { useApplication, getNextIncompleteCheckpointRoute } from '@common/utils'
import useScript from '@shared/utils/useScript'
import { APPLICATION_STATUSES } from '@shared/constants'

const accountsQuery = gql`
  query Accounts {
    accounts {
      accounts {
        accountType
      }
    }
  }
`

/**
 * Authenticated sign-up workflow
 */
const CreateAccount = () => {
  const [scriptLoaded, scriptError] = useScript('https://js.dvnfo.com/devicer.min.js')
  const dispatch = useDispatch()

  const { state } = useLocation()

  const { getApplication, ...application } = useApplication()

  const { loading: accountsLoading, data: accountsData } = useQuery(accountsQuery, {
    fetchPolicy: 'no-cache',
  })

  const accountTypes = useMemo(
    () => accountsData?.accounts?.accounts?.map(account => account.accountType),
    [accountsData?.accounts]
  )

  // Fetch the application
  useEffect(() => {
    getApplication()
  }, [getApplication])

  const onboardingFlowType = useMemo(() => application?.data?.application?.onboardingFlowType, [
    application?.data?.application,
  ])

  const customerApplicationStatus = useMemo(() => (
    application?.data?.application?.customerApplicationStatus
  ), [application?.data?.application?.customerApplicationStatus])

  useEffect(() => {
    if (onboardingFlowType) {
      dispatch(setOnboardingFlowTypeAction(onboardingFlowType))
    }
  }, [onboardingFlowType, dispatch])

  const nextIncompleteCheckpointRoute = getNextIncompleteCheckpointRoute(
    application?.data?.checkpoints
  )

  const getUpdatedRoute = useCallback(() => {
    if (!state?.isEditing) {
      if (nextIncompleteCheckpointRoute === staticRoutes.createAccountReview.pathname) {
        const sessionAgreementsAccepted = !!application?.data?.review?.sessionAgreementsAccepted
        if (!sessionAgreementsAccepted) {
          return <Navigate to={staticRoutes.createAccountAgreements.pathname} state={state} replace={true} />
        }
      }
      return <Navigate to={nextIncompleteCheckpointRoute} />
    }
  }, [state, nextIncompleteCheckpointRoute, application?.data?.review?.sessionAgreementsAccepted])

  return (
    <div className='create-account-wrapper'>
      <LoadingContainer
        loading={application.loading || !scriptLoaded || accountsLoading}
        error={application.error || scriptError}
      >
        {accountTypes?.length ? (
          <Navigate to={staticRoutes.dashboard.pathname} />
        ) : (
          <>
            {nextIncompleteCheckpointRoute && customerApplicationStatus === APPLICATION_STATUSES.INITIAL ? getUpdatedRoute() : null}
            <Routes>
              <Route
                path={staticRoutes.createAccountName.relativePathname}
                element={<ProtectedRoute component={CreateAccountName} componentProps={application} />}
              />
              <Route
                path={staticRoutes.createAccountBirthday.relativePathname}
                element={<ProtectedRoute component={CreateAccountBirthday} componentProps={application} />}
              />
              <Route
                path={staticRoutes.createAccountSSN.relativePathname}
                element={<ProtectedRoute component={CreateAccountSSN} componentProps={application} />}
              />
              <Route
                path={staticRoutes.createAccountAddress.relativePathname}
                element={<ProtectedRoute component={CreateAccountAddress} componentProps={application} />}
              />
              <Route
                path={staticRoutes.createAccountAgreements.relativePathname}
                element={<ProtectedRoute component={CreateAccountAgreements} componentProps={application} />}
              />
              <Route
                path={staticRoutes.createAccountReview.relativePathname}
                element={
                  <ProtectedRoute component={CreateAccountReview} componentProps={{ ...application }} />
                }
              />
              <Route
                path={staticRoutes.createAccountProcessing.relativePathname}
                element={<ProtectedRoute component={CreateAccountProcessing} componentProps={{ getApplication, ...application }} />}
              />
              <Route
                path={staticRoutes.createAccountInReview.relativePathname}
                element={<ProtectedRoute component={CreateAccountInReview} componentProps={application} />}
              />
            </Routes>
          </>
        )}
      </LoadingContainer>
    </div>
  )
}

export default CreateAccount
