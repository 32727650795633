/**
 * Filters an object's properties based on a condition, and returns new objects containing the
 * filtered and rejected properties
 * @param {*} object the original object
 * @param {*} conditionCallback callback which iterates over each key-value pair. The current
 * entry's key and value is passed as the arguments, and expects `true` or `false` to be returned.
 * @returns a new object containing all filtered properties, and a new object ontaining all
 * rejected properties
 */
export const filterObject = (object = {}, conditionCallback = () => true) => {
  const filtered = {}
  const rejected = {}

  Object.entries(object).forEach(([key, value]) => {
    conditionCallback(key, value) === true ? (filtered[key] = value) : (rejected[key] = value)
  })

  return [filtered, rejected]
}

/**
 * Filters an array based on a condition, and returns new arrays representing the filterd and
 * rejected elements
 * @param {*} array the original array
 * @param {*} conditionCallback callback which iterates over each element. The current element is
 * passed as the only argument, and expects `true` or `false` to be returned.
 * @returns a new array containing all filtered elements, and a new array containing all rejected
 * elements
 */
export const filterArray = (array = [], conditionCallback = () => true) => {
  const filtered = []
  const rejected = []

  array.forEach(element => {
    conditionCallback(element) === true ? filtered.push(element) : rejected.push(element)
  })

  return [filtered, rejected]
}
