import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import Button from '@shared/components/button/Button'
import { SEGMENT_EVENTS, trackEvent, trackPage, SEGMENT_PAGE_NAMES } from '@common/utils'

import { staticRoutes } from '@routing/routes'
import CelebrationView from '@common/components/celebrationView/CelebrationView'

const CreateSavingsAccountApproved = () => {
  const navigate = useNavigate()
  const location = useLocation()

  // Navigation guard to disallow view when savings account has not been completed this session
  if (!location?.state?.isSavingsAccountCreated) {
    navigate(staticRoutes.dashboard.pathname, { replace: true })
  }

  useEffect(() => {
    // When a user visits the approved page, track the event
    if (location?.state?.isSavingsAccountCreated) {
      trackPage({ name: SEGMENT_PAGE_NAMES.SAVINGS_ACCOUNT_SUCCESS })
    }
  }, [location?.state?.isSavingsAccountCreated])

  return (
    <CelebrationView data-cy='authenticated-loaded-view'>
      <div className='create-account-content-wrapper'>
        <h1>Congrats!</h1>
        <p>Your Savings Account has been successfully created.</p>

        <div className='buttons-container'>
          <Button
            onClick={() => {
              navigate(staticRoutes.dashboard.pathname)
              trackEvent({ event: SEGMENT_EVENTS.SAVINGS_ACCOUNT_SUCCESS_CLICK })
            }}
            data-cy='approved-done-button'
            onDark
          >
            DONE
          </Button>
        </div>
      </div>
    </CelebrationView>
  )
}

export default CreateSavingsAccountApproved
