import React, { useEffect } from 'react'
import { Navigate, Outlet, Routes, Route, useLocation, useNavigate } from 'react-router-dom'

import ProtectedRoute from '@routing/ProtectedRoute'
import { staticRoutes } from '@routing/routes'

import Hero from '@common/components/hero/Hero'
import useTabRouting from '@common/utils/useTabRouting'
import Tabs from '@shared/components/tabs/Tabs'
import AddMoney from './AddMoney'
import SendMoney from './SendMoney'

const tabOptions = [
  {
    label: 'Add Money',
    enabled: true,
    pathname: staticRoutes.moveMoneyAddMoney.relativePathname,
    component: AddMoney,
  },
  {
    label: 'Send Money',
    enabled: true,
    pathname: staticRoutes.moveMoneySendMoney.relativePathname,
    component: SendMoney,
  },
]

const MoveMoney = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const handleTabChange = tab => {
    if (tab) {
      navigate(tab.pathname)
    }
  }

  const { activeTab, redirectPath } = useTabRouting({
    defaultActiveTab: staticRoutes.moveMoneyAddMoney,
    location,
    tabOptions,
    tabsEnabled: true,
  })

  useEffect(() => {
    if (redirectPath) {
      navigate(redirectPath)
    }
  }, [navigate, redirectPath])

  return (
    <>
      <Hero heading='Move Money' />
      <div className='page-content-container white-bg'>
        <div className='row'>
          <Tabs
            defaultActiveTab={activeTab}
            options={tabOptions}
            onChangeCallback={handleTabChange}
          />
          <Routes>
            {tabOptions.map(option => {
              return (
                <Route
                  key={option.pathname}
                  path={option.pathname}
                  element={<ProtectedRoute component={option.component} />}
                />
              )
            })}
            <Route
              path='*'
              element={
                <Navigate to={staticRoutes.moveMoneyAddMoney.pathname} />
              }
            />
          </Routes>
          <Outlet />
        </div>
      </div>
    </>
  )
}

export default MoveMoney
