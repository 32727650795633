import {
  ApolloClient,
  ApolloLink,
  from,
  fromPromise,
  HttpLink,
  InMemoryCache,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'

import { GRAPHQL_BASE_URL, API_HEADERS, KNOWN_NESTED_400_ERRORS } from '@common/constants'
import { refreshToken } from '@services/serviceUtils'
import { setTokenAction } from '@redux/auth/authActions'
import { addAlertAction } from '@redux/alerts/alertsActions'
import { setApiValidationErrorsAction } from '@redux/apiValidationErrors/apiValidationErrorsActions'
import { purgeStore, store } from '@redux/store'
import { getErrorByStatusCode, filterObject } from '@shared/utils'
import { getRedirectLocationByChallengeTypeAndUserFlow } from '@common/utils'
import { CHALLENGE_TYPES } from '@shared/constants/uiConstants'
import history from '@routing/history'
import { staticRoutes } from '@routing/routes'

const httpLink = new HttpLink({
  uri: GRAPHQL_BASE_URL,
  credentials: 'include',
})

const logUserOut = () => {
  clearPendingRequests()

  /* Set a flag in sessionStorage to display a message to the user to notify them they were
    logged out due to inactivity */
  window.sessionStorage.setItem('showSessionExpiredMessage', true)

  /* Log the user out if the token refresh fails, abandon all pending requests, and bring them
    to the login screen */
  purgeStore({ redirectPath: staticRoutes.signIn.pathname })
}

let isRefreshing = false
let pendingRequests = []

const authMiddleware = new ApolloLink((operation, forward) => {
  // add access token to the headers
  const storeState = store.getState()
  const token = storeState.auth.token?.access_token || window.sessionStorage.getItem('cypressToken')
  const recaptchaToken = window.sessionStorage.getItem('recaptchaToken')

  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
      'recaptcha-token': recaptchaToken || '',
      ...API_HEADERS,
    },
  })

  return forward(operation)
})

const onRefreshToken = async () => {
  const storeState = store.getState()

  try {
    const response = await refreshToken({
      refreshToken: storeState.auth.token?.refresh_token,
      onError: () => {
        logUserOut()
      },
    })

    const { challengeType, token, sessionId } = response?.data || {}

    // If a new token was received, update the auth state
    if (token) {
      store.dispatch(setTokenAction(token))
    }

    /* If there is a challenge type, abandon all pending requests and navigate to the
       appropriate challenge response screen. Otherwise, resolve any pending requests. */
    if (challengeType !== CHALLENGE_TYPES.NONE) {
      const redirectLocation = getRedirectLocationByChallengeTypeAndUserFlow({
        challengeType,
        sessionId,
      })

      history.push({
        pathname: redirectLocation.pathname,
        state: {
          ...redirectLocation.state,
          sessionId,
          displayEmail: storeState.customer?.emailAddress,
        },
      })

      clearPendingRequests()
    } else {
      resolvePendingRequests()
    }
  } catch {
    logUserOut()
  } finally {
    isRefreshing = false
  }
}

const clearPendingRequests = () => {
  pendingRequests = []
}

const resolvePendingRequests = () => {
  pendingRequests.forEach(callback => callback())
  clearPendingRequests()
}

const addPendingRequests = () => {
  return new Promise(resolve => {
    pendingRequests.push(() => resolve())
  })
}

const emitUnexpectedStatusAlertAction = ({ statusCode, error, alertActionConfig }) => {
  // Get user-readable error message
  const responseBody = error?.extensions?.responseBody
  const message = responseBody?.message
  const requestId = responseBody?.requestId

  emitAlertAction({
    message: message || getErrorByStatusCode(statusCode),
    requestId,
    alertActionConfig,
  })
}

const emitAlertAction = ({ message, requestId, alertActionConfig, subText }) => {
  const additionalText = requestId ? `(${requestId})` : undefined

  store.dispatch(
    addAlertAction({
      text: message,
      subText: subText || additionalText,
      ...alertActionConfig,
    })
  )
}

const getRefreshTokenObservable = () => {
  let observable

  if (!isRefreshing) {
    isRefreshing = true
    observable = fromPromise(onRefreshToken())
  } else {
    // Will only emit once the Promise is resolved
    observable = fromPromise(addPendingRequests())
  }

  return observable
}

const handleNon200GraphError = ({ error, statusCode, alertActionConfig }) => {
  const validationErrors = error?.extensions?.responseBody?.errors || {}

  // Split the error messages into known and unknown errors.
  const [knownErrors] = filterObject(validationErrors, key => KNOWN_NESTED_400_ERRORS.includes(key))

  // Get the count of each collection of errors
  const knownErrorsCount = Object.keys(knownErrors).length

  // Report any nested errors
  if (knownErrorsCount > 0) {
    // Persist any known field-specific error messages (do not show a global
    // error for these)
    store.dispatch(setApiValidationErrorsAction(knownErrors))
  }

  if (statusCode >= 500) {
    emitAlertAction({
      message: 'Uh oh, something went wrong.',
      subText: "We aren't able to process your request at the moment.",
      alertActionConfig,
    })
  } else {
    emitUnexpectedStatusAlertAction({
      statusCode,
      error,
      alertActionConfig,
    })
  }
}

/*  If there is a 401 error, attempt to refresh the token.
 *  The code below was adapted from https://able.bio/AnasT/apollo-graphql-async-access-token-refresh--470t1c8
 *  This is a bit complex because onError uses observerables instead of promises and because the code below supports concurrent requests.
 *  A PR was created in June 2019 to add support for promises (https://github.com/apollographql/apollo-link/pull/1066),
 *  but it hasn't been merged and released yet.
 */
const tokenExpiryLink = onError(({ networkError, operation, forward }) => {
  if (networkError && networkError.statusCode === 401) {
    /* Calling `forward(operation)` continues the operation, the authMiddleware link will set the
       new token */
    return getRefreshTokenObservable().flatMap(() => forward(operation))
  } else {
    // All other errors are forwarded onto the errorLink
    forward(operation)
  }
})

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  // Get the alert action configuration, if provided
  const alertActionConfig = operation.getContext()?.alertActionConfig

  if (networkError && networkError.statusCode !== 401) {
    store.dispatch(
      addAlertAction({
        text: getErrorByStatusCode(networkError.statusCode),
        ...alertActionConfig,
      })
    )
  } else if (graphQLErrors) {
    for (const error of graphQLErrors) {
      const statusCode = error?.extensions?.statusCode

      if (statusCode !== 200) {
        handleNon200GraphError({ error, statusCode, alertActionConfig })
      }
    }
  }
})

export const client = new ApolloClient({
  cache: new InMemoryCache({}),
  link: from([tokenExpiryLink, errorLink, authMiddleware, httpLink]),
})
