import React from 'react'
import PropTypes from 'prop-types'
import CurrencyInput from 'react-currency-input-field'
import classNames from 'classnames'
import { useFormikContext, useField } from 'formik'


const FormCurrencyInput = ({
  className,
  disabled = false,
  placeholder = '$0.00',
  intlConfig = { locale: 'en-US', currency: 'USD' },
  errorText,
  hintContent,
  invalid = false,
  label,
  required,
  showRequiredAsterisk = true,
  defaultValue = '',
  prefix = '$',
  onChange,
  ...rest
}) => {
  const containerClasses = classNames(
    className,
    disabled && 'disabled',
    'form-control-container',
    invalid && 'is-invalid'
  )

  const inputClasses = classNames('form-control', invalid && 'is-invalid', disabled && 'disabled')

  const { setFieldValue } = useFormikContext()

  const [field, values] = useField({
    className,
    disabled,
    placeholder,
    intlConfig,
    errorText,
    hintContent,
    invalid,
    label,
    required,
    showRequiredAsterisk,
    defaultValue,
    ...rest,
  })

  return (
    <div className={containerClasses}>
      {label && (
        <label htmlFor={rest.id}>
          {label}
          {required && showRequiredAsterisk && ' *'}
        </label>
      )}
      <CurrencyInput
        {...field}
        {...rest}
        placeholder={placeholder}
        decimalsLimit={2}
        decimalScale={2}
        allowNegativeValue={false}
        prefix={prefix}
        disableAbbreviations
        disabled={disabled}
        defaultValue={defaultValue}
        className={inputClasses}
        value={values.name}
        onValueChange={(value, name) => {
          onChange && onChange(value)
          setFieldValue(name, value)
        }}
      />
      <div className='form-control-descenders'>
        <div>
          {invalid && errorText && <div className='form-control-error'>{errorText}</div>}
          {hintContent && <div className='form-control-hint'>{hintContent}</div>}
        </div>
        {required && !label && !invalid && <div className='form-control-required'>Required</div>}
      </div>
    </div>
  )
}

FormCurrencyInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  /**
   * Configuration for international locale; Object should contain locale and currency strings
   */
  intlConfig: PropTypes.shape({
    locale: PropTypes.string,
    currency: PropTypes.string,
  }),
  errorText: PropTypes.string,
  /**
   * Text to display below the currency input as clues to the user
   */
  hintContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  invalid: PropTypes.bool,
  label: PropTypes.string,
  required: PropTypes.bool,
  /**
   * Whether or not to show the asterisk when the field is required
   */
  showRequiredAsterisk: PropTypes.bool,
  /**
   * The default value of the currency input field
   */
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Character you'd like to set at the beginning of the currency input. Default is '$'.
   */
  prefix: PropTypes.any,
}

export default FormCurrencyInput
