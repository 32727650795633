import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { toDollars } from '@shared/utils'

import styling from './fundingAccountDetail.module.scss'

const FundingAccountDetail = ({ title, lastFourAccountDigits, amount, className }) => {
  return (
    <div className={classNames(styling.container, className)}>
      <div>
        <span className={styling['account-detail']}>
          {`${title} (...${lastFourAccountDigits})`}
        </span>
        {amount && (
          <div className={styling['fund-amount']}>
            <span>{toDollars({ value: amount })}</span>
          </div>
        )}
      </div>
    </div>
  )
}

FundingAccountDetail.propTypes = {
  /**
   * The title of the account
   */
  title: PropTypes.string.isRequired,

  /**
   * The last four account number digits
   */
  lastFourAccountDigits: PropTypes.string.isRequired,

  /**
   * The available balance to display, if available
   */
  amount: PropTypes.number,

  /**
   * Additional classes passed to the container
   */
  className: PropTypes.any,
}

export default FundingAccountDetail
