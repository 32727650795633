import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { ReactComponent as GivingIcon } from '@common/images/icons/giving.svg'

import LoadingContainer from '@shared/components/loadingContainer/LoadingContainer'

import { useQuery, gql } from '@services/serviceUtils'

import styling from './causeCallout.module.scss'

const causesQuery = gql`
  query GivingCauses {
    givingCauses {
      name
      descriptionKey
      logoUrl
    }
  }
`

const CauseCallout = ({ className, asList = false }) => {
  const { data: causesData, loading: causesLoading, error: causesError } = useQuery(causesQuery, {
    fetchPolicy: 'no-cache',
  })

  return (
    <div className={classNames(styling['cause-callout'], className)}>
      <LoadingContainer
        error={causesError}
        errorMessage='Error loading causes'
        loading={causesLoading}
      >
        <div className={classNames(styling['causes-container'], { [styling['as-list']]: asList })}>
          {causesData?.givingCauses?.map((givingCause, givingCauseIndex) => {
            return (
              <div className={styling.cause} key={givingCauseIndex}>
                <div className={styling.logo}>
                  {givingCause.logoUrl ? (
                    <img src={givingCause.logoUrl} alt={givingCause.name + ' logo'} />
                  ) : (
                    <GivingIcon />
                  )}
                </div>
                {asList && givingCause.descriptionKey}
              </div>
            )
          })}
        </div>
      </LoadingContainer>
    </div>
  )
}

CauseCallout.propTypes = {
  /**
   * Additional styles to pass into the container
   */
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

  /**
   * When true, will display the causes as a list with names
   */
  asList: PropTypes.bool,
}

export default CauseCallout
