import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { useSelector } from 'react-redux'

import AccountOptionsForm from './AccountOptionsForm'
import LoadingContainer from '@shared/components/loadingContainer/LoadingContainer'
import Button from '@shared/components/button/Button'
import PlaidLink from '@common/components/plaidLink/PlaidLink'

import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'

import {
  ACCOUNT_FUNDING_REQUEST_PURPOSES,
  FUNDING_FLOWS,
  FUNDING_STATES,
  PRODUCT_TYPES,
} from '@common/constants'
import useFundingFlowRouteGuard from '@common/utils/useFundingFlowRouteGuard'
import { hasSelectDdaTypeCta, hasUpgradeToPremiumAccountTypeCta } from '@common/utils/accountUtils'
import { gql, useQuery, useLazyQuery, useMutation } from '@services/serviceUtils'
import { staticRoutes } from '@routing/routes'

import styling from './premiumPayment.module.scss'

const REQUIRED_FUNDING_FLOWS = [FUNDING_FLOWS.ONBOARDING_PREMIUM, FUNDING_FLOWS.PREMIUM]

const plaidAccountsQuery = gql`
  query PlaidAccounts {
    plaidAccounts {
      id
      balanceWhenLinked {
        amount
      }
      mask
      title
    }
  }
`

const greenwoodAccountsQuery = gql`
  query GreenwoodAccounts {
    accounts {
      accounts {
        id
        balance {
          amount
        }
        accountNumber
        title
        accountType
      }
    }
  }
`

const productsAndCtasQuery = gql`
  query ProductsAndCtas($legacy: Boolean) {
    products(legacy: $legacy) {
      id
      productType
      agreements {
        id
      }
    }
    ctas(legacy: $legacy) {
      callToActionType
    }
  }
`

const selectFundingAccountMutation = gql`
  mutation SelectFundingAccount($accountFundingRequestDTOInput: AccountFundingRequestDTOInput!) {
    selectFundingAccount(accountFundingRequestDTOInput: $accountFundingRequestDTOInput) {
      fundingState
      fundingStateAcknowledged
      id
    }
  }
`

const openDdaMutation = gql`
  mutation OpenDDA($openDDARequestDTOInput: OpenDDARequestDTOInput!) {
    openDDA(openDDARequestDTOInput: $openDDARequestDTOInput) {
      fundingState
    }
  }
`

// Gets the spending account from a list of accounts
const getGreenwoodSpendingAccount = ({ accounts = [] }) => {
  return accounts.find(
    account =>
      account.accountType === PRODUCT_TYPES.basic || account.accountType === PRODUCT_TYPES.premium
  )
}

const PremiumPayment = () => {
  const [greenwoodAccounts, setGreenwoodAccounts] = useState()
  const [canOpenDda, setCanOpenDda] = useState()
  const [openPlaidOnLoad, setOpenPlaidOnLoad] = useState(false)
  const [selectedAccountId, setSelectedAccountId] = useState()

  const [premiumAgreementData, setPremiumAgreementData] = useState({
    productId: null,
    agreementIds: [],
  })

  const {
    state: {
      useGreenwoodAccountFunding: stateUseGreenwoodAccountFunding,
      fundingFlow: stateFundingFlow,
      shouldSetupDirectDeposit,
    } = {},
  } = useLocation()

  const navigate = useNavigate()

  const savedLocationState = useSelector(state => state.savedLocationState)

  const fundingFlow = stateFundingFlow || savedLocationState?.fundingFlow
  const useGreenwoodAccountFunding =
    stateUseGreenwoodAccountFunding || savedLocationState?.useGreenwoodAccountFunding

  /* Prevents navigation to this view without containging the requisite funding flows in the
     router's state */
  useFundingFlowRouteGuard({ requiredFundingFlows: REQUIRED_FUNDING_FLOWS })

  const {
    data: productsAndCtasData,
    loading: productsAndCtasLoading,
    error: productsAndCtasError,
  } = useQuery(productsAndCtasQuery, {
    variables: { legacy: true },
    fetchPolicy: 'no-cache',
  })

  const [
    getPlaidAccounts,
    {
      data: plaidAccountsData,
      loading: plaidAccountsLoading,
      error: plaidAccountsError,
      refetch: refetchPlaidAccounts,
    },
  ] = useLazyQuery(plaidAccountsQuery, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })

  const [
    getGreenwoodAccounts,
    {
      data: greenwoodAccountsData,
      loading: greenwoodAccountsLoading,
      error: greenwoodAccountsError,
    },
  ] = useLazyQuery(greenwoodAccountsQuery, { fetchPolicy: 'no-cache ' })

  const [
    selectFundingAccount,
    { loading: selectFundingAccountLoading, error: selectFundingAccountError },
  ] = useMutation(selectFundingAccountMutation)

  const [submitOpenDda, { loading: openDdaLoading, error: openDdaError }] = useMutation(
    openDdaMutation
  )

  const redirectByFundingState = useCallback(
    fundingState => {
      const newState = {
        fundingFlow,
        shouldSetupDirectDeposit,
        ...savedLocationState,
      }

      if (fundingState === FUNDING_STATES.PASSED) {
        navigate(staticRoutes.premiumCardInbound.pathname, { state: newState })
      } else if (fundingState === FUNDING_STATES.PROCESSING) {
        navigate(staticRoutes.transferProcessing.pathname, { state: newState })
      } else if (fundingState === FUNDING_STATES.FAILED) {
        navigate(staticRoutes.transferFailed.pathname, { state: newState })
      }
    },
    [fundingFlow, navigate, shouldSetupDirectDeposit, savedLocationState]
  )

  // Check if the Greenwood spending account should be picked, or we should load the Plaid accounts
  useEffect(() => {
    if (useGreenwoodAccountFunding === true) {
      getGreenwoodAccounts()
    } else {
      getPlaidAccounts()
    }
  }, [useGreenwoodAccountFunding, getPlaidAccounts, getGreenwoodAccounts])

  useEffect(() => {
    const premiumProduct = productsAndCtasData?.products?.find(
      product => product.productType === PRODUCT_TYPES.premium
    )

    const ctas = productsAndCtasData?.ctas

    if (ctas) {
      // Check if an openDDA has previously been submitted
      setCanOpenDda(
        hasSelectDdaTypeCta(productsAndCtasData?.ctas) ||
          hasUpgradeToPremiumAccountTypeCta(productsAndCtasData?.ctas)
      )
    }

    if (premiumProduct) {
      setPremiumAgreementData({
        productId: premiumProduct.id,
        agreementIds: premiumProduct.agreements.map(agreement => agreement.id),
      })
    }
  }, [productsAndCtasData])

  /* Greenwood accounts are loaded, select the spending account (DDA) as the only option for
    payment */
  useEffect(() => {
    const accounts = greenwoodAccountsData?.accounts?.accounts

    if (accounts) {
      const greenwoodSpendingAccount = getGreenwoodSpendingAccount({ accounts })

      if (greenwoodSpendingAccount) {
        setGreenwoodAccounts([greenwoodSpendingAccount])
      }
    }
  }, [greenwoodAccountsData])

  const isContainerLoading =
    plaidAccountsLoading || greenwoodAccountsLoading || productsAndCtasLoading

  useEffect(() => {
    // Automatically launches the Plaid SDK if no plaid accounts were loaded
    if (plaidAccountsLoading === false && plaidAccountsData?.plaidAccounts?.length === 0) {
      setOpenPlaidOnLoad(true)
    }
  }, [fundingFlow, plaidAccountsLoading, plaidAccountsData?.plaidAccounts])

  // Suppresses any errors on this mutation, since an alert will be displayed instead
  const submitFundingRequest = useCallback(async () => {
    const {
      data: { selectFundingAccount: { fundingState } = {} } = {},
    } = await selectFundingAccount({
      variables: {
        accountFundingRequestDTOInput: {
          fundingAmount: 4.99,
          linkedAccountId: selectedAccountId,
          purpose: ACCOUNT_FUNDING_REQUEST_PURPOSES.PREMIUM_FEE,
        },
      },
    })

    redirectByFundingState(fundingState)
  }, [selectFundingAccount, selectedAccountId, redirectByFundingState])

  const handleConfirmClick = useCallback(async () => {
    switch (fundingFlow) {
      case FUNDING_FLOWS.ONBOARDING_PREMIUM:
        // For onboarding premium flow, submit a funding request
        if (selectedAccountId) {
          submitFundingRequest()
          // TODO: direct user to either payment (transfer) processing, payment (transfer) failed,
          // or Premium Card Inbound screen depending of result of submitting funding request
        }
        break
      case FUNDING_FLOWS.PREMIUM:
        /* For Premium flow, if the user has not previously submitted an OpenDDA request, then
           submit an openDDA request; otherwise, submit a funding request for the existing premium
           upgrade request. */
        if (selectedAccountId) {
          if (canOpenDda) {
            const result = await submitOpenDda({
              variables: {
                openDDARequestDTOInput: {
                  agreementIds: premiumAgreementData?.agreementIds,
                  productId: premiumAgreementData?.productId,
                },
              },
            })

            redirectByFundingState(result?.data?.openDDA?.fundingState)
          } else {
            submitFundingRequest()
          }
        }
        break
      default:
        /* Realistically this will never be reached due to route-guarding but just in case, route
           to the dashboard. */
        navigate(staticRoutes.dashboard.pathname)
    }
  }, [
    canOpenDda,
    fundingFlow,
    premiumAgreementData,
    navigate,
    redirectByFundingState,
    selectedAccountId,
    submitFundingRequest,
    submitOpenDda,
  ])

  const handleCancelClick = () => {
    /* Navigates to add money when premium onboarding and resets funding flow state, to the direct desposit
       screen if required, or to the dashboard in any other case */
    if (fundingFlow === FUNDING_FLOWS.ONBOARDING_PREMIUM) {
      navigate(staticRoutes.addMoney.pathname, {
        state: {
          shouldSetupDirectDeposit,
          ...savedLocationState,
          fundingFlow: FUNDING_FLOWS.BASIC,
        },
      })
    } else if (shouldSetupDirectDeposit || savedLocationState?.shouldSetupDirectDeposit) {
      /* This state flag is used explicitly, since the user might have visited this flow from a
         dashboard CTA, rather than through onboarding */
      navigate(staticRoutes.directDepositSetup.pathname, { state: { fundingFlow } })
    } else {
      navigate(staticRoutes.dashboard.pathname)
    }
  }

  const handleAccountChange = useCallback(accountId => {
    setSelectedAccountId(accountId)
  }, [])

  let errorMessage = ''

  // Sets error message depending on what caused the error
  if (plaidAccountsError || greenwoodAccountsError) {
    errorMessage = 'Error loading accounts'
  } else if (selectFundingAccountError || openDdaError) {
    errorMessage = 'Error submitting funding request'
  } else if (productsAndCtasError) {
    errorMessage = 'Error loading product information'
  }

  return (
    <div className={classNames('white-card-container', styling['premium-payment'])}>
      <GreenwoodLogo className='logo' />
      <h1>Confirm Payment Method</h1>
      <p>We will use your Greenwood account to pay for your Premium Membership.</p>
      <LoadingContainer
        loading={isContainerLoading}
        error={!!errorMessage}
        errorMessage={errorMessage}
      >
        <AccountOptionsForm
          linkedAccounts={plaidAccountsData?.plaidAccounts}
          greenwoodAccounts={greenwoodAccounts}
          onAccountChange={handleAccountChange}
        />
      </LoadingContainer>
      {!useGreenwoodAccountFunding && (
        <PlaidLink
          openOnLoad={openPlaidOnLoad}
          onAfterSuccess={refetchPlaidAccounts}
          className={styling['plaid-link']}
          ghost
        />
      )}
      <Button
        onClick={handleConfirmClick}
        disabled={isContainerLoading || !selectedAccountId}
        isLoading={openDdaLoading || selectFundingAccountLoading}
        className='additional-button'
        data-cy='confirm-funding-button'
      >
        Confirm
      </Button>
      <Button
        ghost
        onClick={handleCancelClick}
        className='additional-button'
        data-cy='cancel-funding-button'
      >
        Cancel
      </Button>
    </div>
  )
}

export default PremiumPayment
