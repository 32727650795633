import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useLocation, NavLink, Link } from 'react-router-dom'
import classNames from 'classnames'
import { motion } from 'framer-motion'
import { throttle } from 'lodash'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { ReactComponent as BankCardIcon } from '@common/images/icons/bank-card.svg'
import { ReactComponent as DotsGridIcon } from '@common/images/icons/dots-grid.svg'
import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'
import { ReactComponent as HomeIcon } from '@common/images/icons/home.svg'
import { ReactComponent as MoneyBagIcon } from '@common/images/icons/money-bag.svg'
import { ReactComponent as GivingHeartIcon } from '@common/images/icons/giving-heart.svg'
import { ReactComponent as ProfileIcon } from '@common/images/icons/profile.svg'
import { ReactComponent as InvestIcon } from '@common/images/icons/invest.svg'
import { ReactComponent as CommunityIcon } from '@common/images/icons/community.svg'

import Collapse from '@shared/components/Collapse'
import Dropdown from '@shared/components/dropdown/Dropdown'
import DropdownItem from '@shared/components/dropdown/DropdownItem'
import DropdownMenu from '@shared/components/dropdown/DropdownMenu'
import DropdownToggle from '@shared/components/dropdown/DropdownToggle'

import { staticRoutes } from '@routing/routes'
import { getMoreSubLinks, getMoveMoneySubLinks, getMembershipSubLinks } from './headerUtils'
import { isAuthenticated, trackEvent, SEGMENT_EVENTS, SEGMENT_SOURCE_DETAILS } from '@common/utils'

import styling from './header.module.scss'

const menuVariants = {
  open: {
    transition: { staggerChildren: 0.025, delayChildren: 0.1 },
  },
  closed: {
    transition: { staggerChildren: 0.025, staggerDirection: -1 },
  },
}

const menuItemVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}

const HeaderLink = ({
  toRoute = '',
  title,
  hasTextClassname = true,
  icon,
  isDropdownItem = false,
  isExact = true,
  state,
  ...rest
}) => {
  const link = (
    <NavLink
      end={isExact}
      className={({ isActive }) => isActive ? styling.active : ''}
      to={{ pathname: toRoute, state }}
      {...rest}
    >
      {icon}
      <span className={classNames({ [styling.text]: hasTextClassname })}>{title}</span>
    </NavLink>
  )

  return isDropdownItem ? <DropdownItem>{link}</DropdownItem> : <>{link}</>
}

const Header = () => {
  const { state, pathname } = useLocation()
  const isUserAuthenticated = useSelector(state => isAuthenticated(state))
  const showSignOutLink = isUserAuthenticated && pathname !== staticRoutes.signIn.pathname
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const [mobileMenuExpansions, toggleMobileMenuExpansions] = useState({
    moveMoney: false,
    more: false,
  })
  const [desktopMenuExpansions, toggleDesktopMenuExpansions] = useState({
    moveMoney: false,
    more: false,
    membership: false,
  })
  const [isStuck, setIsStuck] = useState(false)
  const [rootPath, setRootPath] = useState('')
  const [animateClass, setAnimateClass] = useState('closed')

  const customer = useSelector(state => state.customer)
  const hasSubscription = customer?.hasSubscription
  const firstName = customer?.firstName || ''
  const lastName = customer?.lastName || ''
  const isStandardUser = !customer?.hasElevate && !customer?.hasPremium
  const application = useSelector(state => state.application)
  const applicationStatus = application?.customerApplicationStatus

  const userFullName = `${firstName} ${lastName}`

  const { webEnableCommunityTab } = useFlags()

  const moveMoneySubLinks = getMoveMoneySubLinks()
  const moreSubLinks = getMoreSubLinks({
    applicationStatus,
    showSignOutLink,
    isStandardUser,
    communityTabEnabled: webEnableCommunityTab,
    hasSubscription,
  })
  const membershipSubLinks = getMembershipSubLinks({ applicationStatus, hasSubscription })

  useEffect(() => {
    window.addEventListener('scroll', throttle(handleScroll, 100))
    return () => {
      window.removeEventListener('scroll', throttle(handleScroll, 100))
    }
  }, [])

  const handleScroll = () => {
    setIsStuck(window.scrollY > 0)
  }

  useEffect(() => {
    setIsMobileMenuOpen(false)
    const tempRootPath = pathname?.split('/')?.[1] || ''
    setRootPath(tempRootPath)

    toggleMobileMenuExpansions({
      more: tempRootPath === 'settings',
      moveMoney: tempRootPath === 'move-money',
    })
  }, [pathname])

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen)
  }

  useEffect(() => {
    // prevent page behind menu from scrolling
    if (isMobileMenuOpen) {
      setAnimateClass('open')
      document.body.classList.add('mobile-menu-open')
    } else {
      setAnimateClass('closed')
      document.body.classList.remove('mobile-menu-open')
    }
  }, [isMobileMenuOpen])

  const headerClasses = classNames(styling.header, {
    [styling['black-bg']]: isStuck || isMobileMenuOpen,
  })

  const mobileMenuToggleClasses = classNames(styling.hamburger, styling['hamburger--squeeze'], {
    [styling['is-active']]: isMobileMenuOpen,
  })

  const mobileMenuContainerClasses = classNames(styling['mobile-menu'], {
    [styling['is-open']]: isMobileMenuOpen,
  })

  const getMoreDropdownItems = (isDesktop = false) => (
    moreSubLinks.map(({ toRoute, title, icon, hasTextClassname, isExact }) => {
      const props = {
        toRoute,
        title: isDesktop ? <span>{title}</span> : title,
        hasTextClassname,
        state: title?.toLowerCase() === 'giving' ? { ...state, fromPathname: pathname } : undefined,
      }

      if (typeof isExact === 'boolean') {
        props.isExact = isExact
      }

      if (isDesktop) {
        props.icon = icon
        props.isDropdownItem = true
      }

      return <HeaderLink key={title} {...props}/>
    })
  )

  return (
    <div className={headerClasses}>
      <div className={`${styling['header-content']} row`}>
        <Link to={staticRoutes.dashboard.pathname}>
          <GreenwoodLogo className={styling.logo} />
        </Link>

        {isUserAuthenticated && (
          <>
            <div className={styling['mobile-menu-container']}>
              <motion.nav initial={false} animate={animateClass}>
                <button
                  className={mobileMenuToggleClasses}
                  onClick={toggleMobileMenu}
                  type='button'
                >
                  <span className={styling['hamburger-box']}>
                    <span className={styling['hamburger-inner']} />
                  </span>
                </button>

                <div className={mobileMenuContainerClasses}>
                  <motion.ul variants={menuVariants}>
                    <motion.li variants={menuItemVariants}>
                      <NavLink
                        end
                        className={({ isActive }) => isActive ? styling.active : ''}
                        to={staticRoutes.dashboard.pathname}
                      >
                        <HomeIcon className={styling['home-icon']} />
                        <span>
                          <span className={styling.text}>Dashboard</span>
                        </span>
                      </NavLink>
                    </motion.li>

                    {applicationStatus === 'PASS' && (
                      <>
                        <motion.li variants={menuItemVariants}>
                          <div className={styling.collapsible}>
                            <button
                              className={classNames({
                                [styling.active]:
                                  mobileMenuExpansions.moveMoney || rootPath === 'move-money',
                              })}
                              onClick={() =>
                                toggleMobileMenuExpansions({
                                  ...mobileMenuExpansions,
                                  moveMoney: !mobileMenuExpansions.moveMoney,
                                })
                              }
                            >
                              <MoneyBagIcon className={styling['money-bag-icon']} />
                              Move Money
                            </button>
                          </div>
                          <Collapse open={mobileMenuExpansions.moveMoney}>
                            <div className={styling['expanded-container']}>
                              {moveMoneySubLinks.map(({ toRoute, title }) => (
                                <HeaderLink toRoute={toRoute} title={title} key={title} />
                              ))}
                            </div>
                          </Collapse>
                        </motion.li>

                        <motion.li variants={menuItemVariants}>
                          <NavLink
                            className={({ isActive }) => isActive ? styling.active : ''}
                            to={staticRoutes.myCard.pathname}
                          >
                            <BankCardIcon className={styling['bank-card-icon']} />
                            <span>
                              <span className={styling.text}>My Card</span>
                            </span>
                          </NavLink>
                        </motion.li>

                        <motion.li variants={menuItemVariants}>
                          {isStandardUser ? (
                            <NavLink
                              className={({ isActive }) => isActive ? styling.active : ''}
                              to={staticRoutes.giving.pathname}
                              state={{ ...state, fromPathname: pathname }}
                            >
                              <GivingHeartIcon className={styling['giving-heart-icon']} />
                              <span>
                                <span className={styling.text}>Giving</span>
                              </span>
                            </NavLink>
                          ) : (
                            <NavLink
                              className={({ isActive }) => isActive ? styling.active : ''}
                              to={staticRoutes.invest.pathname}
                            >
                              <InvestIcon className={styling['invest-icon']}/>
                              <span>
                                <span className={styling.text}>Invest</span>
                              </span>
                            </NavLink>
                          )}
                        </motion.li>
                      </>
                    )}

                    <motion.li variants={menuItemVariants}>
                      {(isStandardUser || !webEnableCommunityTab) ? (
                        <>
                          <div className={styling.collapsible}>
                            <button
                              className={classNames({
                                [styling.active]:
                                  mobileMenuExpansions.membership || rootPath === 'membership',
                              })}
                              onClick={() => {
                                toggleMobileMenuExpansions({
                                  ...mobileMenuExpansions,
                                  membership: !mobileMenuExpansions.membership,
                                })
                                trackEvent({
                                  event: SEGMENT_EVENTS.DASHBOARD_BUTTON_CLICK(
                                    SEGMENT_SOURCE_DETAILS.PROFILE_ICON
                                  ),
                                })
                              }}
                            >
                              <ProfileIcon className={styling['membership-icon']} />
                              Membership
                            </button>
                          </div>
                          <Collapse open={mobileMenuExpansions.membership}>
                            <div className={styling['expanded-container']}>
                              {membershipSubLinks.map(({ toRoute, title }) => (
                                <HeaderLink
                                  toRoute={toRoute}
                                  title={title}
                                  key={title}
                                  onClick={() =>
                                    trackEvent({
                                      event: SEGMENT_EVENTS.PROFILE_ICON_DROPDOWN_ITEM_CLICK(title),
                                    })
                                  }
                                />
                              ))}
                            </div>
                          </Collapse>
                        </>
                      ) : (
                        <NavLink
                          className={({ isActive }) => isActive ? styling.active : ''}
                          to={staticRoutes.community.pathname}
                        >
                          <CommunityIcon className={styling['community-icon']} />
                          <span>
                            <span className={styling.text}>Community</span>
                          </span>
                        </NavLink>
                      )}
                    </motion.li>

                    <motion.li variants={menuItemVariants}>
                      <div className={styling.collapsible}>
                        <button
                          className={classNames({
                            [styling.active]: mobileMenuExpansions.more || rootPath === 'settings',
                          })}
                          onClick={() => {
                            trackEvent({
                              event: SEGMENT_EVENTS.DASHBOARD_BUTTON_CLICK(
                                SEGMENT_SOURCE_DETAILS.MORE
                              ),
                            })

                            toggleMobileMenuExpansions({
                              ...mobileMenuExpansions,
                              more: !mobileMenuExpansions.more,
                            })
                          }}
                        >
                          <DotsGridIcon />
                          More
                        </button>
                      </div>
                      <Collapse open={mobileMenuExpansions.more}>
                        <div className={styling['expanded-container']}>
                          {getMoreDropdownItems()}
                        </div>
                      </Collapse>
                    </motion.li>
                  </motion.ul>
                </div>
              </motion.nav>
            </div>

            <div className={styling['desktop-menu-container']}>
              <nav>
                <ul>
                  <li>
                    <HeaderLink
                      toRoute={staticRoutes.dashboard.pathname}
                      title='Dashboard'
                      icon={<HomeIcon className={styling['home-icon']} />}
                      hasTextClassname={false}
                    />
                  </li>
                  {applicationStatus === 'PASS' && (
                    <>
                      <li>
                        <Dropdown
                          nav
                          open={desktopMenuExpansions.moveMoney}
                          toggle={() =>
                            toggleDesktopMenuExpansions({
                              ...desktopMenuExpansions,
                              moveMoney: !desktopMenuExpansions.moveMoney,
                            })
                          }
                        >
                          <DropdownToggle
                            nav
                            className={classNames({
                              [styling.active]:
                                desktopMenuExpansions.moveMoney || rootPath === 'move-money',
                            })}
                          >
                            <MoneyBagIcon className={styling['money-bag-icon']} />
                            <span>Move Money</span>
                          </DropdownToggle>
                          <DropdownMenu className={styling['sub-menu']} alignment='center'>
                            {moveMoneySubLinks.map(({ toRoute, title, icon }) => (
                              <HeaderLink
                                toRoute={toRoute}
                                title={<span>{title}</span>}
                                icon={icon}
                                isDropdownItem
                                key={title}
                              />
                            ))}
                          </DropdownMenu>
                        </Dropdown>
                      </li>
                      <li>
                        <HeaderLink
                          toRoute={staticRoutes.myCard.pathname}
                          title='My Card'
                          icon={<BankCardIcon className={styling['bank-card-icon']} />}
                          hasTextClassname={false}
                          isExact={false}
                        />
                      </li>
                      <li>
                        {isStandardUser ? (
                          <HeaderLink
                            toRoute={staticRoutes.giving.pathname}
                            state={{ ...state, fromPathname: pathname }}
                            title='Giving'
                            icon={<GivingHeartIcon className={styling['giving-heart-icon']} />}
                            data-cy='giving-header-link'
                          />
                        ) : (
                          <HeaderLink
                            toRoute={staticRoutes.invest.pathname}
                            title='Invest'
                            icon={<InvestIcon className={styling['invest-icon']} />}
                            hasTextClassname={false}
                            data-cy='invest-header-link'
                          />
                        )}
                      </li>
                    </>
                  )}
                  <li>
                    {(isStandardUser || !webEnableCommunityTab) ? (
                      <Dropdown
                        nav
                        open={desktopMenuExpansions.membership}
                        toggle={() =>
                          toggleDesktopMenuExpansions({
                            ...desktopMenuExpansions,
                            membership: !desktopMenuExpansions.membership,
                          })
                        }
                      >
                        <DropdownToggle
                          nav
                          className={classNames({
                            [styling.active]:
                              desktopMenuExpansions.membership || rootPath === 'membership',
                          })}
                          onClick={() => {
                            trackEvent({
                              event: SEGMENT_EVENTS.DASHBOARD_BUTTON_CLICK(
                                SEGMENT_SOURCE_DETAILS.PROFILE_ICON
                              ),
                            })
                          }}
                        >
                          <ProfileIcon className={styling['membership-icon']} />
                          <span>Membership</span>
                        </DropdownToggle>
                        <DropdownMenu className={styling['sub-menu']} alignment='center'>
                          {userFullName && <p className={styling.name}>{userFullName}</p>}
                          {membershipSubLinks.map(({ toRoute, title, icon }) => (
                            <HeaderLink
                              toRoute={toRoute}
                              title={<span>{title}</span>}
                              icon={icon}
                              isDropdownItem
                              key={title}
                              onClick={() =>
                                trackEvent({
                                  event: SEGMENT_EVENTS.PROFILE_ICON_DROPDOWN_ITEM_CLICK(title),
                                })
                              }
                            />
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    ) : (
                      <HeaderLink
                        toRoute={staticRoutes.community.pathname}
                        title='Community'
                        icon={<CommunityIcon className={styling['community-icon']} />}
                        data-cy='community-header-link'
                        isExact={false}
                      />
                    )}
                  </li>
                  <li>
                    <Dropdown
                      nav
                      open={desktopMenuExpansions.more}
                      toggle={() =>
                        toggleDesktopMenuExpansions({
                          ...desktopMenuExpansions,
                          more: !desktopMenuExpansions.more,
                        })
                      }
                    >
                      <DropdownToggle
                        nav
                        className={classNames({
                          [styling.active]: desktopMenuExpansions.more || rootPath === 'settings',
                        })}
                        onClick={() => {
                          trackEvent({
                            event: SEGMENT_EVENTS.DASHBOARD_BUTTON_CLICK(
                              SEGMENT_SOURCE_DETAILS.MORE
                            ),
                          })
                        }}
                        data-cy='more-button'
                      >
                        <DotsGridIcon />
                        <span>More</span>
                      </DropdownToggle>
                      <DropdownMenu className={styling['sub-menu']} alignment='right'>
                        {userFullName && <p className={styling.name}>{userFullName}</p>}
                        {getMoreDropdownItems(true)}
                      </DropdownMenu>
                    </Dropdown>
                  </li>
                </ul>
              </nav>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

Header.propTypes = {
  userFullName: PropTypes.string,
  applicationStatus: PropTypes.string,
}

export default Header
