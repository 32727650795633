import React from 'react'

import styling from './downloadAppCallout.module.scss'
import Card from '@shared/components/card/Card'
import AppLinks from './AppLinks'

const DownloadAppCallout = props => {
  return (
    <Card className={styling.card} size='small'>
      <h3>Download the Greenwood app</h3>
      <p>Use the app to find fee-free ATMs, get real-time alerts, view your rewards, and more.</p>
      <AppLinks />
    </Card>
  )
}

DownloadAppCallout.propTypes = {}

export default DownloadAppCallout
