import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import LoadingContainer from '@shared/components/loadingContainer/LoadingContainer'
import FormRadio from '@shared/components/formRadio/FormRadio'
import Button from '@shared/components/button/Button'

import { gql, useQuery, useMutation } from '@services/serviceUtils'
import { MFA_CHANNELS } from '@common/constants'
import { staticRoutes } from '@routing/routes'
import { addAlertAction } from '@redux/alerts/alertsActions'
import { ALERT_TYPES, DEFAULT_ALERT_DISMISS_DELAY } from '@shared/constants/uiConstants'

import styling from './verificationCodes.module.scss'

const availableMfaChannelsQuery = gql`
  query AvailableMfaChannels {
    availableMfaChannels {
      destination
      mfaChannel
      preferred
    }
  }
`

const updateMfaFavoriteChannelMutation = gql`
  mutation UpdateMfaFavoriteChannel($mfaChannelWrapperInput: MfaChannelWrapperInput!) {
    updateMfaFavoriteChannel(mfaChannelWrapperInput: $mfaChannelWrapperInput)
  }
`

const VerificationCodes = () => {
  const [verificationCodeOptions, setVerificationCodeOptions] = useState([])
  const [defaultVerificationCodeOption, setDefaultVerificationCodeOption] = useState('')
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const { loading: mfaChannelsLoading, error: mfaChannelsError, data: mfaChannelsData } = useQuery(
    availableMfaChannelsQuery,
    { fetchPolicy: 'no-cache' }
  )

  const [updateMfaChannel, { loading: updateMfaChannelLoading }] = useMutation(
    updateMfaFavoriteChannelMutation
  )

  useEffect(() => {
    const options = []

    mfaChannelsData?.availableMfaChannels?.forEach((channel, index) => {
      const { destination, preferred, mfaChannel } = channel

      const title = mfaChannel === MFA_CHANNELS.SMS ? 'Text to' : 'Email to'

      // set the default option to this mfa channel, if preferred is true
      if (preferred) {
        setDefaultVerificationCodeOption(mfaChannel)
      }

      // if it's a valid mfa channel, then add it to the options list
      if (mfaChannel !== MFA_CHANNELS.NONE) {
        const label = (
          <div key={index}>
            <p className={styling.label}>{title}</p>
            <p>{destination}</p>
          </div>
        )

        options.push({
          label,
          value: mfaChannel,
        })
      }

      setVerificationCodeOptions(options)
    })
  }, [mfaChannelsData?.availableMfaChannels])

  const handleUpdateMfaChannel = async values => {
    const result = await updateMfaChannel({
      variables: {
        mfaChannelWrapperInput: {
          newFavoriteChannel: values.verificationCodeOption,
        },
      },
    })

    if (result?.data) {
      dispatch(
        addAlertAction({
          dismissible: true,
          autoDismissDelay: DEFAULT_ALERT_DISMISS_DELAY,
          type: ALERT_TYPES.SUCCESS,
          text: 'Successfully updated your preference',
        })
      )

      navigate(staticRoutes.settingsPrivacySecurity.pathname)
    }
  }

  return (
    <div
      className={classNames(
        'settings-view-content',
        styling['settings-privacy-verification-codes-container']
      )}
    >
      <h2>Verification Codes</h2>
      <p className='subtext'>Please, select where you would like verification codes to be sent.</p>
      <LoadingContainer
        error={mfaChannelsError}
        errorMessage='Error loading verification code channels'
        loading={mfaChannelsLoading}
      >
        <Formik
          initialValues={{
            verificationCodeOption: defaultVerificationCodeOption,
          }}
          enableReinitialize
          onSubmit={handleUpdateMfaChannel}
        >
          {({ errors, isSubmitting, touched, values, setFieldTouched, setFieldValue }) => {
            const isDisabled =
              !touched.verificationCodeOption ||
              [MFA_CHANNELS.NONE, defaultVerificationCodeOption].includes(
                values.verificationCodeOption
              )

            return (
              <Form className={styling['verification-codes-form']}>
                <Field
                  as={FormRadio}
                  name='verificationCodeOption'
                  id='verificationCodeOption'
                  type='radio'
                  invalid={errors.verificationCodeOption && touched.verificationCodeOption}
                  errorText={errors.verificationCodeOption}
                  disabled={isSubmitting}
                  options={verificationCodeOptions}
                  data-cy='privacy-security-verification-code-option-list'
                  className={styling['verification-code-options-radio']}
                  defaultSelected={defaultVerificationCodeOption}
                  labelClassname={styling['form-label']}
                  setFieldValue={setFieldValue}
                  onChange={() => setFieldTouched('verificationCodeOption', true)}
                />
                <p className={styling.label}>Message and data rates may apply.</p>
                <Button
                  type='submit'
                  isLoading={isSubmitting || updateMfaChannelLoading}
                  disabled={isDisabled}
                >
                  Save
                </Button>
                <Button
                  ghost
                  type='button'
                  onClick={() => navigate(staticRoutes.settingsPrivacySecurity.pathname)}
                >
                  Back
                </Button>
              </Form>
            )
          }}
        </Formik>
      </LoadingContainer>
    </div>
  )
}

export default VerificationCodes
