import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate, useLocation } from 'react-router-dom'

import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'
import Button from '@shared/components/button/Button'
import ExitModal from '@common/components/exitModal/ExitModal'
import Fee from '@common/components/fee/Fee'

import { staticRoutes } from '@routing/routes'
import {
  FUNDING_FLOWS,
  DEBIT_CARD_DEPOSIT_STATUS_TYPES,
  DEPOSIT_AND_TRANSFER_DISCLAIMER_TEXT,
  DEBIT_CARD_DEPOSIT_3DS_SESSION_TYPES,
  TABAPAY_3DS_SONGBIRD_URL,
} from '@common/constants'
import { toDollars, toMediumDateFormat } from '@shared/utils'
import { gql, useMutation } from '@services/serviceUtils'
import {
  trackPage,
  trackEvent,
  SEGMENT_PAGE_NAMES,
  SEGMENT_EVENTS,
  SEGMENT_SOURCE_DETAILS,
} from '@common/utils'
import {
  resetDebitCardDepositDetailsAction,
  setDebitCardDepositAttemptNumberAction,
} from '@redux/debitCardDepositDetails/debitCardDepositDetailsActions'
import useScript from '@shared/utils/useScript'
import { handleTabapay3DSChallenge } from '@common/utils/tabapayUtils'
import { removeAlertsAction } from '@redux/alerts/alertsActions'

const pullFundsFromExistingCardV2Mutation = gql`
  mutation PullFundsFromExistingCardV2(
    $cardId: ID!
    $pullTransactionInputDTOInput: PullTransactionInputDTOInput!
  ) {
    pullFundsFromExistingCardV2(
      cardId: $cardId
      pullTransactionInputDTOInput: $pullTransactionInputDTOInput
    ) {
      linkedCardTransaction {
        transactionId
        transactionStatus
      }
      transactionSession {
        jwt
        transactionSessionId
        transactionSessionState
      }
    }
  }
`

const pullFundsFromExistingCardChallengeMutation = gql`
  mutation PullFundsFromExistingCardChallenge(
    $linkedCardTransactionSessionInputDTOInput: LinkedCardTransactionSessionInputDTOInput!
    $sessionId: ID!
  ) {
    pullFundsFromExistingCardChallenge(
      linkedCardTransactionSessionInputDTOInput: $linkedCardTransactionSessionInputDTOInput
      sessionId: $sessionId
    ) {
      linkedCardTransaction {
        transactionId
        transactionStatus
      }
      transactionSession {
        challengeUrl
        payload
        transactionId
        transactionSessionId
        transactionSessionState
      }
    }
  }
`

const DebitCardDepositReview = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const dispatch = useDispatch()
  const debitCardDepositDetails = useSelector(state => state.debitCardDepositDetails)
  const attemptNumber = useSelector(state => state.debitCardDepositAttemptNumber)

  const scriptName = `${TABAPAY_3DS_SONGBIRD_URL}?attemptNumber=${attemptNumber}`
  useScript(scriptName)

  const { cvv, depositFrom, depositTo, depositAmount, feePercent, feeAmount } =
    debitCardDepositDetails || {}
  const totalAmount = feeAmount + depositAmount

  const [showCancelDepositModal, toggleShowCancelDepositModal] = useState(false)

  const fundingFlow = state?.fundingFlow
  const shouldSetupDirectDeposit = state?.shouldSetupDirectDeposit

  const [submitDebitCardDepositRequest, { loading: depositSubmitting }] = useMutation(
    pullFundsFromExistingCardV2Mutation
  )

  const [submit3DSChallengeRequest, { loading: challengeFlowSubmitting }] = useMutation(
    pullFundsFromExistingCardChallengeMutation,
    {
      notifyOnNetworkStatusChange: true,
    }
  )

  useEffect(() => {
    return () => {
      const cardinal = window?.Cardinal

      cardinal?.off('payments.setupComplete')
      cardinal?.off('payments.validated')

      dispatch(setDebitCardDepositAttemptNumberAction(attemptNumber + 1))
    }
  }, [attemptNumber, dispatch])

  const getFundFromAccountDetails = account => {
    return `${account?.network} (...${account?.maskedNumber})`
  }

  const redirectToSuccessPage = transactionId => {
    const newState = {
      transactionId,
      fundingFlow,
      shouldSetupDirectDeposit,
    }

    navigate(staticRoutes.debitCardDepositSuccess.pathname, { state: newState })
  }

  const submitTransactionDetails = async ({ jwt, sessionId }) => {
    return await submit3DSChallengeRequest({
      variables: {
        linkedCardTransactionSessionInputDTOInput: {
          deviceCollectionType: 'BROWSER',
          challengeJwt: jwt,
        },
        sessionId,
      },
    })
  }

  const handleCancelClick = () => {
    dispatch(resetDebitCardDepositDetailsAction())

    if (fundingFlow === FUNDING_FLOWS.DEBIT_CARD_DEPOSIT) {
      navigate(staticRoutes.moveMoneyAddMoney.pathname)
    } else if (shouldSetupDirectDeposit) {
      navigate(staticRoutes.directDepositSetup.pathname, {
        state: { fundingFlow: state?.fundingFlow },
      })
    } else {
      navigate(staticRoutes.dashboard.pathname)
    }
  }

  const handleSubmit = async () => {
    dispatch(removeAlertsAction())

    const response = await submitDebitCardDepositRequest({
      variables: {
        cardId: depositFrom?.id,
        pullTransactionInputDTOInput: {
          amount: depositAmount,
          cvv,
        },
      },
    })

    trackEvent({
      event: SEGMENT_EVENTS.INSTANT_CARD_DEPOSIT_BUTTON_CLICK({
        sourceDetail: SEGMENT_SOURCE_DETAILS.CONFIRM,
      }),
    })

    if (response?.data) {
      const {
        linkedCardTransaction = {},
        transactionSession: initialTransactionSession = {},
      } = response.data?.pullFundsFromExistingCardV2

      if (linkedCardTransaction?.transactionStatus === DEBIT_CARD_DEPOSIT_STATUS_TYPES.SUCCESS) {
        // if transaction is successful, then redirect the user to the success page
        redirectToSuccessPage({ transactionId: linkedCardTransaction?.transactionId })
      } else if (
        initialTransactionSession?.transactionSessionState ===
        DEBIT_CARD_DEPOSIT_3DS_SESSION_TYPES.DEVICE_CHECK
      ) {
        // otherwise, begin the 3ds challenge flow
        const { jwt = '', transactionSessionId = '' } = initialTransactionSession

        handleTabapay3DSChallenge({
          jwt,
          redirectToSuccessPage: transactionId => redirectToSuccessPage(transactionId),
          submitTransactionDetails: newJwt =>
            submitTransactionDetails({
              jwt: newJwt,
              sessionId: transactionSessionId,
            }),
          navigateToDepositForm: () =>
            navigate(staticRoutes.debitCardDeposit.pathname, { state }),
        })
      }
    }
  }

  // Tracks page visit
  useEffect(() => {
    trackPage({ name: SEGMENT_PAGE_NAMES.INSTANT_CARD_DEPOSIT_CONFIRM })
  }, [])

  return (
    <>
      {!depositAmount && (
        <Navigate to={staticRoutes.debitCardDeposit.pathname} state={state} />
      )}
      <div className='white-card-container'>
        <GreenwoodLogo className='logo' />
        <h1>Confirm Deposit</h1>
        <p>If you want to change any information below, please go back and edit.</p>
        <table className='simple-table fixed-height'>
          <tbody>
            <tr>
              <th>
                <span>Fund From</span>
              </th>
              <td>{getFundFromAccountDetails(depositFrom)}</td>
            </tr>
            <tr>
              <th>
                <span>Deposit To</span>
              </th>
              <td>{depositTo}</td>
            </tr>
            <tr>
              <th>
                <span>Available</span>
              </th>
              <td>{toMediumDateFormat(new Date())} (within minutes)</td>
            </tr>
            <tr>
              <th>
                <span>Deposit Amount</span>
              </th>
              <td>{toDollars({ value: depositAmount, isSuperscript: false })}</td>
            </tr>
            <tr>
              <th>
                <span>
                  <Fee
                    feePercent={feePercent}
                    segmentSource={SEGMENT_PAGE_NAMES.INSTANT_CARD_DEPOSIT_CONFIRM}
                  />
                </span>
              </th>
              <td>{toDollars({ value: feeAmount, isSuperscript: false })}</td>
            </tr>
            <tr>
              <th>
                <span>Total Charge</span>
              </th>
              <td>{toDollars({ value: totalAmount, isSuperscript: false })}</td>
            </tr>
          </tbody>
        </table>
        <Button
          onClick={handleSubmit}
          disabled={depositSubmitting || challengeFlowSubmitting}
          className='additional-button'
          data-cy='debit-card-deposit-review-submit-button'
          isLoading={depositSubmitting || challengeFlowSubmitting}
        >
          Confirm
        </Button>
        <Button
          onClick={() => navigate(staticRoutes.debitCardDeposit.pathname, { state })}
          outline
          className='additional-button'
          data-cy='debit-card-deposit-review-edit-button'
        >
          Edit
        </Button>
        <Button
          onClick={() => {
            toggleShowCancelDepositModal(true)
            trackEvent({
              event: SEGMENT_EVENTS.INSTANT_CARD_DEPOSIT_BUTTON_CLICK({
                sourceDetail: SEGMENT_SOURCE_DETAILS.CANCEL,
              }),
            })
          }}
          ghost
          className='additional-button'
          data-cy='debit-card-deposit-review-cancel-button'
        >
          Cancel
        </Button>
        <p className='disclaimer-text'>{DEPOSIT_AND_TRANSFER_DISCLAIMER_TEXT}</p>
        <ExitModal
          isOpen={showCancelDepositModal}
          onContinueClick={handleCancelClick}
          toggleModal={() => toggleShowCancelDepositModal(!showCancelDepositModal)}
          closeModal={() => toggleShowCancelDepositModal(false)}
        />
      </div>
    </>
  )
}

export default DebitCardDepositReview
