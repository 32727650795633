import React from 'react'
import PropTypes from 'prop-types'

import Modal from '@shared/components/modal/Modal'
import ModalBody from '@shared/components/modal/ModalBody'
import ModalHeader from '@shared/components/modal/ModalHeader'

const InvalidSessionModal = ({ isOpen = false, closeModal }) => {
  return (
    <Modal centered size='md' open={isOpen} toggleModal={closeModal}>
      <ModalHeader withCloseButton closeModal={closeModal}>
        <h4>Expired Code</h4>
      </ModalHeader>
      <ModalBody>
        <div>
          <p>This verification code has expired. Please resend a new code and try again.</p>
        </div>
      </ModalBody>
    </Modal>
  )
}

InvalidSessionModal.propTypes = {
  /**
   * Boolean value for whether or not the Modal is open
   */
  isOpen: PropTypes.bool.isRequired,
  /**
   * Function for closing the modal
   */
  closeModal: PropTypes.func.isRequired,
}

export default InvalidSessionModal
