import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styling from './viewContainer.module.scss'
import { useLocation } from 'react-router-dom'

const ViewContainer = ({ children, showHeaderFooter }) => {
  const location = useLocation()
  const locationJSONString = JSON.stringify(location)

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0
  }, [locationJSONString])

  return (
    <div
      className={classNames(
        styling['view-container'],
        showHeaderFooter && styling['has-header-footer']
      )}
    >
      {children}
    </div>
  )
}

ViewContainer.propTypes = {
  children: PropTypes.node,
}

export default ViewContainer
