import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form, Field } from 'formik'

import FormRadio from '@shared/components/formRadio/FormRadio'
import FundingAccountDetail from './FundingAccountDetail'

import styling from './accountOptionsForm.module.scss'

/* Combines the linked and greenwood account lists with some data-massaging to contain matching
   data format */
const combineAccounts = ({ linkedAccounts = [], greenwoodAccounts = [] }) => {
  return greenwoodAccounts
    .map(greenwoodAccount => {
      const accountNumber = greenwoodAccount.accountNumber

      return {
        id: greenwoodAccount.id,
        title: greenwoodAccount.title,
        lastFourAccountDigits: accountNumber.slice(accountNumber.length - 4),
        amount: greenwoodAccount.balance?.amount,
      }
    })
    .concat(
      linkedAccounts.map(linkedAccount => ({
        id: linkedAccount.id,
        title: linkedAccount.title,
        lastFourAccountDigits: linkedAccount.mask,
        amount: linkedAccount.balanceWhenLinked?.amount,
      }))
    )
}

// Maps a combined account list to a set of radio options
const mapAccountsToOptions = ({ accounts }) => {
  return accounts.map(account => ({
    value: account.id,
    label: <FundingAccountDetail {...account} />,
  }))
}

// A form component to display a list of selectable account options
const AccountOptionsForm = ({
  linkedAccounts = [],
  greenwoodAccounts = [],
  onAccountChange = () => {},
}) => {
  const options = mapAccountsToOptions({
    accounts: combineAccounts({ linkedAccounts, greenwoodAccounts }),
  })

  const defaultOption = options.length > 0 && options[0].value

  // As soon as options are available, pick the first one
  useEffect(() => {
    if (defaultOption) {
      onAccountChange(defaultOption)
    }
  }, [defaultOption, onAccountChange])

  const handleFormValueChange = event => {
    const value = event?.target?.value

    if (value) {
      onAccountChange(value)
    }
  }

  return options.length > 0 ? (
    <Formik
      initialValues={{
        account: defaultOption,
      }}
    >
      <Form noValidate onChange={handleFormValueChange}>
        <Field
          className={styling['form-radio-container']}
          as={FormRadio}
          name='account'
          id='account'
          type='radio'
          options={options}
          defaultSelected={defaultOption}
          data-cy='account-options-list'
        />
      </Form>
    </Formik>
  ) : null
}

AccountOptionsForm.propTypes = {
  /**
   * An optional list of Plaid Linked accounts
   */
  linkedAccounts: PropTypes.array,

  /**
   * An optional list of Greenwood accounts
   */
  greenwoodAccounts: PropTypes.array,

  /**
   * An optional callback to execute any time the selected account changes
   */
  onAccountChange: PropTypes.func,
}

export default AccountOptionsForm
