import { ONBOARDING_ROUTES } from '@common/constants'
import { CHALLENGE_TYPES, ROUTE_USER_FLOW } from '@shared/constants/uiConstants'
import { staticRoutes, ROUTE_AUTH_REQUIREMENTS } from '@routing/routes'
import { store } from '@redux/store'
import { setSessionIdAction } from '@redux/auth/authActions'

/**
 * Iterates over the known checkpoints, finding the first incomplete
 * checkpoint. If no checkpoint is found, returns null.
 */
export const getNextIncompleteCheckpoint = checkpoints => {
  for (const [key, value] of Object.entries(ONBOARDING_ROUTES)) {
    if (value.isCheckpoint && !checkpoints?.values?.includes(key)) {
      return key
    }
  }

  return null
}

/**
 * Maps a checkpoint to its respective route, or review page if none is found
 */
export const getCheckpointRoute = checkpoint => {
  const onboardingRoute = ONBOARDING_ROUTES[checkpoint]

  if (onboardingRoute?.isCheckpoint) {
    return onboardingRoute.route
  } else {
    return staticRoutes.createAccountReview.pathname
  }
}

/**
 * Gets the next mapped incomplete checkpoint route, given a list of completed
 * checkpoints
 */
export const getNextIncompleteCheckpointRoute = checkpoints =>
  getCheckpointRoute(getNextIncompleteCheckpoint(checkpoints))

/**
 * Checks if the given route maps to a completed checkpoint route
 */
export const isCompleteCheckpointPath = ({ checkpoints = { values: [] }, pathname }) => {
  for (const checkpointValue of checkpoints.values) {
    const onboardingRoute = ONBOARDING_ROUTES[checkpointValue]
    if (onboardingRoute?.isCheckpoint && onboardingRoute?.route === pathname) {
      return true
    }
  }
  return false
}

/**
 * Checks if the given route is part of a specified group, like onboarding
 */
export const isRouteOfGroup = ({ pathname = '', group = {} } = {}) => {
  for (const value of Object.values(group)) {
    if (value?.route === pathname) {
      return true
    }
  }
  return false
}

export const getRedirectLocationByChallengeTypeAndUserFlow = ({
  challengeType,
  userFlow,
  desiredSigninPathname,
  sessionId,
  ...additionalState
} = {}) => {
  const state = {}

  if (sessionId) {
    store.dispatch(setSessionIdAction(sessionId))
  }

  for (const property in additionalState) {
    if (additionalState[property]) {
      state[property] = additionalState[property]
    }
  }

  if (userFlow) {
    state.userFlow = userFlow
  }

  let pathname
  switch (challengeType) {
    case CHALLENGE_TYPES.RESET_PASSWORD:
      pathname = staticRoutes.recoverProvidePassword.pathname
      break

    case CHALLENGE_TYPES.VERIFY_EMAIL:
      pathname = staticRoutes.verifyEmail.pathname
      break

    case CHALLENGE_TYPES.VERIFY_PHONE_NUMBER:
      pathname = staticRoutes.verifyPhone.pathname
      break

    case CHALLENGE_TYPES.PROVIDE_PHONE_NUMBER:
      pathname = staticRoutes.providePhone.pathname
      break

    case CHALLENGE_TYPES.SELECT_MFA_CHANNEL:
      pathname = staticRoutes.chooseVerificationMethod.pathname
      break

    case CHALLENGE_TYPES.VERIFY_MFA:
      pathname = staticRoutes.submitVerificationCode.pathname
      break

    case CHALLENGE_TYPES.NONE:
      if (userFlow === ROUTE_USER_FLOW.SIGNIN) {
        pathname = desiredSigninPathname || staticRoutes.dashboard.pathname
      } else if (userFlow === ROUTE_USER_FLOW.FORGOT_PASSWORD) {
        pathname = staticRoutes.signIn.pathname
      } else {
        pathname = staticRoutes.createAccountName.pathname
      }
      break

    default:
      pathname = staticRoutes.dashboard.pathname
  }

  return {
    pathname,
    state,
  }
}

/**
 * Securely opens a URL in a new browser tab
 * @param {String} url URL to open in a new tab
 */
export const openUrlInNewTab = url => {
  window.open(url, '_blank', 'noreferrer noopener')
}

/**
 * Used to filter routes based on the authentication state
 * @param {String} route The route to check
 * @param {Boolean} isAuthenticated Whether or not the user is currently authenticated
 * @returns True when the route has no auth requirements or has auth requirements and
 * the user auth state matches the requirement. False when the route has auth
 * requirements and the user auth state does not match the requirement
 */
export const routeAuthFilter = (route, isAuthenticated) => {
  /* Only filter out this value if requiresAuth is not NONE and user is not in
     the respective state */
  return (
    route.requiresAuth === undefined ||
    route.requiresAuth === ROUTE_AUTH_REQUIREMENTS.NONE ||
    (isAuthenticated && route.requiresAuth === ROUTE_AUTH_REQUIREMENTS.AUTHENTICATED) ||
    (!isAuthenticated && route.requiresAuth === ROUTE_AUTH_REQUIREMENTS.NOT_AUTHENTICATED)
  )
}
