import { useEffect } from 'react'
import { SEGMENT_EVENTS, trackPage, trackEvent, SEGMENT_PAGE_NAMES } from '@common/utils'
import { store } from '@redux/store'
import { setSegmentTrackingEventOccurredAction } from '@redux/segmentTracking/segmentTrackingActions'

const useAccountCreatedTracking = ({ accountType }) => {
  const initialState = store.getState()
  const { segmentTracking = {} } = initialState

  useEffect(() => {
    if (accountType && !segmentTracking[SEGMENT_PAGE_NAMES.BANK_ACCOUNT_CREATED]) {
      // Track congratulatory page visit
      trackPage({ name: SEGMENT_PAGE_NAMES.BANK_ACCOUNT_CREATED, account_type: accountType })

      // When a user visits the congratulatory page, track the event
      trackEvent({ event: SEGMENT_EVENTS.BANK_ACCOUNT_CREATED, account_type: accountType })

      store.dispatch(
        setSegmentTrackingEventOccurredAction({ [SEGMENT_PAGE_NAMES.BANK_ACCOUNT_CREATED]: true })
      )
    }
  }, [accountType, segmentTracking])
}

export default useAccountCreatedTracking
