import React from 'react'
import parse from 'react-html-parser'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { BANK_DISCLAIMER } from '@common/constants'

import styling from './footer.module.scss'

const Footer = ({ useWhiteBg }) => {
  return (
    <div className={classNames(styling.footer, useWhiteBg && styling['white-bg'])}>
      <div className='row'>
        <div className={styling.content}>
          <p>{parse(BANK_DISCLAIMER)}</p>
        </div>
      </div>
    </div>
  )
}

Footer.propTypes = {
  useWhiteBg: PropTypes.bool,
}

export default Footer
