import { useEffect, useState } from 'react'

import { store } from '@redux/store'

const useReduxStore = () => {
  const initialStore = store.getState()
  const [reduxStore, setReduxStore] = useState(initialStore)

  useEffect(() => {
    store.subscribe(() => {
      const state = store.getState()
      setReduxStore(state)
    })
  }, [])

  return {
    reduxStore,
    dispatch: store.dispatch,
  }
}

export default useReduxStore
