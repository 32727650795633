import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'

import ManualAddressEntryForm from '@common/components/manualAddressEntryForm/ManualAddressEntryForm'

import { staticRoutes } from '@routing/routes'

const AddressResubmissionEntry = ({ data: applicationData, updateApplication }) => {
  const navigate = useNavigate()

  const { application = null } = applicationData

  const selectedAddress = useMemo(() => {
    if (application) {
      return {
        deliveryAddress: application?.deliveryAddress,
        aptUnitFloor: application?.buildingNumber,
        locality: application?.locality,
        state: application?.subdivisions[0],
        zipCode: application?.postalCode,
      }
    }

    return {}
  }, [application])

  const submitAddress = async ({ actions, address }) => {
    const updateApplicationResponse = await updateApplication(address)
    actions.setSubmitting(false)

    // If successful, go to the review screen
    if (updateApplicationResponse?.data?.updateApplication?.applicationId) {
      navigate(staticRoutes.addressResubmissionReview.pathname)
    }
  }

  return (
    <div className='white-card-container'>
      <GreenwoodLogo className='logo' />
      <ManualAddressEntryForm
        isResubmitting
        selectedAddress={selectedAddress}
        selectedCity={application?.locality}
        submitAddress={addressData => submitAddress(addressData)}
      />
    </div>
  )
}

export default AddressResubmissionEntry
