import React from 'react'
import Lottie from 'react-lottie'
import loadingLottie from '@common/images/animations/loading-lottie.json'
import { LOTTIE_CONFIG } from '@common/constants'

const Loading = ({ styles, className = 'generic', title }) => {
  const lottieStyles = {
    width: 'unset',
    height: 'unset',
    margin: 'unset',
    ...styles,
  }

  return (
    <div styles={lottieStyles}>
      <Lottie
        key={`loader-for-${className.replace(/\s/gm, '')}`}
        title={title}
        options={{
          ...LOTTIE_CONFIG,
          loop: true,
          animationData: loadingLottie,
          rendererSettings: {
            ...LOTTIE_CONFIG.rendererSettings,
            className,
          },
        }}
      />
    </div>
  )
}

export default Loading
