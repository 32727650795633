import React, { useState, useEffect } from 'react'
import { Navigate, useNavigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { gql, useMutation } from '@services/serviceUtils'

import Button from '@shared/components/button/Button'
import ExitModal from '../exitModal/ExitModal'

import { toDollars } from '@shared/utils'
import { staticRoutes } from '@routing/routes'
import {
  FUNDING_FLOWS,
  TRANSFER_STATUS_TYPES,
  ACCOUNT_FUNDING_REQUEST_PURPOSES,
  FUNDING_STATES,
  DEPOSIT_AND_TRANSFER_DISCLAIMER_TEXT,
} from '@common/constants'
import {
  trackPage,
  trackEvent,
  SEGMENT_PAGE_NAMES,
  SEGMENT_EVENTS,
  SEGMENT_SOURCE_DETAILS,
} from '@common/utils'

const createTransferMutation = gql`
  mutation CreateTransfer($transferRequestDTOInput: TransferRequestDTOInput!) {
    createTransfer(transferRequestDTOInput: $transferRequestDTOInput) {
      id
      transferResponseStatus
      failureReason
    }
  }
`

const selectFundingAccountMutation = gql`
  mutation SelectFundingAccount($accountFundingRequestDTOInput: AccountFundingRequestDTOInput!) {
    selectFundingAccount(accountFundingRequestDTOInput: $accountFundingRequestDTOInput) {
      id
      fundingState
      fundingStateAcknowledged
    }
  }
`

const getAccountDetails = (account = {}) => {
  const accountLastFour = account?.accountNumber || account?.mask
  return Object.keys(account).length ? `${account?.title} (..${accountLastFour})` : ''
}

const TransferReview = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const transferDetails = useSelector(state => state.transferDetails)

  const fundingFlow = state?.fundingFlow
  const shouldSetupDirectDeposit = state?.shouldSetupDirectDeposit
  const transferFlowStartPath =
    state?.transferFlowStartPath || staticRoutes.moveMoneyAddMoney.pathname

  const [showCancelTransferModal, toggleShowCancelTransferModal] = useState(false)

  const [submitTransferRequest, { loading: transferSubmitting }] = useMutation(
    createTransferMutation
  )
  const [submitFundingRequest, { loading: fundingSubmitting }] = useMutation(
    selectFundingAccountMutation
  )

  const isSubmitting = transferSubmitting || fundingSubmitting

  const navigateTo = (pathname, state) => {
    navigate(pathname, { state })
  }

  const redirectAfterSubmission = (responseStatus, newState) => {
    switch (responseStatus) {
      case FUNDING_STATES.PROCESSING:
      case TRANSFER_STATUS_TYPES.PROCESSING:
        navigateTo(staticRoutes.transferProcessing.pathname, newState)
        break
      case FUNDING_STATES.PASSED:
      case TRANSFER_STATUS_TYPES.COMPLETE:
        navigateTo(staticRoutes.transferSuccess.pathname, newState)
        break
      case FUNDING_STATES.FAILED:
      case TRANSFER_STATUS_TYPES.FAILED:
        navigateTo(staticRoutes.transferFailed.pathname, newState)
        break
      default:
        break
    }
  }

  const handleCancelClick = () => {
    if (fundingFlow === FUNDING_FLOWS.TRANSFER) {
      navigate(transferFlowStartPath)
    } else if (shouldSetupDirectDeposit) {
      navigate(staticRoutes.directDepositSetup.pathname, { state: { fundingFlow } })
    } else {
      navigate(staticRoutes.dashboard.pathname)
    }
  }

  const handleSubmit = async () => {
    const { note, toAccount, fromAccount, amount } = transferDetails
    const amountAsFloat = parseFloat(amount)

    if (fundingFlow === FUNDING_FLOWS.TRANSFER) {
      const { data } = await submitTransferRequest({
        variables: {
          transferRequestDTOInput: {
            amount: amountAsFloat,
            description: note,
            fromAccountId: fromAccount.id,
            fromAccountType: fromAccount.transferAccountType,
            toAccountId: toAccount.id,
            toAccountType: toAccount.transferAccountType,
          },
        },
      })

      const newState = {
        transactionId: data?.createTransfer?.id,
        fundingFlow,
        transferFlowStartPath,
        shouldSetupDirectDeposit,
      }

      redirectAfterSubmission(data?.createTransfer?.transferResponseStatus, newState)
    } else {
      const { data } = await submitFundingRequest({
        variables: {
          accountFundingRequestDTOInput: {
            purpose: ACCOUNT_FUNDING_REQUEST_PURPOSES.FUNDS_IN_DDA,
            linkedAccountId: fromAccount.id,
            fundingAmount: amountAsFloat,
          },
        },
      })

      const newState = {
        transactionId: data?.selectFundingAccount?.id,
        fundingFlow,
        shouldSetupDirectDeposit,
      }

      redirectAfterSubmission(data?.selectFundingAccount?.fundingState, newState)
    }
    trackEvent({
      event: SEGMENT_EVENTS.TRANSFER_CONFIRM_BUTTON_CLICK({
        sourceDetail: SEGMENT_SOURCE_DETAILS.CONFIRM,
      }),
    })
  }

  // Tracks page visit
  useEffect(() => {
    trackPage({ name: SEGMENT_PAGE_NAMES.TRANSFER_MONEY_CONFIRM })
  }, [])

  return (
    <>
      {!transferDetails.amount && (
        <Navigate to={staticRoutes.transfer.pathname} state={state} />
      )}
      <>
        <h1>Confirm Transfer</h1>
        <div data-cy='transfer-review-form'>
          <p>If you want to change any information below, please go back and edit.</p>
          <table className='simple-table fixed-height'>
            <tbody>
              <tr>
                <th>
                  <span>Transfer Amount</span>
                </th>
                <td>
                  {transferDetails?.amount
                    ? toDollars({ value: transferDetails?.amount, isSuperscript: false })
                    : null}
                </td>
              </tr>
              <tr>
                <th>
                  <span>Transfer From</span>
                </th>
                <td>{getAccountDetails(transferDetails?.fromAccount)}</td>
              </tr>
              <tr>
                <th>
                  <span>Transfer To</span>
                </th>
                <td>{getAccountDetails(transferDetails?.toAccount)}</td>
              </tr>
              {fundingFlow === FUNDING_FLOWS.TRANSFER && transferDetails?.note && (
                <tr>
                  <th>
                    <span>Note</span>
                  </th>
                  <td>{transferDetails.note}</td>
                </tr>
              )}
            </tbody>
          </table>
          <Button
            onClick={handleSubmit}
            disabled={isSubmitting}
            className='additional-button'
            data-cy='transfer-review-submit-button'
            isLoading={isSubmitting}
          >
            Confirm
          </Button>
          <Button
            onClick={() => navigate(staticRoutes.transfer.pathname, { state })}
            outline
            className='additional-button'
            data-cy='transfer-review-edit-button'
          >
            Edit
          </Button>
          <Button
            onClick={() => {
              toggleShowCancelTransferModal(true)
              trackEvent({
                event: SEGMENT_EVENTS.TRANSFER_CONFIRM_BUTTON_CLICK({
                  sourceDetail: SEGMENT_SOURCE_DETAILS.CANCEL,
                }),
              })
            }}
            ghost
            className='additional-button'
            data-cy='transfer-review-cancel-button'
          >
            Cancel
          </Button>
        </div>
        <p className='disclaimer-text'>{DEPOSIT_AND_TRANSFER_DISCLAIMER_TEXT}</p>
        <ExitModal
          isOpen={showCancelTransferModal}
          toggleModal={() => toggleShowCancelTransferModal(!showCancelTransferModal)}
          closeModal={() => toggleShowCancelTransferModal(false)}
          onContinueClick={handleCancelClick}
        />
      </>
    </>
  )
}

export default TransferReview
