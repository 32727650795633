import React from 'react'
import classNames from 'classnames'
import { useMediaQuery } from 'react-responsive'

import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'
import { ReactComponent as InvestCenterImage } from '@common/images/invest/investing-mobile-center.svg'
import { ReactComponent as InvestLeftImage } from '@common/images/invest/investing-mobile-left.svg'
import { ReactComponent as InvestRightImage } from '@common/images/invest/investing-mobile-right.svg'

import { MAX_WIDTH_BREAKPOINTS } from '@shared/constants/uiConstants'

import AppLinks from '@common/components/callout/AppLinks'

import styling from './invest.module.scss'

const Invest = () => {
  const isMobile = useMediaQuery({ query: `(max-width: ${MAX_WIDTH_BREAKPOINTS.MEDIUM}px)` })

  const containerClasses = classNames('create-account-black-page', styling['invest-container'])
  const outerImageClasses = classNames(styling['outer-image'], isMobile && styling.hide)

  return (
    <div className={containerClasses}>
      <GreenwoodLogo className='logo' />
      <div>
        <h1>Download Greenwood App</h1>
        <h3>Join Greenwood Invest Exclusively on Mobile</h3>
        <AppLinks className={styling['app-link-container']} alignMobileHorizontally />
        <div className={styling['image-container']}>
          <div className={outerImageClasses}>
            <InvestLeftImage />
          </div>
          <div>
            <InvestCenterImage />
          </div>
          <div className={outerImageClasses}>
            <InvestRightImage />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Invest
