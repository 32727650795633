import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Transition from 'react-transition-group/Transition'
import { pick, omit } from 'lodash'

import { TRANSITION_KEYS, TRANSITION_CLASS_MAP } from '../constants/uiConstants'
import { TIMEOUT } from '../constants'
import layoutUtils from '../utils/layoutUtils'

/**
 * The `Collapse` component allows you to easily toggle the visibility of your content.
 */

const Collapse = ({
  tag: Tag = 'div',
  open = false,
  className,
  navbar,
  children,
  innerRef,
  ...rest
}) => {
  const [height, setHeight] = useState(null)
  const nodeRef = useRef(null)

  const transitionAttributes = {
    ...Transition.defaultProps,
    appear: false,
    enter: true,
    exit: true,
    timeout: TIMEOUT.COLLAPSE,
    ...rest,
  }

  const transitionProps = pick(transitionAttributes, TRANSITION_KEYS)
  const childProps = omit(transitionAttributes, TRANSITION_KEYS)

  const onEntering = () => {
    setHeight(layoutUtils.getNodeHeight(nodeRef.current))
  }

  const onEntered = () => {
    setHeight(null)
  }

  const onExit = () => {
    setHeight(layoutUtils.getNodeHeight(nodeRef.current))
  }

  const onExiting = () => {
    layoutUtils.reflow(nodeRef.current)
    setHeight(0)
  }

  const onExited = () => {
    setHeight(null)
  }

  return (
    <Transition
      {...transitionProps}
      in={open}
      nodeRef={nodeRef}
      onEntering={onEntering}
      onEntered={onEntered}
      onExit={onExit}
      onExiting={onExiting}
      onExited={onExited}
    >
      {status => {
        const style = {
          height: height || null,
          display: status !== 'exited' && 'block',
        }

        const classes = classNames(
          className,
          TRANSITION_CLASS_MAP[status] || 'collapse',
          navbar && 'navbar-collapse'
        )

        return (
          <Tag
            {...childProps}
            style={{ ...childProps.style, ...style }}
            className={classes}
            ref={nodeRef}
          >
            {children}
          </Tag>
        )
      }}
    </Transition>
  )
}

Collapse.propTypes = {
  /**
   * Whether it is open, or not.
   */
  open: PropTypes.bool,
  /**
   * The children components.
   */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  /**
   * The element tag type.
   */
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  /**
   * The class name.
   */
  className: PropTypes.node,
  /**
   * Whether it is located inside a navbar, or not.
   */
  navbar: PropTypes.bool,
  /**
   * The inner ref.
   */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.object]),
}

export default Collapse
