import React from 'react'

import { ReactComponent as ExperiencesIcon } from '@common/images/icons/experiences.svg'
import { ReactComponent as FestivalConferenceIcon } from '@common/images/icons/festival-conference.svg'
import { ReactComponent as GatheringSpotIcon } from '@common/images/icons/gathering-spot.svg'
import { ReactComponent as ShieldCheckIcon } from '@common/images/icons/shield-check.svg'
import { ReactComponent as TravelIcon } from '@common/images/icons/travel.svg'
import { ReactComponent as MatteBlackMetalCardIcon } from '@common/images/icons/matte-black-metal-card.svg'
import { ReactComponent as ShieldIcon } from '@common/images/icons/shield.svg'
import { ReactComponent as EmergencyServicesIcon } from '@common/images/icons/emergency-services.svg'
import { ReactComponent as InvestIcon } from '@common/images/icons/invest.svg'

import ExternalLink from '@common/components/link/ExternalLink'
import { getBootstrapSitemapResource } from './clientBootstrapUtils'
import { SEGMENT_EVENTS, trackEvent } from '@common/utils'

export const getBenefitIcon = benefitTitle => {
  switch (benefitTitle.toLowerCase()) {
    case 'travel':
      return <TravelIcon />
    case 'the gathering spot':
      return <GatheringSpotIcon />
    case 'peace of mind':
      return <ShieldCheckIcon />
    case 'experiences':
      return <ExperiencesIcon />
    case 'valence':
      return <ShieldIcon />
    case 'matte black metal card':
      return <MatteBlackMetalCardIcon />
    case 'a3c festival and conference':
      return <FestivalConferenceIcon />
    case 'emergency services':
      return <EmergencyServicesIcon />
    case 'greenwood invest':
      return <InvestIcon />
    default:
      return null
  }
}

export const getAccessBenefitsForProduct = product => {
  const benefitCategories = product?.benefits?.map(benefit => benefit.category.toLowerCase())
  const mastercardBenefits = product?.agreements?.find(agreement =>
    agreement.title.toLowerCase().includes('benefits')
  )

  const getBenefitLink = (toUrl, title) => (
    <div key={title}>
      <ExternalLink
        to={toUrl}
        className='underlined-link'
        clickListener={() => {
          trackEvent({
            event: SEGMENT_EVENTS.ABOUT_MY_MEMBERSHIP_BUTTON_CLICK(title),
          })
        }}
      >
        {title}
      </ExternalLink>
      <br />
    </div>
  )

  const benefits = []

  benefitCategories.forEach(benefit => {
    const benefitTitle = benefit.split(' ').join('_')
    const benefitResource = getBootstrapSitemapResource(benefitTitle)
    if (benefitResource) {
      benefits.push(getBenefitLink(benefitResource.url, benefitResource.title.value))
    }
  })

  if (mastercardBenefits?.url) {
    benefits.push(getBenefitLink(mastercardBenefits.url, 'Mastercard'))
  }

  return benefits
}
