import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { groupBy } from 'lodash'

import { gql, useLazyQuery } from '@services/serviceUtils'

import Button from '@shared/components/button/Button'
import FormSelect from '@shared/components/formSelect/FormSelect'
import FormTextarea from '@shared/components/formTextarea/FormTextarea'
import FormCurrencyInput from '@shared/components/formCurrencyInput/FormCurrencyInput'
import Loading from '@shared/components/Loading'
import { ReactComponent as PencilIcon } from '@common/images/icons/pencil.svg'

import { setTransferDetailsAction } from '@redux/transferDetails/transferDetailsActions'
import {
  FUNDING_FLOWS,
  MAX_TRANSFER_NOTE_CHAR_COUNT,
  TRANSFER_ACCOUNT_TYPES,
  INTERNAL_EXTERNAL_TRANSFER_LIMIT,
  PLAID_LINKING_STATUSES,
} from '@common/constants'
import { staticRoutes } from '@routing/routes'
import { toDollars, toTimeAndShortMonthDayFormat } from '@shared/utils'
import {
  trackPage,
  trackEvent,
  SEGMENT_PAGE_NAMES,
  SEGMENT_EVENTS,
  SEGMENT_SOURCE_DETAILS,
} from '@common/utils'

import styling from './transferForm.module.scss'

const accountsForMoneyTransferQuery = gql`
  query AccountsForMoneyTransfer($sourceAccountId: ID!) {
    accountsForMoneyTransfer(sourceAccountId: $sourceAccountId) {
      id
      accountNumber
      title
      transferAccountType
      balance {
        amount
      }
    }
  }
`

const plaidAccountsQuery = gql`
  query PlaidAccounts {
    plaidAccounts {
      balanceWhenLinked {
        amount
      }
      id
      linkingStatus
      mask
      title
    }
  }
`

const linkedAccountBalanceQuery = gql`
  query LinkedAccountBalance($accountId: ID!) {
    linkedAccountBalance(accountId: $accountId) {
      balance {
        amount
      }
      balanceUpdatedAt
    }
  }
`

const getOptionsByGroup = ({ group = [], selectedOption }) =>
  group.map((option, optionIndex) => {
    const { id, title, accountNumber, balance, mask, balanceWhenLinked } = option
    const isSelectedOption = id === selectedOption
    const amount = balance?.amount || balanceWhenLinked?.amount || 0

    return (
      <option key={optionIndex} value={id}>
        {title} (..{accountNumber || mask}) {!isSelectedOption ? `- $${amount}` : null}
      </option>
    )
  })

const getAccountOptions = ({ optionList = [], selectedOption }) => {
  const groupedOptionList = groupBy(optionList, account => account.transferAccountType)

  return (
    <>
      <option value=''>Choose an account</option>
      {Object.keys(groupedOptionList).map((groupName, groupIndex) => {
        const label =
          groupName === TRANSFER_ACCOUNT_TYPES.GREENWOOD ? 'Internal Accounts' : 'External Accounts'

        return (
          <optgroup label={label} key={groupIndex}>
            {getOptionsByGroup({ group: groupedOptionList[groupName], selectedOption })}
          </optgroup>
        )
      })}
    </>
  )
}

const getAccountDetails = (value, accounts = []) => accounts?.find(account => account.id === value)

const transferFormSchema = Yup.object().shape({
  amount: Yup.string()
    .required('Required')
    .matches(/^[$]?[\d.,]*$/, 'Transfer amount can only include numbers 0-9, commas and a decimal.')
    .test('amount is greater than zero', 'Amount must be greater than $0.', value => {
      const amountValue = value
        ?.split(',')
        .join('')
        .replace('$', '')
      return !!(amountValue && parseFloat(amountValue) > 0)
    }),
  fromAccount: Yup.string().required('Required'),
  toAccount: Yup.string().required('Required'),
})

const nonErrorPlaidAccountsFilter = account =>
  account.linkingStatus !== PLAID_LINKING_STATUSES.ERROR

const TransferForm = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const dispatch = useDispatch()
  const transferDetails = useSelector(state => state.transferDetails)

  const fundingFlow = state?.fundingFlow
  const shouldSetupDirectDeposit = state?.shouldSetupDirectDeposit

  // counter for note field; hint will show characters remaining
  const [noteCharsUsed, setNoteCharsUsed] = useState(transferDetails?.note?.length || 0)
  const [selectedFromAccountId, setSelectedFromAccountId] = useState(
    transferDetails?.fromAccount?.id
  )
  const [initialToAccountId, setInitialToAccountId] = useState('')
  const [showNoteField, toggleShowNoteField] = useState(!!transferDetails?.note?.length || false)
  const [isLinkedFromAccount, setIsLinkedFromAccount] = useState(false)

  const [
    getTransferFromAccounts,
    { data: transferFromAccountsData, loading: transferFromAccountsLoading },
  ] = useLazyQuery(accountsForMoneyTransferQuery, {
    variables: { sourceAccountId: '' },
    fetchPolicy: 'no-cache',
  })

  const [
    getFundingFromAccounts,
    { data: fundingFromAccountsData, loading: fundingFromAccountsLoading },
  ] = useLazyQuery(plaidAccountsQuery, {
    fetchPolicy: 'no-cache',
  })

  const [getTransferToAccounts, { data: toAccountData, loading: toAccountsLoading }] = useLazyQuery(
    accountsForMoneyTransferQuery,
    {
      fetchPolicy: 'no-cache',
    }
  )

  const [
    getLinkedAccountBalance,
    { data: linkedAccountBalanceData, loading: linkedAccountBalanceLoading },
  ] = useLazyQuery(
    linkedAccountBalanceQuery,
    { variables: { accountId: '' } },
    { fetchPolicy: 'no-cache' }
  )

  // Date information for when balance was last updated
  const balanceLastUpdated = isLinkedFromAccount
    ? linkedAccountBalanceData?.linkedAccountBalance?.balanceUpdatedAt
    : new Date()

  // Loading indicator (boolean) for Transfer From selector
  const fromAccountsLoading =
    fundingFlow === FUNDING_FLOWS.TRANSFER
      ? transferFromAccountsLoading
      : fundingFromAccountsLoading

  // Account option list for Transfer From selector
  const fromAccountOptionsList =
    fundingFlow === FUNDING_FLOWS.TRANSFER
      ? transferFromAccountsData?.accountsForMoneyTransfer
      : fundingFromAccountsData?.plaidAccounts?.filter(nonErrorPlaidAccountsFilter)

  // Specific account details of selected Transfer From account
  const fromAccountDetails =
    selectedFromAccountId && fromAccountOptionsList?.length
      ? getAccountDetails(selectedFromAccountId, fromAccountOptionsList)
      : null

  // Available balance for Transfer From account
  const availableBalance = useMemo(() => {
    if (isLinkedFromAccount) {
      return linkedAccountBalanceData?.linkedAccountBalance?.balance?.amount || 0
    } else {
      return fromAccountDetails?.balance?.amount || 0
    }
  }, [isLinkedFromAccount, linkedAccountBalanceData, fromAccountDetails])

  const accountsLoading = fromAccountsLoading || toAccountsLoading

  const showAvailableBalance =
    !linkedAccountBalanceLoading && fromAccountDetails && availableBalance >= 0

  useEffect(() => {
    if (selectedFromAccountId && !initialToAccountId) {
      // get an updated list for the toAccounts if the value of the fromAccount has changed
      getTransferToAccounts({
        variables: {
          sourceAccountId: selectedFromAccountId,
        },
      })
    }
  }, [selectedFromAccountId, getTransferToAccounts, initialToAccountId])

  // if in the Transfer flow, then get the list of From accounts from the accountsForMoneyTransfer query
  // otherwise, get the list of From accounts from the plaidAccounts query
  useEffect(() => {
    if (fundingFlow === FUNDING_FLOWS.TRANSFER) {
      getTransferFromAccounts()
    } else {
      getFundingFromAccounts()
    }
  }, [fundingFlow, getTransferFromAccounts, getFundingFromAccounts])

  // if in the Basic funding flow, then set the initial value of the toAccount to the user's spending account id
  useEffect(() => {
    if (toAccountData?.accountsForMoneyTransfer?.length && fundingFlow === FUNDING_FLOWS.BASIC) {
      const spendingAccount = toAccountData.accountsForMoneyTransfer.find(
        account => account?.title === 'Spending Account'
      )
      setInitialToAccountId(spendingAccount?.id)
    }
  }, [toAccountData, fundingFlow])

  // If the selected account in the Transfer From field is a linked account,
  // get its updated linkedAccountBalance
  useEffect(() => {
    if (
      fromAccountDetails?.transferAccountType === TRANSFER_ACCOUNT_TYPES.LINKED ||
      fromAccountDetails?.linkingStatus
    ) {
      getLinkedAccountBalance({
        variables: { accountId: selectedFromAccountId },
      })

      setIsLinkedFromAccount(true)
    } else {
      setIsLinkedFromAccount(false)
    }
  }, [fromAccountDetails, getLinkedAccountBalance, selectedFromAccountId])

  const handleCancelClick = () => {
    if (fundingFlow === FUNDING_FLOWS.TRANSFER) {
      navigate(state?.transferFlowStartPath || staticRoutes.moveMoneyAddMoney.pathname, { replace: true })
    } else if (shouldSetupDirectDeposit) {
      navigate(staticRoutes.directDepositSetup.pathname, {
        state: {
          fundingFlow,
        },
        replace: true,
      })
    } else {
      navigate(staticRoutes.dashboard.pathname, { replace: true })
    }
  }

  const handleSubmit = async (values, { setFieldError }) => {
    const fromAccount = getAccountDetails(values.fromAccount, fromAccountOptionsList)

    const toAccount = getAccountDetails(values.toAccount, toAccountData?.accountsForMoneyTransfer)

    trackEvent({
      event: SEGMENT_EVENTS.TRANSFER_MONEY_BUTTON_CLICK({
        sourceDetail: SEGMENT_SOURCE_DETAILS.CONTINUE,
        note: values.note,
        transfer_amount: values.amount,
      }),
    })

    // if the account types are not the same (i.e., one account is GREENWOOD and the other is a LINKED account),
    // and the amount the user is attempting to transfer is greater than the max transfer amount for these types
    // of transfers, then set an error on the amount field.
    if (
      fromAccount.transferAccountType !== toAccount.transferAccountType &&
      parseFloat(values.amount) > INTERNAL_EXTERNAL_TRANSFER_LIMIT
    ) {
      setFieldError(
        'amount',
        `Please enter an amount less than or equal to ${toDollars({
          value: INTERNAL_EXTERNAL_TRANSFER_LIMIT,
          isSuperscript: false,
        })}.`
      )
    } else {
      dispatch(
        setTransferDetailsAction({
          ...values,
          fromAccount,
          toAccount,
        })
      )
      navigate(staticRoutes.transferReview.pathname, { state })
    }
  }

  // Tracks page visit
  useEffect(() => {
    trackPage({ name: SEGMENT_PAGE_NAMES.TRANSFER_MONEY })
  }, [])

  const handleTransferAmountBlur = () => {
    // Track any time the user leaves the field
    trackEvent({
      event: SEGMENT_EVENTS.TRANSFER_AMOUNT_FIELD_ENTERED,
    })
  }

  const handleTransferFromChange = () => {
    trackEvent({
      event: SEGMENT_EVENTS.TRANSFER_MONEY_BUTTON_CLICK({
        sourceDetail: SEGMENT_SOURCE_DETAILS.TRANSFER_FROM,
      }),
    })
  }
  const handleTransferToChange = () => {
    trackEvent({
      event: SEGMENT_EVENTS.TRANSFER_MONEY_BUTTON_CLICK({
        sourceDetail: SEGMENT_SOURCE_DETAILS.DEPOSIT_TO,
      }),
    })
  }
  const handleAddNoteClick = () => {
    trackEvent({
      event: SEGMENT_EVENTS.TRANSFER_MONEY_BUTTON_CLICK({
        sourceDetail: SEGMENT_SOURCE_DETAILS.ADD_NOTE,
      }),
    })
  }
  return (
    <>
      <h1>Transfer Money</h1>
      <Formik
        initialValues={{
          amount: transferDetails?.amount || '',
          fromAccount:
            fromAccountOptionsList?.length && transferDetails?.fromAccount?.id
              ? transferDetails.fromAccount.id
              : '',
          toAccount:
            fromAccountOptionsList?.length && transferDetails?.toAccount?.id
              ? transferDetails.toAccount.id
              : initialToAccountId,
          note: transferDetails?.note || '',
        }}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={transferFormSchema}
      >
        {({ errors, isSubmitting, touched, handleChange, setFieldValue, values, handleBlur }) => {
          const editDisabled = isSubmitting || accountsLoading
          const isInvalid = (errorText, isTouched) => errorText && isTouched

          return (
            <Form
              data-cy='transfer-form'
              className={styling['transfer-form']}
              onChange={handleChange}
            >
              <Field
                as={FormCurrencyInput}
                id='transferAmount'
                name='amount'
                label='Transfer Amount'
                invalid={isInvalid(errors.amount, touched.amount)}
                errorText={errors.amount}
                disabled={editDisabled}
                required
                className='amount-form-field'
                defaultValue={parseFloat(values.amount) || ''}
                showRequiredAsterisk={false}
                onBlur={e => {
                  handleBlur(e)
                  handleTransferAmountBlur()
                }}
              />
              <div className={styling['available-balance-container']}>
                {linkedAccountBalanceLoading && <Loading title='linked-account-balance-loader' />}
                {showAvailableBalance && (
                  <>
                    <div>
                      <span className={styling['balance-header']}>Available Balance:</span>
                      <span
                        className={styling['available-balance']}
                        data-cy='transfer-form-available-balance'
                      >
                        {toDollars({ value: availableBalance, isSuperscript: false })}
                      </span>
                    </div>
                    <p className={styling['last-updated-text']}>
                      <span>Last Updated {!balanceLastUpdated && 'Date Not Available'}</span>
                      {balanceLastUpdated && toTimeAndShortMonthDayFormat(balanceLastUpdated)}
                    </p>
                  </>
                )}
              </div>
              <div className={styling['account-fields-container']}>
                <Field
                  as={FormSelect}
                  name='fromAccount'
                  label='Transfer From'
                  invalid={isInvalid(errors.fromAccount, touched.fromAccount)}
                  errorText={errors.fromAccount}
                  disabled={isSubmitting}
                  required
                  onChange={e => {
                    setSelectedFromAccountId(e.target.value)
                    // only reset the value of the toAccount field if in the Transfer flow
                    if (fundingFlow === FUNDING_FLOWS.TRANSFER) {
                      setFieldValue('toAccount', '')
                    }
                    handleTransferFromChange()
                  }}
                >
                  {getAccountOptions({
                    optionList: fromAccountOptionsList,
                    selectedOption: values.fromAccount,
                  })}
                </Field>
                <div className={styling['to-account-field-container']}>
                  {toAccountsLoading && <Loading title='to-account-loader' />}
                  <Field
                    as={FormSelect}
                    name='toAccount'
                    label='Transfer To'
                    invalid={isInvalid(errors.toAccount, touched.toAccount)}
                    errorText={errors.toAccount}
                    disabled={
                      isSubmitting ||
                      !toAccountData?.accountsForMoneyTransfer ||
                      !fromAccountOptionsList
                    }
                    required
                    readonly={fundingFlow === FUNDING_FLOWS.BASIC}
                    className={styling['basic-funding-transfer-to-account']}
                    onChange={handleTransferToChange}
                  >
                    {!toAccountsLoading && (
                      <>
                        {getAccountOptions({
                          optionList: toAccountData?.accountsForMoneyTransfer,
                          selectedOption: values.toAccount,
                        })}
                      </>
                    )}
                  </Field>
                </div>
                {fundingFlow === FUNDING_FLOWS.TRANSFER && (
                  <div className={styling['add-note-container']}>
                    {!showNoteField ? (
                      <Button
                        className={styling['add-note-button']}
                        ghost
                        data-cy='transfer-form-add-note-button'
                        onClick={() => {
                          toggleShowNoteField(!showNoteField)
                          handleAddNoteClick()
                        }}
                      >
                        <PencilIcon />
                        Add Note
                      </Button>
                    ) : (
                      <div className={styling['note-field-container']}>
                        <p>Note</p>
                        <Field
                          as={FormTextarea}
                          name='note'
                          label=''
                          id='transferNote'
                          placeholder='What is this for? (optional)'
                          maxLength={MAX_TRANSFER_NOTE_CHAR_COUNT}
                          onChange={e => setNoteCharsUsed(e.target.value.length)}
                          hintContent={`${MAX_TRANSFER_NOTE_CHAR_COUNT -
                            noteCharsUsed} of ${MAX_TRANSFER_NOTE_CHAR_COUNT} characters remaining.`}
                          disabled={editDisabled}
                          className={styling['note-form-field']}
                          resizeable={false}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
              <Button
                data-cy='transfer-form-continue-button'
                type='submit'
                className='additional-button'
                disabled={accountsLoading}
              >
                Continue
              </Button>
              <Button type='button' onClick={handleCancelClick} ghost className='additional-button'>
                Cancel
              </Button>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export default TransferForm
