import React, { useCallback, useEffect, useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import * as Yup from 'yup'

import {
  ROUTE_USER_FLOW,
  CHALLENGE_TYPES,
  ALERT_TYPES,
  DEFAULT_ALERT_DISMISS_DELAY,
} from '@shared/constants/uiConstants'
import {
  getRedirectLocationByChallengeTypeAndUserFlow,
  SEGMENT_PAGE_NAMES,
  SEGMENT_IDENTIFIER_TRAITS,
  reset,
  trackPage,
  trackIdentity,
  isAuthenticated,
} from '@common/utils'
import { login, decodeJwtPayload } from '@services/serviceUtils'
import { staticRoutes } from '@routing/routes'
import { setAuthAction } from '@redux/auth/authActions'
import { setEmailAction } from '@redux/unauthenticatedUser/unauthenticatedUserActions'
import { setLaunchDarklyCustomerAction } from '@redux/customer/customerActions'
import { addAlertAction, removeAlertsAction } from '@redux/alerts/alertsActions'
import { FRAUD_ERROR_MESSAGE } from '@common/constants'

import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'
import Button from '@shared/components/button/Button'
import InlineNotification from '@common/components/inlineNotification/InlineNotification'
import FormInput from '@shared/components/formInput/FormInput'
import FormPasswordInput from '@shared/components/FormPasswordInput'
import VerificationFailedModal from '@common/components/verificationFailedModal/VerificationFailedModal'
import AccountIsLockedModal from '@common/components/accountIsLockedModal/AccountIsLockedModal'
import InvalidSessionModal from '@common/components/invalidSessionModal/InvalidSessionModal'

import styling from './login.module.scss'

const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid Email')
    .required('Required'),
  password: Yup.string().required('Required'),
})

const Login = () => {
  const navigate = useNavigate()
  const { pathname, state } = useLocation()
  const dispatch = useDispatch()
  const isUserAuthenticated = useSelector(state => isAuthenticated(state))
  const [isInitiallyAuthenticated] = useState(isUserAuthenticated)
  const [showSessionExpiredMessage] = useState(
    window.sessionStorage.getItem('showSessionExpiredMessage')
  )
  const [hasEditAccountLock] = useState(window.sessionStorage.getItem('hasEditAccountLock'))
  const [showAccountIsLockedModal, toggleShowAccountIsLockedModal] = useState(
    !!state?.accountIsLocked || !!hasEditAccountLock
  )
  const [showVerificationFailedModal, toggleShowVerificationFailedModal] = useState(
    !!state?.phoneVerificationFailed
  )
  const [showInvalidSessionModal, toggleShowInvalidSessionModal] = useState(
    !!state?.hasInvalidSession
  )
  const [hasFraudError, setHasFraudError] = useState(false)
  const [fraudError, setFraudError] = useState(null)

  // Track sign in page visits
  useEffect(() => {
    // First, reset analytics since we can't assume this is the same user as a previous session
    reset()

    trackPage({ name: SEGMENT_PAGE_NAMES.SIGN_IN })
  }, [])

  useEffect(() => {
    /**
     * Check to see if the user is authenticated at time of mounting. If so, redirect them back to the desired
     * route (or dashboard). This route doesn't use ProtectedRoute, otherwise ProtectedRoute would redirect
     * users to the dashboard upon login instead of allowing for Login to determine where to redirect them
     * based on the challenge type.
     */
    if (isInitiallyAuthenticated) {
      navigate(state?.desiredPathname || staticRoutes.dashboard.pathname)
    }
  }, [isInitiallyAuthenticated, navigate, state?.desiredPathname])

  /* Shows a "your session has expired" message to the user if they were logged out due to an
     expired refresh token. As soon as we know we should display the message, clear this flag
     so that it is not displayed again if the user signs out manually, or browses away and back */
  useEffect(() => {
    if (showSessionExpiredMessage) {
      window.sessionStorage.removeItem('showSessionExpiredMessage')
    }
  }, [showSessionExpiredMessage])

  /* Shows the Account Locked modal to the user if they were locked out of their account due to too
     many failed password attempts during editing. As soon as we know we should display the modal, clear this
     flag so that it is not displayed again if the user signs out manually, or browses away and back */
  useEffect(() => {
    if (hasEditAccountLock) {
      window.sessionStorage.removeItem('hasEditAccountLock')
    }
  }, [hasEditAccountLock])

  const loginAndRedirect = useCallback(
    async values => {
      dispatch(setLaunchDarklyCustomerAction({ email: values.email }))

      if (window.sessionStorage.getItem('mfaRememberMeId') == null) {
        window.sessionStorage.setItem('mfaRememberMeId', uuid())
      }

      const rememberMeToken = localStorage.getItem(`rememberMeToken+${values.email}`)

      const loginParams = {
        username: values.email,
        password: values.password,
        onError: error => {
          if (error?.data?.message?.toLowerCase().includes(FRAUD_ERROR_MESSAGE)) {
            dispatch(removeAlertsAction())
            setHasFraudError(true)
            setFraudError(error.data)
          } else {
            setHasFraudError(false)
            setFraudError(null)
          }
        },
      }

      if (rememberMeToken) {
        loginParams.axiosConfig = {
          headers: { 'remember-me-token': rememberMeToken },
        }
      }

      const { data = {} } = await login(loginParams)

      dispatch(setAuthAction(data))

      if (
        [CHALLENGE_TYPES.RESET_PASSWORD, CHALLENGE_TYPES.VERIFY_EMAIL].includes(data?.challengeType)
      ) {
        dispatch(setEmailAction({ email: values.email }))
      }

      // Get the userId out of the JWT payload to identify the user for analytics
      const userId = decodeJwtPayload(data?.token?.access_token)?.sub

      trackIdentity({
        userId,
        traits: SEGMENT_IDENTIFIER_TRAITS.login({ email: values.email }),
      })

      const hasZeroAttemptsRemaining = data.pswChangeRequiredAttemptsRemaining === 0

      if (data.pswChangeRequiredAttemptsRemaining > 0) {
        dispatch(addAlertAction({
          text: 'Your password expires soon. Update now for security.',
          type: ALERT_TYPES.WARNING,
          className: styling['update-password-alert'],
          autoDismissDelay: DEFAULT_ALERT_DISMISS_DELAY,
        }))
      }

      if (hasZeroAttemptsRemaining) {
        navigate(staticRoutes.recoverProvideEmail.pathname, {
          state: {
            userFlow: ROUTE_USER_FLOW.FORGOT_PASSWORD,
            email: values.email,
            isForcedUpdate: true,
          },
        })
      } else {
        const { pathname: navigatePath, state: navigateState } = getRedirectLocationByChallengeTypeAndUserFlow({
          userFlow: ROUTE_USER_FLOW.SIGNIN,
          desiredSigninPathname: state?.desiredPathname,
          challengeType: data?.challengeType,
          sessionId: data?.sessionId,
          displayEmail: values.email,
          mfaChannel: data?.mfaChannel,
        })

        navigate(navigatePath, { state: navigateState })
      }
    },
    [dispatch, navigate, state?.desiredPathname]
  )

  const handleLogin = async values => {
    // Remove any current alerts
    dispatch(removeAlertsAction())
    /* Attach a paralell Promise to delay subsequent form submissions. If the user was allowed to
       attempt login in quick succession, their account might get locked out due to security
       measures in place within the application infrastructure. Forcing a wait between attempts
       prevents this. */
    await Promise.all([loginAndRedirect(values), new Promise(resolve => setTimeout(resolve, 1200))])
  }

  return (
    <>
      <div className='white-card-container'>
        <Link to={staticRoutes.dashboard.pathname}>
          <GreenwoodLogo className='logo' />
        </Link>
        <h1>Hello! Let’s sign in.</h1>
        {showSessionExpiredMessage && (
          <InlineNotification text='Your session has expired due to inactivity' />
        )}
        <Formik
          validationSchema={loginValidationSchema}
          initialValues={{
            email: '',
            password: '',
          }}
          onSubmit={handleLogin}
        >
          {({ errors, isSubmitting, touched }) => (
            <Form data-cy='login-form'>
              <Field
                as={FormInput}
                name='email'
                type='email'
                label='Email Address'
                autoComplete='email'
                required
                invalid={errors.email && touched.email}
                errorText={errors.email}
                disabled={isSubmitting}
              />
              <Field
                as={FormPasswordInput}
                name='password'
                label='Password'
                autoComplete='current-password'
                required
                invalid={errors.password && touched.password}
                errorText={errors.password}
                disabled={isSubmitting}
              />
              <p className='help-text'>
                <Link
                  className='underlined-link'
                  to={staticRoutes.recoverProvideEmail.pathname}
                  state={{ userFlow: ROUTE_USER_FLOW.FORGOT_PASSWORD }}
                >
                  Forgot Password?
                </Link>
              </p>
              <Button type='submit' isLoading={isSubmitting}>
                Sign In
              </Button>
            </Form>
          )}
        </Formik>
        <p className='help-text'>
          Don't have an account?{' '}
          <Link className='underlined-link bold' to={staticRoutes.signUp.pathname}>
            Create an account.
          </Link>
        </p>
      </div>
      <VerificationFailedModal
        isOpen={showVerificationFailedModal}
        toggleModal={() => toggleShowVerificationFailedModal(!showVerificationFailedModal)}
        closeModal={() => {
          toggleShowVerificationFailedModal(false)
          navigate(pathname, { state: { ...state, phoneVerificationFailed: false }, replace: true })
        }}
        isPhoneVerification
      />
      <AccountIsLockedModal
        isOpen={showAccountIsLockedModal || hasFraudError}
        isFraud={hasFraudError}
        closeModal={() => {
          if (hasFraudError) {
            setHasFraudError(false)
          } else {
            toggleShowAccountIsLockedModal(false)
            navigate(pathname, { state: { ...state, accountIsLocked: false }, replace: true })
          }
        }}
        errorDetails={fraudError}
      />
      <InvalidSessionModal
        isOpen={showInvalidSessionModal}
        closeModal={() => {
          toggleShowInvalidSessionModal(false)
          navigate(pathname, { state: { ...state, hasInvalidSession: false }, replace: true })
        }}
      />
    </>
  )
}

export default Login
