import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import Lottie from 'react-lottie'
import { useSelector } from 'react-redux'
import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'
import { ReactComponent as InReview } from '@common/images/illustrations/illustration-in-review.svg'
import { useCheckpointNavigation, trackPage, SEGMENT_PAGE_NAMES } from '@common/utils'
import inReviewLottie from '@common/images/animations/in-review-lottie.json'
import Button from '@shared/components/button/Button'
import { staticRoutes } from '@routing/routes'
import { LOTTIE_CONFIG } from '@common/constants'

const CreateAccountInReview = ({ data }) => {
  const pollingHasMaxedOut = useSelector(state => state?.application?.pollingHasMaxedOut)
  const navigate = useNavigate()

  useCheckpointNavigation({
    checkpoints: data?.checkpoints,
    applicationStatus: data?.application?.customerApplicationStatus,
    pollingHasMaxedOut,
  })

  // Track bank application pending review page visits
  useEffect(() => {
    trackPage({ name: SEGMENT_PAGE_NAMES.BANK_APPLICATION_IN_REVIEW })
  }, [])

  return (
    <div className='create-account-black-page' data-cy='authenticated-loaded-view'>
      <div className='background-lottie'>
        <Lottie
          options={{
            ...LOTTIE_CONFIG,
            animationData: inReviewLottie,
          }}
        />
      </div>

      <div className='create-account-content-wrapper'>
        <GreenwoodLogo className='logo' />

        <InReview className='illustration' />

        <h1>Application is in review</h1>

        <p>
          Thank you for your Greenwood Account application! We are in the process of reviewing your
          information.
        </p>

        <h3>What is Our Next Step?</h3>

        <ol className='offerings-list'>
          <li>
            <span>
              Please keep in mind it may take up to 10 business days to process your application.
            </span>
          </li>
          <li>
            <span>We will contact you via email if we need any extra documents from you.</span>
          </li>
          <li>
            <span>You can check your latest application status in your Dashboard view.</span>
          </li>
        </ol>

        <div className='buttons-container'>
          <Button
            onClick={() => navigate(staticRoutes.dashboard.pathname)}
            data-cy='in-review-done-button'
          >
            DONE
          </Button>
        </div>
      </div>
    </div>
  )
}

CreateAccountInReview.propTypes = {
  /**
   * Contains the 'application' and 'checkpoints' objects
   */
  data: PropTypes.object,
  /**
   * Whether or not polling hit its max timeout
   */
  pollingHasMaxedOut: PropTypes.bool,
}

export default CreateAccountInReview
