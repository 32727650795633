import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form, Field } from 'formik'
import { isEqual } from 'lodash'
import { useDispatch } from 'react-redux'

import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'
import { ReactComponent as PencilIcon } from '@common/images/icons/pencil.svg'
import Button from '@shared/components/button/Button'
import FormInput from '@shared/components/formInput/FormInput'
import FormPlacesInput from '@shared/components/formPlacesInput/FormPlacesInput'
import ManualAddressEntryForm from '@common/components/manualAddressEntryForm/ManualAddressEntryForm'

import { setCustomerAction } from '@redux/customer/customerActions'
import { GOOGLE_API_KEY, ADDRESS_BUILDING_NUMBER_MAX_LENGTH } from '@common/constants'
import {
  SEGMENT_EVENTS,
  SEGMENT_PAGE_NAMES,
  SEGMENT_SOURCE_DETAILS,
  trackEvent,
  trackPage,
  useCheckpointNavigation,
} from '@common/utils'

import styling from './createAccountAddress.module.scss'

const CreateAccountAddress = ({ data, updateApplication }) => {
  const [focusedInputSourceDetail, setFocusedInputSourceDetail] = useState(null)
  const dispatch = useDispatch()

  const { proceedToNextCheckpoint } = useCheckpointNavigation({
    checkpoints: data?.checkpoints,
    applicationStatus: data?.application?.customerApplicationStatus,
  })

  const [searchAddress, setSearchAddress] = useState('')
  const [selectedAddress, setSelectedAddress] = useState(null)
  const [showConfirmAddress, setShowConfirmAddress] = useState(false)
  const [showManualEntry, setShowManualEntry] = useState(false)
  const [hasStreetAddressError, setHasStreetAddressError] = useState(false)
  const [isSelectingAddress, setIsSelectingAddress] = useState(false)

  const trackPendingEvent = () => {
    /* If there is still a focused input, make sure to track the event since onBlur is not executed
       when the form submits */
    if (focusedInputSourceDetail) {
      trackEvent({
        event: SEGMENT_EVENTS.registrationFormFieldEntry({
          sourceDetail: focusedInputSourceDetail,
        }),
      })

      setFocusedInputSourceDetail(null)
    }
  }

  // Track the various address page state visits
  useEffect(() => {
    if (showConfirmAddress === true) {
      trackPage({ name: SEGMENT_PAGE_NAMES.REGISTRATION_ADDRESS_CONFIRMATION })
    } else if (showManualEntry === true) {
      trackPage({ name: SEGMENT_PAGE_NAMES.REGISTRATION_ADDRESS_MANUAL })
    } else {
      trackPage({ name: SEGMENT_PAGE_NAMES.REGISTRATION_ADDRESS_GOOGLE })
    }
  }, [showConfirmAddress, showManualEntry])

  useEffect(() => {
    const previouslyEnteredAddress = data?.application?.deliveryAddress
      ? {
          deliveryAddress: data?.application.deliveryAddress,
          aptUnitFloor: data?.application.buildingNumber,
          locality: data?.application.locality,
          state: data?.application.subdivisions[0],
          zipCode: data?.application.postalCode,
        }
      : null

    if (!isEqual(previouslyEnteredAddress, selectedAddress)) {
      setSelectedAddress(previouslyEnteredAddress)
      setShowConfirmAddress(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.application, setSelectedAddress])

  const handleAddressSearchChange = address => {
    setSearchAddress(address)
  }

  const handleAddressSelection = address => {
    trackPendingEvent()

    setSearchAddress(address.searchAddress)
    const { addressComponents } = address
    setSelectedAddress({
      deliveryAddress: addressComponents?.street_number
        ? `${addressComponents.street_number} ${addressComponents.route}`
        : '',
      aptUnitFloor: '',
      locality:
        addressComponents.locality ||
        addressComponents.administrative_area_level_3 ||
        addressComponents.political,
      state: addressComponents.administrative_area_level_1,
      zipCode: addressComponents.postal_code,
    })

    if (Object.keys(addressComponents).length > 0) {
      setShowConfirmAddress(true)
    } else {
      setShowManualEntry(true)
    }
  }

  let selectedCity = selectedAddress?.locality
  // Remove D.C. from city name if user lives in Washington, DC
  // Having it in both the city and state name (as returned from the Google Address API) causes issues for Tabapay transactions
  if (selectedCity?.includes('D.C.')) {
    selectedCity = selectedCity.split(',')[0]
  }

  const handleConfirmSubmit = (values, actions) => {
    trackPendingEvent()

    const address = {
      deliveryAddress: selectedAddress.deliveryAddress,
      buildingNumber: values.aptUnitFloor,
      locality: selectedCity,
      subdivisions: [selectedAddress.state], // state
      postalCode: selectedAddress.zipCode,
    }

    // Save the customer address info
    dispatch(setCustomerAction(address))

    submitAddress({ actions, address })
  }

  const submitAddress = async ({ actions, address }) => {
    const updateApplicationResponse = await updateApplication(address)
    actions.setSubmitting(false)

    // If successful, go to the next checkpoint
    if (updateApplicationResponse?.data?.updateApplication?.applicationId) {
      proceedToNextCheckpoint()
    }
  }

  const handleNextSubmit = () => {
    if (!searchAddress) {
      setSelectedAddress({
        deliveryAddress: '',
        aptUnitFloor: '',
        locality: '',
        state: '',
        zipCode: '',
      })
    }
    setShowManualEntry(true)
  }

  const renderAddressSearch = () => {
    return (
      <>
        <h1>What's your home address?</h1>
        <p>We need to verify your home address. This is also where we’ll send your new card.</p>

        <FormPlacesInput
          apiKey={GOOGLE_API_KEY}
          label='Street Address'
          name='searchAddress'
          placeholder='(No P.O. Box or Business Address)'
          onChange={handleAddressSearchChange}
          onSelect={handleAddressSelection}
          onSelecting={setIsSelectingAddress}
          value={searchAddress}
          autoFocus
          onFocus={() => {
            setFocusedInputSourceDetail(SEGMENT_SOURCE_DETAILS.ADDRESS)
          }}
          onBlur={() => {
            // Track any time the user leaves the Google Address field during registration
            trackEvent({
              event: SEGMENT_EVENTS.registrationFormFieldEntry({
                sourceDetail: SEGMENT_SOURCE_DETAILS.ADDRESS,
              }),
            })

            setFocusedInputSourceDetail(null)
          }}
        />
        <Button
          className={styling['next-button']}
          onClick={handleNextSubmit}
          isLoading={isSelectingAddress}
          data-cy='create-account-address-next-button'
        >
          Next
        </Button>
      </>
    )
  }

  const renderConfirmAddress = () => {
    return (
      <>
        <h1 data-cy='confirm-address-header'>Confirm your address</h1>

        <div className={styling['address-container']}>
          <div className={styling.address}>
            <p>
              {selectedAddress?.deliveryAddress}
              <br />
              {selectedCity}, {selectedAddress?.state} {selectedAddress?.zipCode}
            </p>
            <div>
              <Button
                className='edit-button'
                ghost
                onClick={() => {
                  setShowConfirmAddress(false)
                  setShowManualEntry(true)
                }}
                size='sm'
                data-cy='edit-button'
              >
                <PencilIcon />
              </Button>
            </div>
          </div>
          {hasStreetAddressError && (
            <p className='error'>A Street Address is required. Please edit and try again.</p>
          )}
        </div>

        <Formik
          initialValues={{
            aptUnitFloor: selectedAddress?.aptUnitFloor || '',
          }}
          onSubmit={handleConfirmSubmit}
          validate={() => {
            const errors = {}
            if (!selectedAddress.deliveryAddress) {
              errors.deliveryAddress = true
              setHasStreetAddressError(true)
            } else {
              setHasStreetAddressError(false)
            }
            return errors
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Field
                as={FormInput}
                name='aptUnitFloor'
                label='Apt#, Suite#, Unit#'
                disabled={isSubmitting}
                placeholder='Optional'
                maxLength={ADDRESS_BUILDING_NUMBER_MAX_LENGTH}
                autoFocus
                onFocus={() => {
                  setFocusedInputSourceDetail(SEGMENT_SOURCE_DETAILS.ADDRESS_LINE_2)
                }}
                onBlur={() => {
                  // Track any time the user leaves the Address Line 2 field during registration
                  trackEvent({
                    event: SEGMENT_EVENTS.registrationFormFieldEntry({
                      sourceDetail: SEGMENT_SOURCE_DETAILS.ADDRESS_LINE_2,
                    }),
                  })

                  setFocusedInputSourceDetail(null)
                }}
              />
              <Button
                type='submit'
                isLoading={isSubmitting}
                id='confirm-address-submit'
                data-cy='create-account-address-submit-confirm-button'
              >
                Confirm
              </Button>
            </Form>
          )}
        </Formik>

        <Button
          className='address-back-button'
          ghost
          onClick={() => {
            setShowConfirmAddress(false)
            setHasStreetAddressError(false)
          }}
          data-cy='create-account-address-submit-back-button'
        >
          Back
        </Button>
      </>
    )
  }

  return (
    <div className='white-card-container' data-cy='authenticated-loaded-view'>
      <GreenwoodLogo className='logo' />
      {!showConfirmAddress && !showManualEntry && renderAddressSearch()}

      {showConfirmAddress && renderConfirmAddress()}

      {showManualEntry && (
        <ManualAddressEntryForm
          selectedAddress={selectedAddress}
          searchAddress={searchAddress}
          selectedCity={selectedCity}
          setConfirmAddress={address => setShowConfirmAddress(address)}
          hideManualEntry={() => setShowManualEntry(false)}
          submitAddress={addressData => submitAddress(addressData)}
        />
      )}
    </div>
  )
}

CreateAccountAddress.propTypes = {
  /**
   * The form data
   */
  data: PropTypes.object,

  /**
   * Error data
   */
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),

  /**
   * Loading indicator when the data is initially fetched
   */
  loading: PropTypes.bool,

  /**
   * Function used to update the application object
   */
  updateApplication: PropTypes.func,
}

export default CreateAccountAddress
