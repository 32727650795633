import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import history from '@routing/history'

const usePreventBrowserBackClick = (allowBrowserBackClick = false) => {
  const navigate = useNavigate()

  useEffect(() => {
    const unlisten = history.listen((_location) => {
      // If the user is trying to use the browser button to go backward, force them to stay on the current page
      if (_location?.action === 'POP' && !allowBrowserBackClick) {
        navigate(1)
      }
    })

    return unlisten
  }, [allowBrowserBackClick, navigate])
}

export default usePreventBrowserBackClick
