import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import classNames from 'classnames'

import Button from '@shared/components/button/Button'
import { staticRoutes } from '@routing/routes'
import CelebrationView from '@common/components/celebrationView/CelebrationView'
import { trackPage, trackEvent, SEGMENT_PAGE_NAMES, SEGMENT_EVENTS } from '@common/utils'

import styling from './WireTransferSuccess.module.scss'

const WireTransferSuccess = () => {
  const navigate = useNavigate()
  const { state } = useLocation()

  // Tracks page visit
  useEffect(() => {
    trackPage({ name: SEGMENT_PAGE_NAMES.WIRE_TRANSFER_FORM_SENT })
  }, [])

  return (
    <CelebrationView>
      <div
        className={classNames(
          'create-account-content-wrapper',
          styling['wire-transfer-success-content-wrapper']
        )}
        data-cy='wire-transfer-success-container'
      >
        <h1>We've Emailed Your Wire Transfer Form</h1>
        <p>
          Your wire transfer form was sent to <strong>{state?.sentToEmail}</strong>.
        </p>
        <div className='buttons-container'>
          <Button
            onClick={() => {
              navigate(staticRoutes.moveMoney.pathname)
              trackEvent({ event: SEGMENT_EVENTS.EMAIL_WIRE_TRANSFER_FORM_SENT_BUTTON_CLICK })
            }}
            onDark
          >
            DONE
          </Button>
        </div>
      </div>
    </CelebrationView>
  )
}

export default WireTransferSuccess
