import React from 'react'
import classNames from 'classnames'
import { useNavigate, useLocation } from 'react-router-dom'

import Button from '@shared/components/button/Button'
import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'
import { ReactComponent as MoneyTransferIcon } from '@common/images/icons/money-transfer.svg'

import useFundingFlowRouteGuard from '@common/utils/useFundingFlowRouteGuard'
import { FUNDING_FLOWS } from '@common/constants'
import { staticRoutes } from '@routing/routes'

import styling from './addMoney.module.scss'

const REQUIRED_FUNDING_FLOWS = [FUNDING_FLOWS.BASIC]

const AddMoney = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  useFundingFlowRouteGuard({ requiredFundingFlows: REQUIRED_FUNDING_FLOWS })

  const handleAddMoneyClick = () => {
    navigate(staticRoutes.accountsForFunding.pathname, { state })
  }

  const handleCancelClick = () => {
    if (state?.shouldSetupDirectDeposit) {
      navigate(staticRoutes.directDepositSetup.pathname, {
        state: {
          fundingFlow: state?.fundingFlow,
        },
      })
    } else {
      navigate(staticRoutes.dashboard.pathname)
    }
  }

  return (
    <div className={classNames('create-account-black-page', styling['add-money-container'])}>
      <GreenwoodLogo className='logo' />
      <div className='start-flow-black-page-content'>
        <MoneyTransferIcon className={styling['money-transfer-icon']} />

        <h1>Add money to your account</h1>
        <p>Transfer money to your Greenwood account from an existing bank account.</p>

        <div className='buttons-container'>
          <Button onClick={handleAddMoneyClick} data-cy='add-money-action' onDark>
            Add Money
          </Button>
          <Button onClick={handleCancelClick} data-cy='add-money-cancel' onDark ghost>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )
}

export default AddMoney
