import React, { useEffect, useMemo } from 'react'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'

import { gql, useQuery } from '@services/serviceUtils'
import LoadingContainer from '@shared/components/loadingContainer/LoadingContainer'
import Button from '@shared/components/button/Button'
import { toDollars } from '@shared/utils'
import {
  getCustomerProductSubscriptions,
  getProductByType,
  SEGMENT_PAGE_NAMES,
  trackPage,
  getAccessBenefitsForProduct,
} from '@common/utils'
import { PRODUCT_TYPES } from '@common/constants'

import styling from './aboutMyMembership.module.scss'

const productsAndCtasQuery = gql`
  query ProductsAndCtas {
    products {
      productType
      title
      alreadyAvailableToCustomer
      fee {
        amount {
          amount
        }
        frequency
      }
      feeOptions {
        amount {
          amount
        }
        frequency
        key
      }
      benefits {
        benefits
        category
      }
      features {
        featureDescriptionKey
        featureTitleKey
      }
      agreements {
        title
        url
      }
    }
    ctas {
      callToActionType
    }
  }
`

const accountSummaryQuery = gql`
  query AccountSummary {
    accountSummary {
      accountType
    }
  }
`

const fetchSubscriptionQuery = gql`
  query Subscriptions {
    subscriptions {
      amount
      billingPeriod
    }
  }
`

const frequencyToLabel = frequency => frequency.replace('/', '').replace('ly', '')

const SimpleTableRow = ({ label, value, dataCy, appendAsterisk }) => {
  return (
    <tr>
      <th>
        <span data-cy={`${dataCy}-label`}>{label}</span>
      </th>
      <td>
        <span
          data-cy={`${dataCy}-data`}
          className={classNames({ 'append-asterisk': appendAsterisk })}
        >
          {typeof value === 'object' && value.length ? (
            value.map((benefit, index) => {
              if (typeof benefit === 'string') {
                if (index === value.length - 1) {
                  return `${benefit}`
                }

                return `${benefit}, `
              } else {
                return benefit
              }
            })
          ) : value}
        </span>
      </td>
    </tr>
  )
}

const MembershipDetails = ({ product, subscriptionAmount, subscriptionPeriod, allProducts }) => {
  const amount = product?.fee?.amount?.amount || subscriptionAmount
  const frequency = product?.fee?.frequency || subscriptionPeriod

  const paymentPlan =
    amount && frequency ? (
      <>
        {toDollars({ value: amount, isSuperscript: false })} /{' '}
        {frequencyToLabel(frequency.toLowerCase())}
      </>
    ) : null

  const newPremiumProduct = allProducts?.find(
    item => item.productType === PRODUCT_TYPES.premiumPlus
  )
  const userProductDetails =
    product?.productType !== PRODUCT_TYPES.premium ? product : newPremiumProduct
  const accessBenefits = getAccessBenefitsForProduct(userProductDetails)

  return (
    <>
      {paymentPlan ? (
        <SimpleTableRow dataCy='payment-plan' label='PAYMENT PLAN' value={paymentPlan} />
      ) : null}
      {/* A weird bug exists in underlying framework/libary code that prevents using string literal
            templates in functions preceded by optional chaining '?.' accessors, so this extra
            'product?.features && ' guard is required here.
            Reference: https://github.com/babel/babel/issues/10904#issuecomment-668907518 */}
      {product?.features.length
        ? product.features.map((feature, featureIndex) => (
          <SimpleTableRow
            key={featureIndex}
            label={feature.featureTitleKey}
            value={feature.featureDescriptionKey}
            dataCy={`feature_${featureIndex}`}
          />
        ))
        : null}
      {product?.benefits.length
        ? product.benefits.map((benefit, benefitIndex) => {
          return (
            <SimpleTableRow
              key={benefitIndex}
              label={benefit.category}
              value={benefit.benefits}
              dataCy={`benefit_${benefitIndex}`}
            />
          )
        })
        : null}
      <SimpleTableRow dataCy='access-benefits' label='ACCESS BENEFITS' value={accessBenefits} />
    </>
  )
}

const AboutMyMembership = () => {
  const navigate = useNavigate()

  useEffect(() => {
    trackPage({ name: SEGMENT_PAGE_NAMES.ABOUT_MY_MEMBERSHIP })
  }, [])

  /* Retrieve product and CTA data. `products.alreadyAvailableToCustomer` and `ctas` both contain
     dynamic data, so the cache cannot be read */
  const {
    data: productsAndCtasData,
    loading: productsAndCtasLoading,
    error: productsAndCtasError,
  } = useQuery(productsAndCtasQuery, {
    fetchPolicy: 'network-only',
  })

  const {
    loading: accountSummaryLoading,
    data: accountSummaryData,
    error: accountSummaryError,
  } = useQuery(accountSummaryQuery, {
    fetchPolicy: 'no-cache',
  })
  const {
    loading: subscriptionLoading,
    data: subscriptionData,
    error: subscriptionError,
  } = useQuery(fetchSubscriptionQuery, {
    fetchPolicy: 'no-cache',
  })

  // Get the DDA product information for the user's account
  const customerProducts = useMemo(() => (
    getCustomerProductSubscriptions(productsAndCtasData?.products)
  ), [productsAndCtasData?.products])

  const userAccountType = useMemo(() => (
    accountSummaryData?.accountSummary?.accountType
  ), [accountSummaryData?.accountSummary?.accountType])

  const product = useMemo(() => (
    getProductByType({
      products: customerProducts,
      productType: userAccountType,
    }) || customerProducts[0]
  ), [customerProducts, userAccountType])

  const monthlyFeeOption = useMemo(() => (
    product?.feeOptions?.find(opt => opt?.key?.includes('MONTHLY'))
  ), [product?.feeOptions])

  const subscriptionAmount = useMemo(() => (
    subscriptionData?.subscriptions[0]?.amount || monthlyFeeOption?.amount?.amount
  ), [subscriptionData?.subscriptions, monthlyFeeOption])
  const subscriptionPeriod = useMemo(() => (
    subscriptionData?.subscriptions[0]?.billingPeriod || monthlyFeeOption?.frequency
  ), [subscriptionData?.subscriptions, monthlyFeeOption])

  const displayName = useMemo(() => (
    product?.displayName || '-'
  ), [product?.displayName])

  const handleBackButtonClick = () => {
    navigate(-1)
  }

  return (
    <div
      className={classNames(
        'membership-view-content',
        'membership-view-container',
        styling['about-membership-container']
      )}
    >
      <h2 data-cy='membership-settings-header'>About my Membership</h2>

      <LoadingContainer
        loading={productsAndCtasLoading || accountSummaryLoading || subscriptionLoading}
        error={!!productsAndCtasError && !!accountSummaryError && !!subscriptionError}
        errorMessage='Error loading membership information'
      >
        <table className={classNames('simple-table', styling['about-membership-table'])} data-cy='about-my-membership-table'>
          <tbody>
            <SimpleTableRow dataCy='type' label='Type' value={displayName} />

            <MembershipDetails
              product={product}
              subscriptionAmount={subscriptionAmount}
              subscriptionPeriod={subscriptionPeriod}
              allProducts={productsAndCtasData?.products}
            />
          </tbody>
        </table>
        <Button onClick={handleBackButtonClick} outline data-cy='about-my-membership-back-button'>
          Back
        </Button>
      </LoadingContainer>
    </div>
  )
}

export default AboutMyMembership
