import React, { useState } from 'react'
import classNames from 'classnames'

import { gql, useMutation } from '@services/serviceUtils'
import Button from '@shared/components/button/Button'
import Modal from '@shared/components/modal/Modal'
import ModalHeader from '@shared/components/modal/ModalHeader'
import ModalBody from '@shared/components/modal/ModalBody'
import { CARD_AVAILABLE_OPERATIONS } from '@common/constants'

import styling from './cardActivationCallout.module.scss'

const activateCardMutation = gql`
  mutation ActivateCard {
    activateCard {
      availableOperations
    }
  }
`

const CardActivationCallout = ({ className, onCardActivated }) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const toggleModal = () => {
    setShowConfirmModal(isShown => !isShown)
  }

  const [activateCard, { loading }] = useMutation(activateCardMutation)

  const handleActivateClick = async () => {
    const response = await activateCard()
    const canActivateCard = response?.data?.activateCard?.availableOperations.includes(
      CARD_AVAILABLE_OPERATIONS.ACTIVATE_CARD_TOGGLE
    )

    /* If successfully activated (make sure response indicates card can no longer be activated),
       close the modal and execute the onCardActivated callback */
    if (!response?.errors && !canActivateCard) {
      toggleModal()
      onCardActivated && onCardActivated()
    }
  }

  return (
    <>
      <Modal
        open={showConfirmModal}
        centered
        withCloseButton
        toggleModal={toggleModal}
        className={styling['confirm-modal']}
        closeOnBackdropClick={false}
      >
        <ModalHeader className={styling['modal-header']}>
          <h4>Received Your Physical Card?</h4>
        </ModalHeader>
        <ModalBody className={styling['modal-body']}>
          <p>Proceed only if you have received your physical card.</p>
          <div className={styling['button-container']}>
            <Button onClick={handleActivateClick} isLoading={loading}>
              Activate
            </Button>
            <Button ghost onClick={toggleModal} disabled={loading}>
              Cancel
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <div className={classNames(styling['card-activation-callout'], className)}>
        <div className='row'>
          <h2>Ready to activate your physical card?</h2>
          <p>You can also activate your card in the Greenwood Mobile App.</p>
          <Button
            className={styling['activate-card-button']}
            onClick={() => {
              setShowConfirmModal(true)
            }}
          >
            Activate Card
          </Button>
        </div>
      </div>
    </>
  )
}

export default CardActivationCallout
