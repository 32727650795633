import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { staticRoutes } from '@routing/routes'
import FlowError from 'src/views/flowError/FlowError'
import {
  getDirectDepositFlowCompletedRoute,
  trackPage,
  trackEvent,
  SEGMENT_PAGE_NAMES,
  SEGMENT_EVENTS,
  SEGMENT_SOURCE_DETAILS,
} from '@common/utils'
import { FUNDING_FLOWS } from '@common/constants'
import useFundingFlowRouteGuard from '@common/utils/useFundingFlowRouteGuard'

import styling from './directDepositFormSendFailed.module.scss'

const REQUIRED_FUNDING_FLOWS = [
  FUNDING_FLOWS.BASIC,
  FUNDING_FLOWS.ONBOARDING_PREMIUM,
  FUNDING_FLOWS.DIRECT_DEPOSIT,
]

const DirectDepositFormSendFailed = () => {
  const navigate = useNavigate()
  const { state } = useLocation()

  useFundingFlowRouteGuard({
    requiredFundingFlows: REQUIRED_FUNDING_FLOWS,
    redirectWithReplace: true,
  })

  const handleDoneClick = () => {
    navigate(getDirectDepositFlowCompletedRoute({ fundingFlow: state?.fundingFlow }))
    trackEvent({
      event: SEGMENT_EVENTS.MOVE_MONEY_DIRECT_DEPOSIT_FORM_FAILED_BUTTON_CLICK(
        SEGMENT_SOURCE_DETAILS.BACK
      ),
    })
  }

  // Tracks page visit
  useEffect(() => {
    trackPage({ name: SEGMENT_PAGE_NAMES.DIRECT_DEPOSIT_FORM_SENT_FAILED })
  }, [])

  return (
    <FlowError
      header='Direct Deposit Failed'
      onPrimaryButtonClick={() => {
        navigate(staticRoutes.directDepositSelection.pathname, { state })
        trackEvent({
          event: SEGMENT_EVENTS.MOVE_MONEY_DIRECT_DEPOSIT_FORM_FAILED_BUTTON_CLICK(
            SEGMENT_SOURCE_DETAILS.TRY_AGAIN
          ),
        })
      }}
      onSecondaryButtonClick={handleDoneClick}
      className={styling['direct-deposit-form-send-failed']}
    />
  )
}

export default DirectDepositFormSendFailed
