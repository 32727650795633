import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import styling from './breadcrumb.module.scss'

const Breadcrumb = ({ crumbs }) => {
  return (
    <nav className={styling.container} aria-label='breadcrumb'>
      <ol>
        {crumbs.map((crumb, index) => {
          const { text, route } = crumb

          return (
            <li key={`crumb_${index}`}>
              {route ? <Link to={route}>{text}</Link> : <span>{text}</span>}
            </li>
          )
        })}
      </ol>
    </nav>
  )
}

Breadcrumb.propTypes = {
  /**
   * Route to go to when user clicks the breadcrumb-back link
   */
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({
      route: PropTypes.string,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default Breadcrumb
