import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { SEGMENT_EVENTS, SEGMENT_IDENTIFIER_TRAITS, trackEvent, trackIdentity } from '@common/utils'
import { gql, useLazyQuery, decodeJwtPayload } from '@services/serviceUtils'
import { setApplicationAction } from '@redux/application/applicationActions'
import { store } from '@redux/store'

export const applicationQuery = gql`
  query {
    application {
      customerApplicationStatus
    }
  }
`

const useFinalizeAccountCreation = () => {
  const navigate = useNavigate()
  const [redirectLocation, setRedirectLocation] = useState('')

  const [getApplication, { data: applicationData }] = useLazyQuery(applicationQuery)

  // Wait for the dispatch to complete before navigation
  const setApplicationThenNavigate = useCallback(
    application => _dispatch => {
      _dispatch(setApplicationAction(application))
      navigate(redirectLocation?.pathname, { state: redirectLocation?.state, replace: true })
    },
    [navigate, redirectLocation]
  )

  // After the application data loads, save it to the store then navigate away
  useEffect(() => {
    if (applicationData) {
      store.dispatch(setApplicationThenNavigate(applicationData.application))
    }
  }, [applicationData, setApplicationThenNavigate])

  const trackUserForAnalytics = ({ data, displayEmail, waitlistCode }) => {
    /* During sign-up, issue an `identify` call. Get the userId out of the JWT payload to
    identify the user for analytics */
    const userId = decodeJwtPayload(data?.token?.access_token)?.sub

    trackIdentity({
      userId,
      traits: SEGMENT_IDENTIFIER_TRAITS.registration({
        email: displayEmail,
        waitlistCode,
      }),
      callback: () => {
        // This track call must come after the identify call
        trackEvent({ event: SEGMENT_EVENTS.ACCOUNT_CREATED })
      },
    })
  }

  const updateAnalyticsAndApplicationStatus = ({
    data,
    displayEmail,
    waitlistCode,
    redirectTo,
  }) => {
    trackUserForAnalytics({ data, displayEmail, waitlistCode })
    setRedirectLocation(redirectTo)
    getApplication()
  }

  return {
    updateAnalyticsAndApplicationStatus,
  }
}

export default useFinalizeAccountCreation
