import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Card from '@shared/components/card/Card'
import CardBody from '@shared/components/card/CardBody'
import { toDollars } from '@shared/utils'
import styling from './accountSummaryCard.module.scss'

const AccountSummaryCard = ({
  title,
  accountNumber,
  amount,
  className,
  active = false,
  activeBorder = true,
  ...rest
}) => {
  return (
    <Card
      className={classNames(styling['account-card'], className)}
      active={active && activeBorder}
      {...rest}
    >
      <CardBody>
        <div className={styling.account}>
          <h3 className={classNames({ [styling.active]: active })}>{title || ''}</h3>
          <span className={classNames({ [styling.active]: active })}>{accountNumber}</span>
        </div>
        <span className={styling['account-balance']}>{toDollars({ value: amount })}</span>
      </CardBody>
    </Card>
  )
}

AccountSummaryCard.propTypes = {
  /**
   * An AccountDTO object
   */
  title: PropTypes.string.isRequired,

  /**
   * An array of ProductDTO objects
   */
  accountNumber: PropTypes.string.isRequired,

  /**
   * An array of ProductDTO objects
   */
  amount: PropTypes.number.isRequired,

  /**
   * Additional classes to pass to the card
   */
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

  /**
   * Highlights the card when it is active. Default false
   */
  active: PropTypes.bool,

  /**
   * Show a border effect when the card is active. Default true
   */
  activeBorder: PropTypes.bool,
}

export default AccountSummaryCard
