import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'

import styling from './transactionDetailModal.module.scss'
import { getTransactionDate } from './transactionDetailUtils'

import Modal from '@shared/components/modal/Modal'
import ModalBody from '@shared/components/modal/ModalBody'
import ModalHeader from '@shared/components/modal/ModalHeader'

import { toDollars } from '@shared/utils'
import { MAX_WIDTH_BREAKPOINTS } from '@shared/constants/uiConstants'
import { trackPage, SEGMENT_PAGE_NAMES } from '@common/utils'

const TransactionDetailModal = ({ transaction, isOpen, onToggleModal, onCloseModal }) => {
  const detailData = transaction?.details?.slice(0)

  const isSmallMobile = useMediaQuery({ query: `(max-width: ${MAX_WIDTH_BREAKPOINTS.SMALL}px)` })

  // Add date row to details
  detailData?.unshift({
    label: 'DATE',
    value: getTransactionDate(transaction),
  })

  const renderDetail = detail =>
    isSmallMobile ? (
      <td className={styling['mobile-small']}>
        <div className={styling['detail-column']}>{detail.label}</div>
        <div className={styling['value-column']}>{detail.value}</div>
      </td>
    ) : (
      <>
        <td className={styling['detail-column']}>{detail.label}</td>
        <td className={styling['value-column']}>{detail.value}</td>
      </>
    )

  // Tracks page visit
  useEffect(() => {
    if (isOpen) {
      trackPage({ name: SEGMENT_PAGE_NAMES.TRANSACTION_DETAILS })
    }
  }, [isOpen])

  return (
    <Modal
      className={styling['transaction-detail-modal']}
      centered
      size='md'
      open={isOpen}
      toggleModal={onToggleModal}
    >
      <ModalHeader withCloseButton closeModal={onCloseModal}>
        <div className={styling.header}>
          <h5>TRANSACTION DETAILS</h5>
          <h2>{toDollars({ value: transaction?.amount?.amount || 0 })}</h2>
          <p>{transaction?.description}</p>
        </div>
      </ModalHeader>
      <ModalBody>
        <table className='simple-table'>
          <tbody>
            {detailData?.map((detail, detailIndex) => (
              <tr key={detailIndex}>{renderDetail(detail)}</tr>
            ))}
          </tbody>
        </table>
      </ModalBody>
    </Modal>
  )
}

TransactionDetailModal.propTypes = {
  /**
   * Transaction DTO
   */
  transaction: PropTypes.object,

  /**
   * Flag to control visibility of modal
   */
  isOpen: PropTypes.bool.isRequired,

  /**
   * ToggleModal callback
   */
  onToggleModal: PropTypes.func.isRequired,

  /**
   * CloseModal callback
   */
  onCloseModal: PropTypes.func.isRequired,
}

export default TransactionDetailModal
