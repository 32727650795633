import React, { useMemo, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { A11y, Pagination } from 'swiper/modules'

import LoadingContainer from '@shared/components/loadingContainer/LoadingContainer'
import VideoCard from '@common/components/videoCard/VideoCard'

import useContentful from '@common/utils/useContentful'

import 'swiper/scss'
import 'swiper/scss/pagination'
import 'swiper/scss/a11y'

import styling from './communityInvest.module.scss'

const contentfulQuery = `
  {
    videoCardCollection {
      total
      items {
        influencerVideo {
          url
          title
        }
        influencerPicture { 
          url
          description
          title
        }
      }
    }
  }
`

const CommunityInvest = () => {
  const { contentfulData, contentfulError } = useContentful({ query: contentfulQuery })

  const [selectedIndex, setSelectedIndex] = useState(0)

  const videoCardCollection = useMemo(() => contentfulData?.videoCardCollection, [contentfulData])

  return (
    <LoadingContainer
      error={!!contentfulError}
      errorMessage='Error loading videos'
      loading={!contentfulData && !contentfulError}
    >
      <div className='row'>
        <Swiper
          modules={[A11y, Pagination]}
          direction='vertical'
          slidesPerView={1}
          a11y
          pagination={{
            clickable: true,
          }}
          loop
          className={styling.swiper}
          onSlideChange={slide => {
            setSelectedIndex(slide.realIndex)
          }}
        >
          {videoCardCollection?.items?.map((item, index) => (
            <SwiperSlide key={`video-${index}`} className={styling['video-slide']}>
              <VideoCard
                cardDetails={item}
                isSelected={selectedIndex === index}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </LoadingContainer>
  )
}

export default CommunityInvest
