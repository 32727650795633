import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import Card from '@shared/components/card/Card'
import { ReactComponent as Chevron } from '@shared/images/icons/chevron.svg'
import PlatinumCard from '@common/images/greenwood-platinum-card.png'
import ElevateCard from '@common/images/greenwood-elevate-card.png'

import { staticRoutes } from '@routing/routes'
import { FUNDING_FLOWS } from '@common/constants'

import styling from './standardUpgradeInvestCampaignCallout.module.scss'

const StandardUpgradeInvestCampaignCallout = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const getImages = firstElevateIndex => {
    const images = []
    for (let i = 0; i < 3; i++) {
      if (i % 2 === firstElevateIndex) {
        images.push(<img src={ElevateCard} alt='elevate-card' key={`elevate-card-${i}`} />)
      } else {
        images.push(<img src={PlatinumCard} alt='platinum-card' key={`premium-card-${i}`} />)
      }
    }

    return images
  }

  return (
    <Card
      className={styling.card}
      size='small'
      onClick={() =>
        navigate(staticRoutes.selectAccountTier.pathname, {
          state: {
            isUpgrading: true,
            fundingFlow: FUNDING_FLOWS.UPGRADE_MEMBERSHIP,
            cancelUpgradePathname: pathname,
          },
        })
      }
      data-cy='standard-upgrade-invest-campaign-callout'
    >
      <div className={styling['text-container']}>
        <span>Get $10</span>
        <p className={styling.subtext}>
          Open an Invest account when upgrading your Greenwood Membership
        </p>
        <p className={styling['upgrade-text']}>
          UPGRADE <Chevron />
        </p>
      </div>
      <div className={styling['image-container']}>
        <div className={styling['image-container-section']}>{getImages(1)}</div>
        <div className={styling['image-container-section']}>{getImages(0)}</div>
      </div>
    </Card>
  )
}

export default StandardUpgradeInvestCampaignCallout
