import React from 'react'
import { useNavigate } from 'react-router-dom'

import Card from '@shared/components/card/Card'
import { ReactComponent as AtmIllustration } from '@common/images/illustrations/illustration-atm.svg'

import { staticRoutes } from '@routing/routes'
import { FUNDING_FLOWS } from '@common/constants'
import { trackEvent, SEGMENT_EVENTS, SEGMENT_SOURCE_DETAILS } from '@common/utils'

import styling from './accountFundingCallout.module.scss'

const AccountFundingCallout = () => {
  const navigate = useNavigate()

  return (
    <Card
      className={styling.card}
      size='small'
      onClick={() => {
        navigate(staticRoutes.addMoney.pathname, {
          state: {
            fundingFlow: FUNDING_FLOWS.BASIC,
          },
        })
        trackEvent({
          event: SEGMENT_EVENTS.DASHBOARD_BUTTON_CLICK(SEGMENT_SOURCE_DETAILS.FUND_YOUR_ACCOUNT),
        })
      }}
      data-cy='account-funding-callout'
    >
      <AtmIllustration className={styling.illustration} />
      <div className={styling['text-container']}>
        <h3>Fund your spending account</h3>
        <p>Your spending account balance is 0. Click here to fund now.</p>
      </div>
    </Card>
  )
}

export default AccountFundingCallout
