import React, { useState, forwardRef, useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { KEYS } from '@shared/constants/codeConstants'
import { maskString, removeBetween, insertSubstring } from '@shared/utils'

// Masks the incoming value depending on the mask configuration
const maskValue = ({ value, maskConfigPart }) => {
  if (typeof maskConfigPart !== 'string' && maskConfigPart.obscuringCharacter) {
    return maskString({ value, mask: maskConfigPart.obscuringCharacter })
  } else {
    // This part is either a delimiter or non-masked value
    return value
  }
}

// Update the value depending on keystrokes and cursor positioning
const getUpdatedMaskedValue = ({ value, start, end, key }) => {
  if (key === 'Delete' || key === 'Backspace') {
    return removeBetween({ value, start, end, backward: key === 'Backspace' })
  } else {
    return insertSubstring({ value, valueToInsert: key, start, end })
  }
}

const ObscuredMaskedInput = forwardRef(
  (
    {
      className,
      name,
      id,
      value = '',
      onChange = () => {},
      show = false,
      disabled = false,
      maskConfigPart = {},
      dataCy,
      previousInputRef,
      nextInputRef,
      ...rest
    },
    ref
  ) => {
    const maskedValue = maskValue({ value, maskConfigPart })

    const [displayValue, setDisplayValue] = useState(show ? value : maskedValue)
    const [selectionData, setSelectionData] = useState({ start: 0, end: 0 })
    const [nextSelectionData, setNextSelectionData] = useState(null)

    const [focusPreviousInput, setFocusPreviousInput] = useState(false)
    const [focusNextInput, setFocusNextInput] = useState({ focus: false, selectAll: false })

    const [key, setKey] = useState('')

    // Update the display value when the value or show prop changes
    useEffect(() => {
      setDisplayValue(show ? value : maskedValue)
    }, [value, show])

    /* Places the cursor at the end of the previous input. Delay is required to
       ensure that the selection change occurs after the value prop changes */
    useEffect(() => {
      const previousInputFocusTimeout = setTimeout(() => {
        if (focusPreviousInput) {
          const previousInputRefValueEndPosition = previousInputRef?.current?.value?.length || 0

          previousInputRef?.current?.focus()
          previousInputRef?.current?.setSelectionRange(
            previousInputRefValueEndPosition,
            previousInputRefValueEndPosition
          )
          setFocusPreviousInput(false)
        }
      })

      return () => {
        clearTimeout(previousInputFocusTimeout)
      }
    }, [focusPreviousInput])

    /* Places the cursor at the beginning of the previous input. Delay is required to
       ensure that the selection change occurs after the value prop changes */
    useEffect(() => {
      const nextInputFocusTimeout = setTimeout(() => {
        if (focusNextInput.focus) {
          nextInputRef?.current?.focus()

          if (focusNextInput.selectAll) {
            nextInputRef?.current?.setSelectionRange(0, nextInputRef?.current?.value?.length)
          } else {
            nextInputRef?.current?.setSelectionRange(0, 0)
          }

          setFocusNextInput({ focus: false, selectAll: false })
        }
      })

      return () => {
        clearTimeout(nextInputFocusTimeout)
      }
    }, [focusNextInput])

    /* Places the cursor in the correct position after update. Delay is required to
       ensure that the selection change occurs after the value prop changes */
    useEffect(() => {
      const nextSelectionTimeout = setTimeout(() => {
        if (nextSelectionData) {
          ref?.current?.setSelectionRange(nextSelectionData.start, nextSelectionData.end)
          setNextSelectionData(null)
        }
      })

      return () => {
        clearTimeout(nextSelectionTimeout)
      }
    }, [ref, nextSelectionData])

    // Updates the value and cursor position
    const handleChange = ({ target: { value: _value } }) => {
      let newValue = _value

      if (!show && maskConfigPart.obscuringCharacter) {
        // Unmask the value
        newValue = getUpdatedMaskedValue({
          value,
          start: selectionData.start,
          end: selectionData.end,
          key,
        })
      }

      onChange(newValue)

      if (
        newValue.length === maskConfigPart.maxLength &&
        selectionData.start + 1 === newValue.length
      ) {
        /* If input reaches max length and the cursor was at the second-last position of
           the input, focus and select the next input */
        setFocusNextInput({ focus: true, selectAll: true })
      } else {
        // Place the cursor in the correct position depending on key pressed
        if (key === KEYS.DELETE) {
          setNextSelectionData({ start: selectionData.start, end: selectionData.start })
        } else if (key === KEYS.BACKSPACE) {
          setNextSelectionData({ start: selectionData.start - 1, end: selectionData.start - 1 })
        } else {
          setNextSelectionData({ start: selectionData.start + 1, end: selectionData.start + 1 })
        }
      }
    }

    // Tracks the cursor position
    const handleSelect = ({ target: { selectionStart, selectionEnd } }) => {
      setSelectionData({ start: selectionStart, end: selectionEnd })
    }

    // Track keystrokes, and handles arrow, backspace, and delete navigation
    const handleKeyDown = ({ key: _key }) => {
      if ((_key === KEYS.ARROW_LEFT || _key === KEYS.BACKSPACE) && selectionData.end === 0) {
        // Beginning of the input, go to the end of the previous input
        setFocusPreviousInput(true)
      } else if (
        (_key === KEYS.ARROW_RIGHT || _key === KEYS.DELETE) &&
        selectionData.start === value.length
      ) {
        // End of the input, go to the beginning of the next input
        setFocusNextInput({ focus: true, selectAll: false })
      }

      setKey(_key)
    }

    // Prevent click propagating through to the container
    const handleClick = event => {
      event.stopPropagation()
    }

    return (
      <input
        className={classNames(className, maskConfigPart.className)}
        name={name}
        id={id}
        value={displayValue}
        ref={ref}
        onChange={handleChange}
        onSelect={handleSelect}
        onKeyDown={handleKeyDown}
        onClick={handleClick}
        maxLength={maskConfigPart.maxLength}
        size={maskConfigPart.maxLength}
        placeholder={maskConfigPart.placeholder}
        disabled={maskConfigPart.disabled || disabled}
        autoComplete='off'
        data-cy={dataCy}
        {...maskConfigPart.inputProps}
        {...rest}
      />
    )
  }
)

export const maskConfigPartPropType = PropTypes.oneOfType([
  /**
   * Use this shape to describe a value part
   */
  PropTypes.shape({
    /**
     * Maximum length of the value
     */
    maxLength: PropTypes.number.isRequired,

    /**
     * Character used to obscure each character in the value. Omit to always
     * display the value
     */
    obscuringCharacter: PropTypes.string,

    /**
     * Placeholder displayed in this input part
     */
    placeholder: PropTypes.string,

    /**
     * Disables user interaction for this input part
     */
    disabled: PropTypes.bool,

    /**
     * Any additional props to pass to this input part
     */
    inputProps: PropTypes.object,

    /**
     * Any additional style classname to pass to this input part
     */
    className: PropTypes.string,
  }),

  /**
   * Use a plain string to represent a delimiter
   */
  PropTypes.string,
])

ObscuredMaskedInput.propTypes = {
  /**
   * Any additional style classname to pass to this input
   */
  className: PropTypes.string,

  /**
   * Input HTML name attribute
   */
  name: PropTypes.string,

  /**
   * Input HTML id attribute
   */
  id: PropTypes.string,

  /**
   * The input's current value
   */
  value: PropTypes.string,

  /**
   * Callback executed when the value is changed
   */
  onChange: PropTypes.func,

  /**
   * Un-obscures the input value
   */
  show: PropTypes.bool,

  /**
   * Disables user interaction with the component
   */
  disabled: PropTypes.bool,

  /**
   * Configures the masking and obscuring characteristics
   */
  maskConfigPart: maskConfigPartPropType,

  /**
   * HTML data-cy attribute to support cypress testing
   */
  dataCy: PropTypes.string,

  /**
   * Reference to the previous input part, if applicable
   */
  previousInputRef: PropTypes.shape({ current: PropTypes.any }),

  /**
   * Reference to the next input part, if applicable
   */
  nextInputRef: PropTypes.shape({ current: PropTypes.any }),
}

export default ObscuredMaskedInput
