import React, { useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { useMediaQuery } from 'react-responsive'

import { ReactComponent as PlusIcon } from '@shared/images/icons/plus.svg'
import Button from '@shared/components/button/Button'

import { MAX_WIDTH_BREAKPOINTS } from '@shared/constants/uiConstants'
import { getDetailsBenefitsList, getPaymentBenefitsList } from '@common/constants/benefitsText'

import styling from './additionalBenefitsDropdown.module.scss'

const AdditionalBenefitsDropdown = ({ collapsible, isPaymentScreen }) => {
  const [dropdownToggle, setDropdownToggle] = useState({})
  const [isCollapsed, setIsCollapsed] = useState(false)

  const isTablet = useMediaQuery({ query: `(max-width: ${MAX_WIDTH_BREAKPOINTS.MEDIUM_LARGE}px)` })

  const list = useMemo(() => (
    isPaymentScreen ? getPaymentBenefitsList() : getDetailsBenefitsList()
  ), [isPaymentScreen])

  const isCollapsible = useMemo(() => (
    collapsible && isTablet
  ), [collapsible, isTablet])

  useEffect(() => {
    if (collapsible) {
      setIsCollapsed(true)
    }
  }, [collapsible])

  useEffect(() => {
    const initialToggleState = {}

    list?.forEach((item, index) => {
      initialToggleState[index] = false
    })

    setDropdownToggle(initialToggleState)
  }, [list])

  const updateDropdownToggle = key => {
    setDropdownToggle({
      ...dropdownToggle,
      [key]: !dropdownToggle[key],
    })
  }

  const getDropdowns = () => {
    const dropdowns = []
    for (let i = 0; i < list.length; i++) {
      const toggleContainerClasses = classNames(
        styling['benefit-toggle-container'],
        list[i]?.color && styling[`color-${list[i].color}`],
        !!dropdownToggle[i] && styling['is-open']
      )

      dropdowns.push(
        <div className={toggleContainerClasses} key={`dropdown-${i}`}>
          <div className={styling['icon-container']}>
            {list[i].icon}
          </div>
          <div className={styling['text-container']}>
            <span className={styling['benefit-title']}>{list[i].title}</span>
            {!!dropdownToggle[i] && <span>{list[i].subtext}</span>}
          </div>
          <PlusIcon className={styling.plus} onClick={() => updateDropdownToggle(i)} />
        </div>
      )
    }

    return dropdowns
  }

  const containerClasses = classNames(
    styling['additional-benefits-container'],
    isCollapsible && styling.collapsible,
    isCollapsed && styling['is-collapsed']
  )

  return (
    <div className={containerClasses}>
      {getDropdowns()}
      {isCollapsible && (
        <div className={styling['collapse-button-container']}>
          <Button onClick={() => setIsCollapsed(!isCollapsed)}>
            {isCollapsed ? 'More Benefits' : 'Collapse'}
          </Button>
        </div>
      )}
    </div>
  )
}

export default AdditionalBenefitsDropdown
