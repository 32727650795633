import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import classNames from 'classnames'
import { useMediaQuery } from 'react-responsive'

import { ROUTE_USER_FLOW, MAX_WIDTH_BREAKPOINTS } from '@shared/constants/uiConstants'
import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'
import FormInput from '@shared/components/formInput/FormInput'
import Button from '@shared/components/button/Button'
import ExternalLink from '@common/components/link/ExternalLink'
import SignUpSideBar from './SignUpSideBar'

import {
  getRedirectLocationByChallengeTypeAndUserFlow,
  SEGMENT_EVENTS,
  SEGMENT_PAGE_NAMES,
  SEGMENT_SOURCE_DETAILS,
  trackEvent,
  trackTvPixelEvent,
  trackPage,
  reset,
  BOOTSTRAP_SITEMAP_RESOURCES,
  getBootstrapSitemapResource,
  getBootstrapFeatureFlag,
  BOOTSTRAP_FEATURE_FLAGS,
} from '@common/utils'
import { setLaunchDarklyCustomerAction } from '@redux/customer/customerActions'
import { staticRoutes } from '@routing/routes'
import { setEmailAction } from '@redux/unauthenticatedUser/unauthenticatedUserActions'
import { resetPassword } from '@services/serviceUtils'

import styling from './provideEmail.module.scss'

// The base validation schema without waitlist code
const baseValidationSchema = {
  email: Yup.string()
    .email('Invalid Email')
    .required('Required'),
  isRegistering: Yup.boolean(),
}

// Valdiation schema used when waitlist is required
const waitlistRequiredValidationSchema = Yup.object().shape({
  ...baseValidationSchema,
  waitlistCode: Yup.string().when('isRegistering', {
    is: true,
    then: Yup.string()
      .matches(/^[0-9A-Za-z]*$/, 'Invalid Waitlist Code. Try Again.')
      .min(7, 'Waitlist Code must be 7 alphanumeric characters.')
      .required('Required'),
  }),
})

// Validation schema used when waitlist is optional
const waitlistOptionalValidationSchema = Yup.object().shape({
  ...baseValidationSchema,
  waitlistCode: Yup.string().when('isRegistering', {
    is: true,
    then: Yup.string()
      .matches(/^[0-9A-Za-z]*$/, 'Invalid Waitlist Code. Try Again.')
      .min(7, 'Waitlist Code must be 7 alphanumeric characters.'),
  }),
})

// Validation schema used when waitlist is hidden
const waitlistHiddenValidationSchema = Yup.object().shape({
  ...baseValidationSchema,
})

const WAITLIST_MODES = {
  REQUIRED: 'REQUIRED',
  OPTIONAL: 'OPTIONAL',
  HIDE: 'HIDE',
}

// Retrieve the waitlist mode depending on what's in the bootstrap file
const getWaitlistMode = () => {
  const { allowInput = false, optional = false } = getBootstrapFeatureFlag(
    BOOTSTRAP_FEATURE_FLAGS.WAITLIST,
    {
      defaultValue: {
        allowInput: false,
        optional: false,
      },
    }
  )

  // Waitlist is either optional, mandatory, or hidden based on bootstrap flags
  if (allowInput && optional) {
    return WAITLIST_MODES.OPTIONAL
  } else if (allowInput && !optional) {
    return WAITLIST_MODES.REQUIRED
  } else {
    return WAITLIST_MODES.HIDE
  }
}

// Retrieve the validation schema based on the waitlist mode
const getValidationSchema = waitlistMode => {
  if (waitlistMode === WAITLIST_MODES.REQUIRED) {
    return waitlistRequiredValidationSchema
  } else if (waitlistMode === WAITLIST_MODES.OPTIONAL) {
    return waitlistOptionalValidationSchema
  } else if (waitlistMode === WAITLIST_MODES.HIDE) {
    return waitlistHiddenValidationSchema
  }
}

const getHeader = ({ showWaitlistCode, isForgotPassword, isResetPassword, isForcedUpdate }) => {
  let heading = 'Join the Greenwood Community'

  if (showWaitlistCode) {
    heading = 'Hi there, what is your email and waitlist code?'
  }

  if (isForgotPassword) {
    if (isForcedUpdate) {
      heading = 'Update Password'
    } else {
      heading = 'Hi there, forgot your password?'
    }
  } else if (isResetPassword) {
    heading = 'Ok, let’s reset your password'
  }
  return heading
}

const ProvideEmail = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const email = useSelector(state => state.unauthenticatedUser.email)
  const waitlistCode = useSelector(state => state.unauthenticatedUser.waitlistCode)
  const [focusedInputSourceDetail, setFocusedInputSourceDetail] = useState(null)
  const [registrationError, setRegistrationError] = useState()

  // These should only be calculated when the component mounts
  const [waitlistMode] = useState(getWaitlistMode())
  const [validationSchema] = useState(getValidationSchema(getWaitlistMode()))

  const isForgotPassword = location.state?.userFlow === ROUTE_USER_FLOW.FORGOT_PASSWORD
  const isResetPassword = location.state?.userFlow === ROUTE_USER_FLOW.RESET_PASSWORD

  const [isSignupOnMount] = useState(!(isForgotPassword || isResetPassword))

  const { url: coastalCommunityPrivacyUrl } = getBootstrapSitemapResource(
    BOOTSTRAP_SITEMAP_RESOURCES.COASTAL_COMMUNITY_PRIVACY_CCPA_NOTICE
  )

  // Track the page visit during registration
  useEffect(() => {
    if (isSignupOnMount) {
      // First, reset analytics since we can't assume this is the same user as a previous session
      reset()

      trackPage({ name: SEGMENT_PAGE_NAMES.REGISTRATION_EMAIL })
    }
  }, [isSignupOnMount])

  useEffect(() => {
    if (isSignupOnMount) {
      // When a user visits the sign up page, track the event
      trackEvent({ event: SEGMENT_EVENTS.CUSTOMER_ACCOUNT_STARTED })
    }
  }, [isSignupOnMount])

  const locationEmailValue = location.state?.email || ''
  const locationWaitlistCodeValue = location.state?.waitlistCode || ''
  const showWaitlistCode = waitlistMode !== WAITLIST_MODES.HIDE

  const handleSubmit = async (values, actions) => {
    dispatch(setLaunchDarklyCustomerAction({ email: values.email }))
    /* If there is still a focused input, make sure to track the event since onBlur is not executed
       when the form submits */
    if (focusedInputSourceDetail) {
      trackEvent({
        event: SEGMENT_EVENTS.registrationFormFieldEntry({
          sourceDetail: focusedInputSourceDetail,
        }),
      })

      setFocusedInputSourceDetail(null)
    }

    if (isForgotPassword || isResetPassword) {
      try {
        const { data } = await resetPassword({ username: values.email })

        await dispatch(setEmailAction({ email: values.email }))

        const { pathname: navigatePath, state: navigateState } = getRedirectLocationByChallengeTypeAndUserFlow({
          userFlow: location.state?.userFlow,
          displayEmail: values.email,
          ...data,
        })

        navigate(navigatePath, { state: navigateState })
      } catch {
        actions.setSubmitting(false)
      }
    } else {
      // Track submitted email
      if (values.email) {
        trackEvent({
          event: SEGMENT_EVENTS.registrationEmailSubmitted({
            email: values.email,
          }),
        })
        trackTvPixelEvent()
      }
      setRegistrationError()

      await dispatch(setEmailAction({ email: values.email, waitlistCode: values.waitlistCode }))
      navigate(staticRoutes.signUpPassword.pathname, { state: { displayEmail: values.email } })
    }
  }

  const emailAutoComplete =
    location.pathname === staticRoutes.signUpEmail.pathname ? 'off' : 'email'

  const isRegistering = !isForgotPassword && !isResetPassword

  const initialValues = {
    email: email || locationEmailValue || '',
    isRegistering,
  }

  // Only set the default waitlist code if it is visible
  if (showWaitlistCode) {
    initialValues.waitlistCode = waitlistCode || locationWaitlistCodeValue || ''
  }

  const handleEmailFocus = () => {
    if (isRegistering) {
      setFocusedInputSourceDetail(SEGMENT_SOURCE_DETAILS.REGISTRATION_EMAIL)
    }
  }

  const handleEmailBlur = () => {
    if (isRegistering) {
      // Track any time the user leaves the email field during registration
      trackEvent({
        event: SEGMENT_EVENTS.registrationFormFieldEntry({
          sourceDetail: SEGMENT_SOURCE_DETAILS.REGISTRATION_EMAIL,
        }),
      })

      setFocusedInputSourceDetail(null)
    }
  }

  const handleWaitlistCodeFocus = () => {
    if (isRegistering) {
      setFocusedInputSourceDetail(SEGMENT_SOURCE_DETAILS.WAITLIST_CODE)
    }
  }

  const handleWaitlistCodeBlur = () => {
    if (isRegistering) {
      // Track any time the user leaves the waitlist code field during registration
      trackEvent({
        event: SEGMENT_EVENTS.registrationFormFieldEntry({
          sourceDetail: SEGMENT_SOURCE_DETAILS.WAITLIST_CODE,
        }),
      })

      setFocusedInputSourceDetail(null)
    }
  }

  // Track email prepoplulated event
  useEffect(() => {
    if (isSignupOnMount) {
      if (locationEmailValue) {
        trackEvent({
          event: SEGMENT_EVENTS.registrationEmailPrepopulated({
            email: locationEmailValue,
          }),
        })
      }
    }
  }, [isSignupOnMount, locationEmailValue])

  const buttonText = isRegistering ? 'Get Access' : 'Next'

  const isMobile = useMediaQuery({ query: `(max-width: ${MAX_WIDTH_BREAKPOINTS.MEDIUM}px)` })

  const emailContainerClasses = classNames('white-card-container', {
    [styling['is-mobile']]: isMobile,
  })

  const showSideBar = location.pathname === staticRoutes.signUpEmail.pathname
  const header = useMemo(() => (
    getHeader({
      showWaitlistCode,
      isForgotPassword,
      isResetPassword,
      isForcedUpdate: location?.state?.isForcedUpdate,
    })
  ), [
    location?.state?.isForcedUpdate,
    showWaitlistCode,
    isForgotPassword,
    isResetPassword,
  ])

  return (
    <>
      <div className={emailContainerClasses}>
        <Link to={staticRoutes.dashboard.pathname}>
          <GreenwoodLogo className='logo' />
        </Link>
        <h1>{header}</h1>
        {isRegistering && <p>Open a Greenwood account to access exclusive benefits.</p>}
        <div className='create-account-content-wrapper'>
          <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {({ errors, isSubmitting, touched, handleBlur }) => (
              <Form data-cy='email-form'>
                <Field
                  as={FormInput}
                  name='email'
                  type='email'
                  label='Email Address'
                  autoComplete={emailAutoComplete}
                  invalid={errors.email && touched.email}
                  placeholder='email@address.com'
                  errorText={errors.email}
                  disabled={isSubmitting}
                  autoCapitalize='off'
                  autoFocus
                  onFocus={handleEmailFocus}
                  onBlur={e => {
                    handleBlur(e)
                    handleEmailBlur()
                  }}
                />
                {isRegistering && showWaitlistCode && (
                  <Field
                    as={FormInput}
                    name='waitlistCode'
                    type='text'
                    label='Waitlist Code'
                    autoComplete='off'
                    invalid={errors.waitlistCode && touched.waitlistCode}
                    placeholder='Enter Code'
                    errorText={errors.waitlistCode}
                    disabled={isSubmitting}
                    maxLength={7}
                    autoCapitalize='off'
                    data-cy='email-waitlist-code'
                    onFocus={handleWaitlistCodeFocus}
                    onBlur={e => {
                      handleBlur(e)
                      handleWaitlistCodeBlur()
                    }}
                  />
                )}
                <Button type='submit' isLoading={isSubmitting}>
                  {buttonText}
                </Button>
                {isResetPassword && (
                  <Button
                    className='additional-button'
                    type='button'
                    ghost
                    onClick={() => navigate(staticRoutes.settingsPrivacySecurity.pathname)}
                  >
                    Cancel
                  </Button>
                )}
                {/* Only show terms/policy links on sign-up */}
                {isRegistering && (
                  <>
                    <p className='help-text'>
                      Already have an account?{' '}
                      <Link className='underlined-link bold' to={staticRoutes.signIn.pathname}>
                        Sign In
                      </Link>
                      .
                    </p>
                    <p className='help-text'>
                      {`By tapping ${buttonText}, I agree that I have read, understand and consent to Greenwood's `}
                      <ExternalLink
                        to='https://gogreenwood.com/privacy-policy/'
                        className='underlined-link bold'
                      >
                        Privacy Policy
                      </ExternalLink>
                      {' & '}
                      <ExternalLink
                        to='https://gogreenwood.com/terms-and-conditions/'
                        className='underlined-link bold'
                      >
                        Terms of Use
                      </ExternalLink>{' '}
                      and the Coastal Community Bank{' '}
                      <ExternalLink
                        to={coastalCommunityPrivacyUrl}
                        className='underlined-link bold'
                      >
                        Privacy Policy
                      </ExternalLink>
                      .
                    </p>
                  </>
                )}
                {registrationError && <p className='error'>{registrationError}</p>}
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {showSideBar && <SignUpSideBar />}
    </>
  )
}

ProvideEmail.propTypes = {
  email: PropTypes.string,
  waitlistCode: PropTypes.string,
}

export default ProvideEmail
