import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { ReactComponent as LeftSmallSquiggle } from '@common/images/illustrations/illustration-squiggle-1.svg'
import { ReactComponent as RightSmallSquiggle } from '@common/images/illustrations/illustration-squiggle-2.svg'
import { ReactComponent as LargeSquiggle } from '@common/images/illustrations/illustration-squiggle-3.svg'

import styling from './banner.module.scss'

const Banner = ({ text, children, showConfetti = false, className }) => {
  return (
    <div className={classNames(styling.banner, className)}>
      <div className={styling['banner-contents']}>
        {showConfetti && (
          <div className={styling['confetti-container']}>
            <div className={styling['left-squiggles']}>
              <LeftSmallSquiggle className={styling['left-small-squiggle']} />
              <LargeSquiggle className={styling['left-large-squiggle']} />
            </div>
            <div className={styling['right-squiggles']}>
              <LargeSquiggle className={styling['right-large-squiggle']} />
              <RightSmallSquiggle className={styling['right-small-squiggle']} />
            </div>
          </div>
        )}
        <div
          className={classNames(styling['banner-container'], {
            [styling['with-confetti']]: showConfetti,
          })}
        >
          <div className={styling['banner-strip']}>
            {text && <div className={styling['banner-strip-text']}>{text}</div>}
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

Banner.propTypes = {
  /**
   * Styled text to be displayed within the ribbon
   */
  text: PropTypes.string,

  /**
   * Any renderable React node to be displayed within the ribbon
   */
  children: PropTypes.node,

  /**
   * Shows confetti squiggles around the banner
   */
  showConfetti: PropTypes.bool,

  /**
   * Additional styles to be applied to the component's container div which are compatible with the
   * `classnames` library
   */
  className: PropTypes.any,
}

export default Banner
