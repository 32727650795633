import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Button from '@shared/components/button/Button'
import Modal from '@shared/components/modal/Modal'
import ModalHeader from '@shared/components/modal/ModalHeader'
import ModalBody from '@shared/components/modal/ModalBody'
import ExternalLink from '../link/ExternalLink'

import { getBootstrapValueByKey, BOOTSTRAP_KEYS } from '@common/utils'

const VerificationFailedModal = ({
  isOpen,
  toggleModal,
  closeModal,
  isPhoneVerification,
  isUpdating,
}) => {
  const contactOptions = getBootstrapValueByKey(BOOTSTRAP_KEYS.SUPPORT)?.contact
  const phoneContact = contactOptions?.find(option => option.method === 'phone')
  const emailContact = contactOptions?.find(option => option.method === 'email')

  const getPhoneLink = () => (
    <a className='bold' href={`tel:${phoneContact.value}`}>
      {phoneContact.value}
    </a>
  )

  const getEmailLink = () => (
    <ExternalLink className='underlined-link bold' to={`mailto:${emailContact?.value}`}>
      {emailContact?.value}
    </ExternalLink>
  )

  return (
    <Modal
      centered
      size='md'
      open={isOpen}
      modalClassName={classNames({ 'centered-content-modal': isPhoneVerification })}
      toggleModal={toggleModal}
    >
      <ModalHeader
        closeModal={closeModal}
        className='centered-content-modal-header'
        withCloseButton={isUpdating}
      >
        <h4>{isPhoneVerification ? 'Phone Number ' : null}Verification Failed</h4>
      </ModalHeader>
      <ModalBody>
        <div>
          {isPhoneVerification && (
            <>
              <p className='centered-content-modal-text'>
                To get help setting up your account, please call {getPhoneLink()}.
              </p>
              <Button className='additional-button' onClick={closeModal}>
                Dismiss
              </Button>
            </>
          )}
          {isUpdating && (
            <p className='centered-content-modal-text'>
              To get help updating your information, please contact support at {getPhoneLink()} or{' '}
              {getEmailLink()}.
            </p>
          )}
        </div>
      </ModalBody>
    </Modal>
  )
}

VerificationFailedModal.propTypes = {
  /**
   * Boolean value for whether or not the modal is open
   */
  isOpen: PropTypes.bool.isRequired,
  /**
   * Function for toggling the modal
   */
  toggleModal: PropTypes.func.isRequired,
  /**
   * Function for closing the modal
   */
  closeModal: PropTypes.func.isRequired,
  /**
   * Boolean for whether or not this modal is for a phone verification failure
   */
  isPhoneVerification: PropTypes.bool,
  /**
   * Boolean for whether or not this modal is for an update verification failure
   */
  isUpdating: PropTypes.bool,
}

export default VerificationFailedModal
