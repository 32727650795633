import React, { useState } from 'react'
import classNames from 'classnames'

import FormInput, { FormInputPropTypes } from './formInput/FormInput'
import FormInputAddon from './formInput/FormInputAddon'

import { ReactComponent as Eye } from '@shared/images/icons/eye.svg'
import { ReactComponent as EyeClosed } from '@shared/images/icons/eye-closed.svg'

/**
 * Extends FormInput functionality, specifically for masked passwords
 */
const FormPasswordInput = ({ disabled, className, ...rest }) => {
  const [isMasked, setIsMasked] = useState(true)

  return (
    <FormInput
      className={classNames(className, 'password-input')}
      disabled={disabled}
      type={!isMasked && !disabled ? 'text' : 'password'}
      {...rest}
    >
      <FormInputAddon className={classNames({ clickable: !disabled })} type='trailing'>
        <div onClick={() => !disabled && setIsMasked(!isMasked)} className='icon-container'>
          {isMasked ? <Eye /> : <EyeClosed />}
        </div>
      </FormInputAddon>
    </FormInput>
  )
}

FormPasswordInput.propTypes = FormInputPropTypes

export default FormPasswordInput
