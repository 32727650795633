import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'
import { ReactComponent as SadFace } from '@common/images/icons/sad-face.svg'

import Button from '@shared/components/button/Button'

import styling from './flowError.module.scss'

const FlowError = ({
  header = 'Request Failed',
  message = "Uh oh, something went wrong. We aren't able to process the request at the moment.",
  className,
  onPrimaryButtonClick,
  primaryButtonContents = 'Try Again',
  primaryButtonProps,
  onSecondaryButtonClick,
  secondaryButtonContents = 'Done',
  secondaryButtonProps,
  ...rest
}) => {
  return (
    <div className={classNames('white-card-container', styling['flow-error'], className)} {...rest}>
      <GreenwoodLogo className='logo' />
      <h1>
        <SadFace className={styling['error-icon']} />
        {header}
      </h1>
      {message && <p>{message}</p>}
      <Button onClick={onPrimaryButtonClick} className='additional-button' {...primaryButtonProps}>
        {primaryButtonContents}
      </Button>
      <Button
        className='additional-button'
        ghost
        onClick={onSecondaryButtonClick}
        {...secondaryButtonProps}
      >
        {secondaryButtonContents}
      </Button>
    </div>
  )
}

FlowError.propTypes = {
  /**
   * The header
   */
  header: PropTypes.node,

  /**
   * The message
   */
  message: PropTypes.node,

  /**
   * Additional `classname` library compatible classes to apply to the container
   */
  className: PropTypes.any,

  /**
   * Callback to execute when the primary button is clicked
   */
  onPrimaryButtonClick: PropTypes.func.isRequired,

  /**
   * The contents to display in the primary button
   */
  primaryButtonContents: PropTypes.node,

  /**
   * Any additional props to apply to the primary button
   */
  primaryButtonProps: PropTypes.object,

  /**
   * Callback to execute when the secondary button is clicked
   */
  onSecondaryButtonClick: PropTypes.func.isRequired,

  /**
   * The contents to display in the secondary button
   */
  secondaryButtonContents: PropTypes.node,

  /**
   * Any additional props to apply to the secondary button
   */
  secondaryButtonProps: PropTypes.object,
}

export default FlowError
