import React from 'react'
import PropTypes from 'prop-types'
import ExternalLink from '@common/components/link/ExternalLink'
import classNames from 'classnames'

import { SEGMENT_EVENTS, trackEvent } from '@common/utils'

import styling from './agreementsList.module.scss'

const AgreementsList = ({ agreements = [], onDark = false }) => {
  const handleAnalyticTracking = agreementTitle => {
    trackEvent({
      event: SEGMENT_EVENTS.specificAgreementButtonClicked({
        sourceDetail: agreementTitle,
      }),
    })
  }

  return (
    <ul
      className={classNames(styling.agreements, { [styling['on-dark']]: onDark })}
      data-cy='agreements'
    >
      {agreements.map(agreement => (
        <li key={agreement.id}>
          <p>
            <ExternalLink
              className='underlined-link bold'
              to={agreement.url}
              clickListener={() => handleAnalyticTracking(agreement.title)}
              data-cy='agreement-link'
            >
              {agreement.title}
            </ExternalLink>
          </p>
        </li>
      ))}
    </ul>
  )
}

AgreementsList.propTypes = {
  /**
   * Agreements data for display
   */
  agreements: PropTypes.array,
  /**
   * Whether or not this list is being rendered on a dark background
   */
  onDark: PropTypes.bool,
}

export default AgreementsList
