import React, { useEffect } from 'react'
import { Outlet, Navigate, Route, Routes, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'

import ProvideEmail from '@common/components/ProvideEmail'
import ProvidePassword from '@common/components/providePassword/ProvidePassword'
import ProtectedRoute from '@routing/ProtectedRoute'

import { staticRoutes } from '@routing/routes'
import { setOnboardingFlowTypeAction } from '@redux/application/applicationActions'
import {
  ONBOARDING_FLOW_TYPES,
  ONBOARDING_FLOW_TYPE_BY_ROUTE,
  SIGN_UP_ROUTES,
} from '@common/constants'
import { ROUTE_USER_FLOW } from '@shared/constants/uiConstants'

/**
 * Unauthenticated sign-up workflow
 */

const SignUp = () => {
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const onboardingType = useSelector(state => state.onboardingType)

  useEffect(() => {
    if (SIGN_UP_ROUTES.includes(pathname) && pathname !== staticRoutes.signUp.pathname) {
      const typeKey = Object.keys(ONBOARDING_FLOW_TYPE_BY_ROUTE).find(key => pathname.includes(key))
      const flowType = ONBOARDING_FLOW_TYPE_BY_ROUTE[typeKey] || ONBOARDING_FLOW_TYPES.ELEVATE

      if (onboardingType !== flowType) {
        // Save the onboardingFlowType in state
        dispatch(setOnboardingFlowTypeAction(flowType))
      }
    }
  }, [pathname, dispatch, onboardingType])

  return (
    <div
      className={classNames('create-account-wrapper', {
        'with-extension': pathname === staticRoutes.signUpEmail.pathname,
      })}
    >
      <Routes>
        <Route
          path={staticRoutes.signUpEmail.relativePathname}
          element={<ProtectedRoute component={ProvideEmail} />}
        />
        <Route
          path={staticRoutes.signUpPassword.relativePathname}
          element={<ProtectedRoute component={ProvidePassword}/>}
        />
        <Route
          path='*'
          element={
            <Navigate
              to={staticRoutes.signUpEmail.pathname}
              state={{ userFlow: ROUTE_USER_FLOW.SIGNUP }}
            />
          }
        />
      </Routes>
      <Outlet />
    </div>
  )
}

export default SignUp
