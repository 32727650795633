import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { Formik, Form, Field } from 'formik'
import { useDispatch } from 'react-redux'

import ReduxField from '@common/components/reduxField/ReduxField'
import FormInput from '@shared/components/formInput/FormInput'
import FormSelect from '@shared/components/formSelect/FormSelect'
import Button from '@shared/components/button/Button'

import { SEGMENT_EVENTS, SEGMENT_SOURCE_DETAILS, trackEvent } from '@common/utils'
import {
  US_STATES,
  ADDRESS_BUILDING_NUMBER_MAX_LENGTH,
  MANUAL_ADDRESS_VALIDATION_SCHEMA,
} from '@common/constants'
import { setCustomerAction } from '@redux/customer/customerActions'
import { removeAlertsAction } from '@redux/alerts/alertsActions'

import styling from './manualAddressEntryForm.module.scss'

const manualAddressValidationSchema = Yup.object().shape({
  // Generic manual address validation schema (for: deliveryAddress, city, state, zipCode)
  ...MANUAL_ADDRESS_VALIDATION_SCHEMA,
})

const ManualAddressEntryForm = ({
  selectedAddress,
  searchAddress,
  selectedCity,
  setConfirmAddress,
  hideManualEntry,
  submitAddress,
  isResubmitting = false,
}) => {
  const hasSelectedAddress = !!selectedAddress?.deliveryAddress
  const dispatch = useDispatch()

  const [focusedInputSourceDetail, setFocusedInputSourceDetail] = useState(null)

  const trackPendingEvent = () => {
    /* If there is still a focused input, make sure to track the event since onBlur is not executed
       when the form submits */
    if (focusedInputSourceDetail) {
      trackEvent({
        event: SEGMENT_EVENTS.registrationFormFieldEntry({
          sourceDetail: focusedInputSourceDetail,
        }),
      })

      setFocusedInputSourceDetail(null)
    }
  }

  const handleManualSubmit = (values, actions) => {
    dispatch(removeAlertsAction())
    trackPendingEvent()

    const address = {
      deliveryAddress: values.deliveryAddress,
      buildingNumber: values.aptUnitFloor,
      locality: values.locality,
      subdivisions: [values.state],
      postalCode: values.zipCode,
    }

    if (isResubmitting) {
      // Save the customer address info
      dispatch(setCustomerAction(address))
    }
    submitAddress({ actions, address })
  }

  return (
    <>
      <h1 data-cy='manual-address-header'>Manually enter your home address</h1>
      <p>We need to verify your home address. This is also where we’ll send your new card.</p>

      <Formik
        validationSchema={manualAddressValidationSchema}
        initialValues={{
          deliveryAddress: selectedAddress?.deliveryAddress || searchAddress || '',
          aptUnitFloor: selectedAddress?.aptUnitFloor || '',
          locality: selectedCity || '',
          state: selectedAddress?.state || '',
          zipCode: selectedAddress?.zipCode || '',
        }}
        enableReinitialize={isResubmitting}
        onSubmit={handleManualSubmit}
      >
        {({ errors, isSubmitting, touched }) => (
          <Form data-cy='manual-address-form'>
            <ReduxField
              as={FormInput}
              name='deliveryAddress'
              label='Street Address'
              invalid={errors.deliveryAddress && touched.deliveryAddress}
              errorText={errors.deliveryAddress}
              disabled={isSubmitting}
              required
              placeholder='(No P.O. Box or Business Address)'
              showRequiredAsterisk={false}
              onFocus={() => {
                setFocusedInputSourceDetail(SEGMENT_SOURCE_DETAILS.ADDRESS_LINE_1)
              }}
              onBlur={() => {
                // Track any time the user leaves the Address Line 1 field during registration
                trackEvent({
                  event: SEGMENT_EVENTS.registrationFormFieldEntry({
                    sourceDetail: SEGMENT_SOURCE_DETAILS.ADDRESS_LINE_1,
                  }),
                })

                setFocusedInputSourceDetail(null)
              }}
            />
            <Field
              as={FormInput}
              name='aptUnitFloor'
              label='Apt#, Suite#, Unit#'
              placeholder='Optional'
              disabled={isSubmitting}
              maxLength={ADDRESS_BUILDING_NUMBER_MAX_LENGTH}
              onFocus={() => {
                setFocusedInputSourceDetail(SEGMENT_SOURCE_DETAILS.ADDRESS_LINE_2)
              }}
              onBlur={() => {
                // Track any time the user leaves the Address Line 1 field during registration
                trackEvent({
                  event: SEGMENT_EVENTS.registrationFormFieldEntry({
                    sourceDetail: SEGMENT_SOURCE_DETAILS.ADDRESS_LINE_2,
                  }),
                })

                setFocusedInputSourceDetail(null)
              }}
            />
            <ReduxField
              as={FormInput}
              name='locality'
              label='City'
              invalid={errors.locality && touched.locality}
              errorText={errors.locality}
              disabled={isSubmitting}
              required
              showRequiredAsterisk={false}
              maxLength={30}
              onFocus={() => {
                setFocusedInputSourceDetail(SEGMENT_SOURCE_DETAILS.CITY)
              }}
              onBlur={() => {
                // Track any time the user leaves the City field during registration
                trackEvent({
                  event: SEGMENT_EVENTS.registrationFormFieldEntry({
                    sourceDetail: SEGMENT_SOURCE_DETAILS.CITY,
                  }),
                })

                setFocusedInputSourceDetail(null)
              }}
            />
            <div className={styling['state-postal-code']}>
              <Field
                as={FormSelect}
                name='state'
                label='State'
                invalid={errors.state && touched.state}
                errorText={errors.state}
                disabled={isSubmitting}
                required
                showRequiredAsterisk={false}
                onFocus={() => {
                  setFocusedInputSourceDetail(SEGMENT_SOURCE_DETAILS.STATE)
                }}
                onBlur={() => {
                  // Track any time the user leaves the State field during registration
                  trackEvent({
                    event: SEGMENT_EVENTS.registrationFormFieldEntry({
                      sourceDetail: SEGMENT_SOURCE_DETAILS.STATE,
                    }),
                  })

                  setFocusedInputSourceDetail(null)
                }}
              >
                <option value=''></option>
                {Object.entries(US_STATES).map(option => (
                  <option value={option[0]} key={option[0]}>
                    {option[1]}
                  </option>
                ))}
              </Field>
              <Field
                as={FormInput}
                name='zipCode'
                label='Zip Code'
                invalid={errors.zipCode && touched.zipCode}
                errorText={errors.zipCode}
                disabled={isSubmitting}
                required
                showRequiredAsterisk={false}
                maxLength='5'
                onFocus={() => {
                  setFocusedInputSourceDetail(SEGMENT_SOURCE_DETAILS.ZIP)
                }}
                onBlur={() => {
                  // Track any time the user leaves the Zip Code field during registration
                  trackEvent({
                    event: SEGMENT_EVENTS.registrationFormFieldEntry({
                      sourceDetail: SEGMENT_SOURCE_DETAILS.ZIP,
                    }),
                  })

                  setFocusedInputSourceDetail(null)
                }}
              />
            </div>
            <Button
              type='submit'
              isLoading={isSubmitting}
              data-cy='create-account-address-manual-confirm-button'
              disabled={isSubmitting}
            >
              Confirm
            </Button>
          </Form>
        )}
      </Formik>

      {!isResubmitting && (
        <Button
          className='address-back-button'
          ghost
          onClick={() => {
            setConfirmAddress(hasSelectedAddress)
            hideManualEntry()
          }}
          data-cy='create-account-address-manual-back-button'
        >
          Back
        </Button>
      )}
    </>
  )
}

ManualAddressEntryForm.propTypes = {
  selectedAddress: PropTypes.any,
  searchAddress: PropTypes.string,
  selectedCity: PropTypes.string,
  setConfirmAddress: PropTypes.func,
  hideManualEntry: PropTypes.func,
  submitAddress: PropTypes.func,
  isResubmitting: PropTypes.bool,
}

export default ManualAddressEntryForm
