import { useCallback, useEffect, useState } from 'react'
import { get } from 'lodash'
import { useLocation, useNavigate } from 'react-router-dom'

import { useLazyQuery } from '@services/serviceUtils'

const usePaginatedQuery = ({
  /**
   * The query to execute
   */
  query,

  /**
   * Any additional variables required to execute the query. Do not pass `page` and `txnsPerPage`
   * variables here; they will be overwritten by this hook.
   */
  queryVariables = {},

  /**
   * Any additional query configurations (e.g. caching policy)
   */
  queryConfig = {},

  /**
   * When true, the query will be executed immediately. When false, the retuned `fetchData`
   * function will need to be called to execute the query. Default is true
   */
  fetchImmediately = true,

  /**
   * The page size. Default is 20
   */
  pageSize = 20,

  /**
   * The initial page to fetch. Default is 1
   */
  initialPage = 1,

  /**
   * The path to the entity array property within the query `data` results
   */
  dataEntitiesPath,

  /**
   * The path to the total number of entities property within the query `data` results
   */
  dataTotalEntitiesPath,
}) => {
  const [data, setData] = useState({
    entities: [],
    totalCount: 0,
  })

  const location = useLocation()
  const navigate = useNavigate()

  const state = location?.state

  const lastPageViewed = state?.lastPageViewed

  /* Prefer the initial page setting from the router, used for page refresh and back/forward
     navigation */
  const [currentPage, setCurrentPage] = useState(lastPageViewed || initialPage)
  const [initialPageLoading, setInitialPageLoading] = useState(fetchImmediately)
  const [initialized, setInitialized] = useState(false)

  const [fetchData, { data: fetchedData, loading: pageLoading, error }] = useLazyQuery(query, {
    ...queryConfig,
    variables: {
      ...queryVariables,
      page: currentPage,
      txnsPerPage: pageSize,
    },
  })

  useEffect(() => {
    const entities = get(fetchedData, dataEntitiesPath, [])
    const totalCount = get(fetchedData, dataTotalEntitiesPath, 0)

    if (fetchedData) {
      setData({
        entities,
        totalCount,
      })
    }

    setInitialPageLoading(false)
    setInitialized(true)
  }, [fetchedData, dataEntitiesPath, dataTotalEntitiesPath])

  // Fetch the data immediately if it has not already been initialized
  useEffect(() => {
    if (fetchImmediately && !initialized) {
      fetchData()
    }
  }, [fetchImmediately, fetchData, initialized])

  const handleFetchData = useCallback(
    fetchParams => {
      if (!initialized) {
        setInitialPageLoading(true)
      }

      fetchData(fetchParams)
    },
    [fetchData, initialized]
  )

  const handlePageClick = useCallback(
    page => {
      setCurrentPage(page)
      navigate(location?.pathname, { state: { ...state, lastPageViewed: page }, replace: true })
    },
    [setCurrentPage, location, state, navigate]
  )

  return {
    /**
     * Execute this function when data should be fetched (to be used when the `fetchImmediately`
     * prop is `false`)
     */
    fetchData: handleFetchData,

    /**
     * Loading indicator for the initial fetch
     */
    initialPageLoading,

    /**
     * Loading indicator for subsequent page fetches
     */
    pageLoading,

    /**
     * The `error` object retuned by the query execution, if any
     */
    error,

    /**
     * The list of entities fetched
     */
    entities: data.entities,

    /**
     * The `totalCount` of entities available
     * Will be `null` until at least the initial fetch is complete
     */
    totalCount: initialized ? data.totalCount : null,

    /**
     * The pagination data required by the table pagination component
     */
    pagination: {
      currentPage,
      totalRecords: data.totalCount,
      pageSize,
    },

    /**
     * Handler to pass to the Table onPageClick prop
     */
    handlePageClick,
  }
}

export default usePaginatedQuery
