import React, { useState, useEffect, useMemo } from 'react'
import classNames from 'classnames'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useCookies } from 'react-cookie'

import { ReactComponent as Chevron } from '@shared/images/icons/chevron.svg'
import AccountsCardSlider from '@common/components/accountsCardSlider/AccountsCardSlider'
import AppLinks from '@common/components/callout/AppLinks'
import Hero from '@common/components/hero/Hero'
import LoadingContainer from '@shared/components/loadingContainer/LoadingContainer'
import Modal from '@shared/components/modal/Modal'
import ModalBody from '@shared/components/modal/ModalBody'
import ModalHeader from '@shared/components/modal/ModalHeader'
import TransactionDetailModal from '@common/components/transactions/TransactionDetailModal'
import Table from '@shared/components/table/Table'
import ApplicationStatusDisplay from '@common/components/applicationStatusDisplay/ApplicationStatusDisplay'
import DashboardCtas from './DashboardCtas'
import InvestStockCampaignModal from '@common/components/investStockCampaignModal/InvestStockCampaignModal'

import { transactionTableColumns } from '@common/utils/transactionTableUtils'
import { gql, useQuery } from '@services/serviceUtils'
import {
  setApplicationAction,
  resetLocationState,
  setOnboardingFlowTypeAction,
} from '@redux/application/applicationActions'
import { setCustomerAction } from '@redux/customer/customerActions'
import { resetTransferDetailsAction } from '@redux/transferDetails/transferDetailsActions'
import { resetDebitCardDepositDetailsAction } from '@redux/debitCardDepositDetails/debitCardDepositDetailsActions'
import { addAlertAction, removeAlertsAction } from '@redux/alerts/alertsActions'
import { staticRoutes } from '@routing/routes'
import { obscureAccountNumberShort, toMediumDateFormat } from '@shared/utils'
import { hasAccountType, showCta } from '@common/utils/accountUtils'
import {
  MAX_WIDTH_BREAKPOINTS,
  APPLICATION_STATUSES,
  ALERT_TYPES,
} from '@shared/constants/uiConstants'
import { CTA_TYPES, PRODUCT_TYPES, INVEST_PROMOTIONS } from '@common/constants'
import {
  SEGMENT_PAGE_NAMES,
  trackEvent,
  trackPage,
  SEGMENT_EVENTS,
  SEGMENT_SOURCE_DETAILS,
} from '@common/utils'

import styling from './dashboard.module.scss'

const getAccountById = (accounts, id) => accounts?.find(account => account.id === id)

const dashboardDataQuery = gql`
  query Dashboard {
    application {
      userId
      submittedAt
      customerApplicationStatus
      onboardingFlowType
      referenceId
    }
    accounts {
      totalBalance {
        amount
      }
      accounts {
        id
        balance {
          amount
        }
        accountType
        title
        accountNumber
        transactions {
          amount {
            amount
          }
          description
          details {
            label
            value
          }
          transactionTime
          transactionType
          isSettled
        }
      }
    }
    ctas {
      callToActionType
      priority
    }
  }
`

const subscriptionsQuery = gql`
  query Subscriptions {
    subscriptions {
      id
    }
  }
`

const investingAccountsQuery = gql`
  query InvestingAccounts {
    investingAccounts {
      investingPromotions {
        promotionCode
      }
      userEligible
    }
  }
`

const investingPromotionsQuery = gql`
  query InvestingPromotions {
    investingPromotions {
      promotionCode
    }
  }
`

const AccountDetailsLink = ({ accountId, mobile = false, ...rest }) => {
  return (
    <Link
      onClick={() => {
        trackEvent({
          event: SEGMENT_EVENTS.DASHBOARD_BUTTON_CLICK(SEGMENT_SOURCE_DETAILS.VIEW_ACCOUNT_DETAILS),
        })
      }}
      to={staticRoutes.myAccount.pathname}
      state={{ id: accountId }}
      className={classNames(styling['account-detail-link'], { [styling.mobile]: mobile })}
      {...rest}
    >
      <span>View Account Details</span> <Chevron />
    </Link>
  )
}

const Dashboard = () => {
  const [selectedAccount, setSelectedAccount] = useState(null)
  const [showDownloadAppModal, toggleShowDownloadAppModal] = useState(false)
  const [showInvestStockCampaignModal, toggleShowInvestStockCampaignModal] = useState(false)
  const [detailModalData, setDetailModalData] = useState({ transaction: {}, isOpen: false })
  const [cookies, setCookie] = useCookies(['hasSeenDownloadAppModal', 'hasSeenInvestPromoModal'])

  const cookieOptions = useMemo(() => (
    {
      path: '/',
      secure: true,
    }
  ), [])

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const customer = useSelector(state => state.customer)
  const pollingHasMaxedOut = useSelector(state => state?.application?.pollingHasMaxedOut)
  const showMfaComingSoonBanner = useSelector(state => state.auth?.showMfaComingSoonBanner)

  const getInvestPromoCodes = arr => (
    arr?.map(promo => promo?.promotionCode)
  )

  // Don't cache, the account and CTA data must be up-to-date on each visit of the dashboard
  const { loading, error, data } = useQuery(dashboardDataQuery, { fetchPolicy: 'no-cache' })
  const { data: subscriptionsData } = useQuery(subscriptionsQuery, {
    fetchPolicy: 'no-cache',
    onError: () => dispatch(removeAlertsAction()),
  })
  const { data: investingAccountsData } = useQuery(investingAccountsQuery, {
    fetchPolicy: 'no-cache',
    onError: () => dispatch(removeAlertsAction()),
  })

  const { data: investingPromotionsData } = useQuery(investingPromotionsQuery, {
    fetchPolicy: 'no-cache',
    onError: () => dispatch(removeAlertsAction()),
  })

  const hasPremium = useMemo(() => customer?.hasPremium, [customer?.hasPremium])
  const hasElevate = useMemo(() => customer?.hasElevate, [customer?.hasElevate])
  const isStandardAccount = useMemo(() => (
    data?.application?.customerApplicationStatus === APPLICATION_STATUSES.PASS && !hasPremium && !hasElevate
  ), [hasPremium, hasElevate, data?.application?.customerApplicationStatus])

  const activePromotions = useMemo(
    () => getInvestPromoCodes(investingPromotionsData?.investingPromotions),
    [investingPromotionsData?.investingPromotions]
  )

  const showInvestPromoCallout = useMemo(() => {
    const userInvestingAccount = investingAccountsData?.investingAccounts[0]

    if (userInvestingAccount?.userEligible) {
      const investingPromotions = getInvestPromoCodes(userInvestingAccount?.investingPromotions)
      return investingPromotions?.includes(INVEST_PROMOTIONS.INVEST_STOCK_PROMO)
    }

    return false
  }, [investingAccountsData?.investingAccounts])

  const currentDate = new Date()

  const showSelectDdaTypeCta = useMemo(() => (
    showCta({
      ctas: data?.ctas,
      ctaType: CTA_TYPES.SELECT_DDA_TYPE,
    })
  ), [data?.ctas])

  /* Track visits to dashboard when account is approved (other statuses are captured in
     ApplicationStatusDisplay.js) */
  useEffect(() => {
    if (
      data?.application?.customerApplicationStatus &&
      data?.application?.customerApplicationStatus === APPLICATION_STATUSES.PASS
    ) {
      trackPage({ name: SEGMENT_PAGE_NAMES.DASHBOARD_HOME })
    }
  }, [data])

  useEffect(() => {
    setSelectedAccount(data?.accounts?.accounts?.[0]?.id || null)

    if (data?.application) {
      // Add application data to redux for use in other components, such as the header
      dispatch(setApplicationAction(data.application))

      if (data?.application?.onboardingFlowType) {
        // Save the user's onboardingFlowType in state
        dispatch(setOnboardingFlowTypeAction(data.application.onboardingFlowType))
      }

      const applicationPassed =
        data.application.customerApplicationStatus === APPLICATION_STATUSES.PASS

      /* When the CTAs load show either the DDA selection page, the premium upgrade CTA
           modal once, or the download mobile app modal once */
      if (data.ctas.length) {
        if (showSelectDdaTypeCta && applicationPassed) {
          navigate(staticRoutes.selectAccountTier.pathname, { replace: true })
        } else if (
          applicationPassed &&
          cookies?.hasSeenDownloadAppModal !== data.application.userId &&
          (!activePromotions?.length ||
            cookies?.hasSeenInvestPromoModal === data.application.userId ||
            !showInvestPromoCallout)
        ) {
          toggleShowDownloadAppModal(true)
          setCookie('hasSeenDownloadAppModal', data.application.userId, cookieOptions)
        }
      }
    }

    // Add account data to redux for use in other components, such as the header
    if (data?.accounts?.accounts?.length) {
      const accounts = data.accounts.accounts
      const isPremiumAccount =
        hasAccountType({ accounts, accountType: PRODUCT_TYPES.premium }) ||
        hasAccountType({ accounts, accountType: PRODUCT_TYPES.premiumPlus })
      const isElevateAccount = hasAccountType({ accounts, accountType: PRODUCT_TYPES.elevateCard })

      dispatch(
        setCustomerAction({
          hasPremium: isPremiumAccount,
          hasElevate: isElevateAccount,
          hasPrimaryAccount: true,
        })
      )
    } else {
      dispatch(setCustomerAction({ hasPrimaryAccount: false }))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    const hasInvestCampaignEligibility = typeof customer?.hasElevate === 'boolean' && isStandardAccount
      ? activePromotions?.includes(INVEST_PROMOTIONS.INVEST_STOCK_PROMO)
      : showInvestPromoCallout

    if (
      data?.application?.userId &&
      hasInvestCampaignEligibility &&
      cookies?.hasSeenInvestPromoModal !== data?.application?.userId
    ) {
      toggleShowInvestStockCampaignModal(true)
      setCookie('hasSeenInvestPromoModal', data.application.userId, cookieOptions)
    }
  }, [
    isStandardAccount,
    activePromotions,
    showInvestPromoCallout,
    cookies?.hasSeenInvestPromoModal,
    setCookie,
    data?.application?.userId,
    cookieOptions,
    customer?.hasElevate,
  ])

  useEffect(() => {
    // Set boolean value for subscription users
    if (subscriptionsData?.subscriptions?.length) {
      dispatch(setCustomerAction({ hasSubscription: true }))
    } else {
      dispatch(setCustomerAction({ hasSubscription: false }))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionsData])

  useEffect(() => {
    /* reset the details of the transfer and debit card deposit
       when user first arrives on the Dashboard */
    dispatch(resetTransferDetailsAction())
    dispatch(resetDebitCardDepositDetailsAction())
    /* reset the location state details when user first arrives on the Dashboard */
    dispatch(resetLocationState())
  }, [dispatch])

  useEffect(() => {
    /* Only show this alert if the showMfaComingSoonBanner is set to true.
       Do not show the alert if the user has already seen it once during this session. */
    const userId = data?.application?.userId

    if (
      userId &&
      showMfaComingSoonBanner &&
      window.sessionStorage.getItem('hasSeenMfaComingSoonBanner') !== userId
    ) {
      dispatch(
        addAlertAction({
          dismissible: true,
          type: ALERT_TYPES.WARNING,
          text:
            'Multi-Factor Authentication coming soon! Please ensure your email and phone number are up to date.',
        })
      )

      window.sessionStorage.setItem('hasSeenMfaComingSoonBanner', userId)
    }
  }, [data, showMfaComingSoonBanner, dispatch])

  const selectedAccountData = useMemo(() => (
    getAccountById(data?.accounts?.accounts, selectedAccount)
  ), [data?.accounts?.accounts, selectedAccount])

  const isApproved = useMemo(() => (
    data?.application?.customerApplicationStatus === APPLICATION_STATUSES.PASS
  ), [data?.application?.customerApplicationStatus])

  const handleRowClick = row => {
    setDetailModalData({
      transaction: row,
      isOpen: true,
    })
    trackEvent({
      event: SEGMENT_EVENTS.TRANSACTION_DETAILS_CLICK(SEGMENT_PAGE_NAMES.DASHBOARD_HOME),
    })
  }

  const handleToggleModal = () =>
    setDetailModalData({ isOpen: !detailModalData.isOpen, ...detailModalData })

  const handleCloseModal = () => setDetailModalData({ details: {}, isOpen: false })

  const customerNameInHeader = useMemo(() => (
    customer?.firstName ? ` ${customer.firstName}` : ''
  ), [customer?.firstName])

  return (
    <>
      <Hero
        heading={`Hello${customerNameInHeader}!`}
        subHeading={toMediumDateFormat(currentDate)}
        totalBalance={isApproved && data && selectedAccountData ? data.accounts.totalBalance : null}
        hasPremium={hasPremium}
        hasElevate={hasElevate}
        segmentSource={SEGMENT_PAGE_NAMES.DASHBOARD_HOME}
      />
      <div className={styling.page}>
        <div className={styling['page-content']}>
          <LoadingContainer error={error} errorMessage='Error loading accounts.' loading={loading}>
            {data && (
              <>
                <div
                  className={classNames('row', styling['page-content-row'])}
                  data-cy='authenticated-loaded-view'
                >
                  <div className={styling['detail-card']}>
                    <section className={styling['dashboard-content']}>
                      {isApproved && data?.accounts?.accounts?.length > 0 && (
                        <AccountsCardSlider
                          accounts={data?.accounts?.accounts}
                          selectedAccount={selectedAccount}
                          setSelectedAccount={setSelectedAccount}
                        />
                      )}
                      <div className={styling['card-details']}>
                        <ApplicationStatusDisplay
                          status={data?.application?.customerApplicationStatus}
                          applicationData={data?.application}
                          pollingHasMaxedOut={pollingHasMaxedOut}
                        />

                        {isApproved && selectedAccountData && (
                          <div className={styling['account-actions']}>
                            <div className={styling['transactions-header']}>
                              <span>
                                {`Latest ${selectedAccountData?.title} Transactions `}
                                <span
                                  className={styling['account-number']}
                                >{`(${obscureAccountNumberShort(
                                  selectedAccountData?.accountNumber || ''
                                )})`}</span>
                              </span>
                            </div>
                            <AccountDetailsLink
                              accountId={selectedAccount}
                              data-cy='view-account-details-link'
                            />
                          </div>
                        )}
                        {isApproved && selectedAccountData?.transactions && (
                          <>
                            <TransactionDetailModal
                              transaction={detailModalData.transaction}
                              isOpen={detailModalData.isOpen}
                              onToggleModal={handleToggleModal}
                              onCloseModal={handleCloseModal}
                            />
                            <Table
                              mobileMaxBreakpoint={MAX_WIDTH_BREAKPOINTS.MEDIUM_LARGE}
                              data={selectedAccountData.transactions}
                              columns={transactionTableColumns}
                              noRecordsMessage={`No ${selectedAccountData?.title} Transactions to Show Within the Past 30 Days`}
                              row={{
                                onClick: handleRowClick,
                                clickable: row => row.details?.length > 0,
                              }}
                            />
                            <AccountDetailsLink accountId={selectedAccount} mobile />
                          </>
                        )}
                      </div>
                    </section>
                  </div>
                  <div className={styling['cta-column']}>
                    <DashboardCtas
                      ctas={data?.ctas}
                      isStandardAccount={isStandardAccount}
                      activePromotions={activePromotions}
                      showInvestPromoCallout={showInvestPromoCallout}
                      />
                  </div>
                </div>
              </>
            )}
          </LoadingContainer>
        </div>
      </div>
      <Modal
        centered
        size='md'
        open={showDownloadAppModal}
        toggleModal={() => toggleShowDownloadAppModal(!showDownloadAppModal)}
      >
        <ModalHeader withCloseButton closeModal={() => toggleShowDownloadAppModal(false)}>
          <h4 data-cy='download-app-header'>Download the Greenwood app!</h4>
        </ModalHeader>
        <ModalBody>
          <p className={styling['do-not-miss']}>
            Manage your accounts and take advantage of other features.
          </p>
          <AppLinks />
        </ModalBody>
      </Modal>
      <InvestStockCampaignModal
        isOpen={showInvestStockCampaignModal}
        toggleModal={() => toggleShowInvestStockCampaignModal(!showInvestStockCampaignModal)}
        closeModal={() => toggleShowInvestStockCampaignModal(false)}
        isUpgrading={isStandardAccount}
      />
    </>
  )
}

export default Dashboard
