import React, { useRef, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { matchPath, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Breadcrumb from '@common/components/link/Breadcrumb'
import Tooltip from '@shared/components/tooltip/Tooltip'
import HeroAccountDetails from './HeroAccountDetails'

import { ReactComponent as QuestionMark } from '@shared/images/icons/question-mark.svg'
import { ReactComponent as PremiumBadge } from '@common/images/illustrations/illustration-premium.svg'
import { ReactComponent as ElevateBadge } from '@common/images/illustrations/illustration-elevate.svg'
import { toDollars } from '@shared/utils'
import { MAX_WIDTH_BREAKPOINTS } from '@shared/constants/uiConstants'
import { staticRoutes } from 'src/routing/routes'
import { trackEvent, SEGMENT_EVENTS } from '@common/utils'

import styling from './hero.module.scss'

const SHOW_PREMIUM_OR_ELEVATE_BADGE_ROUTES = [
  {
    path: staticRoutes.dashboard.pathname,
    end: true,
  },
  {
    path: staticRoutes.settings.pathname,
    end: false,
  },
]

const Hero = ({
  account,
  heading,
  className,
  breadcrumbs,
  subHeading,
  totalBalance,
  mobileMaxBreakpoint = MAX_WIDTH_BREAKPOINTS.MEDIUM,
  segmentSource,
}) => {
  const [showTotalBalanceTooltip, setShowTotalBalanceTooltip] = useState(false)
  const hasPremium = useSelector(state => state.customer?.hasPremium)
  const hasElevate = useSelector(state => state.customer?.hasElevate)

  const { pathname } = useLocation()
  const totalBalanceRef = useRef(null)

  const isMobile = useMediaQuery({ query: `(max-width: ${mobileMaxBreakpoint}px)` })

  const showAccountDetails = totalBalance !== undefined || account !== undefined
  const heroClasses = classNames('row', styling.hero, className, {
    [styling['has-total-balance']]: totalBalance !== undefined,
    [styling['has-account-detail']]: account !== undefined,
  })

  const isPremiumOrElevateBadgeRoute = SHOW_PREMIUM_OR_ELEVATE_BADGE_ROUTES.some(expectedRoute =>
    matchPath(expectedRoute, pathname)
  )
  const showBadge = isPremiumOrElevateBadgeRoute && !showAccountDetails

  const renderTotalBalance = () => {
    return (
      <>
        {totalBalance?.amount !== undefined && (
          <h2>
            Total Balance{' '}
            <QuestionMark className={styling['tooltip-trigger']} ref={totalBalanceRef} />
            <Tooltip
              placement='auto'
              open={showTotalBalanceTooltip}
              target={totalBalanceRef}
              header='Total Balance'
              toggle={open => {
                setShowTotalBalanceTooltip(!open)
              }}
            >
              The total available balance of all of your accounts.
            </Tooltip>
            <span>{toDollars({ value: totalBalance.amount })}</span>
          </h2>
        )}
      </>
    )
  }

  const getBadge = () => {
    if (hasPremium) {
      return <PremiumBadge className={styling.badge} />
    } else if (hasElevate) {
      return <ElevateBadge className={classNames(styling.badge, styling['elevate-badge'])} />
    } else {
      return null
    }
  }

  useEffect(() => {
    if (showTotalBalanceTooltip && segmentSource) {
      trackEvent({ event: SEGMENT_EVENTS.TOOLTIP_HOVER(segmentSource) })
    }
  }, [showTotalBalanceTooltip, segmentSource])

  return (
    <>
      <div className={heroClasses}>
        {breadcrumbs && <Breadcrumb crumbs={breadcrumbs} />}
        <h1
          data-cy='hero-heading'
          className={classNames({ [styling['has-subheading']]: subHeading })}
        >
          {heading || 'Hello'}
          {subHeading && <span>{subHeading}</span>}
          {showBadge && getBadge()}
        </h1>

        {showAccountDetails && (
          <div
            className={classNames(styling['account-box'], {
              [styling['has-account-detail']]: account !== undefined,
            })}
          >
            <div className={styling.gradient} />
            <div
              className={classNames(styling['inner-container'], {
                [styling['has-badge']]: hasPremium || hasElevate,
              })}
            >
              {account && (
                <HeroAccountDetails
                  account={account}
                  isMobile={isMobile}
                  segmentSource={segmentSource}
                />
              )}
              {totalBalance && renderTotalBalance()}
              {isPremiumOrElevateBadgeRoute && getBadge()}
            </div>
          </div>
        )}
      </div>
    </>
  )
}

Hero.propTypes = {
  account: PropTypes.object,
  className: PropTypes.string,
  heading: PropTypes.node,
  subHeading: PropTypes.node,
  breadcrumbs: PropTypes.array,
  totalBalance: PropTypes.object,
  mobileMaxBreakpoint: PropTypes.number,

  /**
   * Show the Premium icon badge
   */
  hasPremium: PropTypes.bool,
  /**
   * Show the Elevate icon badge
   */
  hasElevate: PropTypes.bool,
}

export default Hero
