import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import Tabs from '@shared/components/tabs/Tabs'
import { Navigate, Route, Routes, useNavigate, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { useFlags } from 'launchdarkly-react-client-sdk'

import HelpSupport from './helpSupport/HelpSupport'
import AgreementsDisclosures from './agreementsDisclosures/AgreementsDisclosures'
import Hero from '@common/components/hero/Hero'
import Notifications from './notifications/Notifications'
import Statements from './statements/Statements'
import LinkedAccounts from './linkedAccounts/LinkedAccounts'
import PrivacySecurityView from './privacySecurity/PrivacySecurityView'
import VerificationCodes from './privacySecurity/VerificationCodes'
import ProtectedRoute from '@routing/ProtectedRoute'

import { useTabRouting } from '@common/utils'
import { getTabOptions } from './settingsUtils.js'
import { staticRoutes } from '@routing/routes'

import styling from './settings.module.scss'

const Settings = () => {
  const [levelTwoActiveTab, setLevelTwoActiveTab] = useState()
  const location = useLocation()
  const navigate = useNavigate()
  const [tabOptions, setTabOptions] = useState(getTabOptions({}))
  const { webNotificationsOptOut } = useFlags()

  const applicationStatus = useSelector(state => state.application?.customerApplicationStatus)
  const customer = useSelector(state => state.customer)
  const hasPremium = customer?.hasPremium
  const hasElevate = customer?.hasElevate
  const firstName = customer?.firstName || ''
  const lastName = customer?.lastName || ''
  const userFullName = `${firstName} ${lastName}`

  useEffect(() => {
    setTabOptions(getTabOptions({ applicationStatus, webNotificationsOptOut }))
  }, [applicationStatus, webNotificationsOptOut])

  // If the location is the root `/settings` route, then navigate to the `/settings/account` view
  useEffect(() => {
    if (location.pathname === staticRoutes.settings.pathname) {
      navigate(staticRoutes.settingsPrivacySecurity.pathname, { replace: true })
    }
  }, [location.pathname, navigate])

  const { activeTab } = useTabRouting({
    defaultActiveTab: tabOptions[0],
    location,
    tabOptions,
    tabsEnabled: true,
  })

  useEffect(() => {
    let levelTwoOption = null

    if (activeTab?.subTabs) {
      const { pathname, subTabs } = activeTab
      if (location.pathname === pathname) {
        levelTwoOption = {
          ...subTabs[0],
          pathname,
        }
      } else {
        const option = subTabs.find(
          o =>
            o.pathname.includes(location.pathname) || o.childPathnames?.includes(location.pathname)
        )
        levelTwoOption = {
          ...option,
          pathname: location.pathname,
        }
      }
    }

    delete levelTwoOption?.childPathnames

    setLevelTwoActiveTab(levelTwoOption)
  }, [activeTab, location])

  const handleTabChange = tab => {
    if (tab) {
      navigate(tab.pathname)
    }
  }

  const fullPageViews = [
    'Help & Support',
    'Statements',
    'Agreements & Disclosures',
    'Linked Accounts/Cards',
  ]

  return (
    <>
      <Hero heading={userFullName} hasPremium={hasPremium} hasElevate={hasElevate} />
      <div className='page-content-container white-bg'>
        <div className={classNames('row', styling['page-container'])}>
          <Tabs
            defaultActiveTab={activeTab}
            options={tabOptions}
            onChangeCallback={handleTabChange}
          />
          <div className={styling['tabs-content-container']}>
            {!fullPageViews.includes(activeTab?.label) ? (
              <Tabs
                dataCy='settings-subtabs'
                className={styling['level-two-tabs']}
                defaultActiveTab={levelTwoActiveTab}
                options={tabOptions[0].subTabs}
                onChangeCallback={handleTabChange}
                vertical='md'
                asSubTabs
              />
            ) : null}
            <Routes>
              <Route path={staticRoutes.settingsPrivacySecurity.relativePathname}>
                <Route
                  index
                  element={<ProtectedRoute component={PrivacySecurityView} />}
                />
                <Route
                  path={staticRoutes.settingsPrivacySecurityVerificationCodes.relativePathname}
                  element={<ProtectedRoute component={VerificationCodes} />}
                />
                <Route
                  path='*'
                  element={<Navigate to={staticRoutes.settingsPrivacySecurity.pathname} />}
                />
              </Route>
              <Route
                path={staticRoutes.settingsLinkedAccounts.relativePathname}
                Component={LinkedAccounts}
              />
              <Route
                path={staticRoutes.settingsDocuments.relativePathname}
                Component={Statements}
              />
              <Route
                path={staticRoutes.settingsAgreementsDisclosures.relativePathname}
                Component={AgreementsDisclosures}
              />
              <Route
                path={staticRoutes.settingsHelp.relativePathname}
                Component={HelpSupport}
              />
              <Route
                path={staticRoutes.settingsNotifications.relativePathname}
                Component={Notifications}
              />
            </Routes>
          </div>
        </div>
      </div>
    </>
  )
}

Settings.propTypes = {
  userFullName: PropTypes.string,
}

export default Settings
