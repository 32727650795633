import React, { useMemo } from 'react'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { ReactComponent as Chevron } from '@shared/images/icons/chevron.svg'

import { ROUTE_USER_FLOW } from '@shared/constants/uiConstants'
import { staticRoutes } from '@routing/routes'

import styling from './privacySecurityView.module.scss'

const PrivacySecurityView = () => {
  const navigate = useNavigate()
  const sessionId = useSelector(state => state.auth?.sessionId)

  const securityOptionRows = useMemo(
    () => [
      {
        label: 'Reset Password',
        dataCy: 'settings-security-reset-password-row',
        onClick: () =>
          navigate(staticRoutes.recoverProvideEmail.pathname, {
            state: {
              userFlow: ROUTE_USER_FLOW.RESET_PASSWORD,
              sessionId,
            },
          }),
      },
      {
        label: 'Verification Codes',
        dataCy: 'settings-security-verification-codes-row',
        onClick: () => navigate(staticRoutes.settingsPrivacySecurityVerificationCodes.pathname),
      },
    ],
    [navigate, sessionId]
  )

  return (
    <div
      className={classNames(
        'settings-view-content',
        styling['settings-privacy-security-view-container']
      )}
    >
      <h2>Security</h2>
      <p className='subtext'>Manage security preferences and further secure your accounts.</p>
      <table className={classNames('simple-table', styling['settings-link-table'])}>
        <tbody>
          {securityOptionRows.map((rowItem, index) => (
            <tr className='link-row' key={index} data-cy={rowItem.dataCy} onClick={rowItem.onClick}>
              <th>{rowItem.label}</th>
              <td className='row-chevron'>
                <Chevron />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default PrivacySecurityView
