export const SET_API_VALIDATION_ERRORS = 'SET_API_VALIDATION_ERRORS'
export const CLEAR_API_VALIDATION_ERROR = 'CLEAR_API_VALIDATION_ERROR'
export const CLEAR_ALL_API_VALIDATION_ERRORS = 'CLEAR_ALL_API_VALIDATION_ERRORS'

// Multiple fields can have multiple errors returned by the API, set them all at once
export const setApiValidationErrorsAction = payload => {
  return {
    payload,
    type: SET_API_VALIDATION_ERRORS,
  }
}

// Clears a particular field's errors
export const clearApiValidationErrorAction = payload => {
  return {
    payload,
    type: CLEAR_API_VALIDATION_ERROR,
  }
}

// Clears all errors
export const clearAllApiValidationErrorsAction = payload => {
  return {
    payload,
    type: CLEAR_ALL_API_VALIDATION_ERRORS,
  }
}
