import React from 'react'

import { ReactComponent as MetalBlackCardIcon } from '@common/images/icons/matte-black-metal-card.svg'
import { ReactComponent as TravelIcon } from '@common/images/icons/travel.svg'
import { ReactComponent as DoordashIcon } from '@common/images/icons/benefitIcons/doordash-benefit.svg'
import { ReactComponent as LyftIcon } from '@common/images/icons/benefitIcons/lyft-benefit.svg'
import { ReactComponent as ZeroLiabilityIcon } from '@common/images/icons/benefitIcons/zero-liability-protection-benefit.svg'
import { ReactComponent as ShieldCheckIcon } from '@common/images/icons/shield-check.svg'
import { ReactComponent as ValenceIcon } from '@common/images/icons/benefitIcons/valence-benefit.svg'
import { ReactComponent as ShieldIcon } from '@common/images/icons/shield.svg'
import { ReactComponent as IdentityProtectionIcon } from '@common/images/icons/benefitIcons/identity-protection-benefit.svg'
import { ReactComponent as SavingsAccountIcon } from '@common/images/icons/benefitIcons/savings-account-benefit.svg'
import { ReactComponent as NoHiddenFeesIcon } from '@common/images/icons/benefitIcons/no-hidden-fees-benefit.svg'
import { ReactComponent as TwoDayEarlyPayIcon } from '@common/images/icons/benefitIcons/2-day-early-pay-benefit.svg'
import { ReactComponent as ApplePayIcon } from '@common/images/icons/benefitIcons/apple-pay-benefit.svg'
import { ReactComponent as InstantCardFundingIcon } from '@common/images/icons/benefitIcons/instant-card-funding-benefit.svg'

export const BENEFITS = {
  METAL_BLACK_CARD: {
    title: 'Metal Black Card',
    icon: <MetalBlackCardIcon />,
    subtext:
      "Your Premium membership comes with an ultra luxe and sturdy Black metal card that's sure to draw attention!",
  },
  TRAVEL_IN_STYLE: {
    title: 'Travel In Style',
    icon: <TravelIcon />,
    subtext:
      'Call, email or live chat with a Lifestyle Manager 24/7/365 to help with all your travel needs. Direct access to Mastercard luxury hotel and resorts portfolio with lowest hotel rate guarantee, lost luggage insurance, trip cancellation/interruption insurance and more.',
  },
  DOORDASH: {
    title: 'DoorDash',
    icon: <DoordashIcon />,
    subtext:
      'Get a $0 delivery fee, reduced service charges and exclusive savings and offers with DashPass, the membership service from DoorDash. Save on everything from restaurants, grocery stores, convenience stores and more. Plus, new members get 3 free months of membership and $5 off their first order per month. Start saving!',
  },
  LYFT_ON_US: {
    title: 'Lyft On Us',
    icon: <LyftIcon />,
    subtext:
      'More rides means more adventure. And if you take just 3 rides with Lyft in a month, you’ll get a $5 credit once per month to put towards your next ride.',
  },
  VALENCE_BUSINESS_NETWORK: {
    title: 'Valence Business Network',
    icon: <ValenceIcon />,
    subtext:
      'The Valence platform connects, showcases and empowers the Black professional community through professional development and career opportunities, with an eye toward a future where there are generations of Black professionals who are skilled in the art of business.',
  },
  PRICE_PROTECTION: {
    title: 'Price Protection',
    icon: <ShieldCheckIcon />,
    subtext:
      'Ensure you’re always getting the best price! With Price Protection, you can get reimbursed for the difference in price, up to $250 per claim.',
  },
  ZERO_LIABILITY_PROTECTION: {
    title: 'Zero Liability Protection',
    icon: <ZeroLiabilityIcon />,
    subtext:
      'Whether you pay in store, over the phone or online, you’re not responsible in the event someone makes unauthorized purchases with your card.',
  },
  CELL_PHONE_PROTECTION_INSURANCE: {
    title: 'Cell Phone Protection Insurance',
    icon: <ShieldIcon />,
    subtext:
      'Provides coverage for stolen or damaged cellular telephones when the monthly bill associated with the phone is paid with an eligible Mastercard.',
  },
  IDENTITY_PROTECTION: {
    title: 'Identity Protection',
    icon: <IdentityProtectionIcon />,
    subtext:
      'Monitor your credit file for fraud, receive alert notifications about suspicious activity, and get assistance from a resolution specialist.',
  },
  HIGH_YIELD_SAVINGS_ACCOUNT: {
    title: 'High Yield Savings account with 4.15% APY',
    icon: <SavingsAccountIcon />,
    subtext: 'Earn interest. Grow your wealth faster.',
  },
  NO_HIDDEN_FEES: {
    title: 'No Hidden Fees',
    icon: <NoHiddenFeesIcon />,
    subtext:
      'Very simply, we don’t charge you hidden fees to deposit or access your money. And we don’t have overdraft fees. You made it. You keep it. You prosper.',
  },
  TWO_DAY_EARLY_PAY: {
    title: '2-Day Early Pay',
    icon: <TwoDayEarlyPayIcon />,
    subtext:
      'You work hard for your money. With direct deposit, you receive your payroll and other ACH deposits as soon as they’re available — often as much as two days earlier.',
  },
  APPLE_GOOGLE_SAMSUNG_PAY: {
    title: 'Apple Pay, Google Pay & Samsung Pay',
    icon: <ApplePayIcon />,
    subtext:
      'Sync your Greenwood account to your Apple Pay or Android pay mobile wallets for quick and secure payments.',
  },
  INSTANT_CARD_FUNDING: {
    title: 'Instant Card Funding',
    icon: <InstantCardFundingIcon />,
    subtext: 'With our new instant card funding, you can easily add money to your account in minutes.',
  },
}

export const getDetailsBenefitsList = () => (
  [
    {
      color: 'teal',
      ...BENEFITS.PRICE_PROTECTION,
    }, {
      color: 'orange',
      ...BENEFITS.ZERO_LIABILITY_PROTECTION,
    }, {
      color: 'pink',
      ...BENEFITS.CELL_PHONE_PROTECTION_INSURANCE,
    }, {
      color: 'light-green',
      ...BENEFITS.IDENTITY_PROTECTION,
    }, {
      color: 'blue',
      ...BENEFITS.HIGH_YIELD_SAVINGS_ACCOUNT,
    }, {
      color: 'red',
      ...BENEFITS.NO_HIDDEN_FEES,
    }, {
      color: 'teal',
      ...BENEFITS.TWO_DAY_EARLY_PAY,
    }, {
      color: 'orange',
      ...BENEFITS.APPLE_GOOGLE_SAMSUNG_PAY,
    }, {
      color: 'pink',
      ...BENEFITS.INSTANT_CARD_FUNDING,
    },
  ]
)

export const getPaymentBenefitsList = () => (
  [
    {
      color: 'teal',
      ...BENEFITS.METAL_BLACK_CARD,
    }, {
      color: 'orange',
      ...BENEFITS.TRAVEL_IN_STYLE,
    }, {
      color: 'red',
      ...BENEFITS.DOORDASH,
    }, {
      color: 'pink',
      ...BENEFITS.LYFT_ON_US,
    }, {
      color: 'light-green',
      ...BENEFITS.VALENCE_BUSINESS_NETWORK,
    }, {
      color: 'blue',
      ...BENEFITS.PRICE_PROTECTION,
    }, {
      color: 'teal',
      ...BENEFITS.TWO_DAY_EARLY_PAY,
    }, {
      color: 'orange',
      ...BENEFITS.ZERO_LIABILITY_PROTECTION,
    }, {
      color: 'red',
      ...BENEFITS.NO_HIDDEN_FEES,
    }, {
      color: 'pink',
      ...BENEFITS.CELL_PHONE_PROTECTION_INSURANCE,
    }, {
      color: 'light-green',
      ...BENEFITS.IDENTITY_PROTECTION,
    }, {
      color: 'blue',
      ...BENEFITS.HIGH_YIELD_SAVINGS_ACCOUNT,
    }, {
      color: 'teal',
      ...BENEFITS.APPLE_GOOGLE_SAMSUNG_PAY,
    }, {
      color: 'orange',
      ...BENEFITS.INSTANT_CARD_FUNDING,
    },
  ]
)
