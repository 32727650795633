import { ReactComponent as LockShield } from '@shared/images/icons/lock-shield.svg'
import { ReactComponent as Bell } from '@shared/images/icons/bell.svg'

import { staticRoutes } from '@routing/routes'

export const getTabOptions = ({ applicationStatus = 'PASS', webNotificationsOptOut }) => {
  let tabs = [
    {
      label: 'Settings',
      dataCy: 'settings-tab',
      enabled: true,
      pathname: staticRoutes.settingsPrivacySecurity.pathname,
      subTabs: [
        {
          label: 'Security',
          enabled: true,
          pathname: staticRoutes.settingsPrivacySecurity.pathname,
          childPathnames: [staticRoutes.settingsPrivacySecurityVerificationCodes.pathname],
          icon: LockShield,
        },
      ],
    },
    {
      label: 'Linked Accounts/Cards',
      dataCy: 'linked-accounts-tab',
      enabled: true,
      pathname: staticRoutes.settingsLinkedAccounts.pathname,
    },
    {
      label: 'Statements',
      dataCy: 'statements-tab',
      enabled: true,
      pathname: staticRoutes.settingsDocuments.pathname,
    },
    {
      label: 'Agreements & Disclosures',
      dataCy: 'agreements-disclosures-tab',
      enabled: true,
      pathname: staticRoutes.settingsAgreementsDisclosures.pathname,
    },
    {
      label: 'Help & Support',
      dataCy: 'help-support-tab',
      enabled: true,
      pathname: staticRoutes.settingsHelp.pathname,
    },
  ]

  if (webNotificationsOptOut) {
    tabs[0].subTabs.push({
      label: 'Notifications',
      enabled: true,
      pathname: staticRoutes.settingsNotifications.pathname,
      icon: Bell,
    })
  }

  if (applicationStatus !== 'PASS') {
    // remove tabs and subtabs only for PASS application status from tabs array
    const applicationStatusPassOnlyTabs = [
      staticRoutes.settingsNotifications.pathname,
      staticRoutes.settingsDocuments.pathname,
      staticRoutes.settingsLinkedAccounts.pathname,
    ]

    tabs = tabs.filter(tab => !applicationStatusPassOnlyTabs.includes(tab.pathname))
    tabs[0].subTabs = tabs[0].subTabs.filter(
      tab => !applicationStatusPassOnlyTabs.includes(tab.pathname)
    )
  }

  return tabs
}
