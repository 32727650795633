import { useEffect } from 'react'

import { purgeStore } from '@redux/store'

const Logout = () => {
  useEffect(() => {
    window.sessionStorage.removeItem('hasSeenMfaComingSoonBanner')
    purgeStore({})
  }, [])

  return null
}

export default Logout
