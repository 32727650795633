import React from 'react'
import { useMatch } from 'react-router-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { format, parse } from 'date-fns'

import { ReactComponent as BankIcon } from '@common/images/icons/bank.svg'
import { ReactComponent as DebitCardIcon } from '@common/images/icons/debit-card.svg'
import { ReactComponent as DangerIcon } from '@common/images/icons/danger.svg'
import { staticRoutes } from 'src/routing/routes'
import { PLAID_LINKING_STATUSES } from '@common/constants'

import styling from './linkedAccountStatus.module.scss'

const StatusText = ({ className, status }) => {
  return status ? <span className={className}>{status}</span> : null
}

const LinkedAccountStatus = ({ linkedAccount, className }) => {
  const isDebitCardAccount = !!linkedAccount?.address

  const isLinkedAccountDetailsRoute = !!useMatch(
    staticRoutes.settingsLinkedAccountsLinkedAccountDetails.pathname
  )

  const accountStatusClass =
    linkedAccount.linkingStatus === PLAID_LINKING_STATUSES.VERIFIED ||
    (!linkedAccount.linkingStatus && !linkedAccount.expired)
      ? 'positive'
      : 'negative'

  const getAccountStatusText = () => {
    let text = ''

    if (linkedAccount?.linkingStatusDescriptor) {
      text = linkedAccount.linkingStatusDescriptor.toUpperCase()

      // Used `else if` here to denote that only one of these cases should exist
    } else if (linkedAccount?.expired) {
      text = (
        <>
          EXPIRED <DangerIcon />
        </>
      )
    }

    return text
  }

  const getAccountExpireDateText = () => {
    const formattedDate = format(parse(linkedAccount?.expireDate, 'yyyyMM', new Date()), 'MM/yy')

    return `Exp. ${formattedDate}`
  }

  const getAccountAddressText = () => {
    let addressText = ''

    if (linkedAccount?.address) {
      const address = linkedAccount?.address

      addressText = `${address.addr1 || ''} ${address.addr2 || ''}, ${address.city ||
        ''} ${address.state || ''} ${address.zip || ''}`
        /* replace all one-or-more consecutive spaces with a single space in case multiple
          address parts are missing */
        .replace(/\s+/g, ' ')

        // replace a comma preceded with a space with just a comma
        .replace(/\s,/g, ',')
        .trim()

        // if all parts of the the address before the comma is not provided, remove the comma
        .replace(/^,/, '')
    }

    return addressText
  }

  return (
    <div
      className={classNames(
        styling.container,
        {
          [styling['list-item']]: !isLinkedAccountDetailsRoute,
        },
        className
      )}
    >
      <div
        className={classNames(styling['icon-container'], {
          [styling['fill-bg']]: !isDebitCardAccount,
        })}
      >
        {isDebitCardAccount ? (
          <DebitCardIcon className={styling['debit-card-icon']} data-cy='debit-card-icon' />
        ) : (
          <BankIcon className={styling['bank-icon']} data-cy='bank-icon' />
        )}
      </div>
      <div>
        <span className={styling['account-title']}>
          {linkedAccount.title || `${linkedAccount.network} Debit Card`}
          {isLinkedAccountDetailsRoute && (
            <StatusText
              className={classNames(styling[`${accountStatusClass}`], {
                'negative-status-label-display': accountStatusClass === 'negative',
                'positive-status-label-display':
                  !isLinkedAccountDetailsRoute &&
                  accountStatusClass === 'positive' &&
                  linkedAccount.linkingStatus,
              })}
              status={getAccountStatusText()}
            />
          )}
        </span>
        <div
          className={classNames(styling['account-details'], {
            [styling['debit-card-details']]: isDebitCardAccount,
          })}
        >
          <span>
            {`(...${linkedAccount.mask || linkedAccount.maskedNumber})`}
            {!isLinkedAccountDetailsRoute && (
              <StatusText
                className={classNames(styling[`${accountStatusClass}`], {
                  'negative-status-label-display': accountStatusClass === 'negative',
                  'positive-status-label-display':
                    !isLinkedAccountDetailsRoute &&
                    accountStatusClass === 'positive' &&
                    linkedAccount.linkingStatus,
                })}
                status={getAccountStatusText()}
              />
            )}
          </span>
          {isDebitCardAccount && isLinkedAccountDetailsRoute && (
            <>
              <span className={classNames({ [styling.negative]: linkedAccount.expired })}>
                {getAccountExpireDateText()}
              </span>
              <span>{getAccountAddressText()}</span>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

LinkedAccountStatus.propTypes = {
  /**
   * Object representing a linked account from the linked account list
   */
  linkedAccount: PropTypes.object.isRequired,

  /**
   * Additional classes passed to the container
   */
  className: PropTypes.any,
}

export default LinkedAccountStatus
