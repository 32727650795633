export const ALERT_TYPES = {
  DANGER: 'DANGER',
  INFO: 'INFO',
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING',
}

export const DEFAULT_ALERT_DISMISS_DELAY = 3000

export const TRANSITION_KEYS = [
  'in',
  'mountOnEnter',
  'unmountOnExit',
  'appear',
  'enter',
  'exit',
  'timeout',
  'onEnter',
  'onEntering',
  'onEntered',
  'onExit',
  'onExiting',
  'onExited',
]

export const DROPDOWN_POSITION_MAP = {
  UP: 'top',
  LEFT: 'left',
  RIGHT: 'right',
  DOWN: 'bottom',
}

export const TRANSITION_STATUS = {
  ENTERING: 'entering',
  ENTERED: 'entered',
  EXITING: 'exiting',
  EXITED: 'exited',
}

export const TRANSITION_CLASS_MAP = {
  [TRANSITION_STATUS.ENTERING]: 'collapsing',
  [TRANSITION_STATUS.ENTERED]: 'collapse show',
  [TRANSITION_STATUS.EXITING]: 'collapsing',
  [TRANSITION_STATUS.EXITED]: 'collapse',
}

export const DATE_TIME_FORMAT = {
  LONG_DATE: 'MMMM d, yyyy', // e.g. "January 21, 2021"
  LONG_MONTH_YEAR: 'MMMM yyyy', // e.g. "January 2021"
  MEDIUM_DATE: 'MMM d, yyyy', // e.g. "Jan 21, 2021"
  MONTH_YEAR_DATE: 'MMMM yyyy',
  SHORT_MONTH_DAY_YEAR: 'MM/dd/yyyy', // e.g. "01/21/2021"
  TIME_SHORT_MONTH_DAY: 'hh:mm a, MMM d', // e.g. "10:01 AM, Jan 21"
  ISO_8601_DATE: 'yyyy-MM-dd', // e.g. 2021-01-21
  ISO_7813_DATE: 'MM/yy', // e.g. 12/24
}

export const POPPER_PLACEMENTS = [
  'top-start',
  'top',
  'top-end',
  'right-start',
  'right',
  'right-end',
  'bottom-end',
  'bottom',
  'bottom-start',
  'left-end',
  'left',
  'left-start',
  'auto-start',
  'auto',
  'auto-end',
]

export const ROUTE_USER_FLOW = {
  SIGNIN: 'signin',
  SIGNUP: 'signup',
  FORGOT_PASSWORD: 'forgotPassword',
  RESET_PASSWORD: 'resetPassword',
}

/* Corresponds with _breakpoints.scss. Separate MIN and MAX values are used to avoid
   strange transitions when mixing min and max styles directly on the breakpoint */
export const MIN_WIDTH_BREAKPOINTS = {
  SMALL: 576,
  MEDIUM: 769,
  MEDIUM_LARGE: 992,
  LARGE: 1024,
  EXTRA_LARGE: 1440,
}

export const MAX_WIDTH_BREAKPOINTS = {
  SMALL: MIN_WIDTH_BREAKPOINTS.SMALL - 1,
  MEDIUM: MIN_WIDTH_BREAKPOINTS.MEDIUM - 1,
  MEDIUM_LARGE: MIN_WIDTH_BREAKPOINTS.MEDIUM_LARGE - 1,
  LARGE: MIN_WIDTH_BREAKPOINTS.LARGE - 1,
  EXTRA_LARGE: MIN_WIDTH_BREAKPOINTS.EXTRA_LARGE - 1,
}

export const HTTP_ERROR_CODE_MESSAGES = {
  '400':
    'The request contained invalid data. Please double check the information provided and try again.',
  '401': 'Authorization failed for the given request. Please make sure you are logged in.',
  '404': 'Nothing was found for the requested operation.',
  '413': 'The request you are trying to send is too large. The limit is 20MB.',
  '500': 'The server is having issues. Please try again later.',
  '503': 'An error occurred in making the request. Please try again.',
  '504': 'The request has timed out. Please try again.',
}

export const APPLICATION_STATUSES = {
  INITIAL: 'INITIAL',
  SUBMITTED: 'SUBMITTED',
  REVIEW: 'REVIEW',
  DECLINED: 'DECLINED',
  DOCUMENT_REQUIRED: 'DOCUMENT_REQUIRED',
  DOCUMENT_RESUBMISSION_REQUIRED: 'DOCUMENT_RESUBMISSION_REQUIRED',
  DOCUMENT_UNDER_REVIEW: 'DOCUMENT_UNDER_REVIEW',
  PASS: 'PASS',
  RESUBMISSION_REQUIRED: 'RESUBMISSION_REQUIRED',
}

export const CHALLENGE_TYPES = {
  NONE: 'NONE',
  RESET_PASSWORD: 'RESET_PASSWORD',
  VERIFY_EMAIL: 'VERIFY_EMAIL',
  PROVIDE_PHONE_NUMBER: 'PROVIDE_PHONE_NUMBER',
  VERIFY_PHONE_NUMBER: 'VERIFY_PHONE_NUMBER',
  SELECT_MFA_CHANNEL: 'SELECT_MFA_CHANNEL',
  VERIFY_MFA: 'VERIFY_MFA',
}
