import React, { useRef, useState } from 'react'
import classNames from 'classnames'

import FormInput from '../formInput/FormInput'
import FormInputAddon from '../formInput/FormInputAddon'
import ObscuredMaskedInputGroup from '../obscuredMaskedInput/ObscuredMaskedInputGroup'

import { ReactComponent as Eye } from '@shared/images/icons/eye.svg'
import { ReactComponent as EyeClosed } from '@shared/images/icons/eye-closed.svg'
import { ReactComponent as X } from '@shared/images/icons/x.svg'

const maskConfig = {
  parts: [
    { obscuringCharacter: 'X', maxLength: 3, placeholder: '___' },
    ' - ',
    { obscuringCharacter: 'X', maxLength: 2, placeholder: '__' },
    ' - ',
    { maxLength: 4, placeholder: '____' },
  ],
}

/**
 * Exposes the custom obscured masked input as a FormInput component for connection
 * to form libraries; this is necessary to properly handle onChange nuances around
 * custom values and synthetic events. It also allows reuse of the FormInput's addon capabilities.
 */
const FormSsnInput = ({ name, canShow = false, canClear = false, disabled, ...rest }) => {
  const [show, setShow] = useState(false)
  const inputRef = useRef(null)

  return (
    <FormInput
      as={ObscuredMaskedInputGroup}
      name={name}
      disabled={disabled}
      show={canShow && show}
      innerRef={inputRef}
      maskConfig={maskConfig}
      autoFocus
      dataCy='ssn-input'
      {...rest}
    >
      {(canShow || canClear) && (
        <FormInputAddon type='trailing'>
          {canClear && (
            <div
              className={classNames({ clickable: !disabled }, 'icon-container')}
              onClick={() => inputRef?.current.clear()}
              id='form-ssn-clear-button'
            >
              <X />
            </div>
          )}
          {canShow && (
            <div
              className={classNames({ clickable: !disabled }, 'icon-container')}
              onClick={() => !disabled && setShow(!show)}
              id='form-ssn-show-button'
            >
              {show ? <EyeClosed /> : <Eye />}
            </div>
          )}
        </FormInputAddon>
      )}
    </FormInput>
  )
}

export default FormSsnInput
