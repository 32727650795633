import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import classNames from 'classnames'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import FormInput from '@shared/components/formInput/FormInput'
import Button from '@shared/components/button/Button'
import { ReactComponent as CheckmarkIcon } from '@shared/images/icons/checkmark.svg'
import { ReactComponent as XIcon } from '@shared/images/icons/x.svg'

import { gql, useMutation } from '@services/serviceUtils'
import {
  ALERT_TYPES,
  DEFAULT_ALERT_DISMISS_DELAY,
} from '@shared/constants/uiConstants'
import { addAlertAction, removeAlertsAction } from '@redux/alerts/alertsActions'
import { staticRoutes } from '@routing/routes'

import styling from './promoCodeForm.module.scss'

const openDdaMutation = gql`
  mutation OpenDDA($openDDARequestDTOInput: OpenDDARequestDTOInput!) {
    openDDA(openDDARequestDTOInput: $openDDARequestDTOInput) {
      id
      paymentDetails {
        paymentSessionId
        paymentSessionType
      }
      promoCodeMessage
    }
  }
`

const PROMO_CODE_TYPES = {
  INVALID: 'INVALID',
  APPLIED: 'APPLIED',
}
const invalidPromoCodeMessage = 'Invalid Promo Code. Please try again.'
const validationSchema = Yup.object().shape({
  promoCode: Yup.string().test(
    'only alphanumeric chars allowed',
    invalidPromoCodeMessage,
    value => {
      return !value || value?.match(/^[0-9A-Za-z]*$/)
    }
  ),
})

const PromoCodeForm = ({
  isDarkTheme = false,
  setPaymentSession,
  className,
}) => {
  const { state = {} } = useLocation()
  const { product = {}, feeKey = '' } = state || {}
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const [promoCodeType, setPromoCodeType] = useState('')
  const [messageIcon, setMessageIcon] = useState(null)

  const [submitOpenDda] = useMutation(openDdaMutation, {
    onError: () => {
      dispatch(removeAlertsAction())
      setPromoCodeType(PROMO_CODE_TYPES.INVALID)
    },
  })

  const promoCodeMessage = useMemo(() => {
    let message = ''

    if (promoCodeType) {
      if (promoCodeType === PROMO_CODE_TYPES.INVALID) {
        message = invalidPromoCodeMessage
        setMessageIcon(<XIcon />)
      } else {
        message = 'Promo Code Applied!'

        setMessageIcon(<CheckmarkIcon />)
      }
    } else {
      setMessageIcon(null)
    }

    return message
  }, [promoCodeType])

  const hasInvalidPromoCode = useMemo(() => promoCodeType === PROMO_CODE_TYPES.INVALID, [
    promoCodeType,
  ])
  const hasSuccessfulPromoCode = useMemo(() => promoCodeType === PROMO_CODE_TYPES.APPLIED, [
    promoCodeType,
  ])

  const handleSubmit = async values => {
    const result = await submitOpenDda({
      variables: {
        openDDARequestDTOInput: {
          agreementIds: product?.agreements?.map(agreement => agreement.id),
          productId: product?.id,
          feeKey,
          promoCode: values?.promoCode,
        },
      },
    })

    const openDDA = result?.data?.openDDA

    if (openDDA) {
      const { paymentDetails = null, id = '', promoCodeMessage = '' } = result?.data?.openDDA || {}

      if (paymentDetails) {
        setPromoCodeType(PROMO_CODE_TYPES.APPLIED)
        setPaymentSession(paymentDetails)
      } else if (!id && !paymentDetails) {
        // if a promoCodeMessage exists, show a success alert
        if (promoCodeMessage) {
          dispatch(
            addAlertAction({
              autoDismissDelay: DEFAULT_ALERT_DISMISS_DELAY,
              type: ALERT_TYPES.SUCCESS,
              text: promoCodeMessage,
            })
          )

          navigate(staticRoutes.membershipUpgradeConfirmation.pathname, {
            state: {
              fundingFlow: state?.fundingFlow,
              productType: state?.product?.productType,
              shouldSetupDirectDeposit: state?.shouldSetupDirectDeposit,
              isUpgradeSuccessful: true,
            },
          })
        }
      }
    }
  }

  const containerClasses = classNames(styling['promo-code-form-container'], className && className)
  const formClasses = classNames(
    isDarkTheme && styling['is-dark-theme']
  )
  const inputClasses = classNames(hasSuccessfulPromoCode && styling['has-valid-promo-code'])

  return (
    <div className={containerClasses}>
      <Formik
        validationSchema={validationSchema}
        initialValues={{ promoCode: '' }}
        onSubmit={handleSubmit}
        validateOnBlur={false}
      >
        {({ errors, touched, isSubmitting, values, handleChange, handleBlur }) => {
          const message = errors.promoCode || promoCodeMessage

          const promoCodeTextClasses = classNames(styling['promo-code-text'], {
            [styling['is-error']]: hasInvalidPromoCode || errors.promoCode,
            [styling['is-success']]: hasSuccessfulPromoCode,
          })

          return (
            <Form data-cy='stripe-promo-code-form' className={formClasses}>
              <div className='promo-code-input-container'>
                <Field
                  as={FormInput}
                  name='promoCode'
                  label='Promotional Code'
                  invalid={(errors.promoCode && touched.promoCode) || !!hasInvalidPromoCode}
                  placeholder='ABC123'
                  errorText=''
                  className={inputClasses}
                  maxLength={20}
                  autoComplete='off'
                  onChange={e => {
                    handleChange(e)
                    setPromoCodeType('')
                  }}
                  onBlur={handleBlur}
                />
                {message && (
                  <span className={promoCodeTextClasses}>
                    {messageIcon}
                    {message}
                  </span>
                )}
              </div>
              <Button
                type='submit'
                isLoading={isSubmitting}
                outline
                disabled={!values?.promoCode?.length || isSubmitting || !!promoCodeType}
                styleType='primary'
              >
                Apply Code
              </Button>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

PromoCodeForm.propTypes = {
  /** Boolean value for whether or not the PromoCodeForm should have the dark theme styles applied. */
  isDarkTheme: PropTypes.bool,
  /** Function to update the payment session details. The new paymentDetails object should be passed as the singular parameter. */
  setPaymentSession: PropTypes.func.isRequired,
}

export default PromoCodeForm
