import React, { useEffect, useState, useMemo } from 'react'
import { useMediaQuery } from 'react-responsive'
import { isFunction } from 'lodash'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styling from './table.module.scss'
import {
  getColumnTracks,
  getDataValue,
  isFirstOfRow,
  isLastOfRow,
  isInLastRow,
  isInFirstRow,
  calculateGridColumnLayout,
  orderColumns,
  getNumberOfPages,
} from './tableUtils.js'
import TablePagination, { paginationConfig } from './TablePagination'

import { MAX_WIDTH_BREAKPOINTS } from '@shared/constants/uiConstants'
import LoadingContainer from '../loadingContainer/LoadingContainer'

const Table = ({
  className,
  groupBy,
  repeatHeaderForGroups = true,
  mobileMaxBreakpoint = MAX_WIDTH_BREAKPOINTS.MEDIUM,
  groupHeaderClassName,
  onDark = false,
  columns: columnDefinitions,
  data,
  pagination,
  onPageClick,
  listEndMessage,
  noRecordsMessage,
  row: { onClick: onRowClick, clickable: rowClickable = true } = {},
  noLabels = false,
  loading = false,
}) => {
  const isGrouped = !!groupBy

  const [dataGroups, setDataGroups] = useState(isGrouped ? groupBy(data) : { data })
  const [focusedRow, setFocusedRow] = useState({ groupIndex: null, rowIndex: null })
  const [hoveredRow, setHoveredRow] = useState({ groupIndex: null, rowIndex: null })

  useEffect(() => {
    setDataGroups(isGrouped ? groupBy(data) : { data })
  }, [data, groupBy, isGrouped])

  const isMobile = useMediaQuery({ query: `(max-width: ${mobileMaxBreakpoint}px)` })

  const columns = useMemo(() => (isMobile ? orderColumns(columnDefinitions) : columnDefinitions), [
    isMobile,
    columnDefinitions,
  ])

  const layout = useMemo(() => calculateGridColumnLayout({ columns, isMobile }), [
    columns,
    isMobile,
  ])

  const { currentPage, totalRecords, pageSize } = pagination || {}

  // Only show pagination if the number of records is larger than the page size
  const showPagination = totalRecords > pageSize

  /* Only show the "no records" message when there are no paginated records, or
     there are no records for non-paginated results */
  const showNoRecordsMessage =
    noRecordsMessage && (totalRecords === 0 || (!pagination && data?.length === 0))

  const showListEndMessage =
    listEndMessage &&
    !showNoRecordsMessage &&
    (!pagination ||
      (totalRecords > 0 && getNumberOfPages({ totalRecords, pageSize }) === currentPage))

  const onRowKeyPress = (event, dataRow) => {
    /* Adds keyboard accessibility:
       https://www.w3.org/WAI/GL/wiki/Making_actions_keyboard_accessible_by_using_keyboard_event_handlers_with_WAI-ARIA_controls
    */
    if (event.key === 'Enter') {
      onRowClick(dataRow)
    }
  }

  const dataGroupEntries = Object.entries(dataGroups)

  return (
    <div className={className}>
      <LoadingContainer loading={loading}>
        {dataGroupEntries.map((groupData, groupIndex) => {
          const [groupHeader, dataRows] = groupData

          const showTableHeader =
            groupIndex === 0 || (isGrouped && groupIndex > 0 && repeatHeaderForGroups)

          return (
            <div
              className={classNames(styling.group, {
                [styling['on-dark']]: onDark,
                [styling.mobile]: isMobile,
              })}
              key={`group-container_${groupIndex}`}
            >
              {/* Group header */}
              {isGrouped && (
                <div className={classNames(styling['group-header'], groupHeaderClassName)}>
                  {groupHeader}
                </div>
              )}

              {/* Table */}
              <div className={styling.table} style={getColumnTracks({ columns, isMobile })}>
                {/* Table headers */}
                {columns.map((column, columnIndex) => {
                  const { labelClassName, label } = column

                  return (
                    showTableHeader &&
                    !noLabels && (
                      <div
                        className={classNames(
                          styling.header,
                          {
                            [styling.hidden]: isMobile,
                            [styling['first-of-row']]: isFirstOfRow({ layout, columnIndex }),
                            [styling['last-of-row']]: isLastOfRow({ layout, columnIndex }),
                          },
                          isFunction(labelClassName) ? labelClassName(label) : labelClassName
                        )}
                        key={`column-header_${columnIndex}`}
                      >
                        {label}
                      </div>
                    )
                  )
                })}

                {/* Table data cells */}
                {dataRows.map((dataRow, rowIndex) =>
                  columns.map((column, columnIndex) => {
                    const {
                      dataFormat,
                      dataKey,
                      hideOnMobile = false,
                      dataClassName,
                      mobileDataClassName,
                      visible = true,
                      mobileColumnSpan = 1,
                    } = column

                    const { preformattedValue, formattedValue } = getDataValue({
                      dataFormat,
                      dataRow,
                      dataKey,
                    })

                    const classNameToUse = isMobile
                      ? mobileDataClassName || dataClassName
                      : dataClassName

                    const _dataClassName = isFunction(classNameToUse)
                      ? classNameToUse({ preformattedValue, formattedValue })
                      : classNameToUse

                    const _clickable =
                      (isFunction(rowClickable) ? rowClickable(dataRow) : rowClickable) &&
                      !!onRowClick

                    const highlightedRow = focusedRow.rowIndex !== null ? focusedRow : hoveredRow

                    const _isFirstOfRow = isFirstOfRow({ layout, columnIndex })
                    const _isInFirstRow = isInFirstRow({ layout, columnIndex })

                    return (
                      visible && (
                        <div
                          role={_clickable ? 'button' : undefined}
                          onClick={() => {
                            _clickable && onRowClick && onRowClick(dataRow)
                          }}
                          onKeyPress={event => {
                            _clickable && onRowClick && onRowKeyPress(event, dataRow)
                          }}
                          onMouseOver={() => _clickable && setHoveredRow({ rowIndex, groupIndex })}
                          onMouseOut={() => setHoveredRow({ rowIndex: null, groupIndex: null })}
                          onFocus={() => _clickable && setFocusedRow({ rowIndex, groupIndex })}
                          onBlur={() => setFocusedRow({ rowIndex: null, groupIndex: null })}
                          className={classNames(
                            styling.cell,
                            {
                              clickable: _clickable,
                              plain: _clickable,
                              [styling.highlight]:
                                highlightedRow.rowIndex === rowIndex &&
                                highlightedRow.groupIndex === groupIndex,
                              [styling.hidden]: hideOnMobile && isMobile,
                              [styling['first-of-row']]: _isFirstOfRow,
                              [styling['last-of-row']]: isLastOfRow({ layout, columnIndex }),
                              [styling['top-of-row']]: _isInFirstRow,
                              [styling['bottom-of-row']]: isInLastRow({ layout, columnIndex }),
                            },
                            _dataClassName
                          )}
                          key={`cell_${rowIndex}_${columnIndex}`}
                          style={{
                            gridColumnStart:
                              isMobile && mobileColumnSpan > 1 ? `span ${mobileColumnSpan}` : '',
                          }}
                          tabIndex={_clickable && _isFirstOfRow && _isInFirstRow ? 0 : -1}
                        >
                          {formattedValue}
                        </div>
                      )
                    )
                  })
                )}
              </div>

              {// Displays the listEndMessage when the table is not grouped
              !isGrouped && showListEndMessage && (
                <div className={styling['list-end-message']}>{listEndMessage}</div>
              )}
              {// Displays the noRecordsMessage when the table is not grouped
              !isGrouped && showNoRecordsMessage && (
                <div className={styling['list-end-message']}>{noRecordsMessage}</div>
              )}
            </div>
          )
        })}
        {// Displays the listEndMessage when the table is grouped
        isGrouped && showListEndMessage && (
          <div className={styling['list-end-message']}>{listEndMessage}</div>
        )}
        {// Displays the showNoRecordsMessage when the table is grouped
        isGrouped && showNoRecordsMessage && (
          <div className={styling['list-end-message']}>{noRecordsMessage}</div>
        )}
        {pagination && showPagination && (
          <TablePagination
            className={styling.pagination}
            pagination={pagination}
            onPageClick={onPageClick}
            isMobile={isMobile}
            onDark={onDark}
          />
        )}
      </LoadingContainer>
    </div>
  )
}

Table.propTypes = {
  /**
   * Additional classes to pass to the container
   */
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

  /**
   * If data needs to be displayed as groups, supply a callback to group the data.
   */
  groupBy: PropTypes.func,

  /**
   * Additional classes to pass to the group header
   */
  groupHeaderClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

  /**
   * Repeats the header row for each group (default true, only shown on desktop view)
   */
  repeatHeaderForGroups: PropTypes.bool,

  /**
   * The mobile max breakpoint (default 991px)
   */
  mobileMaxBreakpoint: PropTypes.number,

  /**
   * Changes style when on a dark vs light background (default false)
   */
  onDark: PropTypes.bool,

  /**
   * An array of column definitions (required)
   */
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      // The header title/node to render (only shown on desktop view)
      label: PropTypes.node.isRequired,

      // The data key path
      dataKey: PropTypes.string.isRequired,

      /* Custom data value format callback. The data value and the entire row is passed
         to this callback, then the callback must return a renderable node to display */
      dataFormat: PropTypes.func,

      // Hide this column on mobile view (default false)
      hideOnMobile: PropTypes.bool,

      /* Additional classes to pass to the header fields. If a function is passed, it will be
         invoked with a parameter containing the header contents */
      labelClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]),

      /* Additional classes to pass to the data fields. If a function is passed, it will be
         invoked with a parameter containing preformattedValue and formattedValue object keys */
      dataClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]),

      // If mobile style should be different, pass styles to this prop
      mobileDataClassName: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.func,
      ]),

      /* Whether or not this column is displayed at all (default true). Normally, you
         only want to define a column for rendered data, but it may be useful to hide
         some data from view so it can be used in callbacks (e.g. a calculated 'format') */
      visible: PropTypes.bool,

      // CSS Grid track-size unit for this column (default 'auto', only applies to destkop view)
      trackSize: PropTypes.string,

      // Number of visual columns this column should span in mobile view (default 1)
      mobileColumnSpan: PropTypes.number,

      /* Used to re-order the fields in mobile view. The numbered columns will be ordered first,
         then the non-numbered columns will be ordered as they are defined */
      mobileColumnOrder: PropTypes.number,
    }).isRequired
  ),

  /**
   * Row configuration
   */
  row: PropTypes.shape({
    /* Controls whether the row is clickable (default true). Only applies a 'clickable' style if
       the onRowClick function is passed as well. If passed a function, the parameter will be
       the current row, and expects a boolean return value. */
    clickable: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),

    // Callback to execute when row is clicked. The row value is included as a parameter
    onClick: PropTypes.func,
  }),

  /**
   * The data set to display, passed as an array of objects. Use 'dataKey' prop to
   * define automatic traversal of the object structure
   */
  data: PropTypes.arrayOf(PropTypes.object),

  // Describes the pagination information
  pagination: paginationConfig,

  /* Callback to execute when a page was clicked (when pagination is available). The clicked page
     number is passed to the callback */
  onPageClick: PropTypes.func,

  /**
   * Message to display when there are no more records (when at the end of pagination, or no
   * pagination is present)
   */
  listEndMessage: PropTypes.node,

  /**
   * Message to display when no records exist
   */
  noRecordsMessage: PropTypes.node,

  /**
   * Never display header labels (default false)
   */
  noLabels: PropTypes.bool,
}

export default Table
