import React, { useEffect } from 'react'
import classNames from 'classnames'
import { useNavigate, useLocation } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { gql, useMutation } from '@services/serviceUtils'
import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'
import Button from '@shared/components/button/Button'
import Atomic from '@common/components/atomic/Atomic'
import ExternalLink from '@common/components/link/ExternalLink'
import CopyAccountNumbers from '@common/components/copy/CopyAccountNumbers'
import {
  BOOTSTRAP_SITEMAP_RESOURCES,
  getBootstrapSitemapResource,
  getDirectDepositFlowCompletedRoute,
  trackPage,
  trackEvent,
  SEGMENT_PAGE_NAMES,
  SEGMENT_EVENTS,
  SEGMENT_SOURCE_DETAILS,
} from '@common/utils'

import { staticRoutes } from '@routing/routes'
import { FUNDING_FLOWS } from '@common/constants'
import useFundingFlowRouteGuard from '@common/utils/useFundingFlowRouteGuard'
import Banner from '@common/components/banner/Banner'
import DirectDepositContestRulesLink from '@common/components/DirectDepositContestRulesLink'

import styling from './directDepositSelection.module.scss'

const emailDirectDepositFormMutation = gql`
  mutation EmailDirectDepositForm {
    emailDirectDepositForm {
      email
    }
  }
`

const { url: directDepositFaqLink } = getBootstrapSitemapResource(
  BOOTSTRAP_SITEMAP_RESOURCES.FAQ_DIRECT_DEPOSIT
)

const REQUIRED_FUNDING_FLOWS = [
  FUNDING_FLOWS.BASIC,
  FUNDING_FLOWS.ONBOARDING_PREMIUM,
  FUNDING_FLOWS.DIRECT_DEPOSIT,
]

const bannerText = 'Sign up to WIN $2,500!'
const promotionDateText = 'Promotion dates from June 20, 2022 to July 29, 2022.'

const DirectDepositSelection = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const { campaignDirectDepositSweepstakes } = useFlags()

  useFundingFlowRouteGuard({
    requiredFundingFlows: REQUIRED_FUNDING_FLOWS,
    redirectWithReplace: true,
  })

  const [emailDirectDepositForm, { loading: emailDirectDepositFormLoading }] = useMutation(
    emailDirectDepositFormMutation,
    {
      onError: () => {
        navigate(staticRoutes.directDepositFormSendFailed.pathname, {
          state: {
            fundingFlow: state?.fundingFlow,
          },
        })
      },
    }
  )

  const handleSendClick = async () => {
    const response = await emailDirectDepositForm()

    if (response) {
      const { data: emailDirectDepositFormData } = response

      if (emailDirectDepositFormData) {
        navigate(staticRoutes.directDepositFormSent.pathname, {
          state: {
            sentToEmail: emailDirectDepositFormData.emailDirectDepositForm?.email,
            fundingFlow: state?.fundingFlow,
          },
        })
      }
    }
    trackEvent({
      event: SEGMENT_EVENTS.MOVE_MONEY_DIRECT_DEPOSIT_BUTTON_CLICK(
        SEGMENT_SOURCE_DETAILS.SEND_DIRECT_DEPOSIT_FORM
      ),
    })
  }

  const goToCompletedRoute = () => {
    navigate(getDirectDepositFlowCompletedRoute({ fundingFlow: state?.fundingFlow }))
  }

  // Tracks page visit
  useEffect(() => {
    trackPage({ name: SEGMENT_PAGE_NAMES.MOVE_MONEY_DIRECT_DEPOSIT })
  }, [])

  return (
    <div
      className={classNames('white-card-container', styling['direct-deposit-selection'], {
        [styling['with-promotion']]: campaignDirectDepositSweepstakes,
      })}
    >
      <GreenwoodLogo className='logo' />
      <h1>Direct Deposit</h1>
      {campaignDirectDepositSweepstakes && (
        <div className={styling['direct-deposit-promotion']}>
          <Banner text={bannerText} showConfetti className={styling.banner} />
          <DirectDepositContestRulesLink className={styling['promotion-link']} />
        </div>
      )}
      <div className={styling.card}>
        <h3>Do it yourself</h3>
        <p>Send over the below info or let us send you a form to send to your employer.</p>
        <CopyAccountNumbers
          className={styling['medium-margin']}
          loadingContainerClassName={styling['loading-container']}
          accountsQueryOptions={{ fetchPolicy: 'no-cache' }}
          accountNumberCopyProps={{
            onCopyCallback: () => {
              trackEvent({
                event: SEGMENT_EVENTS.MOVE_MONEY_DIRECT_DEPOSIT_BUTTON_CLICK(
                  SEGMENT_SOURCE_DETAILS.COPY_ACCOUNT_NUMBER
                ),
              })
            },
            onShowHideToggleCallback: () => {
              trackEvent({
                event: SEGMENT_EVENTS.MOVE_MONEY_DIRECT_DEPOSIT_BUTTON_CLICK(
                  SEGMENT_SOURCE_DETAILS.MASK_ACCOUNT_NUMBER
                ),
              })
            },
          }}
          routingNumberCopyProps={{
            onCopyCallback: () => {
              trackEvent({
                event: SEGMENT_EVENTS.MOVE_MONEY_DIRECT_DEPOSIT_BUTTON_CLICK(
                  SEGMENT_SOURCE_DETAILS.COPY_ROUTING_NUMBER
                ),
              })
            },
          }}
        />
        <Button
          onClick={handleSendClick}
          isLoading={emailDirectDepositFormLoading}
          data-cy='send-direct-deposit-form-button'
        >
          Send Direct Deposit Form
        </Button>
      </div>
      <div className={styling.card}>
        <h3>We'll do it for you</h3>
        <p>Find your employer/payroll provider and let us take care of the rest.</p>
        <Atomic
          buttonContent='Find your Payroll Provider'
          className={styling['atomic-button']}
          onFinish={goToCompletedRoute}
          data-cy='find-payroll-provider-button'
          onClickCallback={() => {
            trackEvent({
              event: SEGMENT_EVENTS.MOVE_MONEY_DIRECT_DEPOSIT_BUTTON_CLICK(
                SEGMENT_SOURCE_DETAILS.FIND_YOUR_PAYROLL_PROVIDER
              ),
            })
          }}
        />
      </div>
      <Button ghost onClick={goToCompletedRoute} data-cy='direct-deposit-selection-cancel-button'>
        Cancel
      </Button>
      <p className='help-text'>
        {campaignDirectDepositSweepstakes && (
          <>
            {promotionDateText}
            <br />
            <br />
          </>
        )}
        Greenwood partners with Atomic to setup or switch direct deposit.{' '}
        <ExternalLink className='underlined-link bold' to={directDepositFaqLink}>
          Learn More
        </ExternalLink>
        .
        {!campaignDirectDepositSweepstakes && (
          <>
            <br />
            <br />
          </>
        )}
        This service only allows access to update your direct deposit to your Greenwood account. We
        never allow access to income, paystubs, timesheets, name, address, and contact info. Our
        information security plan is built to meet or exceed industry standards on account security.
        We use AES-256-CBC. AES is considered the gold-standard for encryption, is NIST certified,
        and is what the government uses to encrypt secure data. We only support the latest, and
        strictest form of Transport Layer Security (TLS) to secure network communications. All of
        our critical systems use Multi-Factor Authentication (MFA) to keep intruders out. Security
        researchers regularly audit our security controls against industry standards to help
        maintain our security plan.
      </p>
    </div>
  )
}

export default DirectDepositSelection
