// Formik doesn't support passing options when using a validation schema.
// This workaround allows options to be passed. The critical part here is
// the abortEarly prop. Turning this off allows for all errors to be returned,
// not just the first one.
// Source: https://github.com/formium/formik/issues/1864#issuecomment-762514479
export const fullValidatorForSchema = schema => values =>
  schema
    .validate(values, {
      abortEarly: false,
      strict: false,
    })
    .then(() => ({}))
    .catch(({ inner }) =>
      inner.reduce(
        (memo, { path, message }) => ({
          ...memo,
          [path]: (memo[path] || []).concat(message),
        }),
        {}
      )
    )
