import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { ReactComponent as Chevron } from '@shared/images/icons/chevron.svg'

import styling from './tablePagination.module.scss'

import { getNumberOfPages, getVisiblePages } from './tableUtils'

const TablePagination = ({
  className,
  pagination: { currentPage, totalRecords, pageSize } = {},
  onPageClick,
  isMobile = false,
  onDark = false,
  loading = false,
}) => {
  const numberOfPages = getNumberOfPages({ totalRecords, pageSize })

  const visiblePages = useMemo(
    () => getVisiblePages({ currentPage, totalRecords, pageSize, isMobile }),
    [currentPage, totalRecords, pageSize, isMobile]
  )

  // Builds page list data required to render
  const pageList = visiblePages.map(visiblePage => ({
    pageNumber: visiblePage,
    onClick: () => onPageClick(visiblePage),
    isActive: currentPage === visiblePage,
    isPlaceholder: visiblePage === '...',
  }))

  const handlePrevPageClick = () => onPageClick(currentPage - 1)
  const handleNextPageClick = () => onPageClick(currentPage + 1)

  return (
    <div className={classNames(styling.container, { [styling['on-dark']]: onDark }, className)}>
      <button
        className={classNames('plain', styling['prev-button'], {
          clickable: currentPage > 1,
          [styling.mobile]: isMobile,
          [styling.loading]: loading,
        })}
        disabled={currentPage <= 1 || loading}
        onClick={handlePrevPageClick}
      >
        <Chevron className={styling['prev-chevron']} />
        {!isMobile && 'PREV'}
      </button>
      <div className={styling['page-list']}>
        {pageList.map((pageListItem, pageIndex) => {
          const { pageNumber, isActive, onClick, isPlaceholder } = pageListItem
          const enabled = currentPage !== pageNumber && !isPlaceholder

          return (
            <button
              key={pageIndex}
              className={classNames('plain', styling.page, {
                [styling.active]: isActive,
                [styling.loading]: loading,
                clickable: enabled && !isActive,
              })}
              disabled={!enabled || loading}
              onClick={onClick}
            >
              {pageNumber}
            </button>
          )
        })}
      </div>
      <button
        className={classNames('plain', styling['next-button'], {
          clickable: currentPage < numberOfPages,
          [styling.mobile]: isMobile,
          [styling.loading]: loading,
        })}
        disabled={currentPage >= numberOfPages || loading}
        onClick={handleNextPageClick}
      >
        {!isMobile && 'NEXT'}
        <Chevron className={styling['next-chevron']} />
      </button>
    </div>
  )
}

export const paginationConfig = PropTypes.shape({
  // Current page number
  currentPage: PropTypes.number.isRequired,

  // Total number of records
  totalRecords: PropTypes.number.isRequired,

  // Number of records displayed per page
  pageSize: PropTypes.number.isRequired,

  // Changes style when on a dark vs light background (default false)
  onDark: PropTypes.bool,
})

TablePagination.propTypes = {
  // Additional classes to pass
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

  // Pagination data
  pagination: paginationConfig.isRequired,

  // Callback executed when page number is clicked
  onPageClick: PropTypes.func.isRequired,

  // Whether or not the component is displayed as mobile
  isMobile: PropTypes.bool,
}

export default TablePagination
