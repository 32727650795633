import {
  SET_DEBIT_CARD_DEPOSIT_DETAILS,
  RESET_DEBIT_CARD_DEPOSIT_DETAILS,
  SET_DEBIT_CARD_DEPOSIT_ATTEMPT_NUMBER,
} from './debitCardDepositDetailsActions'

export const debitCardDepositDetailsReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case SET_DEBIT_CARD_DEPOSIT_DETAILS:
      return action.payload
    case RESET_DEBIT_CARD_DEPOSIT_DETAILS:
      return {}
    default:
      return state
  }
}

export const debitCardDepositAttemptNumberReducer = (state = 1, action = {}) => {
  switch (action.type) {
    case SET_DEBIT_CARD_DEPOSIT_ATTEMPT_NUMBER:
      return action.payload
    default:
      return state
  }
}
