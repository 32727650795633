import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import classNames from 'classnames'

import Button from '@shared/components/button/Button'
import CelebrationView from '@common/components/celebrationView/CelebrationView'
import {
  getDirectDepositFlowCompletedRoute,
  trackPage,
  SEGMENT_PAGE_NAMES,
  SEGMENT_EVENTS,
  trackEvent,
} from '@common/utils'
import { FUNDING_FLOWS } from '@common/constants'
import useFundingFlowRouteGuard from '@common/utils/useFundingFlowRouteGuard'

import styling from './directDepositFormSent.module.scss'

const REQUIRED_FUNDING_FLOWS = [
  FUNDING_FLOWS.BASIC,
  FUNDING_FLOWS.ONBOARDING_PREMIUM,
  FUNDING_FLOWS.DIRECT_DEPOSIT,
]

const DirectDepositFormSent = () => {
  const navigate = useNavigate()
  const { state } = useLocation()

  useFundingFlowRouteGuard({
    requiredFundingFlows: REQUIRED_FUNDING_FLOWS,
    redirectWithReplace: true,
  })

  // Tracks page visit
  useEffect(() => {
    trackPage({ name: SEGMENT_PAGE_NAMES.MOVE_MONEY_DIRECT_DEPOSIT_FORM_SENT })
  }, [])

  return (
    <CelebrationView className={styling['direct-deposit-form-sent']}>
      <div className={classNames('create-account-content-wrapper', styling['content-wrapper'])}>
        <h1>We've Emailed Your Completed Direct Deposit Form</h1>
        <p>
          Your completed direct deposit form was sent to{' '}
          <strong>{state?.sentToEmail || 'your email'}</strong>.
          <br />
          Send the form to your employer or HR provider!
        </p>

        <div className='buttons-container'>
          <Button
            className='done-button'
            onClick={() => {
              navigate(getDirectDepositFlowCompletedRoute({ fundingFlow: state?.fundingFlow }))
              trackEvent({ event: SEGMENT_EVENTS.MOVE_MONEY_DIRECT_DEPOSIT_FORM_SENT_DONE_CLICK })
            }}
            data-cy='form-sent-done-button'
            onDark
          >
            Done
          </Button>
        </div>
      </div>
    </CelebrationView>
  )
}

export default DirectDepositFormSent
