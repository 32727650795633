import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'

import FlowError from 'src/views/flowError/FlowError'
import { staticRoutes } from '@routing/routes'
import { resetTransferDetailsAction } from '@redux/transferDetails/transferDetailsActions'
import { FUNDING_FLOWS } from '@common/constants'
import useTransferRouteGuard from '@common/utils/useTransferRouteGuard'

const TransferFailed = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const dispatch = useDispatch()

  const isTransfer = state?.fundingFlow === FUNDING_FLOWS.TRANSFER
  const shouldSetupDirectDeposit = state?.shouldSetupDirectDeposit

  useTransferRouteGuard({ fundingFlow: state?.fundingFlow })

  const header = isTransfer ? 'Transfer Failed' : 'Payment Failed'
  const message = isTransfer
    ? "Uh oh, something went wrong. We aren't able to process the transfer at the moment."
    : "We aren't able to process your payment with your account at this time. Please try using a different payment method."
  const retryButtonText = isTransfer ? 'Retry Transfer' : 'Use Another Account'

  const handleRetryClick = () => {
    if (isTransfer) {
      navigate(staticRoutes.transfer.pathname, { state })
    } else if (
      [FUNDING_FLOWS.PREMIUM, FUNDING_FLOWS.ONBOARDING_PREMIUM].includes(state?.fundingFlow)
    ) {
      dispatch(resetTransferDetailsAction())
      navigate(staticRoutes.premiumPayment.pathname, {
        state: {
          fundingFlow: state?.fundingFlow,
        },
      })
    } else {
      dispatch(resetTransferDetailsAction())
      navigate(staticRoutes.accountsForFunding.pathname, {
        state: {
          fundingFlow: state?.fundingFlow,
        },
      })
    }
  }

  const handleDoneClick = () => {
    dispatch(resetTransferDetailsAction())

    if (isTransfer) {
      navigate(state?.transferFlowStartPath || staticRoutes.moveMoneyAddMoney.pathname)
    } else if (state?.fundingFlow === FUNDING_FLOWS.ONBOARDING_PREMIUM) {
      navigate(staticRoutes.addMoney.pathname, {
        state: {
          fundingFlow: FUNDING_FLOWS.BASIC,
          shouldSetupDirectDeposit,
        },
      })
    } else if (shouldSetupDirectDeposit) {
      navigate(staticRoutes.directDepositSetup.pathname, {
        state: {
          fundingFlow: state?.fundingFlow,
        },
      })
    } else {
      navigate(staticRoutes.dashboard.pathname)
    }
  }

  return (
    <FlowError
      header={header}
      message={message}
      onPrimaryButtonClick={handleRetryClick}
      primaryButtonContents={retryButtonText}
      onSecondaryButtonClick={handleDoneClick}
      secondaryButtonContents='Done'
      data-cy='transfer-failed-view'
    />
  )
}

export default TransferFailed
