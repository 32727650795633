import React, {
  useState,
  useEffect,
  useRef,
  createRef,
  forwardRef,
  useImperativeHandle,
} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import ObscuredMaskedInput, { maskConfigPartPropType } from './ObscuredMaskedInput'

import styling from './obscuredMaskedInputGroup.module.scss'

// Break the value down into individual (optionally obscured) parts matching
// the mask config
const getValues = (stringValue, maskConfig) => {
  let valueStartIndex = 0

  return maskConfig.parts.map(part => {
    if (typeof part !== 'string') {
      const valuePart = stringValue.substr(valueStartIndex, part.maxLength)
      valueStartIndex += part.maxLength
      return valuePart
    } else {
      return part
    }
  })
}

// Convert the non-masked value parts into a whole string value
const getStringValue = (values, maskConfig) => {
  let value = ''

  maskConfig.parts.forEach((part, partIndex) => {
    // Ignore delimiters
    if (typeof part !== 'string') {
      value += values[partIndex]
    }
  })

  return value
}

// Finds the index of the next input in mask config relative to the passed index
const findNextInputIndex = ({ startIndex, maskConfig }) => {
  for (let index = startIndex; index < maskConfig.parts.length; index++) {
    if (typeof maskConfig.parts[index] !== 'string') {
      return index
    }
  }

  // Not found
  return -1
}

// Finds the index of the previous input in mask config relative to the passed index
const findPreviousInputIndex = ({ startIndex, maskConfig }) => {
  for (let index = startIndex; index >= 0; index--) {
    if (typeof maskConfig.parts[index] !== 'string') {
      return index
    }
  }

  // Not found
  return -1
}

const ObscuredMaskedInputGroup = (
  {
    value,
    onChange,
    onBlur,
    maskConfig = {},
    className,
    disabled = false,
    show = false,
    name,
    id,
    innerRef,
    dataCy = 'obscured-masked-input',
    inputMode = 'numeric',
    autoFocus = false,
    ...rest
  },
  ref
) => {
  const [values, setValues] = useState(getValues(value, maskConfig))
  const [triggerOnBlur, setTriggerOnBlur] = useState(false)
  const [triggerOnBlurTimeout, setTriggerOnBlurTimeout] = useState(null)

  const partRefs = useRef(maskConfig.parts?.map(createRef))

  const updateValues = newValues => {
    setValues(newValues)
  }

  /* Inserts a 'clear()' function into the ref of this component, so any
     parent component can invoke it to clear the value of this component */
  useImperativeHandle(ref, () => ({
    clear: () => {
      updateValues(getValues('', maskConfig))
    },
  }))

  // Update the values if the mask was toggled
  useEffect(() => {
    updateValues(values)
  }, [show])

  // Updates the value if it changes externally
  useEffect(() => {
    if (value !== getStringValue(values, maskConfig)) {
      updateValues(getValues(value, maskConfig))
    }
  }, [value])

  useEffect(() => {
    if (triggerOnBlur) {
      onBlur && onBlur()
    }
  }, [triggerOnBlur])

  // When the internal value changes, call the onChange callback
  useEffect(() => {
    onChange(getStringValue(values, maskConfig))
  }, [values])

  // If autoFocus property is set to true, then focus on the first input field in the component
  useEffect(() => {
    const partRef = partRefs.current[0].current

    if (autoFocus && partRef) {
      partRef.focus()
    }
  }, [autoFocus, partRefs.current[0].current])

  const handleContainerClick = () => {
    // Focus the first input
    for (const index = 0; index < partRefs.current.length; index++) {
      const partRef = partRefs.current[index].current
      if (partRef.nodeName === 'INPUT') {
        partRef.focus()
        return
      }
    }
  }

  const handleDelimiterClick = (event, partIndex) => {
    // Prevent click propagating through to the container
    event.stopPropagation()

    // Focus the next input
    for (const index = partIndex + 1; index < partRefs.current.length; index++) {
      const partRef = partRefs.current[index].current

      if (partRef.nodeName === 'INPUT') {
        partRef.focus()
        return
      }
    }
  }

  const handleInputPartChange = ({ value, index }) => {
    // Shallow clone the existing values
    const newValues = [...values]
    newValues[index] = value

    setValues(newValues)
  }

  const handleBlur = () => {
    /* Causes this to be called one render later, so that
       it can be cancelled if another field is taking focus */
    setTriggerOnBlurTimeout(
      setTimeout(() => {
        setTriggerOnBlur(true)
      }, 0)
    )
  }

  const handleFocus = () => {
    clearTimeout(triggerOnBlurTimeout)
    setTriggerOnBlurTimeout(null)
    setTriggerOnBlur(false)
  }

  return (
    <div
      className={classNames(styling.container, className, 'obscured-masked-input')}
      ref={innerRef}
      name={name}
      id={id}
      onClick={handleContainerClick}
      onFocus={handleFocus}
      onBlur={handleBlur}
      {...rest}
    >
      {maskConfig.parts?.map((part, index) => {
        if (typeof part === 'string') {
          return (
            <span
              className={styling.delimiter}
              key={index}
              ref={partRefs.current[index]}
              onClick={event => handleDelimiterClick(event, index)}
              data-cy={`${dataCy}-part-${index}`}
            >
              {values[index]}
            </span>
          )
        } else {
          const previousInputIndex = findPreviousInputIndex({ startIndex: index - 1, maskConfig })
          const previousInputRef =
            previousInputIndex > -1 ? partRefs.current[previousInputIndex] : undefined

          const nextInputIndex = findNextInputIndex({ startIndex: index + 1, maskConfig })
          const nextInputRef = nextInputIndex > -1 ? partRefs.current[nextInputIndex] : undefined

          return (
            <ObscuredMaskedInput
              onChange={value => handleInputPartChange({ value, index })}
              value={values[index]}
              show={show}
              disabled={disabled}
              maskConfigPart={part}
              previousInputRef={previousInputRef}
              ref={partRefs.current[index]}
              nextInputRef={nextInputRef}
              className={styling['input-part']}
              key={index}
              name={name && `${name}_${index}`}
              id={id && `${id}_${index}`}
              data-cy={`${dataCy}-part-${index}`}
              inputMode={inputMode}
            />
          )
        }
      })}
    </div>
  )
}

const RefWrappedObscuredMaskedInput = forwardRef(ObscuredMaskedInputGroup)

RefWrappedObscuredMaskedInput.propTypes = {
  /**
   * Un-obscures the input value
   */
  show: PropTypes.bool,

  /**
   * Disables user interaction with the component
   */
  disabled: PropTypes.bool,

  /**
   * Each underlying input has a 'data-cy' attribute consisting of this
   * value followed by '-part-' then the part index, used for cypress
   * test targeting. The name will look like `${dataCy}-part-${index}`
   *
   * Default prop value is 'obscured-masked-input'
   */
  dataCy: PropTypes.string,

  /**
   * Callback to execute when the overall component is blurred. The
   * included blur event parameter is the synthetic event produced by
   * the internal input
   */
  onBlur: PropTypes.func,

  /**
   * Configures the masking and obscuring characteristics
   */
  maskConfig: PropTypes.shape({
    parts: PropTypes.arrayOf(maskConfigPartPropType),
  }),
}

export default RefWrappedObscuredMaskedInput
