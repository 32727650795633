import {
  SET_APPLICATION,
  POLLING_HAS_MAXED_OUT,
  SAVE_LOCATION_STATE,
  RESET_LOCATION_STATE,
  SET_ONBOARDING_FLOW_TYPE,
} from './applicationActions'

const initialState = {}

export const applicationReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case POLLING_HAS_MAXED_OUT:
    case SET_APPLICATION: {
      return {
        ...state,
        ...action.payload,
      }
    }
    default:
      return state
  }
}

export const onboardingTypeReducer = (state = '', action = {}) => {
  switch (action.type) {
    case SET_ONBOARDING_FLOW_TYPE:
      return action.payload
    default:
      return state
  }
}

export const savedLocationStateReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SAVE_LOCATION_STATE:
      return action.payload || state
    case RESET_LOCATION_STATE:
      return initialState
    default:
      return state
  }
}
