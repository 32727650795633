import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { staticRoutes } from '@routing/routes'
import { FUNDING_FLOWS } from '@common/constants'

const useTransferRouteGuard = ({ fundingFlow }) => {
  const { state } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (!state.transactionId && fundingFlow === FUNDING_FLOWS.TRANSFER) {
      navigate(staticRoutes.transfer.pathname, { state, replace: true })
    }
  }, [navigate, state, fundingFlow])
}

export default useTransferRouteGuard
