import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import Button from '@shared/components/button/Button'
import useFundingFlowRouteGuard from '@common/utils/useFundingFlowRouteGuard'
import { FUNDING_FLOWS, PRODUCT_TYPES } from '@common/constants'
import { staticRoutes } from '@routing/routes'
import { ReactComponent as GreenwoodPlasticCard } from '@common/images/greenwood-plastic-card.svg'
import CelebrationView from '@common/components/celebrationView/CelebrationView'
import useAccountCreatedTracking from '@common/utils/useAccountCreatedTracking'
import usePreventBrowserBackClick from '@common/utils/usePreventBrowserBackClick'

const REQUIRED_FUNDING_FLOWS = [FUNDING_FLOWS.BASIC]

const BasicCardInbound = () => {
  const navigate = useNavigate()
  const { state } = useLocation()

  usePreventBrowserBackClick()

  useFundingFlowRouteGuard({ requiredFundingFlows: REQUIRED_FUNDING_FLOWS })

  useAccountCreatedTracking({ accountType: PRODUCT_TYPES.basic })

  return (
    <CelebrationView>
      <div className='card-inbound-container'>
        <h1>A new Greenwood Debit card is coming to you</h1>
        <p>It will be sent to your permanent address on file.</p>
        <div className='card-image-container'>
          <GreenwoodPlasticCard />
        </div>
        <div className='buttons-container'>
          <Button
            onDark
            onClick={() =>
              navigate(staticRoutes.addMoney.pathname, { state })
            }
            data-cy='basic-card-inbound-done-button'
          >
            Done
          </Button>
        </div>
      </div>
    </CelebrationView>
  )
}

export default BasicCardInbound
