import React, { useEffect } from 'react'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'

import Button from '@shared/components/button/Button'

import CopyAccountNumbers from '@common/components/copy/CopyAccountNumbers'
import {
  SEGMENT_EVENTS,
  SEGMENT_PAGE_NAMES,
  SEGMENT_SOURCE_DETAILS,
  trackEvent,
  trackPage,
} from '@common/utils'
import { staticRoutes } from 'src/routing/routes'

import styling from './payBills.module.scss'

const PayBills = () => {
  const navigate = useNavigate()

  useEffect(() => {
    trackPage({ name: SEGMENT_PAGE_NAMES.PAY_BILLS })
  }, [])

  return (
    <div
      className={classNames('white-card-container', styling['pay-bills-container'])}
      data-cy='pay-bills-view'
    >
      <GreenwoodLogo className='logo' />
      <h1>Pay Bills</h1>
      <p>Select an option to pay your bills.</p>
      <div className={styling.card}>
        <h3>Pay with your card (recommended)</h3>
        <p>
          Nearly all business merchants across the globe allow bill pay with your debit card. Enter
          your card number and pay your bills today!
        </p>
      </div>
      <div className={styling.card}>
        <h3>Pay with your account numbers</h3>
        <p>Copy and enter the below info on the biller's website.</p>
        <CopyAccountNumbers
          className={classNames(styling['copy-account-numbers'])}
          accountNumberCopyProps={{
            showInitially: true,
            onCopyCallback: () => {
              trackEvent({
                event: SEGMENT_EVENTS.PAY_BILLS_BUTTON_CLICK(
                  SEGMENT_SOURCE_DETAILS.COPY_ACCOUNT_NUMBER
                ),
              })
            },
            onShowHideToggleCallback: () => {
              trackEvent({
                event: SEGMENT_EVENTS.PAY_BILLS_BUTTON_CLICK(
                  SEGMENT_SOURCE_DETAILS.MASK_ACCOUNT_NUMBER
                ),
              })
            },
          }}
          routingNumberCopyProps={{
            onCopyCallback: () => {
              trackEvent({
                event: SEGMENT_EVENTS.PAY_BILLS_BUTTON_CLICK(
                  SEGMENT_SOURCE_DETAILS.COPY_ROUTING_NUMBER
                ),
              })
            },
          }}
        />
      </div>
      <Button
        type='submit'
        onClick={() => navigate(staticRoutes.moveMoneySendMoney.pathname)}
        data-cy='pay-bills-view-done-button'
      >
        Done
      </Button>
    </div>
  )
}

export default PayBills
