import React from 'react'
import PropTypes from 'prop-types'

import ExternalLink from '@common/components/link/ExternalLink'
import { BOOTSTRAP_SITEMAP_RESOURCES, getBootstrapSitemapResource } from '@common/utils'

const DirectDepositContestRulesLink = ({ className }) => {
  const contestRules = getBootstrapSitemapResource(
    BOOTSTRAP_SITEMAP_RESOURCES.DIRECT_DEPOSIT_CONTEST_RULES
  )

  return (
    <span className={className}>
      <ExternalLink className='underlined-link bold' to={contestRules?.url || ''}>
        {contestRules?.title?.value || ''}
      </ExternalLink>
      .
    </span>
  )
}

DirectDepositContestRulesLink.propTypes = {
  /**
   * A className string applied to the component's container
   */
  className: PropTypes.string,
}

export default DirectDepositContestRulesLink
