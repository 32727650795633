import React from 'react'
import classNames from 'classnames'
import { Swiper, SwiperSlide } from 'swiper/react'
import { A11y } from 'swiper/modules'

import { ReactComponent as ReviewStarIcon } from '@common/images/icons/review-star.svg'

import 'swiper/scss'
import 'swiper/scss/a11y'

import styling from './reviews.module.scss'

const reviews = [
  {
    color: 'green',
    title: 'Banking made easy',
    numStars: 5,
    subtext:
      "I'm so excited to be a part of this banking system. I love how the app is set up, very user-friendly and straight to the points.",
    reviewer: 'BossLady',
  }, {
    color: 'blue',
    title: 'I love it!',
    numStars: 5,
    subtext: 'Banking with Greenwood is the best decision I ever made.  Their online banking is pretty simple.',
    reviewer: 'Renelebbu',
  }, {
    color: 'pink',
    title: 'Circulate Our Money',
    numStars: 5,
    subtext:
      'Top tier baning in the palm of my hands.  No fees for holding your money and you can earn interest on your savings!',
    reviewer: 'Meeep3',
  }, {
    color: 'purple',
    title: 'Great App!!!!',
    numStars: 5,
    subtext: 'Wonderful App and very user friendly.',
    reviewer: 'The CG Team',
  },
]

const Reviews = () => {
  const getStars = numStars => {
    const stars = []
    let i = 0

    while (i < numStars) {
      stars.push(<ReviewStarIcon key={`star-${i}`} />)
      i++
    }

    return stars
  }

  const getReviews = () => (
    reviews.map((review, index) => {
      const reviewClasses = classNames(styling.review, styling[`color-${review.color}`])

      return (
        <SwiperSlide key={index} className={styling.slide}>
          <div className={reviewClasses}>
            <div className={styling['star-container']}>
              {getStars(review.numStars)}
            </div>
            <h4 className={styling.heading}>{review.title}</h4>
            <p className={styling.subtext}>{review.subtext}</p>
            <p className={styling.reviewer}>- {review.reviewer}</p>
          </div>
        </SwiperSlide>
      )
    })
  )

  return (
    <Swiper
      modules={[A11y]}
      a11y
      className={styling['reviews-container']}
      autoplay={{
        delay: 1000,
      }}
    >
      {getReviews()}
    </Swiper>
  )
}

export default Reviews
