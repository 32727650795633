import { SET_TRANSFER_DETAILS, RESET_TRANSFER_DETAILS } from './transferDetailsActions'

export const transferDetailsReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case SET_TRANSFER_DETAILS:
      return action.payload
    case RESET_TRANSFER_DETAILS:
      return {}
    default:
      return state
  }
}
