import React, { useEffect } from 'react'
import classNames from 'classnames'

import { gql, useQuery, useLazyQuery } from '@services/serviceUtils'
import LoadingContainer from '@shared/components/loadingContainer/LoadingContainer'
import { openUrlInNewTab } from '@common/utils'
import Loading from '@shared/components/Loading'
import { ReactComponent as PDFIcon } from '@common/images/icons/statement-pdf.svg'

import styling from './statements.module.scss'

const statementListQuery = gql`
  query {
    statements {
      statements {
        id
        title
      }
    }
  }
`

const statementUrlQuery = gql`
  query($statementId: ID!) {
    statement(statementId: $statementId) {
      url
    }
  }
`

const StatementRow = ({ statement }) => {
  const [getStatementUrl, { data: statementData, loading: statementLoading }] = useLazyQuery(
    statementUrlQuery,
    {
      fetchPolicy: 'no-cache',
    }
  )

  // Fetch the URL for this statement
  const handleClick = () => {
    getStatementUrl({ variables: { statementId: statement.id } })
  }

  const url = statementData?.statement?.url

  // When the URL arrives, open it in a new tab
  useEffect(() => {
    if (url) {
      openUrlInNewTab(url)
    }
  }, [url])

  return (
    <tr>
      <th
        scope='row'
        className={classNames('link-row', styling['statement-row-header'], {
          disabled: statementLoading,
        })}
      >
        <span className={styling['statement-icon']}>
          <PDFIcon />
        </span>
        <p
          role='button'
          tabIndex='0'
          onClick={handleClick}
          data-cy={`statement-list-item_${statement.id}`}
        >
          {statement.title}
        </p>
        <Loading
          styles={{ marginLeft: 'auto', flexShrink: 0 }}
          className={classNames('row-loader', { invisible: !statementLoading })}
        />
      </th>
    </tr>
  )
}

const Statements = () => {
  const {
    data: statementListData,
    loading: statementListLoading,
    error: statementListError,
  } = useQuery(statementListQuery)

  const statements = statementListData?.statements?.statements || []

  return (
    <div className='settings-view-content' data-cy='statements-view'>
      <h2>Statements</h2>
      <LoadingContainer
        error={statementListError}
        errorMessage='Error loading statements.'
        loading={statementListLoading}
      >
        <table
          className={classNames('simple-table', styling['statements-table'])}
          data-cy='statements-list'
        >
          <tbody>
            {statements.map(statement => (
              <StatementRow key={statement.id} statement={statement} />
            ))}
          </tbody>
        </table>
      </LoadingContainer>
    </div>
  )
}

export default Statements
