import React from 'react'

import HelpSupportTable from './HelpSupportTable'

const HelpSupport = () => (
  <div className='settings-view-content'>
    <h2>How can we help you?</h2>
    <HelpSupportTable />
  </div>
)

export default HelpSupport
