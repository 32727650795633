import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Form } from 'formik'
import { useMediaQuery } from 'react-responsive'
import { useLocation } from 'react-router-dom'

import BenefitCards from '@common/components/benefitCards/BenefitCards'
import AdditionalBenefitsDropdown from '@common/components/additionalBenefitsDropdown/AdditionalBenefitsDropdown'
import Reviews from '@common/components/reviews/Reviews'
import MyCardImage from 'src/views/myCard/MyCardImage'

import { ReactComponent as SkyNewsIcon } from '@common/images/icons/sky-news.svg'
import { ReactComponent as ForbesIcon } from '@common/images/icons/forbes.svg'
import { ReactComponent as BbcIcon } from '@common/images/icons/bbc.svg'
import { ReactComponent as ReviewStarGroupIcon } from '@common/images/icons/review-star-group.svg'

import { MAX_WIDTH_BREAKPOINTS } from '@shared/constants/uiConstants'
import { PRODUCT_DETAILS, BANK_DISCLAIMER } from '@common/constants'

import styling from './newUserForm.module.scss'

const NewUserForm = ({
  subText,
  isSubmitting,
  product,
  getSubmitButton,
  cardType,
  isMonthlyTerm,
  cardImage,
  hasFee,
  // setProduct,
  // productList,
}) => {
  const { state } = useLocation()

  const { isUpgrading = false } = state || {}

  const isTablet = useMediaQuery({ query: `(max-width: ${MAX_WIDTH_BREAKPOINTS.MEDIUM_LARGE}px)` })

  const priceText = useMemo(() => {
    if (product?.feeOptions) {
      let feeOption
      if (isMonthlyTerm) {
        feeOption = product.feeOptions.find(option => option?.key.includes('MONTHLY'))
      } else {
        feeOption = product.feeOptions.find(option => option?.key.includes('YEARLY'))
      }
      return `$${feeOption?.amount?.amount} ${isMonthlyTerm ? 'Monthly' : 'Annually'}`
    }
    return ''
  }, [product?.feeOptions, isMonthlyTerm])

  const buttonText = useMemo(() => (
   `${isUpgrading ? 'Upgrade' : 'Subscribe'} to ${cardType}`
  ), [isUpgrading, cardType])

  return (
    <Form data-cy='select-tier-form' className={styling['select-tier-form']}>
    <div className={styling['select-tier-container']}>
      <h1 className={classNames({ [styling['without-subtext']]: !subText })}>
        {hasFee ? 'Start Your Free Trial Today' : 'Start For Free Today'}
      </h1>
      {subText ? <p>{subText}</p> : null}
      <div className={styling['main-detail-container']}>
        <div className={styling['card-img-container']}>
          <div className={styling.cards}>
            {isTablet && (
              <div className={styling['ratings-container']}>
                <BbcIcon className={styling['ratings-icon']}/>
                <ForbesIcon className={styling['ratings-icon']}/>
                <SkyNewsIcon className={classNames(styling['ratings-icon'], styling.large)}/>
              </div>
            )}
            {cardImage && (
              <MyCardImage
                className='clickable'
                image={cardImage}
                displayUserCard={false}
                includeGlowingRing={true}
            />
            )}
            {isTablet && (
              <div className={styling['ratings-container']}>
                <p className={styling.rating}>4.8</p>
                <ReviewStarGroupIcon className={classNames(styling['ratings-icon'], styling.large)} />
                <p>App Rating</p>
              </div>
            )}
            {!isTablet && (
              <div className={styling['card-subtext']}>
                <span>{PRODUCT_DETAILS[product?.productType]?.cardSubText}</span>
              </div>
            )}
          </div>
          {(isTablet && priceText) && (
            <p className={styling['card-subtext']}>{priceText}, No Hidden Fees</p>
          )}
        </div>
        {!isTablet && (
          <div className={styling.banner}>
            {getSubmitButton({ text: isUpgrading ? 'Upgrade' : 'Subscribe', isSubmitting })}
          </div>
        )}
        {product?.benefits?.length && (
          <div className={styling['benefits-container']}>
            {isTablet && (
              <h3>What's inside</h3>
            )}
                <p className={styling['benefits-subtext']}>
                  Get exclusive banking<sup>1</sup> services, access to events, members-only workspace, and more.
                </p>
                <BenefitCards />
          </div>
        )}
        <AdditionalBenefitsDropdown />
        <h2 className={styling['million-text']}>1.3 Million Strong</h2>
        {!isTablet && (
          <p className={styling['benefits-subtext']}>
            Get exclusive banking<sup>1</sup> services, access to events, members-only workspace, and more.
          </p>
        )}
        <Reviews />
      </div>
      {isTablet ? (
        <div className='buttons-container'>
          {getSubmitButton({ text: buttonText, isSubmitting })}
          <p className='disclaimer-text'><sup>1</sup>{BANK_DISCLAIMER}</p>
        </div>
      ) : (
        <p className='disclaimer-text'><sup>1</sup>{BANK_DISCLAIMER}</p>
      )}
    </div>
    </Form>
  )
}

NewUserForm.propTypes = {
  subText: PropTypes.string,
  isSubmitting: PropTypes.bool,
  product: PropTypes.object,
  getSubmitButton: PropTypes.func,
  cardType: PropTypes.string,
  isMonthlyTerm: PropTypes.bool,
  cardImage: PropTypes.any,
  hasFee: PropTypes.bool,
  // setProduct: PropTypes.func,
  // productList: PropTypes.array,
}

export default NewUserForm
