import React, { useEffect } from 'react'
import { Navigate, useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import Button from '@shared/components/button/Button'
import { trackPage, trackEvent, SEGMENT_PAGE_NAMES, SEGMENT_EVENTS } from '@common/utils'
import { FUNDING_FLOWS } from '@common/constants'
import { staticRoutes } from '@routing/routes'
import { resetDebitCardDepositDetailsAction } from '@redux/debitCardDepositDetails/debitCardDepositDetailsActions'

import styling from './debitCardDepositSuccess.module.scss'
import CelebrationView from '@common/components/celebrationView/CelebrationView'

const DebitCardDepositSuccess = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const dispatch = useDispatch()

  const shouldSetupDirectDeposit = state?.shouldSetupDirectDeposit
  const fundingFlow = state?.fundingFlow

  const handleDoneClick = () => {
    dispatch(resetDebitCardDepositDetailsAction())

    if (fundingFlow === FUNDING_FLOWS.DEBIT_CARD_DEPOSIT) {
      navigate(staticRoutes.moveMoneyAddMoney.pathname)
    } else if (shouldSetupDirectDeposit) {
      navigate(staticRoutes.directDepositSetup.pathname, {
        state: {
          fundingFlow,
        },
      })
    } else {
      navigate(staticRoutes.dashboard.pathname)
    }

    trackEvent({
      event: SEGMENT_EVENTS.INSTANT_CARD_DEPOSIT_SUCCESS_BUTTON_CLICK,
    })
  }

  const handleMakeAnotherDepositClick = () => {
    dispatch(resetDebitCardDepositDetailsAction())

    navigate(staticRoutes.debitCardDeposit.pathname, {
      state: {
        fundingFlow,
        shouldSetupDirectDeposit,
      },
    })
  }

  // Tracks page visit
  useEffect(() => {
    trackPage({ name: SEGMENT_PAGE_NAMES.INSTANT_CARD_DEPOSIT_SUCCESS })
  }, [])

  return (
    <>
      {!state?.transactionId && (
        <Navigate to={staticRoutes.debitCardDeposit.pathname} state={state} />
      )}
      <CelebrationView className={styling['debit-card-deposit-success-container']}>
        <div className='start-flow-black-page-content'>
          <h1>Your Money Is Ready To Spend!</h1>
          <p>
            Your funds are now available in your Spending Account! Access your money by using your
            Greenwood card or account number.
          </p>

          <div className='buttons-container'>
            <Button
              onClick={handleDoneClick}
              data-cy='debit-card-deposit-success-done-button'
              onDark
            >
              Done
            </Button>
            <Button
              onClick={handleMakeAnotherDepositClick}
              data-cy='debit-card-deposit-success-make-another-deposit-button'
              onDark
              ghost
            >
              Make Another Deposit
            </Button>
          </div>
        </div>
      </CelebrationView>
    </>
  )
}

export default DebitCardDepositSuccess
