import React, { useEffect } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import LoadingContainer from '@shared/components/loadingContainer/LoadingContainer'
import ProtectedRoute from '@routing/ProtectedRoute'
import AddressResubmissionRequired from './AddressResubmissionRequired'
import AddressResubmissionEntry from './AddressResubmissionEntry'
import CreateAccountReview from 'src/views/createAccount/createAccountReview/CreateAccountReview'

import { useApplication } from '@common/utils'
import { staticRoutes } from '@routing/routes'

const AddressResubmission = () => {
  const { getApplication, loading, fetchError, ...application } = useApplication()

  // Fetch the application
  useEffect(() => {
    getApplication()
  }, [getApplication])

  return (
    <div className='create-account-wrapper'>
      <LoadingContainer
        loading={loading}
        error={fetchError}
        errorMessage='Error loading application data.'
      >
        <Routes>
          <Route
            path={staticRoutes.addressResubmissionRequired.relativePathname}
            element={<ProtectedRoute component={AddressResubmissionRequired} componentProps={application} />}
          />
          <Route
            path={staticRoutes.addressResubmissionEntry.relativePathname}
            element={<ProtectedRoute component={AddressResubmissionEntry} componentProps={application} />}
          />

          <Route
            path={staticRoutes.addressResubmissionReview.relativePathname}
            element={
              <ProtectedRoute component={CreateAccountReview} componentProps={{ isResubmitting: true, ...application }} />
            }
          />
          <Route
            path='*'
            element={
              <Navigate to={staticRoutes.addressResubmissionRequired.pathname} />
            }
          />
        </Routes>
      </LoadingContainer>
    </div>
  )
}

export default AddressResubmission
