import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'

import Button from '@shared/components/button/Button'
import ExternalLink from '@common/components/link/ExternalLink'
import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'
import { ReactComponent as MoneyTransferIcon } from '@common/images/icons/money-transfer.svg'

import { staticRoutes } from '@routing/routes'
import { resetTransferDetailsAction } from '@redux/transferDetails/transferDetailsActions'
import useTransferRouteGuard from '@common/utils/useTransferRouteGuard'
import {
  getBootstrapSitemapResource,
  BOOTSTRAP_SITEMAP_RESOURCES,
  trackPage,
  trackEvent,
  SEGMENT_PAGE_NAMES,
  SEGMENT_EVENTS,
  SEGMENT_SOURCE_DETAILS,
} from '@common/utils'

import styling from './transferProcessing.module.scss'
import { FUNDING_FLOWS } from '@common/constants'

const TransferProcessing = () => {
  const { state: { fundingFlow, shouldSetupDirectDeposit } = {} } = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useTransferRouteGuard({ fundingFlow })

  const { url: faqUrl } = getBootstrapSitemapResource(BOOTSTRAP_SITEMAP_RESOURCES.FAQ)

  const handleDoneClick = () => {
    dispatch(resetTransferDetailsAction())

    if (fundingFlow === FUNDING_FLOWS.ONBOARDING_PREMIUM) {
      // If the user is onboarding and selected a premium account, go to the basic funding flow
      navigate(staticRoutes.addMoney.pathname, {
        state: {
          fundingFlow: FUNDING_FLOWS.BASIC,
          shouldSetupDirectDeposit,
        },
      })
    } else if (shouldSetupDirectDeposit) {
      navigate(staticRoutes.directDepositSetup.pathname, { state: { fundingFlow } })
    } else {
      // Otherwise go to the dashboard
      navigate(staticRoutes.dashboard.pathname)
    }

    trackEvent({
      event: SEGMENT_EVENTS.TRANSFER_PROCESSING_BUTTON_CLICK({
        sourceDetail: SEGMENT_SOURCE_DETAILS.DONE,
      }),
    })
  }

  const handleLearnMoreClick = () => {
    trackEvent({
      event: SEGMENT_EVENTS.TRANSFER_PROCESSING_BUTTON_CLICK({
        sourceDetail: SEGMENT_SOURCE_DETAILS.LEARN_MORE,
      }),
    })
  }
  // Tracks page visit
  useEffect(() => {
    trackPage({ name: SEGMENT_PAGE_NAMES.TRANSFER_MONEY_PROCESSING })
  }, [])

  return (
    <div
      data-cy='transfer-processing-view'
      className={classNames('white-card-container', styling['transfer-processing-container'])}
    >
      <GreenwoodLogo className='logo' />
      <h1 className={styling.header}>
        <MoneyTransferIcon />
        Processing...
      </h1>
      <p>
        We are processing your payment at the moment. It may take up to 1 to 3 business days to
        complete. Please check back later to see the update.
      </p>

      <p className={styling['learn-more-link']}>
        <ExternalLink to={faqUrl} onClick={handleLearnMoreClick}>
          Learn More About Processing Times
        </ExternalLink>
      </p>
      <Button onClick={handleDoneClick} data-cy='transfer-processing-done-button'>
        Done
      </Button>
    </div>
  )
}

export default TransferProcessing
