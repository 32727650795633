import React, { useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'
import LoadingContainer from '@shared/components/loadingContainer/LoadingContainer'

import useScript from '@shared/utils/useScript'
import { SOCURE_KEY, SOCURE_DOCV_STATUSES } from '@common/constants'
import { useApplication, useCheckpointNavigation } from '@common/utils'
import { staticRoutes } from '@routing/routes'
import { addAlertAction } from '@redux/alerts/alertsActions'
import { DEFAULT_ALERT_DISMISS_DELAY } from '@shared/constants'

import styling from './documentSubmit.module.scss'

const DocumentSubmit = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const referenceId = useSelector(state => state?.application?.referenceId)

  // Load Socure script
  const [scriptLoaded, scriptError] = useScript('https://websdk.socure.com/bundle.js')

  const [documentUUID, setDocumentUUID] = useState('')
  const [isInitialized, setIsInitialized] = useState(false)

  const {
    getApplication,
    data: applicationData,
    loading,
    fetchError,
    submitError,
    submitApplication,
  } = useApplication()
  const { application = {} } = applicationData

  useEffect(() => {
    if (submitError) {
      navigate(staticRoutes.dashboard.pathname)

      dispatch(
        addAlertAction({
          text: 'Uh oh, something went wrong.',
          subText: "We aren't able to process your request at the moment.",
          autoDismissDelay: DEFAULT_ALERT_DISMISS_DELAY,
          dismissible: false,
        })
      )
    }
  }, [submitError, navigate, dispatch])

  useEffect(() => {
    const submit = async () => {
      await submitApplication({ documentUUID })
    }

    if (documentUUID) {
      submit()
    }
  }, [documentUUID, submitApplication])

  useEffect(() => {
    getApplication()
  }, [getApplication])

  useCheckpointNavigation({
    checkpoints: applicationData?.checkpoints,
    applicationStatus: application?.customerApplicationStatus,
  })

  const userData = useMemo(
    () => ({
      firstName: application?.firstName,
      surName: application?.lastName,
      physicalAddress: application?.deliveryAddress,
      physicalAddress2: application?.buildingNumber,
      city: application?.locality,
      state: application?.subdivisions?.length ? application.subdivisions[0] : '',
      zip: application?.postalCode,
      country: 'us',
      customerUserId: referenceId,
    }),
    [application, referenceId]
  )

  useEffect(() => {
    const socureInitializer = window?.SocureInitializer

    const socureSdkConfig = {
      onSuccess: data => {
        if (data?.status === SOCURE_DOCV_STATUSES.VERIFICATION_COMPLETE) {
          const { documentUuid = '' } = data
          setDocumentUUID(documentUuid)
        }
      },
    }

    const { firstName = '', customerUserId = '' } = userData

    if (scriptLoaded && socureInitializer && firstName && customerUserId && !isInitialized) {
      socureInitializer?.init(SOCURE_KEY).then(lib => {
        lib.init(SOCURE_KEY, '#socure-document-upload', socureSdkConfig).then(() => {
          lib.start(2, userData)
          setIsInitialized(true)
        })
      })
    }

    return () => {
      const socure = window?.Socure

      socure?.cleanup()
      socure?.reset()
    }
  }, [userData, scriptLoaded, isInitialized])

  return (
    <div className='white-card-container'>
      <GreenwoodLogo className='logo' />
      <div className={classNames('create-account-content-wrapper', styling['content-container'])}>
        <LoadingContainer
          loading={loading || !scriptLoaded}
          error={fetchError || scriptError}
          errorMessage={
            scriptError ? 'Error loading verification script.' : 'Error loading application data'
          }
        >
          <div id='socure-document-upload' />
        </LoadingContainer>
      </div>
    </div>
  )
}

export default DocumentSubmit
