import React from 'react'
import { useNavigate, useLocation } from 'react-router'
import classNames from 'classnames'
import { useFlags } from 'launchdarkly-react-client-sdk'

import Atomic from '@common/components/atomic/Atomic'
import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'
import { ReactComponent as MoveMoneyIllustration } from '@common/images/illustrations/illustration-move-money.svg'
import { ReactComponent as LeftSmallSquiggle } from '@common/images/illustrations/illustration-squiggle-1.svg'
import { ReactComponent as RightSmallSquiggle } from '@common/images/illustrations/illustration-squiggle-2.svg'
import { ReactComponent as LargeSquiggle } from '@common/images/illustrations/illustration-squiggle-3.svg'
import Button from '@shared/components/button/Button'
import ExternalLink from '@common/components/link/ExternalLink'
import {
  BOOTSTRAP_SITEMAP_RESOURCES,
  getBootstrapSitemapResource,
  getDirectDepositFlowCompletedRoute,
} from '@common/utils'
import DirectDepositContestRulesLink from '@common/components/DirectDepositContestRulesLink'
import useFundingFlowRouteGuard from '@common/utils/useFundingFlowRouteGuard'
import { FUNDING_FLOWS } from '@common/constants'

import styling from './directDepositSetup.module.scss'

const REQUIRED_FUNDING_FLOWS = [FUNDING_FLOWS.BASIC, FUNDING_FLOWS.ONBOARDING_PREMIUM]

const DirectDepositSetup = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const { campaignDirectDepositSweepstakes } = useFlags()

  useFundingFlowRouteGuard({
    requiredFundingFlows: REQUIRED_FUNDING_FLOWS,
    redirectWithReplace: true,
  })

  const headingText = campaignDirectDepositSweepstakes ? (
    <span>
      Don't miss your chance to <strong>win</strong> with Direct Deposit
    </span>
  ) : (
    'Connect your direct deposit to Greenwood'
  )

  const paragraphText = campaignDirectDepositSweepstakes
    ? `Don’t miss your chance to win $2,500 when you set up Direct Deposit and move your paycheck
    over to Greenwood. Must direct deposit $200 or more to be eligible.`
    : `Let Greenwood help you find your employer from over 30,000 providers. Greenwood partners
    with Atomic to set up or switch your direct deposit.`

  return (
    <div className={classNames('create-account-black-page', styling['direct-deposit-setup'])}>
      {campaignDirectDepositSweepstakes && (
        <div className={styling['squiggles-container']}>
          <div className={styling['left-squiggles']}>
            <LargeSquiggle className={styling['left-large-squiggle']} />
            <LeftSmallSquiggle className={styling['left-small-squiggle']} />
          </div>
          <div className={styling['right-squiggles']}>
            <RightSmallSquiggle className={styling['right-small-squiggle']} />
            <LargeSquiggle className={styling['right-large-squiggle']} />
          </div>
        </div>
      )}
      <div className={classNames('start-flow-black-page-content', styling.page)}>
        <div>
          {/* This is used simply to vertically space the page content */}
          &nbsp;
        </div>
        <div className={styling['main-content-container']}>
          <GreenwoodLogo className={classNames('logo', styling['greenwood-logo'])} />
          <MoveMoneyIllustration
            className={classNames('user-action-view-icon', styling['move-money-illustration'])}
          />

          <h1>{headingText}</h1>
          <p>{paragraphText}</p>
          {campaignDirectDepositSweepstakes && (
            <DirectDepositContestRulesLink className={styling['promotion-link']} />
          )}
          <div className='buttons-container'>
            <Atomic
              buttonContent='Set Up Direct Deposit'
              onFinish={() => {
                navigate(getDirectDepositFlowCompletedRoute({ fundingFlow: state?.fundingFlow }))
              }}
              data-cy='find-payroll-provider-button'
            />
            <Button
              onClick={() => {
                navigate(getDirectDepositFlowCompletedRoute({ fundingFlow: state?.fundingFlow }))
              }}
              data-cy='direct-deposit-setup-cancel-button'
              onDark
              ghost
            >
              Skip for Now
            </Button>
          </div>
        </div>
        <p className='help-text'>
          Greenwood partners with Atomic to setup or switch direct deposit.{' '}
          <ExternalLink className='underlined-link bold' to={directDepositFaqLink}>
            Learn More
          </ExternalLink>
        </p>
      </div>
    </div>
  )
}

const { url: directDepositFaqLink } = getBootstrapSitemapResource(
  BOOTSTRAP_SITEMAP_RESOURCES.FAQ_DIRECT_DEPOSIT
)

export default DirectDepositSetup
