import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import Dashboard from './dashboard/Dashboard'
import Splash from './splash/Splash'

import { isAuthenticated } from '@common/utils'
import { setOnboardingFlowTypeAction } from '@redux/application/applicationActions'
import { ONBOARDING_FLOW_TYPES, ONBOARDING_FLOW_TYPE_BY_ROUTE } from '@common/constants'

// Displays either the Dashboard or Splash screen depending on user auth state
const Home = () => {
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const isUserAuthenticated = useSelector(state => isAuthenticated(state))

  useEffect(() => {
    const onboardingFlowType =
      ONBOARDING_FLOW_TYPE_BY_ROUTE[pathname] || ONBOARDING_FLOW_TYPES.ELEVATE

    // Save the onboardingFlowType in state
    dispatch(setOnboardingFlowTypeAction(onboardingFlowType))
  }, [pathname, dispatch])

  return isUserAuthenticated ? <Dashboard /> : <Splash />
}

export default Home
