import React, { useEffect, useMemo } from 'react'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useSelector } from 'react-redux'

import UpgradeMembershipButton from '@common/components/UpgradeMembershipButton'
import Button from '@shared/components/button/Button'
import HelpSupportTable from '../settings/helpSupport/HelpSupportTable'

import {
  SEGMENT_PAGE_NAMES,
  trackPage,
  SEGMENT_EVENTS,
  trackEvent,
  hasUpgradeDdaAccountCta,
} from '@common/utils'
import { gql, useQuery } from '@services/serviceUtils'

import styling from './changeMyMembership.module.scss'

const ctasQuery = gql`
  query Ctas {
    ctas {
      callToActionType
    }
  }
`

const ChangeMyMembership = () => {
  const navigate = useNavigate()

  const { upgradeMembership } = useFlags()

  useEffect(() => {
    trackPage({ name: SEGMENT_PAGE_NAMES.CHANGE_MY_MEMBERSHIP })
  }, [])

  const { data: ctasData, loading: ctasLoading } = useQuery(ctasQuery, { fetchPolicy: 'no-cache' })
  const hasUpgradeCta = useMemo(() => hasUpgradeDdaAccountCta(ctasData?.ctas), [ctasData])

  const hasElevate = useSelector(state => state.customer?.hasElevate)
  const hasPremium = useSelector(state => state.customer?.hasPremium)

  const isStandardAccount = useMemo(() => (
    !hasElevate && !hasPremium
  ), [hasElevate, hasPremium])

  const handleBackButtonClick = () => {
    navigate(-1)
  }

  const upgradeButtonClickListener = () => {
    trackEvent({
      event: SEGMENT_EVENTS.UPGRADE_MEMBERSHIP_BUTTON_CLICK(
        SEGMENT_PAGE_NAMES.CHANGE_MY_MEMBERSHIP
      ),
    })
  }

  const showUpgradeCta = hasUpgradeCta && !ctasLoading && upgradeMembership && isStandardAccount

  return (
    <div
      className={classNames(
        'membership-view-content',
        'membership-view-container',
        styling['change-my-membership-container']
      )}
    >
      <h2 data-cy='membership-settings-header'>Change my Membership</h2>
      <p className='subtext'>
        To cancel or downgrade your Greenwood membership, please contact support, otherwise you can
        make changes to your membership by clicking the upgrade button below.
      </p>
      <HelpSupportTable
        className={styling['help-support-table']}
        segmentSource={SEGMENT_PAGE_NAMES.CHANGE_MY_MEMBERSHIP}
      />
      <div className='actions'>
        {showUpgradeCta && (
          <UpgradeMembershipButton
            buttonText='Upgrade Membership'
            clickListener={upgradeButtonClickListener}
          />
        )}
        <Button onClick={handleBackButtonClick} outline data-cy='change-my-membership-back-button'>
          Back
        </Button>
      </div>
    </div>
  )
}

export default ChangeMyMembership
