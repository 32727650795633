import React from 'react'
import PropTypes from 'prop-types'

import { useLazyQuery, gql } from '@services/serviceUtils'
import Button from '@shared/components/button/Button'
import { ATOMIC_CONFIG } from '@common/constants'
import { Atomic as AtomicSDK, Product } from '@atomicfi/transact-javascript'

/**
 * Atomic configuration parameters are documented here:
 * https://docs.atomicfi.com/reference/transact-sdk#sdk-parameters
 * @param {Object} config
 * @param {string} config.publicToken The public token provided by the Greenwood API, used to
 * initiate an Atomic session. Required.
 * @param {string} config.product Sets the Atomic product context. Default is `deposit`.
 * @param {function} config.onFinish Callback executed when the Atomic interaction is completed.
 * @param {function} config.onClose Callback executed when the user closes the Atomic interaction
 * before completion.
 * @param {function} config.onInteraction Callback executed Any time there is a user interaction
 * with Atomic.
 */
const launchAtomic = config => {
  const {
    publicToken,
    product = Product.DEPOSIT,
    onFinish,
    onClose,
    onInteraction,
  } = config

  if (publicToken) {
    AtomicSDK.transact({
      config: {
        publicToken,
        tasks: [{
          product,
          distribution: {
            type: 'percent',
            amount: 100, // Must be 100% of their paycheque
            canUpdate: true, // User can change this distribution
          },
        }],
        ...ATOMIC_CONFIG,
      },
      onFinish,
      onClose,
      onInteraction,
    })
  }
}

const atomicTokenForNewLinkQuery = gql`
  query AtomicTokenForNewLink {
    atomicTokenForNewLink {
      linkToken
    }
  }
`

const Atomic = ({
  buttonContent = 'Start',
  component: Component,
  onFinish = () => {
    /* Not implemented */
  },
  onClose = () => {
    /* Not implemented */
  },
  onInteraction = () => {
    /* Not implemented */
  },
  onClickCallback,
  ...rest
}) => {
  // Launch the Atomic SDK
  const handleGetAtomicToken = ({ atomicTokenForNewLink }) => {
    const publicToken = atomicTokenForNewLink?.linkToken

    // For now pass callback stubs which will be replaced with proper handlers as necessary
    launchAtomic({
      publicToken,
      onFinish,
      onClose,
      onInteraction,
    })
  }

  const [getAtomicToken, { loading: atomicTokenLoading }] = useLazyQuery(
    atomicTokenForNewLinkQuery,
    { fetchPolicy: 'no-cache', onCompleted: handleGetAtomicToken }
  )

  const onClickListener = () => {
    getAtomicToken()
    onClickCallback && onClickCallback()
  }

  return Component ? (
    <Component
      onClick={getAtomicToken}
      isLoading={atomicTokenLoading}
      {...rest}
    />
  ) : (
    <Button
      onClick={onClickListener}
      isLoading={atomicTokenLoading}
      {...rest}
    >
      {buttonContent}
    </Button>
  )
}

Atomic.propTypes = {
  /**
   * Change the content displayed within the standard button component
   */
  buttonContent: PropTypes.string,

  /**
   * Any renderable component can be displayed instead of the default button. Will pass `onClick`,
   * `isLoading`, and `disabled` props to the component to provide button-like behaviours and
   * states to the custom component. The `buttonContent` prop will be ignored if this prop is used
   */
  component: PropTypes.element,

  /**
   * Callback to execute when the Atomic SDK closes and completes successfully
   */
  onFinish: PropTypes.func,

  /**
   * Callback to execute when the Atomic SDK closes but does not complete
   */
  onClose: PropTypes.func,

  /**
   * Callback to execute any time Atomic SDK detects a user interaction
   */
  onInteraction: PropTypes.func,
}

export default Atomic
