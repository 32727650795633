import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import MaskedInput from 'react-text-mask'
import classNames from 'classnames'

import Button from '@shared/components/button/Button'
import FormCurrencyInput from '@shared/components/formCurrencyInput/FormCurrencyInput'
import FormInput from '@shared/components/formInput/FormInput'
import FormSelect from '@shared/components/formSelect/FormSelect'
import Loading from '@shared/components/Loading'
import LoadingContainer from '@shared/components/loadingContainer/LoadingContainer'
import Fee from '@common/components/fee/Fee'
import MaxCardsModal from '@common/components/maxCardsModal/MaxCardsModal'
import Modal from '@shared/components/modal/Modal'
import ModalHeader from '@shared/components/modal/ModalHeader'
import ModalBody from '@shared/components/modal/ModalBody'

import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'

import { staticRoutes } from '@routing/routes'
import { ADD_DEBIT_CARD_FLOW_TYPES, CVV_VALIDATION_SCHEMA, FUNDING_FLOWS } from '@common/constants'
import { toDollars } from '@shared/utils'
import { gql, useQuery } from '@services/serviceUtils'
import { getAccountByTitle } from '@common/utils/accountUtils'
import {
  useDebitDepositFeesLimits,
  trackPage,
  trackEvent,
  SEGMENT_PAGE_NAMES,
  SEGMENT_EVENTS,
  SEGMENT_SOURCE_DETAILS,
} from '@common/utils'
import { getActiveCardCount } from '@common/utils/cardUtils'
import { setDebitCardDepositDetailsAction } from '@redux/debitCardDepositDetails/debitCardDepositDetailsActions'

import styling from './debitCardDepositForm.module.scss'

const linkedCardsQuery = gql`
  query LinkedCards {
    linkedCards {
      id
      maskedNumber
      network
      expired
    }
  }
`

const accountsForMoneyTransferQuery = gql`
  query AccountsForMoneyTransfer {
    accountsForMoneyTransfer {
      id
      accountNumber
      title
      transferAccountType
      balance {
        amount
      }
    }
  }
`

const valueToFloat = value => parseFloat(value?.replace(',', '')?.replace('$', '') || '0')

const dollarStringToFloat = dollarString => {
  switch (typeof dollarString) {
    case 'number':
      return dollarString
    case 'string':
      return parseFloat(dollarString.replace(',', '').replace('$', ''))
    default:
      return NaN
  }
}

const depositFormSchema = ({ userDailyLimit, minTransAmount, userPerTransactionLimit } = {}) =>
  Yup.object().shape({
    ...CVV_VALIDATION_SCHEMA,
    depositAmount: Yup.string()
      .required('Required')
      .matches(
        /^[$]?[\d.,]*$/,
        'Deposit amount can only include numbers 0-9, commas and a decimal.'
      )
      .test(
        'amount is less than or equal to daily limit',
        `Your daily limit is ${toDollars({
          value: userDailyLimit || 0,
          isSuperscript: false,
        })}.`,
        value => !value || valueToFloat(value) <= userDailyLimit
      )
      .test(
        'amount is less than or equal to per transaction limit',
        `Your per transaction limit is ${toDollars({
          value: userPerTransactionLimit || 0,
          isSuperscript: false,
        })}.`,
        value => !value || valueToFloat(value) <= userPerTransactionLimit
      )
      .test(
        'amount is greater than or equal to minimum',
        `Minimum transaction amount is ${toDollars({
          value: minTransAmount || 0,
          isSuperscript: false,
        })}.`,
        value => !minTransAmount || !value || valueToFloat(value) >= minTransAmount
      ),
    depositFrom: Yup.string().required('Required'),
  })

const DebitCardDepositForm = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const [isMaxCardsModalOpen, setIsMaxCardsModalOpen] = useState(false)
  const dispatch = useDispatch()
  const debitCardDepositDetails = useSelector(state => state.debitCardDepositDetails)

  const [depositAmount, setDepositAmount] = useState(debitCardDepositDetails?.depositAmount || 0)
  const [initialDepositFromCardId, setInitialDepositFromCardId] = useState('')

  const {
    feePercent = 0,
    feeAmount = 0,
    userDailyLimit,
    maxActiveCardLimit,
    minTransAmount,
    userPerTransactionLimit,
    userDailyNumber,
    loading: feesLimitsLoading,
    error: feesLimitsError,
  } = useDebitDepositFeesLimits({
    requestedAmount: depositAmount,
  })

  const { loading: linkedCardsLoading, data: linkedCardsData } = useQuery(linkedCardsQuery, {
    fetchPolicy: 'no-cache',
  })

  const { loading: accountsLoading, data: accountsData } = useQuery(accountsForMoneyTransferQuery, {
    fetchPolicy: 'no-cache',
  })

  const activeCardCount = getActiveCardCount(linkedCardsData?.linkedCards)

  const spendingAccount = getAccountByTitle({
    accounts: accountsData?.accountsForMoneyTransfer,
    accountTitle: 'Spending',
  })

  const maskedAccountNumber = spendingAccount?.accountNumber?.substring(
    spendingAccount?.accountNumber?.length - 4
  )
  const depositTo = spendingAccount ? `${spendingAccount?.title} (...${maskedAccountNumber})` : ''

  useEffect(() => {
    if (linkedCardsData?.linkedCards?.length === 1 && !linkedCardsData?.linkedCards[0]?.expired) {
      setInitialDepositFromCardId(linkedCardsData.linkedCards[0].id)
    }
  }, [linkedCardsData?.linkedCards])

  const handleCancelClick = () => {
    if (state?.fundingFlow === FUNDING_FLOWS.DEBIT_CARD_DEPOSIT) {
      navigate(staticRoutes.moveMoneyAddMoney.pathname, { replace: true })
    } else if (state?.shouldSetupDirectDeposit) {
      navigate(staticRoutes.directDepositSetup.pathname, {
        state: { fundingFlow: state?.fundingFlow },
        replace: true,
      })
    } else {
      navigate(staticRoutes.dashboard.pathname, { replace: true })
    }
  }

  const handleSubmit = async values => {
    const { depositFrom } = values
    const depositDetails = {
      ...values,
      depositFrom: linkedCardsData?.linkedCards?.find(card => card.id === depositFrom),
      depositTo,
      depositAmount: dollarStringToFloat(values.depositAmount),
      feePercent,
      feeAmount,
    }

    dispatch(setDebitCardDepositDetailsAction(depositDetails))

    trackEvent({
      event: SEGMENT_EVENTS.INSTANT_CARD_DEPOSIT_BUTTON_CLICK({
        sourceDetail: SEGMENT_SOURCE_DETAILS.CONTINUE,
        deposit_amount: values.depositAmount,
      }),
    })

    navigate(staticRoutes.debitCardDepositReview.pathname, { state })
  }

  const [formValidationSchema, setFormValidationSchema] = useState(
    depositFormSchema({ userDailyLimit, minTransAmount, userPerTransactionLimit })
  )

  useEffect(() => {
    setFormValidationSchema(
      depositFormSchema({ userDailyLimit, minTransAmount, userPerTransactionLimit })
    )
  }, [depositAmount, userDailyLimit, minTransAmount, userPerTransactionLimit])

  const handleAddDebitCardClick = () => {
    if (activeCardCount >= maxActiveCardLimit) {
      // open max active cards modal'
      setIsMaxCardsModalOpen(true)
    } else {
      navigate(staticRoutes.addDebitCard.pathname, {
        state: {
          ...state,
          addDebitCardFlow: ADD_DEBIT_CARD_FLOW_TYPES.FROM_DEPOSIT_FORM,
        },
      })
    }
  }

  // Tracks page visit
  useEffect(() => {
    trackPage({ name: SEGMENT_PAGE_NAMES.INSTANT_CARD_DEPOSIT })
  }, [])

  const handleDepositAmountBlur = () => {
    // Track any time the user leaves the field
    trackEvent({
      event: SEGMENT_EVENTS.INSTANT_CARD_DEPOSIT_AMOUNT_FIELD_ENTERED,
    })
  }

  const handleFundFromChange = () => {
    trackEvent({
      event: SEGMENT_EVENTS.INSTANT_CARD_DEPOSIT_BUTTON_CLICK(SEGMENT_SOURCE_DETAILS.FUND_FROM),
    })
  }

  return (
    <div className='white-card-container'>
      <GreenwoodLogo className='logo' />
      <h1>Deposit Funds</h1>
      <Formik
        initialValues={{
          depositAmount: debitCardDepositDetails?.depositAmount || '',
          depositFrom: debitCardDepositDetails?.depositFrom?.id || initialDepositFromCardId,
          cvv: debitCardDepositDetails?.cvv || '',
        }}
        validationSchema={formValidationSchema}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ errors, touched, values, isSubmitting, handleBlur, handleChange }) => {
          const isDisabled = isSubmitting || accountsLoading || linkedCardsLoading

          return (
            <Form data-cy='deposit-form' className={styling['deposit-form']}>
              <Field
                as={FormCurrencyInput}
                name='depositAmount'
                label='Deposit Amount'
                invalid={errors.depositAmount && touched.depositAmount}
                errorText={errors.depositAmount}
                disabled={isSubmitting}
                required
                className='amount-form-field'
                defaultValue={parseFloat(values?.depositAmount) || ''}
                showRequiredAsterisk={false}
                hintContent='Funds Available Immediately'
                data-cy='deposit-amount-input-field'
                onChange={value => {
                  setDepositAmount(parseFloat(value) || 0)
                }}
                onBlur={e => {
                  handleBlur(e)
                  handleDepositAmountBlur()
                }}
              />
              <LoadingContainer
                loading={feesLimitsLoading}
                error={!!feesLimitsError}
                errorMessage='Error loading fee information'
              >
                <Fee
                  feeAmount={feeAmount}
                  feePercent={feePercent}
                  segmentSource={SEGMENT_PAGE_NAMES.INSTANT_CARD_DEPOSIT}
                />
              </LoadingContainer>
              <Button
                className={styling['add-debit-card-button']}
                ghost
                type='button'
                data-cy='deposit-form-add-debit-card-button'
                onClick={handleAddDebitCardClick}
              >
                <div className={styling.plus}>&#43;</div>
                Add Debit Card
              </Button>
              <div className={styling['input-fields-container']}>
                <div className={styling['deposit-container']}>
                  {linkedCardsLoading && <Loading title='loader' />}
                  <Field
                    as={FormSelect}
                    name='depositFrom'
                    label='Fund From'
                    invalid={errors.depositFrom && touched.depositFrom}
                    errorText={errors.depositFrom}
                    disabled={isSubmitting}
                    required
                    data-cy='deposit-from-selection-field'
                    onChange={e => {
                      handleChange(e)
                      handleFundFromChange()
                    }}
                  >
                    {!linkedCardsLoading && (
                      <>
                        <option value=''>Choose Debit Card</option>
                        <optgroup label='Linked Debit Cards'>
                          {linkedCardsData?.linkedCards?.map((card, index) => (
                            <option key={index} value={card.id} disabled={card.expired}>
                              {card.network} (...{card.maskedNumber})
                              {card.expired ? ' | EXPIRED' : ''}
                            </option>
                          ))}
                        </optgroup>
                      </>
                    )}
                  </Field>
                </div>
                <Field name='cvv'>
                  {({ field }) => (
                    <MaskedInput
                      {...field}
                      mask={[/\d/, /\d/, /\d/, /\d/]}
                      guide={false}
                      onBlur={e => {
                        handleBlur(e)
                        trackEvent({
                          event: SEGMENT_EVENTS.INSTANT_CARD_DEPOSIT_BUTTON_CLICK({
                            sourceDetail: SEGMENT_SOURCE_DETAILS.ENTER_CVV,
                          }),
                        })
                      }}
                      render={(ref, props) => (
                        <FormInput
                          label='Enter your CVV'
                          required
                          invalid={errors.cvv && touched.cvv}
                          errorText={errors.cvv}
                          autoComplete='off'
                          data-cy='cvv-input-field'
                          innerRef={ref}
                          {...props}
                        />
                      )}
                    />
                  )}
                </Field>
              </div>
              <div className={classNames(styling['deposit-container'], styling['to-account'])}>
                <p>Deposit To</p>
                {accountsLoading ? (
                  <Loading title='loader' />
                ) : (
                  <p className={styling['to-account-details']}>{depositTo}</p>
                )}
              </div>
              <Button type='submit' data-cy='deposit-form-continue-button' disabled={isDisabled}>
                Continue
              </Button>
              <Button type='button' onClick={handleCancelClick} ghost className='additional-button'>
                Cancel
              </Button>
            </Form>
          )
        }}
      </Formik>
      <MaxCardsModal
        isOpen={isMaxCardsModalOpen}
        toggleModal={() => setIsMaxCardsModalOpen(!isMaxCardsModalOpen)}
        closeModal={() => setIsMaxCardsModalOpen(false)}
      />
      <Modal
        centered
        size='md'
        open={!feesLimitsLoading && !feesLimitsError && !userDailyNumber}
        toggleModal={handleCancelClick}
        className={styling['deposit-limit-reached-modal']}
      >
        <ModalHeader className={styling['deposit-limit-reached-modal-header']}>
          <h4>Daily Limit Reached</h4>
        </ModalHeader>
        <ModalBody>
          <p>Your Daily Transaction Limit has been reached.</p>
          <p>Please try again in 24 hours.</p>
          <Button type='button' onClick={handleCancelClick} className='additional-button'>
            Dismiss
          </Button>
        </ModalBody>
      </Modal>
      <p className='disclaimer-text'>
        By selecting continue, you attest and acknowledge that you are the owner of the debit card
        provided.
      </p>
    </div>
  )
}

export default DebitCardDepositForm
