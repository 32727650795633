import React from 'react'
import PropTypes from 'prop-types'

import SavingsAccountCallout from '@common/components/callout/SavingsAccountCallout'
import AccountFundingCallout from '@common/components/callout/AccountFundingCallout'
import DownloadAppCallout from '@common/components/callout/DownloadAppCallout'
import UpgradeMembershipCallout from '@common/components/callout/UpgradeMembershipCallout'
import StandardUpgradeInvestCampaignCallout from '@common/components/callout/invest/StandardUpgradeInvestCampaignCallout'
import NewUserInvestCampaignCallout from '@common/components/callout/invest/NewUserInvestCampaignCallout'

import { CTA_TYPES, MAX_NUM_CTAS, INVEST_PROMOTIONS } from '@common/constants'
import { SEGMENT_PAGE_NAMES } from '@common/utils'

const DashboardCtas = ({ ctas, isStandardAccount, activePromotions, showInvestPromoCallout }) => {
  const ctaList = []

  if (isStandardAccount) {
    ctaList.push(
      <UpgradeMembershipCallout
        isDashboardCta
        key='upgrade-membership-cta'
        segmentSource={SEGMENT_PAGE_NAMES.DASHBOARD_HOME}
      />
    )
  }

  for (let i = 0; i < MAX_NUM_CTAS; i++) {
    const cta = ctas.find(callout => callout.priority === i)
    switch (cta?.callToActionType) {
      case CTA_TYPES.OPEN_SAVINGS_ACCOUNT:
        ctaList.push(<SavingsAccountCallout key='savings-account-cta' />)
        break
      case CTA_TYPES.FUND_ACCOUNT:
        ctaList.push(<AccountFundingCallout key='fund-account-cta' />)
        break
      default:
        break
    }
  }

  if (ctaList.length < MAX_NUM_CTAS) {
    ctaList.push(<DownloadAppCallout key='download-app-cta' />)
  }

  if (isStandardAccount && activePromotions?.includes(INVEST_PROMOTIONS.INVEST_STOCK_PROMO)) {
    ctaList.unshift(
      <StandardUpgradeInvestCampaignCallout key='standard-upgrade-invest-campaign-cta' />
    )
  } else if (showInvestPromoCallout) {
    ctaList.unshift(<NewUserInvestCampaignCallout key='new-user-invest-campaign-cta' />)
  }

  return ctaList
}

DashboardCtas.propTypes = {
  ctas: PropTypes.array,
}

export default DashboardCtas
