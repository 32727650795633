import React, { useState, useEffect, useCallback } from 'react'
import classNames from 'classnames'
import { useNavigate, useLocation } from 'react-router'

import { gql, useMutation, useQuery } from '@services/serviceUtils'
import { GIVING_STATES } from '@common/constants'
import CauseCallout from '@common/components/callout/CauseCallout'
import LoadingContainer from '@shared/components/loadingContainer/LoadingContainer'
import Table from '@shared/components/table/Table'
import Hero from '@common/components/hero/Hero'
import TransactionDetailModal from '@common/components/transactions/TransactionDetailModal'
import { toDollars, toMediumDateFormat } from '@shared/utils'
import { staticRoutes } from '@routing/routes'
import usePaginatedQuery from '@common/utils/usePaginatedQuery'
import Button from '@shared/components/button/Button'

import styling from './giving.module.scss'

const givingStateQuery = gql`
  query GivingState {
    givingState {
      givingState
    }
    givingTransactions {
      totalCount
    }
  }
`

/* Note that the default start/end date range on the givingTransactions query produces a 30-day
   transaction history, so no need to supply those date variables */
const transactionDataQuery = gql`
  query GivingTransactions($page: Int, $txnsPerPage: Int) {
    givingTransactions(page: $page, txnsPerPage: $txnsPerPage) {
      totalCount
      transactions {
        amount {
          amount
          currency
        }
        transactionTime
        description
      }
    }
  }
`

const setGivingStateMutation = gql`
  mutation SetGivingState($givingStateDTOInput: GivingStateDTOInput!) {
    setGivingState(givingStateDTOInput: $givingStateDTOInput) {
      success
    }
  }
`

const GivingStateToggleButton = ({
  givingState,
  loading,
  onStartGivingClick,
  onStopGivingClick,
}) => {
  return [GIVING_STATES.DISABLED, GIVING_STATES.RECENT].includes(givingState) ? (
    <Button onClick={onStartGivingClick} isLoading={loading} data-cy='start-giving-button'>
      Start Giving
    </Button>
  ) : (
    <Button outline onClick={onStopGivingClick} isLoading={loading} data-cy='stop-giving-button'>
      Stop Giving
    </Button>
  )
}

const Giving = () => {
  const [detailModalData, setDetailModalData] = useState({ transaction: {}, isOpen: false })

  /* Used to track only the initial loading state to prevent full page transition when the
     GivingState query operation is re-fetched */
  const [givingStateInitialLoading, setGivingStateInitialLoading] = useState(true)
  const navigate = useNavigate()
  const { state } = useLocation()

  // Sets initial loading state to false when the fetch completes or fails
  const handleFetchComplete = () => {
    setGivingStateInitialLoading(false)
  }

  const {
    data: givingStateData,
    error: givingStateError,
    loading: givingStateLoading,
    refetch: refetchGivingState,
  } = useQuery(givingStateQuery, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: handleFetchComplete,
    onError: handleFetchComplete,
  })

  const [setGivingState, { loading: setGivingStateLoading }] = useMutation(setGivingStateMutation, {
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      refetchGivingState()
    },
  })

  const givingState = givingStateData?.givingState?.givingState
  const totalGivingTransactions = givingStateData?.givingTransactions?.totalCount || 0

  const {
    initialPageLoading,
    pageLoading,
    error: transactionsError,
    entities: transactions,
    pagination,
    handlePageClick,
    fetchData: fetchTransactions,
  } = usePaginatedQuery({
    query: transactionDataQuery,
    dataEntitiesPath: 'givingTransactions.transactions',
    dataTotalEntitiesPath: 'givingTransactions.totalCount',
    fetchImmediately: false,
  })

  useEffect(() => {
    if (
      [GIVING_STATES.DISABLED, GIVING_STATES.RECENT].includes(givingState) &&
      totalGivingTransactions === 0
    ) {
      // If the giving state is not enabled and there is no transaction history, go to the splash screen instead
      navigate(staticRoutes.startGiving.pathname, { state, replace: true })
    } else {
      // Otherwise fetch the transactions
      fetchTransactions()
    }
  }, [givingState, totalGivingTransactions, navigate, state, fetchTransactions])

  const handleRowClick = row =>
    setDetailModalData({
      transaction: row,
      isOpen: true,
    })

  const handleToggleModal = () =>
    setDetailModalData({ isOpen: !detailModalData.isOpen, ...detailModalData })

  const handleCloseModal = () => setDetailModalData({ details: {}, isOpen: false })

  const handleStopGivingClick = useCallback(async () => {
    const { data: disableGivingData } = await setGivingState({
      variables: {
        givingStateDTOInput: {
          enableGiving: false,
        },
      },
    })

    // If successful and there are no giving transactions, navigate back to the start giving screen
    if (disableGivingData?.setGivingState?.success === true && totalGivingTransactions === 0) {
      navigate(staticRoutes.startGiving.pathname, { replace: true })
    }
  }, [navigate, setGivingState, totalGivingTransactions])

  const handleStartGiving = useCallback(() => {
    // Go to the Start Giving splash page
    navigate(staticRoutes.startGiving.pathname, {
      state: { fromPathname: staticRoutes.giving.pathname },
    })
  }, [navigate])

  return (
    <>
      <Hero heading='Giving' />
      <div className='page-content-container white-bg'>
        <div className='row'>
          <div className={styling.giving}>
            <h2>Give back with Greenwood</h2>
            <p>Support our community by rounding up your purchases to the nearest whole dollar.</p>

            <LoadingContainer
              error={givingStateError}
              errorMessage='Error loading giving data.'
              loading={givingStateInitialLoading}
            >
              <div className={styling.content}>
                <div className={styling['cause-actions']}>
                  <CauseCallout />
                  <GivingStateToggleButton
                    givingState={givingState}
                    loading={setGivingStateLoading || givingStateLoading}
                    onStartGivingClick={handleStartGiving}
                    onStopGivingClick={handleStopGivingClick}
                  />
                </div>
                <div className={styling.history}>
                  <h4>Round Up History (Past 30 Days)</h4>

                  <LoadingContainer
                    error={transactionsError}
                    errorMessage='Error loading transactions.'
                    loading={initialPageLoading}
                  >
                    <Table
                      columns={historyColumns}
                      data={transactions}
                      onPageClick={handlePageClick}
                      loading={pageLoading}
                      pagination={pagination}
                      listEndMessage='End of Your 30 Day Transaction History'
                      noRecordsMessage='No Giving Transactions to Show Within the Past 30 Days'
                      row={{
                        onClick: handleRowClick,
                        clickable: row => row.details?.length > 0,
                      }}
                    />
                    <TransactionDetailModal
                      transaction={detailModalData.transaction}
                      isOpen={detailModalData.isOpen}
                      onToggleModal={handleToggleModal}
                      onCloseModal={handleCloseModal}
                    />
                  </LoadingContainer>
                </div>
              </div>
            </LoadingContainer>
          </div>
        </div>
      </div>
    </>
  )
}

const historyColumns = [
  {
    label: 'Date',
    mobileColumnOrder: 2,
    mobileColumnSpan: 2,
    dataKey: 'transactionTime',
    dataFormat: transactionTime => toMediumDateFormat(transactionTime),
    dataClassName: 'transaction-table-date',
  },
  {
    label: 'Description',
    mobileColumnOrder: 1,
    mobileColumnSpan: 3,
    dataKey: 'description',
    dataClassName: 'transaction-table-description',
  },
  {
    label: 'Amount',
    mobileColumnOrder: 3,
    mobileColumnSpan: 1,
    dataKey: 'amount.amount',
    dataFormat: amount => toDollars({ value: amount }),
    dataClassName: ({ preformattedValue: amount }) =>
      classNames('transaction-table-amount', {
        'transaction-table-negative-amount': amount < 0,
      }),
    labelClassName: 'transaction-table-amount-label',
  },
]

export default Giving
