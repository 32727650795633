import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import Lottie from 'react-lottie'

import { gql, useMutation } from '@services/serviceUtils'
import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'
import { ReactComponent as GivingIcon } from '@common/images/icons/giving.svg'
import Button from '@shared/components/button/Button'
import { staticRoutes } from '@routing/routes'
import CauseCallout from '@common/components/callout/CauseCallout'
import { LOTTIE_CONFIG } from '@common/constants'
import heartsLottie from '@common/images/animations/hearts-lottie.json'

import styling from './startGiving.module.scss'

const setGivingStateMutation = gql`
  mutation SetGivingState($givingStateDTOInput: GivingStateDTOInput!) {
    setGivingState(givingStateDTOInput: $givingStateDTOInput) {
      success
    }
  }
`

const StartGiving = () => {
  const navigate = useNavigate()
  const { state } = useLocation()

  const [enableGiving, { loading: enableGivingLoading }] = useMutation(setGivingStateMutation, {
    variables: {
      givingStateDTOInput: {
        enableGiving: true,
      },
    },
  })

  const handleStartGivingClick = async () => {
    const { data: enableGivingData } = await enableGiving()

    // If successful, navigate to the giving screen
    if (enableGivingData?.setGivingState?.success === true) {
      navigate(staticRoutes.giving.pathname, { replace: true })
    }
  }

  return (
    <div className={classNames('create-account-black-page', styling['start-giving'])}>
      <div className='background-lottie'>
        <Lottie
          options={{
            ...LOTTIE_CONFIG,
            animationData: heartsLottie,
          }}
        />
      </div>
      <GreenwoodLogo className='logo' />
      <div className='start-flow-black-page-content'>
        <GivingIcon className='user-action-view-icon' />
        <h1>Give Back with Greenwood</h1>
        <p>
          Support our community by rounding up your purchases to the nearest whole dollar for the
          causes featured below.
        </p>
        <CauseCallout asList className={styling.callouts} />
        <div className={classNames('buttons-container', styling['giving-buttons-container'])}>
          <Button
            onClick={handleStartGivingClick}
            onDark
            isLoading={enableGivingLoading}
            data-cy='start-giving-button'
          >
            Start Giving
          </Button>
          <Button
            onClick={() => {
              // Go back to the last page visited before arriving at the Giving screens, or the dashboard
              navigate(state?.fromPathname || staticRoutes.dashboard.pathname, { state })
            }}
            data-cy='skip-giving-button'
            onDark
            ghost
          >
            Skip for Now
          </Button>
        </div>
      </div>
    </div>
  )
}

export default StartGiving
