import React, { useEffect } from 'react'
import { Navigate, useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Button from '@shared/components/button/Button'
import { resetTransferDetailsAction } from '@redux/transferDetails/transferDetailsActions'
import { staticRoutes } from '@routing/routes'
import { toDollars } from '@shared/utils'
import { FUNDING_FLOWS } from '@common/constants'
import { trackPage, trackEvent, SEGMENT_PAGE_NAMES, SEGMENT_EVENTS } from '@common/utils'

const TransferSuccess = () => {
  const { state } = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const transferDetails = useSelector(state => state.transferDetails)

  const transactionId = state?.transactionId
  const shouldSetupDirectDeposit = state?.shouldSetupDirectDeposit

  const handleDoneClick = () => {
    dispatch(resetTransferDetailsAction())

    if (state?.fundingFlow === FUNDING_FLOWS.TRANSFER) {
      navigate(state?.transferFlowStartPath || staticRoutes.moveMoneyAddMoney.pathname)
    } else if (shouldSetupDirectDeposit) {
      navigate(staticRoutes.directDepositSetup.pathname, { state: { fundingFlow: state?.fundingFlow } })
    } else {
      navigate(staticRoutes.dashboard.pathname)
    }

    trackEvent({ event: SEGMENT_EVENTS.TRANSFER_COMPLETED_DONE_BUTTON_CLICK })
  }

  const handleTransferClick = () => {
    dispatch(resetTransferDetailsAction())

    if (state?.fundingFlow === FUNDING_FLOWS.TRANSFER) {
      navigate(staticRoutes.transfer.pathname, { state })
    } else {
      navigate(staticRoutes.accountsForFunding.pathname, {
        state: {
          fundingFlow: state?.fundingFlow,
          shouldSetupDirectDeposit,
        },
      })
    }
  }

  // Tracks page visit
  useEffect(() => {
    trackPage({ name: SEGMENT_PAGE_NAMES.TRANSFER_MONEY_COMPLETED })
  }, [])

  return (
    <>
      {!transactionId && (
        <Navigate
          to={staticRoutes.transfer.pathname}
          state={{ fundingFlow: state?.fundingFlow }}
        />
      )}
      <h1>Transfer Completed</h1>
      <div data-cy='transfer-success-view'>
        {transferDetails?.amount && (
          <p>
            Congrats you have completed a transfer of{' '}
            {toDollars({ value: transferDetails.amount, isSuperscript: false })}.
          </p>
        )}
        <table className='simple-table fixed-height'>
          <tbody>
            <tr>
              <th>
                <span>Transaction Number</span>
              </th>
              <td>{transactionId}</td>
            </tr>
            <tr>
              <th>
                <span>Transfer From</span>
              </th>
              <td>{transferDetails?.fromAccount?.accountNumber}</td>
            </tr>
            <tr>
              <th>
                <span>Transfer To</span>
              </th>
              <td>{transferDetails?.toAccount?.accountNumber}</td>
            </tr>
            <tr>
              <th>
                <span>Note</span>
              </th>
              <td>{transferDetails?.note}</td>
            </tr>
            <tr>
              <th>
                <span>Status</span>
              </th>
              <td>Completed</td>
            </tr>
          </tbody>
        </table>
        <Button onClick={handleDoneClick} className='additional-button'>
          Done
        </Button>
        <Button onClick={handleTransferClick} outline className='additional-button'>
          Schedule Another Transfer
        </Button>
      </div>
    </>
  )
}

export default TransferSuccess
