export const KEYCODES = {
  ESC: 27,
  SPACE: 32,
  ENTER: 13,
  TAB: 9,
  UP: 38,
  DOWN: 40,
}

export const KEYS = {
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight',
  BACKSPACE: 'Backspace',
  DELETE: 'Delete',
}

export const EVENTS = {
  CLICK: ['click', 'touchstart', 'keyup'],
  MOUSE: ['mouseenter', 'mouseleave'],
  FOCUS: ['focusin', 'focusout'],
}

export const TIMEOUT = {
  FADE: 150,
  COLLAPSE: 350,
  SHOW: 0,
  HIDE: 0,
}
