import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { gql, useLazyQuery } from '@services/serviceUtils'
import Button from '@shared/components/button/Button'
import Modal from '@shared/components/modal/Modal'
import ModalBody from '@shared/components/modal/ModalBody'
import ModalHeader from '@shared/components/modal/ModalHeader'

import { FUNDING_FLOWS } from '@common/constants'
import { staticRoutes } from '@routing/routes'
import { resetTransferDetailsAction } from '@redux/transferDetails/transferDetailsActions'

import styling from './transferMoneyButton.module.scss'

const readinessForMoneyTransferQuery = gql`
  query ReadinessForMoneyTransfer {
    readinessForMoneyTransfer {
      message
      canInitiateTransfer
    }
  }
`

const TransferMoneyButton = ({ className, displayText, ...rest }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [showNotAbleToTransferModal, toggleShowNotAbleToTransferModal] = useState(false)
  const [modalMessage, setModalMessage] = useState('')
  const dispatch = useDispatch()

  const [getReadinessForMoneyTransfer, { loading }] = useLazyQuery(readinessForMoneyTransferQuery, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data?.readinessForMoneyTransfer?.canInitiateTransfer) {
        navigate(staticRoutes.transfer.pathname, {
          state: {
            fundingFlow: FUNDING_FLOWS.TRANSFER,
            transferFlowStartPath: pathname,
          },
        })
      } else {
        setModalMessage(data?.readinessForMoneyTransfer?.message)
        toggleShowNotAbleToTransferModal(true)
      }
    },
  })

  useEffect(() => {
    // reset the details of the transfer when user first arrives on the Transfer Money screen
    dispatch(resetTransferDetailsAction())
  }, [dispatch])

  return (
    <>
      <Button
        onClick={() => getReadinessForMoneyTransfer()}
        isLoading={loading}
        data-cy='move-money-transfer-money-button'
        className={classNames(className, styling['transfer-money-button'])}
        {...rest}
      >
        {displayText || 'Transfer Money'}
      </Button>
      <Modal
        centered
        size='md'
        open={showNotAbleToTransferModal}
        modalContentClassName={styling['transfer-money-modal-content']}
        toggleModal={() => toggleShowNotAbleToTransferModal(!showNotAbleToTransferModal)}
      >
        <ModalHeader
          className={styling['transfer-money-modal-header']}
          withCloseButton
          closeModal={() => toggleShowNotAbleToTransferModal(false)}
        >
          <h4 data-cy='transfer-money-not-able-to-transfer-modal-header'>Transfer Money</h4>
        </ModalHeader>
        <ModalBody className={styling['transfer-money-modal-body']}>
          <div>
            <p>{modalMessage}</p>
            <Button
              data-cy='transfer-money-not-able-to-transfer-modal-okay-button'
              onClick={() => toggleShowNotAbleToTransferModal(false)}
            >
              Okay
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

TransferMoneyButton.propTypes = {
  /**
   * Any additional class names to pass to the button element.
   */
  className: PropTypes.any,

  /**
   * displayText is the content of the button
   * Note: When passed this will overwrite the default button text
   */
  displayText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export default TransferMoneyButton
