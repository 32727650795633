import { get, orderBy } from 'lodash'

/**
 * Gets the visible column count, taking the mobile view flag, the
 * visible property, and the hideOnMobile property into consideration
 */
const getVisibleColumnCount = ({ columns, isMobile }) =>
  columns.reduce((visibleColumnCount, column) => {
    const { visible = true, hideOnMobile = false } = column
    return (visibleColumnCount += !visible || (isMobile && hideOnMobile) ? 0 : 1)
  }, 0)

/**
 * Builds CSS 'grid-template-columns' track values based on number of
 * columns defined, with ability to override the 'auto' value per-column
 */
export const getColumnTracks = ({ columns, isMobile }) => ({
  gridTemplateColumns: `${columns
    .map(column => {
      const { visible = true, hideOnMobile = false, trackSize = 'auto' } = column
      return visible && (!isMobile || !hideOnMobile) ? ` ${trackSize}` : ''
    })
    .join('')
    .trim()}`,
})

/**
 * Receives the (optional) format, the data row object, and the data key
 * path and returns the preformatted and formatted retrieved data
 */
export const getDataValue = ({ dataFormat, dataRow, dataKey }) => {
  const preformattedValue = get(dataRow, dataKey)
  const formattedValue = dataFormat ? dataFormat(preformattedValue, dataRow) : preformattedValue

  return { formattedValue, preformattedValue }
}

/**
 * For grid-view mobile layout, find the relative display rows according to
 * the mobileColumnSpan property
 */
export const calculateGridColumnLayout = ({ columns, isMobile }) => {
  const visibleColumnCount = getVisibleColumnCount({ columns, isMobile })

  let usedColumnSpan = 0
  const layout = [[]]

  for (let colIndex = 0; colIndex < columns.length; colIndex++) {
    const { mobileColumnSpan = 1, visible = true, hideOnMobile = false } = columns[colIndex]

    // Always 1 for desktop
    const _columnSpan = isMobile ? mobileColumnSpan : 1

    // Skip column if not visible
    if (!visible || (isMobile && hideOnMobile)) {
      continue
    }

    if (usedColumnSpan + _columnSpan <= visibleColumnCount) {
      // Column fits within current row
      usedColumnSpan += _columnSpan
      layout[layout.length - 1].push(colIndex)
    } else {
      // Column overflows, so it's part of the next row
      layout.push([colIndex])
      usedColumnSpan = _columnSpan
    }
  }

  return layout
}

/**
 * Checks if the current column index is the first column in any of
 * the layout rows
 */
export const isFirstOfRow = ({ layout, columnIndex }) => {
  for (let layoutIndex = 0; layoutIndex < layout.length; layoutIndex++) {
    if (layout[layoutIndex][0] === columnIndex) {
      return true
    }
  }

  return false
}

/**
 * Checks if the current column index is the last column in any of
 * the layout rows
 */
export const isLastOfRow = ({ layout, columnIndex }) => {
  for (let layoutIndex = 0; layoutIndex < layout.length; layoutIndex++) {
    const layoutRow = layout[layoutIndex]
    if (layoutRow[layoutRow.length - 1] === columnIndex) {
      return true
    }
  }

  return false
}

/**
 * Checks if the current column index is in a specific row of the layout
 */
const isInLayoutRow = ({ layoutRow, columnIndex }) => {
  for (let rowIndex = 0; rowIndex < layoutRow.length; rowIndex++) {
    if (layoutRow[rowIndex] === columnIndex) {
      return true
    }
  }

  return false
}

/**
 * Checks if the current column index is in the last row of the layout
 */
export const isInFirstRow = ({ layout, columnIndex }) =>
  isInLayoutRow({ layoutRow: layout[0], columnIndex })

/**
 * Checks if the current column index is in the last row of the layout
 */
export const isInLastRow = ({ layout, columnIndex }) =>
  isInLayoutRow({ layoutRow: layout[layout.length - 1], columnIndex })

/**
 * Reorders the columns according to the 'mobileColumnOrder' property, ascending. If the
 * property does not exist, loash will order those last in the same order they
 * appear in the column array
 */
export const orderColumns = columns => orderBy(columns, ['mobileColumnOrder'], ['asc'])

/**
 * Calculates number of pages based on total number of records and page size
 */
export const getNumberOfPages = ({ totalRecords, pageSize }) => Math.ceil(totalRecords / pageSize)

/**
 * Forms a list of visible page numbers including placeholders based on
 * pagination data
 */
export const getVisiblePages = ({ totalRecords, pageSize, currentPage, isMobile = false }) => {
  const pages = []
  const numberOfPages = getNumberOfPages({ totalRecords, pageSize })

  const leftLimit = Math.min(
    Math.max(currentPage - (isMobile ? 1 : 2), 1),
    Math.max(numberOfPages - (isMobile ? 2 : 4), 1)
  )

  const rightLimit = Math.max(
    Math.min(currentPage + (isMobile ? 1 : 2), numberOfPages),
    Math.min(numberOfPages, isMobile ? 3 : 5)
  )

  let leftPlaceholder = false
  let rightPlaceholder = false

  for (let index = 1; index <= numberOfPages; index++) {
    if (index === 1 || index === numberOfPages || (index >= leftLimit && index <= rightLimit)) {
      pages.push(index)
    } else if (index !== 1 && index < leftLimit && !leftPlaceholder) {
      pages.push('...')
      leftPlaceholder = true
    } else if (index > rightLimit && !rightPlaceholder) {
      pages.push('...')
      rightPlaceholder = true
    }
  }

  return pages
}
