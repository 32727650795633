import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useLocation } from 'react-router-dom'
import classNames from 'classnames'

import Card from '@shared/components/card/Card'
import ElevateCard from '@common/images/greenwood-elevate-card.png'
import UpgradeMembershipButton from '@common/components/UpgradeMembershipButton'
import { ReactComponent as Chevron } from '@shared/images/icons/chevron.svg'

import { staticRoutes } from '@routing/routes'
import { FUNDING_FLOWS } from '@common/constants'
import { trackEvent, SEGMENT_EVENTS } from '@common/utils'
import styling from './upgradeMembershipCallout.module.scss'

const UpgradeMembershipCallout = ({ isDashboardCta = false, segmentSource }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const contentText = isDashboardCta
    ? 'Get even more amazing benefits with an Elevate Membership.'
    : 'Get access to even more amazing benefits with the Greenwood Elevate Card.'

  const handleClick = () => {
    if (isDashboardCta) {
      navigate(staticRoutes.selectAccountTier.pathname, {
        state: {
          isUpgrading: true,
          fundingFlow: FUNDING_FLOWS.UPGRADE_MEMBERSHIP,
          cancelUpgradePathname: pathname,
        },
      })
    }
    trackEvent({
      event: SEGMENT_EVENTS.UPGRADE_MEMBERSHIP_BUTTON_CLICK(segmentSource),
    })
  }

  const cardClasses = classNames(
    styling['upgrade-membership-callout'],
    isDashboardCta && styling['is-dashboard-cta']
  )

  return (
    <Card className={cardClasses} data-cy='upgrade-membership-callout' onClick={handleClick}>
      <div className={styling['img-container']}>
        <img src={ElevateCard} alt='Elevate card' />
      </div>
      <div className={styling['text-container']}>
        <h3>Ready to Elevate your Greenwood Account?</h3>
        <p>{contentText}</p>
        {isDashboardCta ? (
          <p className='callout-link'>
            <span>Explore Benefits</span> <Chevron />
          </p>
        ) : null}
      </div>
      {!isDashboardCta ? <UpgradeMembershipButton /> : null}
    </Card>
  )
}

UpgradeMembershipCallout.propTypes = {
  isDashboardCta: PropTypes.bool,
}

export default UpgradeMembershipCallout
