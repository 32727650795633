import { cloneDeep } from 'lodash'

import {
  SET_API_VALIDATION_ERRORS,
  CLEAR_API_VALIDATION_ERROR,
  CLEAR_ALL_API_VALIDATION_ERRORS,
} from './apiValidationErrorsActions'

const initialState = {}

export const apiValidationsErrorsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_API_VALIDATION_ERRORS:
      /* Expect an object with key-value pairs describing the CustomerApplicationResponse field and
         the associated user-friendly error message to be displayed. */
      return action.payload

    case CLEAR_API_VALIDATION_ERROR: {
      /* Clear all errors for a single field. Deep-clone the original state so that it's not
         mutated. The expected payload is the name of the field */
      const errors = cloneDeep(state) || {}
      const fieldName = action.payload

      delete errors[fieldName]

      return errors
    }

    case CLEAR_ALL_API_VALIDATION_ERRORS:
      // Clear all errors for all fields
      return initialState

    default:
      return state
  }
}
