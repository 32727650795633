import { gql, useQuery } from '@apollo/client'

export const debitDepositFeesLimitsQuery = gql`
  query LinkedCardFeesLimits {
    linkedCardFeesLimits {
      linkedCardFees {
        amountFrom
        amountTo
        feePercent
      }
      userDailyLimit
      maxActiveCardLimit
      minTransAmount
      maxTransAmount
      userDailyNumber
    }
  }
`

const findFeeForAmount = ({ linkedCardFees = [], requestedAmount = 0 } = {}) => {
  return (
    linkedCardFees.find(linkedCardFee => {
      const amountFrom = linkedCardFee.amountFrom || 0
      const amountTo = linkedCardFee.amountTo || 999999999

      return amountFrom < requestedAmount && requestedAmount <= amountTo
    })?.feePercent || 0
  )
}

/**
 * Fetches the debit card deposit fee structure and the user's daily deposit limit. Returns the
 * associated fee percent and amount based on the requested deposit amount, and the user's daily
 * deposit limit.
 */
const useDebitDepositFeesLimits = ({ requestedAmount = 0 } = {}) => {
  const {
    data: {
      linkedCardFeesLimits: {
        linkedCardFees,
        userDailyLimit,
        maxActiveCardLimit,
        minTransAmount,
        maxTransAmount,
        userDailyNumber,
      } = {},
    } = {},
    loading,
    error,
  } = useQuery(debitDepositFeesLimitsQuery, { fetchPolicy: 'no-cache' })

  const fee = findFeeForAmount({ linkedCardFees, requestedAmount })

  return {
    feePercent: Math.max(0, fee * 100),
    feeAmount: Math.max(0, fee * requestedAmount),
    userDailyLimit,
    maxActiveCardLimit,
    minTransAmount,
    userPerTransactionLimit: maxTransAmount,
    userDailyNumber,
    loading,
    error,
  }
}

export default useDebitDepositFeesLimits
