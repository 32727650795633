import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { gql, useLazyQuery } from '@services/serviceUtils'
import { setCustomerAction } from '@redux/customer/customerActions'
import { hasAccountType } from './accountUtils'
import { PRODUCT_TYPES } from '@common/constants'

export const fetchAccountQuery = gql`
  query Accounts {
    accounts {
      accounts {
        accountType
      }
    }
  }
`

const useAccount = () => {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()

  const [getAccount, { loading: fetching, data: fetchData }] = useLazyQuery(fetchAccountQuery, {
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    // Store hasPremium and hasElevate account status in redux
    if (fetchData) {
      setData(fetchData)

      const accounts = fetchData.accounts?.accounts
      const hasPremium =
        hasAccountType({ accounts, accountType: PRODUCT_TYPES.premium }) ||
        hasAccountType({ accounts, accountType: PRODUCT_TYPES.premiumPlus })
      const hasElevate = hasAccountType({ accounts, accountType: PRODUCT_TYPES.elevateCard })

      dispatch(setCustomerAction({ hasPremium, hasElevate }))
    }
  }, [dispatch, fetchData])

  // Only update loading indicator once data has been fetched
  useEffect(() => {
    data && setLoading(fetching)
  }, [fetching, data])

  return {
    loading,
    getAccount,
  }
}

export default useAccount
