import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { CopyToClipboard as Copy } from 'react-copy-to-clipboard'

import { ReactComponent as CheckmarkIcon } from '@shared/images/icons/checkmark.svg'
import { ReactComponent as Eye } from '@shared/images/icons/eye.svg'
import { ReactComponent as ClosedEye } from '@shared/images/icons/eye-closed.svg'

import Button from '@shared/components/button/Button'

import { maskString } from '@shared/utils'

import styling from './copyToClipboard.module.scss'

// Default number masking function, all but the last four characters is an `x`
const showLastFour = value =>
  maskString({
    value,
    mask: 'x',
    length: value?.length - 4,
    spaceAfterMask: true,
  })

const CopyToClipboard = ({
  className,
  label,
  value,
  obscure = false,
  obscureToggle = false,
  obscureFunction = showLastFour,
  onCopyCallback,
  showInitially = false,
  copyIconTimeout = 2500,
  onShowHideToggleCallback,
}) => {
  const [showCopiedIcon, setShowCopiedIcon] = useState(false)
  const [show, setShow] = useState((!obscureToggle && !obscure) || (obscureToggle && showInitially))

  // Reverts the copy icon change after a specified timeout period
  useEffect(() => {
    if (showCopiedIcon) {
      const copyIconTimeoutId = setTimeout(() => {
        setShowCopiedIcon(false)
      }, copyIconTimeout)

      return () => {
        clearTimeout(copyIconTimeoutId)
      }
    }
  }, [showCopiedIcon, copyIconTimeout])

  const onCopyHandler = () => {
    onCopyCallback && onCopyCallback()
    setShowCopiedIcon(true)
  }

  const onShowHideToggle = value => {
    setShow(value)
    onShowHideToggleCallback && onShowHideToggleCallback()
  }
  return (
    <div className={classNames(styling['copy-to-clipboard'], className)}>
      <div>
        <span>
          {label}
          {obscureToggle && (
            <ShowHideToggle onToggle={onShowHideToggle} showInitially={showInitially} />
          )}
        </span>
        <span data-cy='copy-display-value'>{show ? value : obscureFunction(value)}</span>
      </div>
      <Copy onCopy={onCopyHandler} text={value}>
        <Button
          className={classNames(styling['copy-button'], { [styling['with-icon']]: showCopiedIcon })}
          ghost
          size='sm'
          aria-label={`Copy ${label}`}
          onClick={event => event.target.blur()}
        >
          {showCopiedIcon ? <CheckmarkIcon /> : 'Copy'}
        </Button>
      </Copy>
    </div>
  )
}

const ShowHideToggle = ({
  className,
  onDark,
  onToggle = () => {
    /* Empty default implementation */
  },
  showInitially = false,
}) => {
  const [show, setShow] = useState(showInitially)

  return (
    <Button
      ghost
      onDark={onDark}
      onClick={() => {
        setShow(!show)
        onToggle(!show)
      }}
      className={classNames(styling['show-hide-toggle'], className)}
      data-cy='copy-show-hide-button'
    >
      {show ? <ClosedEye /> : <Eye />}
    </Button>
  )
}

CopyToClipboard.propTypes = {
  /**
   * The value which will be copied when the Copy button is clicked
   */
  value: PropTypes.string.isRequired,

  /**
   * Additional `classname` library compatible classes to pass to the container
   */
  className: PropTypes.any,

  /**
   * Label displayed above the value to be copied
   */
  label: PropTypes.node,

  /**
   * Enables value obscurity/mask when true, without the ability to toggle full value visibility on
   * and off
   */
  obscure: PropTypes.bool,

  /**
   * Enables value obscurity/mask when true, with the ability to toggle full value visibility on
   * and off
   */
  obscureToggle: PropTypes.bool,

  /**
   * Overrides the default value obscurity function (which is to replace all except the last four
   * characters with an `x` character). One argument is passed which is the unobscured display
   * value. Must return a renderable node.
   */
  obscureFunction: PropTypes.func,

  /**
   * Callback function to use when item is copied (Optional)
   */
  onCopyCallback: PropTypes.func,

  /**
   * When true, show the value by default when obscurity is enabled. No effect when obscurity is
   * not enabled
   */
  showInitially: PropTypes.bool,

  /**
   * The amount of time the "copied" checkmark should display for. Default is 2500ms
   */
  copyIconTimeout: PropTypes.number,
}

export default CopyToClipboard
