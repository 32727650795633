export const SET_DEBIT_CARD_DEPOSIT_DETAILS = 'SET_DEBIT_CARD_DEPOSIT_DETAILS'
export const RESET_DEBIT_CARD_DEPOSIT_DETAILS = 'RESET_DEBIT_CARD_DEPOSIT_DETAILS'
export const SET_DEBIT_CARD_DEPOSIT_ATTEMPT_NUMBER = 'SET_DEBIT_CARD_DEPOSIT_ATTEMPT_NUMBER'

export const setDebitCardDepositDetailsAction = payload => {
  return {
    payload,
    type: SET_DEBIT_CARD_DEPOSIT_DETAILS,
  }
}

export const resetDebitCardDepositDetailsAction = () => {
  return {
    type: RESET_DEBIT_CARD_DEPOSIT_DETAILS,
  }
}

export const setDebitCardDepositAttemptNumberAction = payload => {
  return {
    payload,
    type: SET_DEBIT_CARD_DEPOSIT_ATTEMPT_NUMBER,
  }
}
