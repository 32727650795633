import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import Button from '@shared/components/button/Button'
import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'
import FormInput from '@shared/components/formInput/FormInput'
import {
  useCheckpointNavigation,
  trackEvent,
  trackPage,
  SEGMENT_EVENTS,
  SEGMENT_PAGE_NAMES,
  SEGMENT_SOURCE_DETAILS,
} from '@common/utils'
import { setCustomerAction } from '@redux/customer/customerActions'
import { CUSTOMER_NAME_VALIDATION_REGEX, CUSTOMER_NAME_MAX_LENGTH } from '@common/constants'
import ReduxField from '@common/components/reduxField/ReduxField'
import usePreventBrowserBackClick from '@common/utils/usePreventBrowserBackClick'
import { removeAlertsAction } from '@redux/alerts/alertsActions'

const nameValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('Required')
    .matches(CUSTOMER_NAME_VALIDATION_REGEX, 'First name contains invalid characters.'),
  lastName: Yup.string()
    .required('Required')
    .matches(CUSTOMER_NAME_VALIDATION_REGEX, 'Last name contains invalid characters.')
    .min(2, 'Last name must include at least 2 characters.'),
})

const CreateAccountName = ({ updateApplication, data, loading }) => {
  const [focusedInputSourceDetail, setFocusedInputSourceDetail] = useState(null)

  const dispatch = useDispatch()
  usePreventBrowserBackClick()

  // Track visits to registration name page
  useEffect(() => {
    trackPage({ name: SEGMENT_PAGE_NAMES.REGISTRATION_NAME })
  }, [])

  useEffect(() => {
    // When a user visits the name input page, track the event
    trackEvent({ event: SEGMENT_EVENTS.BANK_APPLICATION_STARTED })
  }, [])

  const { proceedToNextCheckpoint } = useCheckpointNavigation({
    checkpoints: data?.checkpoints,
    applicationStatus: data?.application?.customerApplicationStatus,
  })

  const handleSubmit = async (values, actions) => {
    /* If there is still a focused input, make sure to track the event since onBlur is not executed
       when the form submits */
    dispatch(removeAlertsAction())

    if (focusedInputSourceDetail) {
      trackEvent({
        event: SEGMENT_EVENTS.registrationFormFieldEntry({
          sourceDetail: focusedInputSourceDetail,
        }),
      })

      setFocusedInputSourceDetail(null)
    }

    const response = await updateApplication(values)
    if (response) {
      dispatch(setCustomerAction(values))
      proceedToNextCheckpoint()
    }

    actions.setSubmitting(false)
  }

  return (
    <div className='white-card-container' data-cy='authenticated-loaded-view'>
      <GreenwoodLogo className='logo' />
      <h1 id='name-header'>What's your name?</h1>
      <p>Let’s continue with some basic information.</p>
      <Formik
        initialValues={{
          firstName: data?.application?.firstName || '',
          lastName: data?.application?.lastName || '',
        }}
        onSubmit={handleSubmit}
        validationSchema={nameValidationSchema}
        enableReinitialize
      >
        {({ errors, isSubmitting, touched, handleBlur }) => {
          const disabled = isSubmitting || loading

          return (
            <Form data-cy='name-form'>
              <ReduxField
                as={FormInput}
                name='firstName'
                type='text'
                label='First Name'
                autoComplete='given-name'
                required
                showRequiredAsterisk={false}
                invalid={errors.firstName && touched.firstName}
                errorText={errors.firstName}
                disabled={disabled}
                autoFocus
                maxLength={CUSTOMER_NAME_MAX_LENGTH}
                data-cy='create-account-name-first-name-input'
                onFocus={() => {
                  setFocusedInputSourceDetail(SEGMENT_SOURCE_DETAILS.FIRST_NAME)
                }}
                onBlur={e => {
                  handleBlur(e)

                  // Track any time the user leaves the first name field during registration
                  trackEvent({
                    event: SEGMENT_EVENTS.registrationFormFieldEntry({
                      sourceDetail: SEGMENT_SOURCE_DETAILS.FIRST_NAME,
                    }),
                  })

                  setFocusedInputSourceDetail(null)
                }}
              />
              <ReduxField
                as={FormInput}
                name='lastName'
                type='text'
                label='Last Name'
                autoComplete='family-name'
                required
                showRequiredAsterisk={false}
                invalid={errors.lastName && touched.lastName}
                errorText={errors.lastName}
                disabled={disabled}
                maxLength={CUSTOMER_NAME_MAX_LENGTH}
                data-cy='create-account-name-last-name-input'
                onFocus={() => {
                  setFocusedInputSourceDetail(SEGMENT_SOURCE_DETAILS.LAST_NAME)
                }}
                onBlur={e => {
                  handleBlur(e)

                  // Track any time the user leaves the last name field during registration
                  trackEvent({
                    event: SEGMENT_EVENTS.registrationFormFieldEntry({
                      sourceDetail: SEGMENT_SOURCE_DETAILS.LAST_NAME,
                    }),
                  })

                  setFocusedInputSourceDetail(null)
                }}
              />
              <br />
              <Button type='submit' isLoading={disabled}>
                NEXT
              </Button>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

CreateAccountName.propTypes = {
  /**
   * Function used to update the application object
   */
  updateApplication: PropTypes.func,

  /**
   * Error data
   */
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),

  /**
   * The form data
   */
  data: PropTypes.object,

  /**
   * Loading indicator when the data is initially fetched
   */
  loading: PropTypes.bool,
}

export default CreateAccountName
