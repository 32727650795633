import { RISK_API_CNAME, SOCURE_KEY } from '@common/constants'

const deviceFPOptions = {
  publicKey: SOCURE_KEY,
  endpoint: `https://${RISK_API_CNAME}`,
  userConsent: true,
  context: 'signup',
}

export const runDeviceRisk = () => {
  /* Wraps the synchronous function and callback execution in a promise
     so that it can be executed asynchronously */
  return new Promise(resolve => {
    window.devicer.run(deviceFPOptions, response => resolve(response))
  })
}
