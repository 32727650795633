import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'

import Button from '@shared/components/button/Button'
import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'
import FormSsnInput from '@shared/components/formSsnInput/FormSsnInput'
import ExternalLink from '@common/components/link/ExternalLink'
import {
  useCheckpointNavigation,
  trackPage,
  SEGMENT_PAGE_NAMES,
  trackEvent,
  SEGMENT_EVENTS,
  BOOTSTRAP_SITEMAP_RESOURCES,
  getBootstrapSitemapResource,
  SEGMENT_SOURCE_DETAILS,
} from '@common/utils'
import ReduxField from '@common/components/reduxField/ReduxField'
import { removeAlertsAction } from '@redux/alerts/alertsActions'
import styling from './createAccountSSN.module.scss'

const ssnValidationSchema = Yup.object().shape({
  taxNumber: Yup.string()
    .matches(/^[0-9]*$/, 'SSN must be a number')
    .length(9, 'SSN must be 9 digits')
    .required('Required'),
})

const CreateAccountSSN = ({ updateApplication, data }) => {
  const dispatch = useDispatch()
  const [focusedInputSourceDetail, setFocusedInputSourceDetail] = useState(null)

  const { proceedToNextCheckpoint } = useCheckpointNavigation({
    checkpoints: data?.checkpoints,
    applicationStatus: data?.application?.customerApplicationStatus,
  })

  const handleSubmit = async (values, actions) => {
    /* If there is still a focused input, make sure to track the event since onBlur is not executed
       when the form submits */

    dispatch(removeAlertsAction())
    if (focusedInputSourceDetail) {
      trackEvent({
        event: SEGMENT_EVENTS.registrationFormFieldEntry({
          sourceDetail: focusedInputSourceDetail,
        }),
      })

      setFocusedInputSourceDetail(null)
    }
    const response = await updateApplication({ taxNumber: { value: values.taxNumber } })

    if (response) {
      proceedToNextCheckpoint()
    }

    actions.setSubmitting(false)
  }

  const { url: faqPrivacySecurityUrl } = getBootstrapSitemapResource(
    BOOTSTRAP_SITEMAP_RESOURCES.FAQ_PRIVACY_SECURITY
  )

  // Track registration social page visits
  useEffect(() => {
    trackPage({ name: SEGMENT_PAGE_NAMES.REGISTRATION_SOCIAL })
  }, [])

  return (
    <div
      className={classNames('white-card-container', styling['create-account-ssn'])}
      data-cy='authenticated-loaded-view'
    >
      <GreenwoodLogo className='logo' />
      <h1>Social Security number</h1>
      <p>
        We need to verify your identity with your Social Security number. This won’t affect your
        credit score.
      </p>
      <Formik
        validationSchema={ssnValidationSchema}
        initialValues={{
          taxNumber: '',
        }}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ errors, isSubmitting, touched, setFieldValue, setFieldTouched }) => {
          return (
            <Form data-cy='ssn-form'>
              <ReduxField
                as={FormSsnInput}
                name='taxNumber'
                label='Social Security Number (SSN)'
                required
                showRequiredAsterisk={false}
                invalid={errors.taxNumber && touched.taxNumber}
                errorText={errors.taxNumber}
                disabled={isSubmitting}
                canShow
                canClear
                onFocus={() => {
                  setFocusedInputSourceDetail(SEGMENT_SOURCE_DETAILS.SOCIAL)
                }}
                onBlur={() => {
                  // set field 'touched' on blur to trigger field validation
                  setFieldTouched('taxNumber')

                  // Track any time the user leaves the SSN field during registration
                  trackEvent({
                    event: SEGMENT_EVENTS.registrationFormFieldEntry({
                      sourceDetail: SEGMENT_SOURCE_DETAILS.SOCIAL,
                    }),
                  })

                  setFocusedInputSourceDetail(null)
                }}
                onChange={value => {
                  // save custom value to field
                  setFieldValue('taxNumber', value)
                }}
              />
              <Button type='submit' isLoading={isSubmitting}>
                NEXT
              </Button>
            </Form>
          )
        }}
      </Formik>
      <div className={styling['faq-link-container']}>
        <ExternalLink className='underlined-link bold' to={faqPrivacySecurityUrl}>
          Why does Greenwood need your social security?
        </ExternalLink>
      </div>
    </div>
  )
}

CreateAccountSSN.propTypes = {
  /**
   * Function used to update the application object
   */
  updateApplication: PropTypes.func,

  /**
   * The form data
   */
  data: PropTypes.object,
}

export default CreateAccountSSN
