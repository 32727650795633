import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Loading from '@shared/components/Loading'

import styling from './loadingContainer.module.scss'

const LoadingContainer = ({ className, children, error, errorMessage, loading }) => {
  return loading || !!error ? (
    <div className={classNames('row', styling.container, className)}>
      {loading || error?.networkError?.statusCode === 401 ? ( // suppress the errorMessage display if a 401, since they are about to get booted anyway
        <span className={styling.loading} aria-label='Loading'>
          <Loading />
        </span>
      ) : (
        <span className='error'>{errorMessage || 'Error loading data.'}</span>
      )}
    </div>
  ) : (
    children
  )
}

LoadingContainer.propTypes = {
  /**
   * Additional classnames to be applied to the container. Can be anything compatible with the
   * classnames library
   */
  className: PropTypes.any,

  /**
   * Child node to display when loading is false and there is no error
   */
  children: PropTypes.node,

  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),

  errorMessage: PropTypes.string,

  loading: PropTypes.bool.isRequired,
}

export default LoadingContainer
