import axios from 'axios'

import { purgeStore, store } from '@redux/store'
import { REST_API_BASE_URL, API_HEADERS } from '@common/constants'
import { refreshToken } from '@services/serviceUtils'
import { setTokenAction } from '@redux/auth/authActions'
import { addAlertAction } from '@redux/alerts/alertsActions'
import { getErrorByStatusCode } from '@shared/utils'
import { getAnonymousId } from '@common/utils'

const CancelToken = axios.CancelToken

const recaptchaUrls = ['/login']

const getHeaders = ({ url = '' }) => {
  const headers = {}
  const anonymousId = getAnonymousId()

  // Only add this optional header if the value exists
  if (anonymousId) {
    headers['analytics-anonymous-id'] = anonymousId
  }
  // Pass remember_me_id all the time for MFA
  headers['remember-me-id'] = window.sessionStorage.getItem('mfaRememberMeId')

  if (recaptchaUrls.includes(url)) {
    // Set the recaptcha token in header if available in storage and user is attempting to login.
    headers['recaptcha-token'] = window.sessionStorage.getItem('recaptchaToken')
  }

  return headers
}

// Auth Instance ------
// set up auth instance, that listens for 401
// use this for all RESTful calls that require auth
const authInstance = axios.create({
  baseURL: REST_API_BASE_URL,
  headers: API_HEADERS,
})

authInstance.interceptors.request.use(config => {
  if (!config.cancelToken) {
    // allow for preattached cancel tokens, so specific call targeting is possible
    config.cancelToken = new CancelToken(function executor (c) {
      // An executor function receives a cancel function as a parameter
      authInstance.cancelLastRequest = c
    })
  }

  // Add any additional headers
  if (config.headers) {
    config.headers = {
      ...config.headers,
      ...getHeaders({}),
    }
  }

  return config
})

authInstance.interceptors.response.use(
  response => response,
  async error => {
    const status = error?.response?.status
    const data = error?.response?.data
    const message = data?.message
    const requestId = data?.requestId

    // If unauthorized, attempt to refresh the token if the refresh token exists.
    // If no refresh token exists or an error occurs trying to refresh the token, log the user out.
    if (status === 401) {
      const storeState = store.getState()

      if (storeState.auth.token?.refresh_token) {
        try {
          const response = refreshToken({ refreshToken: storeState.auth.token.refresh_token })
          store.dispatch(setTokenAction(response.token))
        } catch {
          purgeStore({})
        }
      } else {
        purgeStore({})
      }
    } else {
      const alertActionConfig = error?.config?.alertActionConfig || {}

      /* Globally display the associated error message. First check if a message was supplied by
         response. If not provided, use the fallback by statusCode. */
      store.dispatch(
        addAlertAction({
          text: message || getErrorByStatusCode(status),
          subText: requestId ? `(${requestId})` : undefined,
          ...alertActionConfig,
        })
      )
    }
    return Promise.reject(error.response)
  }
)

// Public Instance ------
// use this for all RESTful calls that don't require auth

const publicInstance = axios.create({
  baseURL: REST_API_BASE_URL,
  headers: API_HEADERS,
})

publicInstance.interceptors.request.use(config => {
  if (!config.cancelToken) {
    // allow for preattached cancel tokens, so specific call targeting is possible
    config.cancelToken = new CancelToken(function executor (c) {
      // An executor function receives a cancel function as a parameter
      publicInstance.cancelLastRequest = c
    })
  }

  // Add any additional headers
  if (config.headers) {
    config.headers = {
      ...config.headers,
      ...getHeaders({ url: config?.url }),
    }
  }

  return config
})

publicInstance.interceptors.response.use(
  response => response,
  async error => {
    /* Globally display
        the associated error message. First check if a message was supplied by
        response. If not provided, use the fallback by statusCode */

    const data = error?.response?.data
    const message = data?.message
    const requestId = data?.requestId

    const alertActionConfig = error?.config?.alertActionConfig || {}

    const alertConfig = {
      text: message || getErrorByStatusCode(error.response.status),
      subText: requestId ? `(${requestId})` : undefined,
      ...alertActionConfig,
    }

    store.dispatch(addAlertAction(alertConfig))

    return Promise.reject(error.response)
  }
)

const INSTANCES = {
  PUBLIC: 'PUBLIC',
  AUTH: 'AUTH',
}

/* Aligns with available methods that are configuable in Axios:
   https://axios-http.com/docs/api_intro */
const METHODS = {
  GET: 'GET',
  DELETE: 'DELETE',
  HEAD: 'HEAD',
  OPTIONS: 'OPTIONS',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
}

const _axios = async ({
  url = '',
  instance = INSTANCES.PUBLIC,
  method = METHODS.GET,
  data = {},
  params = {},
  onError,
  alertActionConfig = {},
  ...axiosConfig
}) => {
  const config = {
    ...axiosConfig,
    alertActionConfig,
  }

  try {
    if (instance === INSTANCES.PUBLIC) {
      return await publicInstance({ url, method, data, params, ...config })
    } else {
      return await authInstance({ url, method, data, params, ...config })
    }
  } catch (error) {
    /* Unless the implementing code supplies an `onError` callback, any errors generated at this
       level get suppressed. This error handling is in addition to the global error handling at
       the response level which presents an error message to the user. */
    onError && onError(error)
  }
}

export default _axios
export { INSTANCES, METHODS }
