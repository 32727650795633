import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { Elements } from '@stripe/react-stripe-js'
import { useDispatch } from 'react-redux'

import LoadingContainer from '@shared/components/loadingContainer/LoadingContainer'
import Button from '@shared/components/button/Button'
import { ReactComponent as DebitCardIcon } from '@common/images/icons/debit-card.svg'
import StripePaymentForm from '@common/components/stripePayment/StripePaymentForm'

import { getStripeDisclaimerText, stripePromise } from '@common/constants'
import { gql, useLazyQuery, useMutation } from '@services/serviceUtils'
import { staticRoutes } from '@routing/routes'
import { addAlertAction } from '@redux/alerts/alertsActions'
import { DEFAULT_ALERT_DISMISS_DELAY } from '@shared/constants/uiConstants'

import styling from './updatePaymentMethod.module.scss'

const paymentMethodForSubscriptionQuery = gql`
  query PaymentMethodForSubscription($subId: ID!) {
    paymentMethodForSubscription(subId: $subId) {
      maskedNumber
      network
    }
  }
`

const paymentMethodSessionQuery = gql`
  query PaymentMethodSession {
    paymentMethodSession {
      paymentSessionId
      paymentSessionType
    }
  }
`

const upgradeDdaMutation = gql`
  mutation UpgradeDDA($upgradeDDARequestDTOInput: UpgradeDDARequestDTOInput!) {
    upgradeDDA(upgradeDDARequestDTOInput: $upgradeDDARequestDTOInput) {
      id
      paymentFailure {
        paymentFailureReason
      }
      paymentDetails {
        paymentSessionId
        paymentSessionType
      }
    }
  }
`

const UpdatePaymentMethod = ({
  subscriptionId = '',
  isUpgrading = true,
  feeKey = '',
  newProduct = '',
}) => {
  const navigate = useNavigate()
  const { state = {} } = useLocation()
  const dispatch = useDispatch()

  const [isUpdatingPaymentMethod, setIsUpdatingPaymentMethod] = useState(false)

  const { cancelUpgradePathname = '' } = state || {}

  const [
    getPaymentMethod,
    {
      data: paymentMethodData,
      loading: paymentMethodLoading,
      error: paymentMethodError,
      refetch: refetchPaymentMethod,
    },
  ] = useLazyQuery(paymentMethodForSubscriptionQuery, {
    variables: { subId: subscriptionId },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })

  const [
    getPaymentMethodSessionToken,
    {
      data: paymentMethodSessionData,
      loading: paymentMethodSessionLoading,
      error: paymentMethodSessionError,
    },
  ] = useLazyQuery(paymentMethodSessionQuery, { fetchPolicy: 'no-cache' })

  const [submitUpgradeDda, { loading: upgradeDdaLoading, error: upgradeDdaError }] = useMutation(
    upgradeDdaMutation
  )

  const options = useMemo(() => {
    return {
      clientSecret: paymentMethodSessionData?.paymentMethodSession?.paymentSessionId,
      appearance: {
        theme: 'night',
        variables: {
          colorDanger: '#dc3519',
          colorPrimary: '#1f9081',
          borderRadius: '12px',
          fontSizeBase: '14px',
          fontLineHeight: '18px',
          spacingUnit: '5px',
          spacingGridRow: '18px',
          spacingGridColumn: '7px',
        },
      },
    }
  }, [paymentMethodSessionData])

  const buttonText = 'Confirm Payment'
  const showPaymentButtons = !paymentMethodLoading && !paymentMethodError

  useEffect(() => {
    if (isUpgrading && subscriptionId) {
      getPaymentMethod()
    }
  }, [isUpgrading, subscriptionId, getPaymentMethod])

  const getUpdatePaymentMethodForm = () => (
    <LoadingContainer
      loading={paymentMethodSessionLoading}
      error={paymentMethodSessionError}
      errorMessage='Error loading update payment method form'
    >
      <Elements stripe={stripePromise} options={options}>
        <StripePaymentForm
          buttonText='Update Payment Method'
          isUpdatingMethod
          subscriptionId={subscriptionId}
          showStripePrivacyPolicyLink={false}
          className={styling['update-payment-method-form']}
          handleSuccess={() => {
            setIsUpdatingPaymentMethod(false)
            refetchPaymentMethod()
          }}
          sessionType={paymentMethodSessionData?.paymentMethodSession?.paymentSessionType}
        />
      </Elements>
    </LoadingContainer>
  )

  const handleConfirmUpgrade = async () => {
    const result = await submitUpgradeDda({
      variables: {
        upgradeDDARequestDTOInput: {
          agreementIds: newProduct?.agreements?.map(agreement => agreement.id),
          productId: newProduct?.id,
          feeKey,
        },
      },
    })

    const { paymentFailure = null, id = '', paymentDetails = null } = result?.data?.upgradeDDA || {}
    const upgradeSucceeded = id && !paymentFailure && !paymentDetails

    if (upgradeSucceeded) {
      navigate(staticRoutes.membershipUpgradeConfirmation.pathname, {
        state: {
          isUpgrading,
          isUpgradeSuccessful: true,
          productType: newProduct?.productType,
          fundingFlow: state?.fundingFlow,
          cancelUpgradePathname,
        },
      })
    } else if (paymentFailure) {
      dispatch(
        addAlertAction({
          autoDismissDelay: DEFAULT_ALERT_DISMISS_DELAY,
          text:
            paymentFailure?.paymentFailureReason ||
            "Uh oh, something went wrong. We aren't able to process your payment at this time.",
        })
      )
    }
  }

  const handleUpdatePaymentMethod = () => {
    setIsUpdatingPaymentMethod(true)
    getPaymentMethodSessionToken()
  }

  return (
    <LoadingContainer
      loading={false}
      error={upgradeDdaError}
      errorMessage='Error upgrading account'
    >
      <div className={styling['update-payment-method-container']}>
        <LoadingContainer
          loading={paymentMethodLoading}
          error={paymentMethodError}
          errorMessage='Error loading payment method'
        >
          <h2>Payment Method</h2>
          <table className={classNames('simple-table', 'payment-method-table')}>
            <tbody>
              <tr>
                <th>
                  <div className='payment-method-details'>
                    <DebitCardIcon data-cy='payment-method-card-icon' />
                    <div>
                      <span>{paymentMethodData?.paymentMethodForSubscription?.network}</span>
                      <span>
                        (...{paymentMethodData?.paymentMethodForSubscription?.maskedNumber})
                      </span>
                    </div>
                  </div>
                </th>
              </tr>
            </tbody>
          </table>
          {!isUpdatingPaymentMethod ? (
            <p className={styling['payment-disclaimer-text']}>
              {getStripeDisclaimerText(buttonText)}
            </p>
          ) : (
            getUpdatePaymentMethodForm()
          )}
        </LoadingContainer>
        <div
          className={classNames('buttons-container', {
            [styling['only-back-button']]: isUpdatingPaymentMethod,
          })}
        >
          {showPaymentButtons && (
            <>
              {isUpdatingPaymentMethod ? (
                <Button onClick={() => setIsUpdatingPaymentMethod(false)} outline>
                  Back
                </Button>
              ) : (
                <>
                  <Button
                    onClick={handleConfirmUpgrade}
                    isLoading={upgradeDdaLoading}
                    disabled={upgradeDdaLoading}
                  >
                    Confirm Payment
                  </Button>
                  <Button
                    onClick={handleUpdatePaymentMethod}
                    outline
                    isLoading={paymentMethodSessionLoading}
                    disabled={paymentMethodLoading}
                  >
                    Update Payment Method
                  </Button>
                </>
              )}
            </>
          )}
          <Button onClick={() => navigate(cancelUpgradePathname, { replace: true })} ghost>
            Cancel Upgrade
          </Button>
        </div>
      </div>
    </LoadingContainer>
  )
}

UpdatePaymentMethod.propTypes = {
  subscriptionId: PropTypes.string.isRequired,
  isUpgrading: PropTypes.bool,
  feeKey: PropTypes.string,
  newProduct: PropTypes.any,
}

export default UpdatePaymentMethod
