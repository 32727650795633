import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link, useNavigate } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { ReactComponent as PencilIcon } from '@common/images/icons/pencil.svg'

import Button from '@shared/components/button/Button'
import FormRadio from '@shared/components/formRadio/FormRadio'
import LoadingContainer from '@shared/components/loadingContainer/LoadingContainer'

import { staticRoutes } from '@routing/routes'
import { useApplication } from '@common/utils'
import { gql, useLazyQuery } from '@services/serviceUtils'

import styling from './reportCard.module.scss'

const reportValidationSchema = Yup.object().shape({
  status: Yup.string().required('An option must be selected.'),
})

const ReportCard = ({ last4, updateCardStatus, reportingOptions, canActivateCard = false }) => {
  const { getApplication, data: appData } = useApplication()
  const [reportStatus, setReportStatus] = useState()
  const [showReviewStep, setShowReviewStep] = useState(false)
  const [showSuccessStep, setShowSuccessStep] = useState(false)

  const { hideReportCardLostStolen } = useFlags()

  const customer = useSelector(state => state.customer)
  const firstName = customer?.firstName || ''
  const lastName = customer?.lastName || ''
  const userFullName = `${firstName} ${lastName}`

  useEffect(() => {
    getApplication()
  }, [getApplication])

  const [
    getShippingOptions,
    { data: shippingOptionsData, error: shippingOptionsError, loading: shippingOptionsLoading },
  ] = useLazyQuery(gql`
    query ShippingOptions {
      shippingOptions {
        descriptionKey
        id
        titleKey
      }
    }
  `)

  let shippingOptions = []
  if (shippingOptionsData?.shippingOptions?.length) {
    shippingOptions = shippingOptionsData.shippingOptions.map(option => {
      return {
        label: (
          <p className={styling['shipping-option']}>
            <span>{option.titleKey}</span> ({option.descriptionKey})
          </p>
        ),
        value: option.id,
      }
    })
  }

  useEffect(() => {
    if (showReviewStep) {
      getShippingOptions()
    }
  }, [getShippingOptions, showReviewStep])

  const handleReportSuccess = () => {
    setShowReviewStep(false)
    setShowSuccessStep(true)
    cardReplacementSubmitCardSent()
  }

  const handleReportStepOne = (values, actions) => {
    setReportStatus(values.status)
    setShowReviewStep(true)
    actions.setSubmitting(false)
  }

  const handleReportStepTwo = async (values, actions) => {
    try {
      await updateCardStatus({
        variables: {
          updatedCardStatus: {
            shippingOption: values.shippingOption,
            cardUpdateStatus: reportStatus,
          },
        },
      }, handleReportSuccess)
    } catch (error) {
      // error handling is done globally via AlertsManager
    } finally {
      actions.setSubmitting(false)
    }
  }

  const handleReportDone = () => {
    setShowSuccessStep(false)
  }

  const defaultShippingOption = shippingOptionsData?.shippingOptions[0]?.id

  const reasons =
    reportingOptions?.map(option => {
      return {
        label: option.caption,
        value: option.cardStatus,
      }
    }) || []

  const getShippingOptionList = ({ errors, touched, isSubmitting }) => {
    if (shippingOptions.length > 1) {
      return (
        <Field
          as={FormRadio}
          name='shippingOption'
          id='shippingOption'
          type='radio'
          invalid={errors.shippingOption && touched.shippingOption}
          errorText={errors.shippingOption}
          disabled={isSubmitting}
          options={shippingOptions}
          data-cy='report-card-shipping-option-list'
          className={styling['shipping-options-radio']}
          defaultSelected={defaultShippingOption}
        />
      )
    }
    return (
      <span className={styling['only-shipping-option']} data-cy='report-card-shipping-option-list'>
        {shippingOptions[0]?.label || null}
      </span>
    )
  }

  const navigate = useNavigate()

  const cardReplacementSubmitCardSent = () => {
    navigate(staticRoutes.replacementCardSent.pathname)
  }

  const getReviewStepForm = () => (
    <LoadingContainer
      error={shippingOptionsError}
      errorMessage='Error loading shipping options.'
      loading={shippingOptionsLoading}
    >
      <h2>Is this information correct?</h2>
      <p>
        If you want to change any information below, please{' '}
        <Link
          className='underlined-link bold'
          to={staticRoutes.settingsHelp.pathname}
          data-cy='report-card-contact-us-link'
        >
          Contact Us
        </Link>
        .
      </p>
      <Formik
        initialValues={{
          shippingOption: defaultShippingOption,
        }}
        onSubmit={handleReportStepTwo}
      >
        {({ errors, isSubmitting, touched }) => (
          <Form className={styling.form} noValidate>
            <table className={classNames('simple-table', styling['report-table'])}>
              <tbody>
                <tr>
                  <th scope='row'>
                    <span>Card</span>
                  </th>
                  <td>DEBIT Card (...{last4})</td>
                </tr>
                <tr>
                  <th scope='row'>
                    <span>Reason</span>
                  </th>
                  <td>
                    {reasons.find(reason => reason.value === reportStatus)?.label}
                    <Button
                      className={classNames('edit-button', styling['change-reason-btn'])}
                      ghost
                      onClick={() => setShowReviewStep(false)}
                      size='sm'
                      id='edit-button'
                    >
                      <PencilIcon />
                    </Button>
                  </td>
                </tr>
                <tr>
                  <th scope='row'>
                    <span>Cardholder</span>
                  </th>
                  <td>{userFullName}</td>
                </tr>
                <tr>
                  <th scope='row'>
                    <span>Mailing Address</span>
                  </th>
                  <td>
                    {appData.application?.deliveryAddress}
                    <br />
                    {appData.application?.locality ? `${appData.application?.locality}, ` : ''}
                    {appData.application?.subdivisions && appData.application?.subdivisions[0]}{' '}
                    {appData.application?.postalCode}
                  </td>
                </tr>
                <tr>
                  <th scope='row'>
                    <span>Shipping Method</span>
                    <p className='disclaimer-text'>
                      Please refer to your Account Agreement for card replacement and delivery fees.
                    </p>
                  </th>
                  <td>{getShippingOptionList({ errors, touched, isSubmitting })}</td>
                </tr>
              </tbody>
            </table>

            <Button className={styling['submit-btn']} type='submit' isLoading={isSubmitting}>
              Request Replacement
            </Button>
          </Form>
        )}
      </Formik>
    </LoadingContainer>
  )

  const reportCardText =
    `If you think someone used your card without your permission,${hideReportCardLostStolen ? ' or if you need to report your card lost or stolen, ' : ''} please`

  const getSuccessOrReportStep = () => {
    return showSuccessStep ? (
      <>
        <h2 data-cy='report-card-tab-title'>We've received your replacement request</h2>
        <p>
          A new card will be sent to your mailing address.
          <br />
          Your new card will have a different card number.
        </p>
        <Button className={styling['submit-btn']} onClick={handleReportDone}>
          DONE
        </Button>
      </>
    ) : (
      <>
        <h2 data-cy='report-card-tab-title'>Report Card</h2>
        <p>
          {reportCardText}{' '}
          <Link
            className='underlined-link bold'
            to={staticRoutes.settingsHelp.pathname}
            data-cy='report-card-contact-us-link'
          >
            Contact Us
          </Link>
          .
        </p>
        {reasons.length && !hideReportCardLostStolen ? getReportReasonForm() : null}
      </>
    )
  }

  const getReportReasonForm = () => (
    <Formik
      initialValues={{
        status: '',
      }}
      onSubmit={handleReportStepOne}
      validationSchema={reportValidationSchema}
    >
      {({ errors, isSubmitting, touched }) => (
        <Form className={styling.form} noValidate>
          <Field
            as={FormRadio}
            name='status'
            type='radio'
            invalid={errors.status && touched.status}
            errorText={errors.status}
            disabled={isSubmitting}
            options={reasons}
            className={styling['report-options-radio']}
          />
          <Button
            className={styling['submit-btn']}
            type='submit'
            isLoading={isSubmitting}
            data-cy='report-card-next-button'
            outline={canActivateCard}
          >
            NEXT
          </Button>
        </Form>
      )}
    </Formik>
  )

  return <>{showReviewStep ? getReviewStepForm() : getSuccessOrReportStep()}</>
}

ReportCard.propTypes = {
  /* Last 4 digits of the card number */
  last4: PropTypes.string,

  /* Function to call to update the card status */
  updateCardStatus: PropTypes.func.isRequired,

  /**
   * User's full name
   */
  userFullName: PropTypes.string,

  /**
   * Whether or not the card can be activated. This applies a secondary styling to the `NEXT`
   * button so that only one primary action is visible upon landing on the My Card page.
   * Default is false.
   */
  canActivateCard: PropTypes.bool,
}

export default ReportCard
