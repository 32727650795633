import React from 'react'
import classNames from 'classnames'

import { ReactComponent as InfoIcon } from '@shared/images/icons/info.svg'

import styling from './inlineNotification.module.scss'

const InlineNotification = ({
  className,
  text,
  showIcon = true,
  iconType = ICON_TYPES.INFO,
  withBackground = true,
}) => {
  return (
    <div
      className={classNames(
        styling['inline-notification'],
        {
          [styling['with-background']]: withBackground,
        },
        className
      )}
    >
      <p>
        {showIcon && <Icon iconType={iconType} />}
        {text}
      </p>
    </div>
  )
}

const Icon = ({ iconType }) => {
  switch (iconType) {
    case ICON_TYPES.INFO:
      return <InfoIcon />
    default:
      return null
  }
}

const ICON_TYPES = {
  INFO: 'INFO',
}

export default InlineNotification
export { ICON_TYPES }
