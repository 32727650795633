import React from 'react'
import classNames from 'classnames'

import ExternalLink from '@common/components/link/ExternalLink'
import { ReactComponent as AppStoreBadge } from '@common/images/icons/app-store-badge.svg'
import { ReactComponent as GooglePlayBadge } from '@common/images/icons/google-play-badge.svg'
import { trackEvent, SEGMENT_EVENTS, SEGMENT_SOURCE_DETAILS } from '@common/utils'

import styling from './appLinks.module.scss'

const AppLinks = ({ className, alignMobileHorizontally = false }) => {
  const clickListener = () => {
    trackEvent({
      event: SEGMENT_EVENTS.DASHBOARD_BUTTON_CLICK(SEGMENT_SOURCE_DETAILS.DOWNLOAD_APP),
    })
  }

  const appLinkClasses = classNames(
    styling['link-container'],
    className,
    alignMobileHorizontally && styling['align-horizontally']
  )
  return (
    <div className={appLinkClasses} data-cy='app-link-container'>
      <ExternalLink
        to='https://apps.apple.com/us/app/greenwood-mobile-banking/id1627062465'
        clickListener={clickListener}
      >
        <AppStoreBadge />
      </ExternalLink>

      <ExternalLink
        to='https://play.google.com/store/apps/details?id=com.bankgreenwood.app'
        clickListener={clickListener}
      >
        <GooglePlayBadge />
      </ExternalLink>
    </div>
  )
}

AppLinks.propTypes = {}

export default AppLinks
