import React from 'react'
import MaskedInput from 'react-text-mask'

import FormInput from '@shared/components/formInput/FormInput'
import FormInputAddon from '@shared/components/formInput/FormInputAddon'

const PhoneInputField = ({
  value,
  isInvalid,
  errorText,
  isDisabled = true,
  isRequired = true,
  handlePhoneFocus,
  handlePhoneBlur,
  handlePhoneChange,
  fieldName,
  autoFocus,
}) => (
  <MaskedInput
    mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
    onChange={handlePhoneChange}
    onFocus={handlePhoneFocus}
    onBlur={handlePhoneBlur}
    value={value}
    disabled={isDisabled}
    render={(ref, props) => (
      <FormInput
        name={fieldName}
        type='tel'
        label='Mobile Number (No VoIP)'
        autoComplete='tel'
        required={isRequired}
        showRequiredAsterisk={false}
        invalid={isInvalid}
        errorText={errorText}
        placeholder='(888) 888-8888'
        autoFocus={autoFocus}
        innerRef={ref}
        {...props}
      >
        <FormInputAddon type='prepend'>+1</FormInputAddon>
      </FormInput>
    )}
  />
)

export default PhoneInputField
