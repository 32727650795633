import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, useLocation } from 'react-router'
import { useSelector } from 'react-redux'

import { APPLICATION_STATUSES } from '@shared/constants/uiConstants'
import { staticRoutes } from './routes'
import { isRouteOfGroup, routeAuthFilter, isAuthenticated } from '@common/utils'
import { ONBOARDING_ROUTES, POST_ONBOARDING_ROUTES } from '@common/constants'

/**
 * Renders a component if within an authenticated session and/or with authorized access. Otherwise it will
 * redirect to the specified route (or /sign-in if not specified).
 */
const ProtectedRoute = ({ component: Component, componentProps, children }) => {
  const isUserAuthenticated = useSelector(state => isAuthenticated(state))
  const application = useSelector(state => state.application)
  const customerApplicationStatus = application?.customerApplicationStatus
  const pollingHasMaxedOut = application?.pollingHasMaxedOut

  const location = useLocation()

  const userIsOnboarding = [
    APPLICATION_STATUSES.INITIAL,
    APPLICATION_STATUSES.RESUBMISSION_REQUIRED,
  ].includes(customerApplicationStatus)

  const userIsInPostOnboarding =
    !!customerApplicationStatus &&
    ![
      APPLICATION_STATUSES.INITIAL,
      APPLICATION_STATUSES.PASS,
      APPLICATION_STATUSES.RESUBMISSION_REQUIRED,
    ].includes(customerApplicationStatus)

  const isSubmittedAndNotMaxedOut =
    customerApplicationStatus === APPLICATION_STATUSES.SUBMITTED && !pollingHasMaxedOut

  const _isOnboardingRoute = isRouteOfGroup({
    pathname: location.pathname,
    group: ONBOARDING_ROUTES,
  })

  const _isPostOnboardingRoute = isRouteOfGroup({
    pathname: location.pathname,
    group: POST_ONBOARDING_ROUTES,
  })

  const _shouldRedirectToProcessing =
    location.pathname === staticRoutes.dashboard.pathname && isSubmittedAndNotMaxedOut

  // Get all routes that match the current path
  const routesMatchingPath = Object.values(staticRoutes).filter(
    route => route.pathname === location.pathname
  )

  /* Further filter based on auth config; some paths can exist in different auth states,
      e.g. the root path `/` represents both the authenticated dasboard and the
      unauthenticated splash page. */
  const routesMatchingAuthConfig = routesMatchingPath.filter(routeMatchingPath =>
    routeAuthFilter(routeMatchingPath, isUserAuthenticated)
  )

  const getRouteComponent = () => {
    /* If all auth config checks failed but there was at least one matching path, then
          redirect to either sign-in or dashboard, according to the current auth state */
    if (routesMatchingAuthConfig.length === 0 && routesMatchingPath.length > 0) {
      if (isUserAuthenticated) {
        // When authenticated, go to the dashboard
        console.debug(
          '%cROUTER: authenticated user attempted to access an unauthenticated route, send them to the dashboard route',
          'color:#eb8934',
          location
        )
        return <Navigate to={staticRoutes.dashboard.pathname} />
      } else {
        // When unauthenticated, go to the sign-in screen and save the desired pathname
        console.debug(
          '%cROUTER: unauthenticated user attempted to access an authenticated route, send them to the sign-in route',
          'color:#eb8934',
          location
        )
        return (
          <Navigate
            to={staticRoutes.signIn.pathname}
            state={{ desiredPathname: location.pathname }}
          />
        )
      }
    } else if (userIsOnboarding && !_isOnboardingRoute) {
      // If user is still onboarding, send them to the create-account route
      console.debug(
        '%cROUTER: user is still onboarding, send them to the create-account route',
        'color:#eb8934',
        location
      )

      if (customerApplicationStatus === APPLICATION_STATUSES.RESUBMISSION_REQUIRED) {
        return (
          <Navigate to={staticRoutes.addressResubmission.pathname} />
        )
      } else {
        return (
          <Navigate to={staticRoutes.createAccount.pathname} />
        )
      }
    } else if (
      userIsInPostOnboarding &&
        (!_isPostOnboardingRoute || _shouldRedirectToProcessing)
    ) {
      if (_shouldRedirectToProcessing) {
        // Still waiting for application submission, redirect to processing screen
        console.debug('%cROUTER: redirecting into processing view', 'color:#eb8934', location)
        return (
          <Navigate to={staticRoutes.createAccountProcessing.pathname} />
        )
      } else {
        /* If user is in a post onboarding state and is attempting to visit a non-post
          onboarding allowed view, redirect to dashboard */
        console.debug(
          '%cROUTER: user is in a post onboarding state and is attempting to visit a non-post onboarding allowed view, redirect to dashboard',
          'color:#eb8934',
          location
        )
        return <Navigate to={staticRoutes.dashboard.pathname} />
      }
    } else {
      /* There is a matching path and all checks have passed */
      console.debug('%cROUTER: default', 'color:#eb8934', location)
      if (Component) {
        return <Component {...componentProps} />
      } else if (children) {
        return children
      } else {
        return null
      }
    }
  }

  return (
    <>{getRouteComponent()}</>
  )
}

ProtectedRoute.propTypes = {
  /**
   * The component function to render within the route, if authenticated
   */
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),

  /**
   * Flag to determine if within an authenticated session
   */
  isAuthenticated: PropTypes.bool,
  /**
   * If the application state is submitted and polling has not yet maxed out
   */
  isSubmittedAndNotMaxedOut: PropTypes.bool,
}

export default ProtectedRoute
