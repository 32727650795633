import {
  gql,
  useQuery as useApolloQuery,
  useLazyQuery as useApolloLazyQuery,
  useMutation as useApolloMutation,
} from '@apollo/client'
import axios, { INSTANCES, METHODS } from './axiosInstance'
import { ONBOARDING_FLOW_TYPES, DEPLOY_ENV } from '@common/constants'

/**
 * Custom wrapped implementation of the `useQuery` hook supplied by Apollo GraphQL.
 *
 * The `alertActionConfig` option is used to control alert behaviour when apolloClient displays
 * global error alerts.
 */
const useQuery = (query, { alertActionConfig = {}, ...options } = {}) => {
  return useApolloQuery(query, {
    ...options,
    context: {
      ...options.context,
      alertActionConfig,
    },
  })
}

/**
 * Custom wrapped implementation of the `useLazyQuery` hook supplied by Apollo GraphQL.
 *
 * The `alertActionConfig` option is used to control alert behaviour when apolloClient displays
 * global error alerts.
 */
const useLazyQuery = (query, { alertActionConfig = {}, ...options } = {}) => {
  return useApolloLazyQuery(query, {
    ...options,
    context: {
      ...options.context,
      alertActionConfig,
    },
  })
}

/**
 * Custom wrapped implementation of the `useMutation` hook supplied by Apollo GraphQL. This
 * implementation always provides an `onError` handler to the mutation options, whether or not it
 * is explicitly provided by the implementing code. This ensures that any errors will either be
 * handled or quietly suppressed.
 *
 * The `alertActionConfig` option is used to control alert behaviour when apolloClient displays
 * global error alerts.
 */
const useMutation = (
  mutation,
  {
    onError = () => {
      /* This default implementation prevents exceptions from being shown */
    },
    alertActionConfig = {},
    ...options
  } = {}
) => {
  return useApolloMutation(mutation, {
    ...options,
    onError,
    context: {
      ...options.context,
      alertActionConfig,
    },
  })
}

/* For each of these Axios method calls, when an error is generated it will be suppressed by
   default via try/catch. The implementing code can hook in its own error handling via the
   `onError` callback property. The returned `error` will either be the `response.error`
   returned by Axios if the call completed but returned with errors, or it will be a thrown
   Javascript `error` object if for some reason the Axios call failed altogether (I assume, I have
   not seen this happen).

   Within the `axiosInstance`, if a call completes but an error is returned, it will show a global
   error alert to the user using `addAlertAction`. The configuration for the alert can be
   overridden using the `alertActionConfig` object property.

   Any additional axios-specific configurations can be passed as additional properties.
*/

const register = ({
  username,
  password,
  waitlistCode,
  onboardingFlowType = ONBOARDING_FLOW_TYPES.ELEVATE,
  onError,
  alertActionConfig,
  axiosConfig,
}) => {
  const data = {
    username,
    password,
    onboardingFlowType,
  }

  if (waitlistCode) {
    data.waitlistCode = waitlistCode
  }

  return axios({
    url: '/register',
    method: METHODS.POST,
    data,
    onError,
    alertActionConfig,
    ...axiosConfig,
  })
}

const login = ({ username, password, onError, alertActionConfig, axiosConfig }) => {
  return axios({
    url: '/login',
    method: METHODS.POST,
    data: {
      username,
      password,
    },
    onError,
    alertActionConfig,
    withCredentials: DEPLOY_ENV !== 'dev',
    ...axiosConfig,
  })
}

const authorize = ({
  username,
  password,
  redirectUri,
  state,
  responseType,
  clientId,
  responseFormat,
  onError,
  alertActionConfig,
  axiosConfig,
}) => {
  return axios({
    url: '/oauth2/authorize',
    method: METHODS.POST,
    instance: INSTANCES.AUTH,
    data: {
      username,
      password,
    },
    params: {
      redirect_uri: redirectUri,
      state,
      response_type: responseType,
      client_id: clientId,
      response_format: responseFormat === null ? undefined : responseFormat,
    },
    onError,
    alertActionConfig,
    ...axiosConfig,
  })
}

const refreshToken = ({ refreshToken: _refreshToken, onError, alertActionConfig, axiosConfig }) => {
  return axios({
    url: '/refresh-token',
    method: METHODS.POST,
    instance: INSTANCES.AUTH,
    data: {
      refreshToken: _refreshToken,
    },
    onError,
    alertActionConfig,
    withCredentials: DEPLOY_ENV !== 'dev',
    ...axiosConfig,
  })
}

const verify = ({ sessionId, value, onError, alertActionConfig, axiosConfig }) => {
  return axios({
    url: '/verify',
    method: METHODS.POST,
    data: {
      sessionId,
      value,
    },
    onError,
    alertActionConfig,
    withCredentials: DEPLOY_ENV !== 'dev',
    ...axiosConfig,
  })
}

const resendVerifyChallenge = ({
  sessionId,
  channelOverride,
  onError,
  alertActionConfig,
  axiosConfig,
}) => {
  return axios({
    url: '/resend-verify-challenge',
    method: METHODS.POST,
    data: {
      sessionId,
      channelOverride,
    },
    onError,
    alertActionConfig,
    ...axiosConfig,
  })
}

const resetPassword = ({ username, onError, alertActionConfig, axiosConfig }) => {
  return axios({
    url: '/reset-password',
    method: METHODS.POST,
    data: {
      username,
    },
    onError,
    alertActionConfig,
    ...axiosConfig,
  })
}

const secondaryLogin = ({ username, password, onError, alertActionConfig, axiosConfig }) => {
  return axios({
    url: '/secondary/login',
    method: METHODS.POST,
    data: {
      username,
      password,
    },
    onError,
    alertActionConfig,
    ...axiosConfig,
  })
}

// Decodes a JWT token. Adapted from: https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
const decodeJwtPayload = (jwt = '') => {
  /* A JWT token is broken into three parts delimited by a `.`, each base64Url encoded:
     [0] = header
     [1] = payload
     [2] = signature
  */
  const base64UrlTokenParts = jwt.split('.')
  const base64UrlPayload = base64UrlTokenParts.length > 1 ? base64UrlTokenParts[1] : null

  /* Convert base64Url encoding into base64 encoding by replacing `-` characters with `+` and
     replacing `_` characters with `/` */
  if (base64UrlPayload) {
    const base64Payload = base64UrlPayload.replace(/-/g, '+').replace(/_/g, '/')

    const jsonPayload = decodeURIComponent(
      // Decode the base64 payload into a proper string
      atob(base64Payload)
        .split('')
        .map(character => '%' + ('00' + character.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    )

    return JSON.parse(jsonPayload)
  } else {
    return null
  }
}

/* *** Apollo GraphQL related utilities *** */
export { gql, useQuery, useLazyQuery, useMutation }

/* *** Axios related utilities *** */
export {
  register,
  login,
  refreshToken,
  verify,
  resendVerifyChallenge,
  resetPassword,
  authorize,
  secondaryLogin,
}

/* *** Miscellaneous service utilities *** */
export { decodeJwtPayload }
