import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'

import { gql, useQuery, useMutation, useLazyQuery } from '@services/serviceUtils'
import { ReactComponent as AgreementsIllustration } from '@common/images/illustrations/illustration-legal.svg'
import { PRODUCT_TYPES } from '@common/constants'
import LoadingContainer from '@shared/components/loadingContainer/LoadingContainer'
import Button from '@shared/components/button/Button'
import { staticRoutes } from '@routing/routes'
import AgreementsList from '@common/components/agreementsList/AgreementsList'
import { hasSelectDdaTypeCta, hasUpgradeToPremiumAccountTypeCta } from '@common/utils/accountUtils'

const productsAndCtasQuery = gql`
  query ProductsAndCtas($legacy: Boolean) {
    products(legacy: $legacy) {
      id
      productType
      agreements {
        id
        url
        title
        description
      }
    }
    ctas(legacy: $legacy) {
      callToActionType
    }
  }
`

const premiumUpgradeRequirementsQuery = gql`
  query PremiumUpgradeRequirements {
    premiumUpgradeRequirements {
      sufficientFundsAvailable
      upgradeRequiresFunds
    }
  }
`

const openDdaMutation = gql`
  mutation OpenDDA($openDDARequestDTOInput: OpenDDARequestDTOInput!) {
    openDDA(openDDARequestDTOInput: $openDDARequestDTOInput) {
      id
    }
  }
`

const getErrorMessage = ({
  productsAndCtasError,
  premiumUpgradeRequirementsError,
  openDdaError,
}) => {
  let errorMessage = ''

  if (productsAndCtasError) {
    errorMessage = 'Error loading agreements.'
  } else if (premiumUpgradeRequirementsError) {
    errorMessage = 'Error checking for premium account requirements.'
  } else if (openDdaError) {
    errorMessage = 'Error submitting a request to open a premium account.'
  }

  return errorMessage
}

const CreateAccountPremiumAgreements = () => {
  const [product, setProduct] = useState()
  const { state } = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {
    loading: productsAndCtasLoading,
    error: productsAndCtasError,
    data: productsAndCtasData,
  } = useQuery(productsAndCtasQuery, {
    variables: { legacy: true },
    fetchPolicy: 'no-cache',
  })

  const [
    getPremiumUpgradeRequirements,
    {
      loading: premiumUpgradeRequirementsLoading,
      error: premiumUpgradeRequirementsError,
      data: premiumUpgradeRequirementsData,
    },
  ] = useLazyQuery(premiumUpgradeRequirementsQuery, { fetchPolicy: 'no-cache' })

  const [submitOpenDda, { loading: openDdaLoading, error: openDdaError }] = useMutation(
    openDdaMutation
  )

  useEffect(() => {
    const tempProduct =
      productsAndCtasData?.products?.find(p => p.productType === PRODUCT_TYPES.premium) || null
    if (tempProduct) {
      setProduct(tempProduct)
    }
  }, [productsAndCtasData])

  /* Check whether upgrade requires funding
        Yes: Check whether available funding and push to account funding
              - If funding is available preselect greenwood account
        No: submitOpenDda and push to premium card inbound
  */
  const _submitOpenDda = useCallback(async () => {
    const result = await submitOpenDda({
      variables: {
        openDDARequestDTOInput: {
          agreementIds: product?.agreements?.map(agreement => agreement.id),
          productId: product?.id,
        },
      },
    })

    if (result?.data?.openDDA?.id) {
      // DDA submission was successful
      if (
        premiumUpgradeRequirementsData?.premiumUpgradeRequirements?.upgradeRequiresFunds === true &&
        premiumUpgradeRequirementsData?.premiumUpgradeRequirements?.sufficientFundsAvailable ===
          false
      ) {
        // Funding required and insufficient funds, navigate to account funding
        navigate(staticRoutes.premiumPayment.pathname, { state })
      } else {
        // Navigate to premium card inbound
        navigate(staticRoutes.premiumCardInbound.pathname, {
          state: {
            ...state,
            fundingFlow: state?.fundingFlow,
            shouldSetupDirectDeposit: state?.shouldSetupDirectDeposit,
          },
        })
      }
    } else {
      /* The user already submitted an OpenDDA request at some point, so go directly to the
           accounts for funding screen */
      navigate(staticRoutes.premiumPayment.pathname, { state })
    }
  }, [submitOpenDda, product, premiumUpgradeRequirementsData, navigate, state])

  useEffect(() => {
    if (!!productsAndCtasData && !!premiumUpgradeRequirementsData && !!product) {
      if (
        hasSelectDdaTypeCta(productsAndCtasData?.ctas) ||
        hasUpgradeToPremiumAccountTypeCta(productsAndCtasData?.ctas)
      ) {
        /* Only submit an OpenDDA request if the user has not previously done so (either the
            SELECT_DDA_TYPE or UPGRADE_TO_PREMIUM_ACCOUNT cta exists) */
        _submitOpenDda()
      } else {
        /* The user already submitted an OpenDDA request at some point, so go directly to the
            accounts for funding screen */
        navigate(staticRoutes.premiumPayment.pathname, { state })
      }
    }
  }, [
    productsAndCtasData,
    premiumUpgradeRequirementsData,
    product,
    _submitOpenDda,
    navigate,
    dispatch,
    state,
  ])

  return (
    <div className={classNames('center-text', 'create-account-black-page')}>
      <div className='create-account-content-wrapper'>
        <AgreementsIllustration className='illustration' />
        <h1>Premium Account Upgrade Agreement</h1>
        <p>
          By selecting AGREE, I understand my account will be upgraded to Premium, I will incur a
          fee of $4.99 per month and I have received the Mastercard<sup>&#x24C7;</sup> Guide to
          Benefits - World Debit.
        </p>
        <LoadingContainer
          error={productsAndCtasError || premiumUpgradeRequirementsError || openDdaError}
          errorMessage={getErrorMessage({
            productsAndCtasError,
            premiumUpgradeRequirementsError,
            openDdaError,
          })}
          loading={productsAndCtasLoading}
        >
          <AgreementsList agreements={product?.agreements} onDark />
        </LoadingContainer>
        <div className='buttons-container'>
          <Button
            onClick={getPremiumUpgradeRequirements}
            disabled={productsAndCtasLoading || premiumUpgradeRequirementsLoading}
            isLoading={openDdaLoading}
            onDark
            data-cy='premium-agreements-agree-button'
          >
            AGREE
          </Button>
          <Button
            onClick={() => navigate(-1)}
            disabled={openDdaLoading}
            onDark
            ghost
            data-cy='premium-agreements-cancel-button'
          >
            CANCEL
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CreateAccountPremiumAgreements
