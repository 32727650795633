import React, { useMemo } from 'react'
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom'

import DebitCardDepositForm from './DebitCardDepositForm'
import DebitCardDepositReview from './DebitCardDepositReview'
import DebitCardDepositSuccess from './DebitCardDepositSuccess'
import LoadingContainer from '@shared/components/loadingContainer/LoadingContainer'

import ProtectedRoute from '@routing/ProtectedRoute'
import { staticRoutes } from '@routing/routes'
import { gql, useQuery } from '@services/serviceUtils'
import { FUNDING_FLOWS } from '@common/constants'
import useFundingFlowRouteGuard from '@common/utils/useFundingFlowRouteGuard'

const REQUIRED_FUNDING_FLOWS = [FUNDING_FLOWS.BASIC, FUNDING_FLOWS.DEBIT_CARD_DEPOSIT]

const linkedCardsQuery = gql`
  query LinkedCards {
    linkedCards {
      id
    }
  }
`

const DebitCardDeposit = () => {
  const { state } = useLocation()
  useFundingFlowRouteGuard({ requiredFundingFlows: REQUIRED_FUNDING_FLOWS })

  const { loading: linkedCardsLoading, data: linkedCardsData, error: linkedCardsError } = useQuery(
    linkedCardsQuery,
    {
      fetchPolicy: 'no-cache',
    }
  )

  const noLinkedCards = useMemo(() => !linkedCardsData?.linkedCards?.length, [linkedCardsData?.linkedCards?.length])

  return (
    <LoadingContainer
      error={linkedCardsError}
      loading={linkedCardsLoading}
      errorMessage='Error loading linked cards'
    >
      {/* If the user has no linked cards, then redirect user to Add Debit Card screen */}
      {/* Otherwise, show the Debit Card Deposit screens */}
      <>
        {noLinkedCards ? (
          <Navigate to={staticRoutes.addDebitCard.pathname} state={state} />
        ) : (
          <>
            <Routes>
              <Route
                path={staticRoutes.debitCardDepositForm.relativePathname}
                element={<ProtectedRoute component={DebitCardDepositForm} />}
              />
              <Route
                path={staticRoutes.debitCardDepositReview.relativePathname}
                element={<ProtectedRoute component={DebitCardDepositReview} />}
              />
              <Route
                path={staticRoutes.debitCardDepositSuccess.relativePathname}
                element={<ProtectedRoute component={DebitCardDepositSuccess} />}
              />
              <Route
                path='*'
                element={
                  <Navigate
                    to={staticRoutes.debitCardDepositForm.pathname}
                    state={state}
                  />
                }
              />
            </Routes>
            <Outlet />
          </>
        )}
      </>
    </LoadingContainer>
  )
}

export default DebitCardDeposit
