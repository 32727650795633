import { HTTP_ERROR_CODE_MESSAGES, DATE_TIME_FORMAT } from '@shared/constants/uiConstants'
import { format, parse } from 'date-fns'
import ReactHtmlParser from 'react-html-parser'

/**
 * Accepts a number or string representing a number, and formats as a dollar display value
 */
export const toDollars = ({ value, isSuperscript = true, removeDecimals = false, useGrouping = true }) => {
  const val = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: removeDecimals ? 0 : 2,
    useGrouping,
  }).format(
    new Number(value)
  )
  return ReactHtmlParser(isSuperscript ? val.replace('$', '<sup>$</sup>') : val)
}

/**
 * Standardized display date format in the form of "January 21, 2021"
 */
export const toLongDateFormat = date => format(new Date(date), DATE_TIME_FORMAT.LONG_DATE)

/**
 * Standardized display date format in the form of "Jan 21, 2021"
 */
export const toMediumDateFormat = date => format(new Date(date), DATE_TIME_FORMAT.MEDIUM_DATE)

/**
 * Standardized display date format in the form of "January 2021"
 */
export const toLongMonthYearFormat = date =>
  format(new Date(date), DATE_TIME_FORMAT.LONG_MONTH_YEAR)

/**
 * Standardized display date format in the form of "01/21/2021"
 */
export const toShortMonthDayYearFormat = date =>
  format(new Date(date), DATE_TIME_FORMAT.SHORT_MONTH_DAY_YEAR)

/**
 * Standardized display date format in the form of "12/24"
 */
export const toShortMonthYearFormat = date => format(new Date(date), DATE_TIME_FORMAT.ISO_7813_DATE)

/**
 * Standardized display date format in the form of "10:01 AM, Jan 21"
 */
export const toTimeAndShortMonthDayFormat = date =>
  format(new Date(date), DATE_TIME_FORMAT.TIME_SHORT_MONTH_DAY)

/**
 * Standardized display date format from "01/21/2020" formatted string to
 * "2021-01-21" ISO 8601 short date format, generally used to supply date data
 *  to the backend
 */
export const shortMonthDayYearToIso8601Date = date =>
  format(
    parse(date, DATE_TIME_FORMAT.SHORT_MONTH_DAY_YEAR, new Date()),
    DATE_TIME_FORMAT.ISO_8601_DATE
  )

/**
 * Accepts a string value which is then masked according to supplied options and returns the new value
 * @param {Object} options Input value and options
 * @param {string} options.value The unmasked input value
 * @param {string} options.mask The character(s) which replace the originals
 * @param {number} options.length The number of characters which should be masked. If not supplied, the entire value starting at the start value will be masked
 * @param {number} options.start The start index of the mask. If not supplied, it will mask starting at the first character
 * @returns {string} The masked value
 */
export const maskString = ({
  value = '',
  mask = 'X',
  length,
  start = 0,
  spaceBeforeMask = false,
  spaceAfterMask = false,
}) => {
  let maskedValue = ''

  const _length = length || value.length
  const endIndex = start + _length

  // Masks the start to end characters
  for (let index = 0; index < value.length; index++) {
    if (index >= start && index < endIndex) {
      // Insert space before beginning of mask
      if (spaceBeforeMask && index === start) {
        maskedValue += ' '
      }

      maskedValue += mask
    } else {
      // Insert space after end of mask
      if (spaceAfterMask && index === endIndex) {
        maskedValue += ' '
      }

      maskedValue += value.charAt(index)
    }
  }

  return maskedValue.trim()
}

/**
 * Inserts (or replaces) a string into an existing string, and returns the new value
 * @param {Object} options Input value and options
 * @param {string} options.value The original input value
 * @param {string} options.valueToInsert The value to insert
 * @param {number} options.start The start index, defaults to the beginning of the original value
 * @param {number} options.end The end index, defaults to the start value to do a regular insert. If end is larger than start, then a partial replacement will be done
 * @returns {string} The full value
 */
export const insertSubstring = ({ value = '', valueToInsert = '', start, end }) => {
  let _start = start === undefined ? 0 : Math.max(start, 0)
  let _end = end === undefined ? _start : Math.max(end, 0)

  // Make sure values are in the right order
  if (_start > _end) {
    const tempStart = _start
    _start = _end
    _end = tempStart
  }

  return [value.slice(0, _start), valueToInsert, value.slice(_end)].join('')
}

/**
 * Removes text between the specified indicies and returns the new string value
 * @param {Object} options Input value and options
 * @param {string} options.value The original input value
 * @param {number} options.start The start index. If not supplied, the last character will be deleted
 * @param {number} options.end The end index. If not supplied, will delete one character from the start
 * @returns {string} The new value
 */
export const removeBetween = ({ value = '', start, end, backward }) => {
  const dir = backward ? -1 : 1

  let _start = start === undefined ? value.length - 1 : start
  let _end = _start === end ? end + dir : end || _start + dir

  // Make sure values are in the right order
  if (_start > _end) {
    const tempStart = _start
    _start = _end
    _end = tempStart
  }

  return [value.slice(0, Math.max(_start, 0)), value.slice(Math.min(_end, value.length))].join('')
}

/**
 * Maps a generic error message to an HTTP error code
 */
export const getErrorByStatusCode = statusCode =>
  HTTP_ERROR_CODE_MESSAGES[statusCode] || 'Unknown error. Please try again.'

/**
 * Obscures the account number in a shorter format
 * @param {string} accountNumber The original account number
 * @returns Obscured account number in short format
 */
export const obscureAccountNumberShort = accountNumber =>
  `...${accountNumber.substring(accountNumber.length - 4)}`

export const isEqualIgnoreCase = (string1, string2) => {
  return string1?.toLowerCase() === string2?.toLowerCase()
}

// Converts provided base64 formatted string to base64 url-safe format
export const base64ToBase64UrlSafe = string => {
  return string
    ?.replace(/[+]/g, '-')
    .replace(/[/]/g, '_')
    .replace(/[=$]/g, '')
}
// Converts provided base64 url-safe formatted string to base64 format
export const base64UrlSafeToBase64 = string => {
  return string?.replace(/-/g, '+').replace(/_/g, '/')
}
