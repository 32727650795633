import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { Navigate, useLocation } from 'react-router'
import classNames from 'classnames'

import { gql, useQuery } from '@services/serviceUtils'

import Hero from '@common/components/hero/Hero'
import LoadingContainer from '@shared/components/loadingContainer/LoadingContainer'
import TransactionDetailModal from '@common/components/transactions/TransactionDetailModal'
import Table from '@shared/components/table/Table'

import Card from '@shared/components/card/Card'

import { staticRoutes } from '@routing/routes'
import { trackPage, trackEvent, SEGMENT_PAGE_NAMES, SEGMENT_EVENTS } from '@common/utils'

import { transactionTableColumns } from '@common/utils/transactionTableUtils'
import { toLongMonthYearFormat } from '@shared/utils'
import { MAX_WIDTH_BREAKPOINTS } from '@shared/constants/uiConstants'
import usePaginatedQuery from '@common/utils/usePaginatedQuery'

import styling from './myAccount.module.scss'

const myAccountQuery = gql`
  query MyAccountQuery($accountId: ID!) {
    account(accountId: $accountId) {
      accountNumber
      accountType
      balance {
        amount
        currency
      }
      id
      lastStatementDate
      routingNumber
      title
    }
    accountBalance(accountId: $accountId) {
      availableBalance {
        amount
      }
      currentBalance {
        amount
      }
    }
  }
`

const transactionsQuery = gql`
  query TransactionsQuery($accountId: ID!, $page: Int, $txnsPerPage: Int) {
    account(accountId: $accountId, page: $page, txnsPerPage: $txnsPerPage) {
      totalTransactionCount
      transactions {
        amount {
          amount
        }
        description
        details {
          value
          label
        }
        transactionTime
        transactionType
        isSettled
      }
    }
  }
`

const MyAccount = () => {
  const { state } = useLocation()
  const [detailModalData, setDetailModalData] = useState({ transaction: {}, isOpen: false })

  const accountId = state?.id

  const {
    initialPageLoading,
    pageLoading,
    error: transactionsError,
    entities: transactions,
    pagination,
    handlePageClick,
  } = usePaginatedQuery({
    query: transactionsQuery,
    queryVariables: { accountId },
    queryConfig: {
      fetchPolicy: 'no-cache',
    },
    dataEntitiesPath: 'account.transactions',
    dataTotalEntitiesPath: 'account.totalTransactionCount',
  })

  const { loading: myAccountLoading, error: myAccountError, data: myAccountData } = useQuery(
    myAccountQuery,
    { variables: { accountId } }
  )

  const handleToggleModal = () =>
    setDetailModalData({ isOpen: !detailModalData.isOpen, ...detailModalData })

  const handleCloseModal = () => setDetailModalData({ details: {}, isOpen: false })

  const segmentSource = useMemo(() => {
    let sourceName
    if (myAccountData?.account?.accountType) {
      if (myAccountData.account.accountType === 'SAVINGS') {
        sourceName = SEGMENT_PAGE_NAMES.TRANSACTION_HISTORY_SAVINGS
      } else {
        sourceName = SEGMENT_PAGE_NAMES.TRANSACTION_HISTORY
      }
    }
    return sourceName
  }, [myAccountData?.account?.accountType])

  useEffect(() => {
    if (segmentSource) {
      trackPage({ name: segmentSource })
    }
  }, [segmentSource])

  const handleRowClick = useCallback(
    row => {
      setDetailModalData({
        transaction: row,
        isOpen: true,
      })
      if (segmentSource) {
        trackEvent({
          event: SEGMENT_EVENTS.TRANSACTION_DETAILS_CLICK(segmentSource),
        })
      }
    },
    [segmentSource]
  )

  return (
    <>
      {!accountId && <Navigate to={staticRoutes.dashboard.pathname} />}
      <Hero
        heading={myAccountData?.account?.title}
        account={
          myAccountData
            ? { ...myAccountData.account, balance: { ...myAccountData?.accountBalance } }
            : null
        }
        breadcrumbs={[
          { route: staticRoutes.dashboard.pathname, text: 'Dashboard' },
          { text: 'Account Overview' },
        ]}
        segmentSource={segmentSource}
      />
      <TransactionDetailModal
        transaction={detailModalData.transaction}
        isOpen={detailModalData.isOpen}
        onToggleModal={handleToggleModal}
        onCloseModal={handleCloseModal}
      />
      <div className={classNames('page-content-container', styling['my-account'])}>
        <div className='row'>
          <Card className={styling.card}>
            <LoadingContainer
              error={myAccountError || transactionsError}
              errorMessage='Error loading account.'
              loading={initialPageLoading || myAccountLoading}
            >
              {myAccountData?.account ? (
                <Table
                  data={transactions}
                  columns={transactionTableColumns}
                  mobileMaxBreakpoint={MAX_WIDTH_BREAKPOINTS.MEDIUM}
                  groupBy={groupTransactionsByMonth}
                  noRecordsMessage={`No ${myAccountData?.account?.title} Transactions to Show Within the Past 30 Days`}
                  listEndMessage='End of Your 30 Day Transaction History'
                  pagination={pagination}
                  onPageClick={handlePageClick}
                  loading={pageLoading}
                  row={{
                    onClick: handleRowClick,
                    clickable: row => row.details?.length > 0,
                  }}
                />
              ) : null}
            </LoadingContainer>
          </Card>
        </div>
      </div>
    </>
  )
}

const groupTransactionsByMonth = transactions => {
  return transactions.reduce((groupedTransactions, transaction) => {
    const transactionTime = toLongMonthYearFormat(transaction.transactionTime)

    groupedTransactions[transactionTime] = groupedTransactions[transactionTime] || []
    groupedTransactions[transactionTime].push(transaction)

    return groupedTransactions
  }, [])
}

export default MyAccount
