import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { gql, useQuery } from '@services/serviceUtils'

import Button from '@shared/components/button/Button'
import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'
import { ReactComponent as SavingsIllustration } from '@common/images/illustrations/illustration-savings.svg'
import LoadingContainer from '@shared/components/loadingContainer/LoadingContainer'
import { PRODUCT_TYPES } from '@common/constants'
import { staticRoutes } from '@routing/routes'
import { trackPage, trackEvent, SEGMENT_PAGE_NAMES, SEGMENT_EVENTS } from '@common/utils'

const productsQuery = gql`
  query Products {
    products {
      productType
      features {
        featureDescriptionKey
      }
    }
  }
`
const CreateSavingsAccount = () => {
  const navigate = useNavigate()

  const { loading, error, data } = useQuery(productsQuery, { fetchPolicy: 'network-only' })

  const product =
    data?.products?.find(_product => _product.productType === PRODUCT_TYPES.savings) || null
  const productFeatures = product?.features?.map(feature => feature.featureDescriptionKey) || []

  // Tracks page visit
  useEffect(() => {
    trackPage({ name: SEGMENT_PAGE_NAMES.SAVINGS_ACCOUNT_SPLASH_SCREEN })
  }, [])

  return (
    <div className='create-account-black-page' data-cy='create-savings-account-view'>
      <GreenwoodLogo className='logo' />

      <div className='create-account-content-wrapper'>
        <SavingsIllustration className='illustration' />

        <h1>Grow your wealth with Greenwood</h1>

        <LoadingContainer error={error} errorMessage='Error loading features.' loading={loading}>
          <h3>What our savings offers:</h3>

          <ol className='offerings-list'>
            {productFeatures.map((feature, featureIndex) => (
              <li key={featureIndex}>
                <span>{feature}</span>
              </li>
            ))}
          </ol>
        </LoadingContainer>

        <div className='buttons-container'>
          <Button
            onDark
            data-cy='create-savings-account-button'
            onClick={() => {
              navigate(staticRoutes.createSavingsAccountAgreements.pathname)
              trackEvent({ event: SEGMENT_EVENTS.CREATE_SAVINGS_ACCOUNT_CLICK })
            }}
          >
            CREATE SAVINGS ACCOUNT
          </Button>
          <Button
            onDark
            ghost
            data-cy='create-savings-account-back-button'
            onClick={() => {
              navigate(staticRoutes.dashboard.pathname)
            }}
          >
            CANCEL
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CreateSavingsAccount
