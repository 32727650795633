import React from 'react'
import classNames from 'classnames'
import { toMediumDateFormat, toDollars } from '@shared/utils'
import PendingTransactionDate from '@common/components/transactions/PendingTransactionDate'

export const transactionTableColumns = [
  {
    label: 'Date',
    dataKey: 'transactionTime',
    dataFormat: (transactionTime, row) =>
      row.isSettled ? toMediumDateFormat(transactionTime) : <PendingTransactionDate />,
    mobileColumnSpan: 2,
    mobileColumnOrder: 2,
    dataClassName: 'transaction-table-date',
  },
  {
    label: 'Description',
    dataKey: 'description',
    truncateData: true,
    mobileColumnSpan: 3,
    mobileColumnOrder: 1,
    dataClassName: 'transaction-table-description',
  },
  {
    label: 'Type',
    dataKey: 'transactionType',
    hideOnMobile: true,
    dataClassName: 'transaction-table-type',
  },
  {
    label: 'Amount',
    dataKey: 'amount.amount',
    dataFormat: amount => toDollars({ value: amount }),
    dataClassName: ({ preformattedValue: amount }) =>
      classNames('transaction-table-amount', {
        'transaction-table-negative-amount': amount < 0,
      }),
    labelClassName: 'transaction-table-amount-label',
  },
]
