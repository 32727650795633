import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'

import Card from '@shared/components/card/Card'
import { ReactComponent as SavingsIllustration } from '@common/images/illustrations/illustration-savings.svg'
import { ReactComponent as Chevron } from '@shared/images/icons/chevron.svg'
import { staticRoutes } from '@routing/routes'
import {
  getNumberToTwoDecimalPlaces,
  trackEvent,
  SEGMENT_EVENTS,
  SEGMENT_SOURCE_DETAILS,
} from '@common/utils'

import styling from './savingsAccountCallout.module.scss'

const SavingsAccountCallout = () => {
  const navigate = useNavigate()
  const { savingsApyAmount } = useFlags()

  const apy = useMemo(() => getNumberToTwoDecimalPlaces(parseFloat(savingsApyAmount)), [
    savingsApyAmount,
  ])

  return (
    <Card
      className={styling.card}
      size='small'
      onClick={() => {
        navigate(staticRoutes.createSavingsAccount.pathname)
        trackEvent({
          event: SEGMENT_EVENTS.DASHBOARD_BUTTON_CLICK(SEGMENT_SOURCE_DETAILS.OPEN_SAVINGS_ACCOUNT),
        })
      }}
      data-cy='savings-account-callout'
    >
      <SavingsIllustration className={styling.illustration} />
      <div>
        <h3>Ready to open a savings account?</h3>
        <p>
          Start saving and growing your wealth with Greenwood at {apy}% Annual Percentage Yield
          (APY).
        </p>
        <p className='callout-link'>
          <span>Start Saving</span> <Chevron />
        </p>
      </div>
    </Card>
  )
}

export default SavingsAccountCallout
