import React from 'react'
import Lottie from 'react-lottie'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import congratsLottie from '@common/images/animations/congrats-lottie.json'
import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'

import { LOTTIE_CONFIG } from '@common/constants'

const CelebrationView = ({ children, className, withLogo = true, ...rest }) => {
  return (
    <div className={classNames('create-account-black-page', className)} {...rest}>
      <div className='background-lottie'>
        <Lottie
          options={{
            ...LOTTIE_CONFIG,
            animationData: congratsLottie,
          }}
        />
      </div>
      {withLogo && <GreenwoodLogo className='logo' />}
      {children}
    </div>
  )
}

CelebrationView.propTypes = {
  /**
   * The page's contents
   */
  children: PropTypes.node,

  /**
   * Any `classname` library compatible classnames which should be applied to the view's container
   */
  className: PropTypes.any,

  /**
   * Specifies that the GREENWOOD logo should be displayed at the top of the screen. Default is
   * true
   */
  withLogo: PropTypes.bool,
}

export default CelebrationView
