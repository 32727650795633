import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { gql, useQuery, useMutation } from '@services/serviceUtils'
import { staticRoutes } from '@routing/routes'
import { ReactComponent as Eye } from '@shared/images/icons/eye.svg'
import { ReactComponent as EyeClosed } from '@shared/images/icons/eye-closed.svg'
import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'
import LoadingContainer from '@shared/components/loadingContainer/LoadingContainer'
import Button from '@shared/components/button/Button'
import { getDisplayAddress, getDisplayName } from '@common/utils/accountUtils'
import { trackPage, trackEvent, SEGMENT_PAGE_NAMES, SEGMENT_EVENTS } from '@common/utils'

import styling from './wireTransfer.module.scss'

const wireAccountsQuery = gql`
  query BankInformation {
    accountSummary {
      accountNumber
      routingNumber
      bankName
      bankAddress
      bankZip
    }
  }
`

const emailwireFormMutation = gql`
  mutation EmailWireTransferForm {
    emailWireTransferForm {
      email
    }
  }
`

const CustomerInformation = ({ name, detail, withMaskToggle, accountNumber = '' }) => {
  const [isMasked, setIsMasked] = useState(true)

  return (
    <div className={styling['faq-transfer-table']}>
      <div className={styling['icon-name-container']}>
        <p>{name}</p>
        {withMaskToggle && (
          <Button
            ghost
            onClick={() => {
              setIsMasked(!isMasked)
            }}
            className={classNames(styling['show-hide-toggle'])}
          >
            {isMasked ? <Eye /> : <EyeClosed />}
          </Button>
        )}
      </div>
      <p>
        {detail}
        {!isMasked ? accountNumber : accountNumber.replace(/.(?=.{4,}$)/g, 'x')}
      </p>
    </div>
  )
}

const WireTransfer = () => {
  const { data, loading, error: wireAccountsQueryError } = useQuery(wireAccountsQuery, {})
  const [emailWireForm, { loading: emailWireFormLoading }] = useMutation(emailwireFormMutation)
  const navigate = useNavigate()
  const firstName = useSelector(state => state.customer?.firstName)
  const lastName = useSelector(state => state.customer?.lastName)
  const buildingNumber = useSelector(state => state.customer?.buildingNumber)
  const deliveryAddress = useSelector(state => state.customer?.deliveryAddress)
  const locality = useSelector(state => state.customer?.locality)
  const subdivisions = useSelector(state => state.customer?.subdivisions)
  const postalCode = useSelector(state => state.customer?.postalCode)

  const { accountNumber, bankAddress, bankZip, bankName, routingNumber } =
    data?.accountSummary || {}

  const handleSendFormClick = async () => {
    const {
      data: emailWireFormData,
      errors: emailWireInformationFormErrors,
    } = await emailWireForm()

    if (emailWireInformationFormErrors) {
      navigate(staticRoutes.wireTransferFormSendFailed.pathname)
    } else {
      navigate(staticRoutes.wireTransferSuccess.pathname, {
        state: {
          sentToEmail: emailWireFormData?.emailWireTransferForm?.email,
        },
      })
    }
    trackEvent({ event: SEGMENT_EVENTS.EMAIL_WIRE_TRANSFER_FORM_BUTTON_CLICK })
  }

  // Tracks page visit
  useEffect(() => {
    trackPage({ name: SEGMENT_PAGE_NAMES.WIRE_TRANSFER })
  }, [])

  return (
    <div
      className={classNames('white-card-container', styling['wire-transfer'])}
      data-cy='wire-transfer-container'
    >
      <GreenwoodLogo className='logo' />
      <h1>Wire Transfer</h1>
      <p>
        Provide your financial institution with the information below to wire funds to your account.
      </p>
      <LoadingContainer
        loading={loading}
        error={wireAccountsQueryError}
        errorMessage='Error loading customer information'
      >
        <div className={classNames(styling.information)}>
          {routingNumber && <CustomerInformation name='ROUTING NUMBER' detail={routingNumber} />}
          {accountNumber && (
            <CustomerInformation
              withMaskToggle
              name='ACCOUNT NUMBER'
              accountNumber={accountNumber}
            />
          )}
          {bankName && <CustomerInformation name='BANK' detail={bankName} />}
          {bankAddress && (
            <CustomerInformation name='BANK ADDRESS' detail={`${bankAddress} ${bankZip}`} />
          )}
          <CustomerInformation
            name='ACCOUNT HOLDER NAME'
            detail={getDisplayName({
              firstName,
              lastName,
            })}
          />
          <CustomerInformation
            name='ACCOUNT HOLDER ADDRESS'
            detail={getDisplayAddress({
              buildingNumber,
              deliveryAddress,
              locality,
              subdivisions,
              postalCode,
            })}
          />
        </div>
        <div className={classNames('buttons-container', styling['wire-buttons-container'])}>
          <Button
            onClick={handleSendFormClick}
            isLoading={emailWireFormLoading}
            data-cy='email-wire-transfer-form-button'
          >
            EMAIL WIRE TRANSFER FORM
          </Button>
          <Button ghost onClick={() => navigate(-1)} data-cy='email-wire-transfer-form-cancel-button'>
            CANCEL
          </Button>
        </div>
      </LoadingContainer>
    </div>
  )
}

export default WireTransfer
