import { useState, useEffect } from 'react'

function useTabRouting ({
  defaultActiveTab,
  loading = false,
  location,
  tabOptions,
  tabsDisabledRoute,
  tabsEnabled,
}) {
  const [activeTab, setActiveTab] = useState()
  const [redirectPath, setRedirectPath] = useState()

  useEffect(() => {
    const tempDefaultActiveTab = tabsEnabled ? defaultActiveTab : ''
    const matchingTab =
      tabsEnabled && tabOptions.find(tab => location.pathname.includes(tab.pathname))

    const tempActiveTab = matchingTab || tempDefaultActiveTab

    let tempRedirectPath = null

    if (!loading) {
      setActiveTab(tempActiveTab)

      // If path is different than tab's path, redirect to tab's path (allows for nested paths)
      if (tempActiveTab?.pathname && !location.pathname.includes(tempActiveTab.pathname)) {
        tempRedirectPath = tempActiveTab.pathname
      }

      // If path is invalid, redirect to tabsDisabledRoute
      if (!tempActiveTab && location.pathname !== tabsDisabledRoute) {
        tempRedirectPath = tabsDisabledRoute
      }

      setRedirectPath(tempRedirectPath)
    }
  }, [defaultActiveTab, loading, location, tabsDisabledRoute, tabsEnabled, tabOptions])

  return { activeTab, redirectPath }
}

export default useTabRouting
