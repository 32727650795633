import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { toDollars } from '@shared/utils'
import Tooltip from '@shared/components/tooltip/Tooltip'
import { ReactComponent as QuestionMark } from '@shared/images/icons/question-mark.svg'
import { trackEvent, SEGMENT_EVENTS } from '@common/utils'
import styling from './fee.module.scss'

const Fee = ({ className, feeAmount, feePercent = 0, segmentSource }) => {
  const feeTooltipRef = useRef(null)
  const [showFeeTooltip, setShowFeeTooltip] = useState(false)

  useEffect(() => {
    if (showFeeTooltip && segmentSource) {
      trackEvent({ event: SEGMENT_EVENTS.TRANSFER_MONEY_TOOLTIP_HOVER(segmentSource) })
    }
  }, [showFeeTooltip, segmentSource])

  return (
    <div className={classNames(styling.fee, className)}>
      {`${
        feeAmount || feeAmount === 0 ? toDollars({ value: feeAmount, isSuperscript: false }) : ''
      } Fee (${feePercent}%)`.trim()}
      <QuestionMark className={styling['tooltip-trigger']} ref={feeTooltipRef} />
      <Tooltip
        placement='bottom'
        open={showFeeTooltip}
        target={feeTooltipRef}
        header='Fee Information'
        toggle={open => {
          setShowFeeTooltip(!open)
        }}
      >
        Instant card deposit allows you to add funds to your Greenwood account within minutes.
        <br />
        <br />
        Fees will vary by transaction and will be equivalent to 1% to 1.5% of the transfer amount.
      </Tooltip>
    </div>
  )
}

Fee.propTypes = {
  /**
   * Additional class names to append to the container
   */
  className: PropTypes.string,

  /**
   * The fee dollar amount, must be a number or a string that can be converted to a number.
   */
  feeAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  /**
   * The fee percent, must be a number or a string that can be converted to a number.
   */
  feePercent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default Fee
