import React, { useMemo } from 'react'
import classNames from 'classnames'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { gql, useMutation, useQuery } from '@services/serviceUtils'
import FormCheckbox from '@shared/components/formCheckbox/FormCheckbox'
import Loading from '@shared/components/Loading'
import LoadingContainer from '@shared/components/loadingContainer/LoadingContainer'
import { NOTIFICATION_UPDATE_TYPES } from '@common/constants'

import styling from './notifications.module.scss'

const notificationSettingsQuery = gql`
  query NotificationSettings {
    notificationSettingsV2 {
      enableTransactionalNotifications
      enableSmsChannel
      enableBalanceNotifications
      enablePushChannel
      enableEmailChannel
    }
  }
`

const updateNotificationSettingsMutation = gql`
  mutation($notificationSettingsDTOInput: NotificationSettingsDTOInput!) {
    updateNotificationSettingsV2(notificationSettingsDTOInput: $notificationSettingsDTOInput)
  }
`

const Others = () => {
  const { loading, error, data } = useQuery(notificationSettingsQuery)

  const [updateNotificationSettings, { loading: updatingNotificationSettings }] = useMutation(
    updateNotificationSettingsMutation,
    {
      refetchQueries: [{ query: notificationSettingsQuery }],
      awaitRefetchQueries: true,
    }
  )

  const { webAppAlertsOptIn, webBrazeSmsOptIn } = useFlags()

  const currentNotifications = useMemo(() => {
    if (data?.notificationSettingsV2) {
      const {
        enableTransactionalNotifications,
        enableSmsChannel,
        enableBalanceNotifications,
        enablePushChannel,
        enableEmailChannel,
      } = data.notificationSettingsV2

      return {
        enableTransactionalNotifications,
        enableSmsChannel,
        enableBalanceNotifications,
        enablePushChannel,
        enableEmailChannel,
      }
    }

    return {}
  }, [data?.notificationSettingsV2])

  const toggleNotifications = ({ types, value }) => {
    const notifications = {}
    types.map(type => (notifications[type] = value))

    updateNotificationSettings({
      variables: {
        notificationSettingsDTOInput: {
          ...currentNotifications,
          ...notifications,
        },
      },
    })
  }

  const containerClasses = classNames(
    'settings-view-content',
    styling['settings-notifications-container']
  )

  const appAlertsChecked = useMemo(
    () =>
      currentNotifications?.enablePushChannel && currentNotifications?.enableBalanceNotifications,
    [currentNotifications]
  )

  return (
    <div className={containerClasses}>
      <LoadingContainer
        loading={loading}
        error={error}
        errorMessage='Error loading notification settings.'
      >
        <h2 data-cy='account-information-header'>Notifications</h2>
        <p>Manage notification preferences and further secure your accounts.</p>
        {webAppAlertsOptIn && (
          <table className='simple-table'>
            <h3>Push Notifications</h3>
            <tbody>
              <tr className={styling['notifications-row']}>
                <th scope='row'>
                  <span>App alerts</span>
                  <br />
                  <span className={styling['notifications-description']}>
                    Turn on/off all app alerts (including balance)
                  </span>
                </th>
                <td className={styling['checkbox-column']}>
                  <Loading
                    className={classNames('row-loader', {
                      invisible: !updatingNotificationSettings,
                    })}
                  />
                  <FormCheckbox
                    className={styling['app-notifications-checkbox']}
                    description='Turn on/off all app alerts (including balance)'
                    name='app-notifications-toggle'
                    id='app-notifications-toggle'
                    data-cy='app-notifications-toggle'
                    type='checkbox'
                    toggle
                    onChange={() =>
                      toggleNotifications({
                        types: [
                          NOTIFICATION_UPDATE_TYPES.ENABLE_BALANCE_NOTIFICATIONS,
                          NOTIFICATION_UPDATE_TYPES.ENABLE_PUSH_CHANNEL,
                        ],
                        value: !currentNotifications?.enablePushChannel,
                      })
                    }
                    checked={appAlertsChecked}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        )}
        {webBrazeSmsOptIn && (
          <table className='simple-table'>
            <h3>SMS Notifications</h3>
            <tbody>
              <tr className={styling['notifications-row']}>
                <th scope='row'>
                  <span>Text alerts</span>
                  <br />
                  <span className={styling['notifications-description']}>
                    Stay up to date with anything new or relevant
                  </span>
                </th>
                <td className={styling['checkbox-column']}>
                  <Loading
                    className={classNames('row-loader', {
                      invisible: !updatingNotificationSettings,
                    })}
                  />
                  <FormCheckbox
                    className={styling['notifications-checkbox']}
                    description='Stay up to date with anything new or relevant'
                    name='braze-sms-notifications-toggle'
                    id='braze-sms-notifications-toggle'
                    data-cy='braze-sms--notifications-toggle'
                    type='checkbox'
                    toggle
                    onChange={() =>
                      toggleNotifications({
                        types: [NOTIFICATION_UPDATE_TYPES.ENABLE_SMS_CHANNEL],
                        value: !currentNotifications?.enableSmsChannel,
                      })
                    }
                    checked={currentNotifications?.enableSmsChannel}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </LoadingContainer>
    </div>
  )
}

export default Others
