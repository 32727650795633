import React, { useEffect } from 'react'
import classNames from 'classnames'
import { useNavigate, useLocation } from 'react-router-dom'

import CelebrationView from '@common/components/celebrationView/CelebrationView'
import Button from '@shared/components/button/Button'
import MyCardImage from 'src/views/myCard/MyCardImage'

import ElevateCard from '@common/images/greenwood-elevate-card.png'
import PlatinumCard from '@common/images/greenwood-platinum-card.png'
import useAccountCreatedTracking from '@common/utils/useAccountCreatedTracking'

import { staticRoutes } from '@routing/routes'
import { FUNDING_FLOWS, PRODUCT_TYPES, MEMBERSHIP_CARD_TYPES } from '@common/constants'
import { SEGMENT_PAGE_NAMES, SEGMENT_EVENTS, trackEvent, trackPage } from '@common/utils'
import usePreventBrowserBackClick from '@common/utils/usePreventBrowserBackClick'

import styling from './createAccountMembershipUpgradeConfirmation.module.scss'

const CreateAccountMembershipUpgradeConfirmation = () => {
  const { state = {} } = useLocation()
  const navigate = useNavigate()

  usePreventBrowserBackClick()

  const {
    isUpgradeSuccessful = true,
    productType = PRODUCT_TYPES.elevateCard,
    fundingFlow,
    shouldSetupDirectDeposit,
    isUpgrading,
    cancelUpgradePathname,
  } = state || {}

  // This constant will need to be dynamic
  const membershipType = MEMBERSHIP_CARD_TYPES[productType]

  useEffect(() => {
    trackPage({
      name: SEGMENT_PAGE_NAMES.SUCCESSFUL_MEMBERSHIP(membershipType),
    })
  }, [membershipType])

  const handleContinueClick = () => {
    trackEvent({
      event: SEGMENT_EVENTS.successfulMembershipContinueButtonClicked({
        productType: membershipType,
      }),
    })

    if (fundingFlow?.includes('ONBOARDING')) {
      navigate(staticRoutes.addMoney.pathname, {
        state: {
          shouldSetupDirectDeposit,
          fundingFlow: FUNDING_FLOWS.BASIC,
        },
      })
    } else if (
      isUpgrading &&
      cancelUpgradePathname !== staticRoutes.dashboard.pathname
    ) {
      navigate(staticRoutes.membershipDetails.pathname)
    } else {
      navigate(staticRoutes.dashboard.pathname)
    }
  }

  const header = isUpgradeSuccessful
    ? `You're now ${
        productType === PRODUCT_TYPES.elevateCard ? 'an' : 'a'
      } ${membershipType} Member!`
    : `Your ${membershipType} membership payment is processing.`

  const subText = isUpgradeSuccessful
    ? 'Your card will arrive in 5- 7 days, but you can still enjoy all the benefits now.'
    : ''

  const cardImage = productType === PRODUCT_TYPES.elevateCard ? ElevateCard : PlatinumCard
  const buttonText = isUpgrading ? 'Done' : 'Continue'

  useAccountCreatedTracking({ accountType: productType })

  return (
    <CelebrationView className={styling['account-membership-upgrade-confirmation-container']}>
      <div
        className={classNames(
          'start-flow-black-page-content',
          styling['account-membership-upgrade-confirmation-content']
        )}
      >
        <h1>{header}</h1>
        <p>{subText}</p>
        <MyCardImage displayUserCard={false} image={cardImage} />
        <div className='buttons-container'>
          <Button
            onClick={handleContinueClick}
            data-cy='account-membership-upgrade-confirmation-done-button'
            onDark
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </CelebrationView>
  )
}

export default CreateAccountMembershipUpgradeConfirmation
