import React from 'react'

import Button from '@shared/components/button/Button'
import Modal from '@shared/components/modal/Modal'
import ModalHeader from '@shared/components/modal/ModalHeader'
import ModalBody from '@shared/components/modal/ModalBody'

const MaxCardsModal = ({ isOpen, toggleModal, closeModal }) => {
  return (
    <Modal
      centered
      size='md'
      open={isOpen}
      modalClassName='confirm-modal'
      toggleModal={toggleModal}
    >
      <ModalHeader withCloseButton closeModal={closeModal}>
        <h4 className='wider'>Debit card limit reached</h4>
      </ModalHeader>
      <ModalBody>
        <div>
          <p>Number of debit cards limit has been reached. Please remove a card to add another.</p>
          <Button className='additional-button' onClick={closeModal}>
            Continue
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default MaxCardsModal
