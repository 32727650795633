import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Field, connect as formikConnect } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

import { clearApiValidationErrorAction } from '@redux/apiValidationErrors/apiValidationErrorsActions'

/**
 * A Formik Field component with additional Redux connection logic
 */
const ReduxField = ({ formik, name, invalid, errorText, onChange, children, ...fieldProps }) => {
  const dispatch = useDispatch()
  const apiErrors = useSelector(state => state?.apiValidationErrors)
  // Make sure any remaining API error messages are cleared any time this component mounts or unmounts
  useEffect(() => {
    dispatch(clearApiValidationErrorAction(name))
    return () => {
      dispatch(clearApiValidationErrorAction(name))
    }
  }, [dispatch, name])

  const handleChange = event => {
    // Make sure to execute the custom onChange or the default Formik handleChange callback
    onChange ? onChange(event) : formik.handleChange(event)

    // Clears the applicable API validation error message
    dispatch(clearApiValidationErrorAction(name))
  }

  return (
    <Field
      {...fieldProps}
      formik={formik}
      name={name}
      onChange={handleChange}
      invalid={invalid || (formik.touched[name] && !!apiErrors[name])}
      errorText={errorText || apiErrors[name]}
    >
      {children}
    </Field>
  )
}

ReduxField.propTypes = {
  /**
   * An object injected into all Formik fields containing all Formik form context data
   */
  formik: PropTypes.object,

  /**
   * The name of the field, passed into the underlying Formik field and used to get additional
   * metadata
   */
  name: PropTypes.string.isRequired,

  /**
   * Boolean indicating whether or not the field's value is invalid
   */
  invalid: PropTypes.bool,

  /**
   * The error text to display when the field contains an invalid value. Takes precedense over any
   * error messages returned by the API
   */
  errorText: PropTypes.string,

  /**
   * An onChange callback which should be executed any time the field's value changes. It will
   * either be supplied by Formik, or explicitly declared when this component is implemented
   */
  onChange: PropTypes.func,

  /**
   * Any children which might be rendered by this field
   */
  children: PropTypes.node,

  /**
   * Any additional props can be passed into the underlying Field component.
   */
  // ...fieldProps
}

export default formikConnect(ReduxField)
