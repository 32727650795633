import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'

import { gql, useQuery, useMutation } from '@services/serviceUtils'
import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'
import { ReactComponent as AgreementsIllustration } from '@common/images/illustrations/illustration-legal.svg'
import { PRODUCT_TYPES, CTA_TYPES, COASTAL_BANKING_SERVICES } from '@common/constants'
import LoadingContainer from '@shared/components/loadingContainer/LoadingContainer'
import Button from '@shared/components/button/Button'
import { staticRoutes } from '@routing/routes'
import AgreementsList from '@common/components/agreementsList/AgreementsList'
import { addAlertAction } from '@redux/alerts/alertsActions'
import { ALERT_TYPES, DEFAULT_ALERT_DISMISS_DELAY } from '@shared/constants/uiConstants'
import { hasSelectDdaTypeCta } from '@common/utils/accountUtils'
import { trackPage, trackEvent, SEGMENT_PAGE_NAMES, SEGMENT_EVENTS } from '@common/utils'

const productsQuery = gql`
  query Products {
    products {
      productType
      agreements {
        id
        url
        title
        description
      }
    }
    ctas {
      callToActionType
    }
  }
`

const createSavingsAccountMutation = gql`
  mutation createSavingsAccountV2($openSavingsRequestDTOInput: OpenSavingsRequestDTOInput!) {
    createSavingsAccountV2(openSavingsRequestDTOInput: $openSavingsRequestDTOInput)
  }
`

const CreateSavingsAccountAgreements = () => {
  const [product, setProduct] = useState()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // NOTE: QGL Cache is not correctly storing new results
  //   when 'network-only' policy is used
  const { loading, error, data } = useQuery(productsQuery, { fetchPolicy: 'no-cache' })

  useEffect(() => {
    const tempProduct =
      data?.products?.find(_product => _product.productType === PRODUCT_TYPES.savings) || null
    if (tempProduct) {
      setProduct(tempProduct)
    }
  }, [data])

  const [submitCreateSavingsAccount, { loading: submitting, error: submitError }] = useMutation(
    createSavingsAccountMutation
  )

  const handleAgreeClick = useCallback(async () => {
    if (hasSelectDdaTypeCta(data?.ctas, CTA_TYPES.OPEN_SAVINGS_ACCOUNT)) {
      const { data: submissionData } = await submitCreateSavingsAccount({
        variables: {
          openSavingsRequestDTOInput: {
            agreementIds: product?.agreements?.map(agreement => agreement.id),
          },
        },
      })

      // Submission was successful, navigate to the dashboard
      if (submissionData?.createSavingsAccountV2) {
        navigate(staticRoutes.createSavingsAccountApproved.pathname, {
          state: { isSavingsAccountCreated: true },
        })
      }
    } else {
      // Notify the user that they already selected a savings account and go to dashboard
      dispatch(
        addAlertAction({
          dismissible: false,
          autoDismissDelay: DEFAULT_ALERT_DISMISS_DELAY,
          type: ALERT_TYPES.SUCCESS,
          text: 'Your previous selection has already been saved successfully',
        })
      )

      navigate(staticRoutes.dashboard.pathname)
    }
    trackEvent({ event: SEGMENT_EVENTS.SAVINGS_ACCOUNT_AGREEMENT_CLICK })
  }, [data?.ctas, dispatch, product?.agreements, navigate, submitCreateSavingsAccount])

  // Tracks page visit
  useEffect(() => {
    trackPage({ name: SEGMENT_PAGE_NAMES.SAVINGS_ACCOUNT_AGREEMENT })
  }, [])

  return (
    <div className={classNames('center-text', 'create-account-black-page')}>
      <GreenwoodLogo className='logo' />
      <div className='create-account-content-wrapper'>
        <AgreementsIllustration className='illustration' />
        <h1>Savings Account Agreement</h1>
        <p>
          By selecting AGREE, I acknowledge that I have read, understand and consent to the
          following:
        </p>

        <LoadingContainer error={error} errorMessage='Error loading agreements.' loading={loading}>
          <AgreementsList agreements={product?.agreements} onDark />
        </LoadingContainer>

        <p>{COASTAL_BANKING_SERVICES}</p>

        <div className='buttons-container'>
          <Button
            onDark
            disabled={loading || !!error || !!submitError}
            isLoading={submitting}
            onClick={handleAgreeClick}
            data-cy='create-savings-account-agreements-agree-button'
          >
            AGREE
          </Button>
          <Button
            onDark
            ghost
            onClick={() => {
              navigate(staticRoutes.dashboard.pathname)
            }}
            data-cy='create-savings-account-agreements-cancel-button'
          >
            CANCEL
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CreateSavingsAccountAgreements
