import { useEffect, useState } from 'react'

import {
  CONTENTFUL_DELIVERY_TOKEN,
  CONTENTFUL_ENVIRONMENT,
  CONTENTFUL_SPACE_ID,
} from '@common/constants'

const useContentful = ({ query = {} }) => {
  const [contentfulData, setContentfulData] = useState(null)
  const [contentfulError, setContentfulError] = useState(null)

  useEffect(() => {
    const contentfulGraphqlEndpoint =
      `https://graphql.contentful.com/content/v1/spaces/${CONTENTFUL_SPACE_ID}/environments/${CONTENTFUL_ENVIRONMENT}/`

    window.fetch(contentfulGraphqlEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${CONTENTFUL_DELIVERY_TOKEN}`,
      },
      body: JSON.stringify({ query }),
    })
      .then(response => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          setContentfulData(null)
          setContentfulError(errors)
        } else {
          setContentfulError(null)
          setContentfulData(data)
        }
      })
  }, [query])

  return {
    contentfulData,
    contentfulError,
  }
}

export default useContentful
