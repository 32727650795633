import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import Modal from '@shared/components/modal/Modal'
import ModalBody from '@shared/components/modal/ModalBody'
import ModalHeader from '@shared/components/modal/ModalHeader'
import Button from '@shared/components/button/Button'

import { MFA_CHANNELS } from '@common/constants'
import {
  getBootstrapValueByKey,
  BOOTSTRAP_KEYS,
  SEGMENT_PAGE_NAMES,
  trackPage,
  isInvalidSession,
} from '@common/utils'
import { resendVerifyChallenge } from '@services/serviceUtils'
import { setSessionIdAction } from '@redux/auth/authActions'
import { removeAlertsAction } from '@redux/alerts/alertsActions'
import { staticRoutes } from '@routing/routes'

import styling from './needHelpVerificationCodeModal.module.scss'

const NeedHelpVerificationCodeModal = ({
  isOpen,
  toggleModal,
  closeModal,
  selectedVerificationChannel,
  setShowNewCodeSentMessage,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const sessionId = useSelector(state => state.auth?.sessionId)

  const contactOptions = getBootstrapValueByKey(BOOTSTRAP_KEYS.SUPPORT)?.contact
  const phoneContact = contactOptions?.find(option => option.method === 'phone')

  const otherVerificationChannel =
    selectedVerificationChannel === MFA_CHANNELS.SMS ? MFA_CHANNELS.EMAIL : MFA_CHANNELS.SMS
  const otherChannelText = selectedVerificationChannel === MFA_CHANNELS.SMS ? 'Email' : 'Text'

  const [selectedChannelLoading, setSelectedChannelLoading] = useState(false)
  const [otherChannelLoading, setOtherChannelLoading] = useState(false)

  useEffect(() => {
    trackPage({ name: SEGMENT_PAGE_NAMES.MFA_HELP })
  }, [])

  const navigateFromInvalidSession = () => {
    dispatch(removeAlertsAction())
    navigate(staticRoutes.signIn.pathname, {
      state: {
        hasInvalidSession: true,
      },
    })
  }

  const handleResendCode = async channel => {
    let response
    const newCodeVerificationMethod = channel === MFA_CHANNELS.SMS ? 'mobile number' : 'email'

    const resendChallengeData = {
      sessionId,
      onError: error => {
        if (isInvalidSession(error)) {
          navigateFromInvalidSession()
        }
      },
    }

    if (channel === selectedVerificationChannel) {
      setSelectedChannelLoading(true)

      response = await resendVerifyChallenge(resendChallengeData)

      setSelectedChannelLoading(false)
    } else {
      setOtherChannelLoading(true)

      resendChallengeData.channelOverride = otherVerificationChannel

      response = await resendVerifyChallenge(resendChallengeData)

      setOtherChannelLoading(false)
    }

    const data = response?.data

    if (data) {
      dispatch(setSessionIdAction(data?.sessionId))
      closeModal()
      setShowNewCodeSentMessage(
        `A new verification code has been sent to your ${newCodeVerificationMethod}.`
      )
    }
  }

  return (
    <Modal
      centered
      size='md'
      open={isOpen}
      modalClassName='need-help-verification-code-modal'
      toggleModal={toggleModal}
    >
      <ModalHeader
        withCloseButton
        closeModal={closeModal}
        data-cy='need-help-verification-code-modal-header'
      >
        <h4>Need Help?</h4>
      </ModalHeader>
      <ModalBody>
        <div>
          <p>
            Didn’t receive your code, we can send the code again. For more help, please call{' '}
            <a className='underlined-link bold' href={`tel:${phoneContact.value}`}>
              {phoneContact.value}
            </a>
            .
          </p>
          <div className={styling['button-container']}>
            <Button
              className='additional-button'
              onClick={() => handleResendCode(selectedVerificationChannel)}
              isLoading={selectedChannelLoading}
              data-cy='need-help-resend-code-button'
            >
              Resend Code
            </Button>
            <Button
              className='additional-button'
              onClick={() => handleResendCode(otherVerificationChannel)}
              isLoading={otherChannelLoading}
              outline
            >
              {otherChannelText} Me Code Instead
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

NeedHelpVerificationCodeModal.propTypes = {
  /**
   * Boolean value for whether or not the Modal is open
   */
  isOpen: PropTypes.bool.isRequired,
  /**
   * Function for toggling the modal
   */
  toggleModal: PropTypes.func.isRequired,
  /**
   * Function for closing the modal
   */
  closeModal: PropTypes.func.isRequired,
  /**
   *User's selected verification channel for MFA
   */
  selectedVerificationChannel: PropTypes.string.isRequired,
  /**
   * Function that takes in message to show to user after modal is closed
   */
  setShowNewCodeSentMessage: PropTypes.func,
}

export default NeedHelpVerificationCodeModal
