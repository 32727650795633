import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import { ReactComponent as SadFace } from '@common/images/icons/sad-face.svg'
import Button from '@shared/components/button/Button'

import { staticRoutes } from '@routing/routes'
import { isAuthenticated } from '@common/utils'

import styling from './pageNotFound.module.scss'

const PageNotFound = () => {
  const navigate = useNavigate()
  const isUserAuthenticated = useSelector(state => isAuthenticated(state))

  return (
    <div className={styling.page} data-cy='page-not-found-container'>
      <div className='row'>
        <div className={styling['page-content']}>
          <h1 aria-label='404'>
            4<SadFace />4
          </h1>

          <h2>We encountered an error.</h2>

          <p>
            Unfortunately, we weren't able to retreive that information.
            <br />
            Please try again later
            {isUserAuthenticated && (
              <>
                , or{' '}
                <Link className='underlined-link bold' to={staticRoutes.settingsHelp.pathname}>
                  contact us
                </Link>{' '}
                for assistance
              </>
            )}
            .
          </p>

          <Button data-cy='page-not-found-btn' outline onClick={() => navigate(staticRoutes.dashboard.pathname)}>
            {isUserAuthenticated ? 'Return to Dashboard' : 'Return Home'}
          </Button>
        </div>
      </div>
    </div>
  )
}

PageNotFound.propTypes = {
  isAuthenticated: PropTypes.bool,
}

export default PageNotFound
