import React from 'react'
import { Outlet, Navigate, Route, Routes } from 'react-router-dom'

import Hero from '@common/components/hero/Hero'
import CommunityInvest from './CommunityInvest'
import ProtectedRoute from '@routing/ProtectedRoute'

import { staticRoutes } from '@routing/routes'

const Community = () => {
  return (
    <>
      <Hero heading='Community' />
      <div className='page-content-container white-bg'>
        <Routes>
          <Route
            path={staticRoutes.communityInvest.relativePathname}
            element={<ProtectedRoute component={CommunityInvest} />}
          />
          <Route
            path='*'
            element={
              <Navigate to={staticRoutes.communityInvest.pathname} />
            }
          />
        </Routes>
        <Outlet />
      </div>
    </>
  )
}

export default Community
