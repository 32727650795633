import React, { useRef, useState } from 'react'

import { ReactComponent as QuestionMark } from '@shared/images/icons/question-mark.svg'
import Tooltip from '@shared/components/tooltip/Tooltip'

import styling from './pendingTransactionDate.module.scss'

const PendingTransactionDate = () => {
  const tooltipRef = useRef(null)
  const [showTooltip, setShowTooltip] = useState(false)

  return (
    <div className={styling['pending-transaction']}>
      Pending <QuestionMark className={styling['tooltip-trigger']} ref={tooltipRef} />
      <Tooltip
        placement='auto'
        open={showTooltip}
        target={tooltipRef}
        toggle={open => {
          setShowTooltip(!open)
        }}
        header='Pending Transaction'
      >
        A pending transaction, whether withdrawn or added to your account, means it has been
        submitted but is not complete and is being processed.
      </Tooltip>
    </div>
  )
}

export default PendingTransactionDate
