import React, { useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { useNavigate, useLocation } from 'react-router-dom'
import { Elements } from '@stripe/react-stripe-js'
import { useMediaQuery } from 'react-responsive'

import StripePaymentForm from '@common/components/stripePayment/StripePaymentForm'
import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'
import { ReactComponent as Chevron } from '@shared/images/icons/chevron.svg'
import ElevateCard from '@common/images/greenwood-elevate-card.png'
import PlatinumCard from '@common/images/greenwood-platinum-card.png'
import MyCardImage from 'src/views/myCard/MyCardImage'
import UpdatePaymentMethod from '@common/components/updatePaymentMethod/UpdatePaymentMethod'
import Button from '@shared/components/button/Button'
import ExternalLink from '@common/components/link/ExternalLink'
import PromoCodeForm from '@common/components/promoCodeForm/PromoCodeForm'
import AdditionalBenefitsDropdown from '@common/components/additionalBenefitsDropdown/AdditionalBenefitsDropdown'

import useFundingFlowRouteGuard from '@common/utils/useFundingFlowRouteGuard'
import {
  FUNDING_FLOWS,
  MEMBERSHIP_CARD_TYPES,
  PRODUCT_TYPES,
  stripePromise,
} from '@common/constants'
import { MAX_WIDTH_BREAKPOINTS } from '@shared/constants/uiConstants'
import {
  getBenefitIcon,
  getBootstrapSitemapResource,
  BOOTSTRAP_SITEMAP_RESOURCES,
  SEGMENT_PAGE_NAMES,
  trackPage,
} from '@common/utils'

import styling from './createAccountMembershipAddPaymentMethod.module.scss'

const REQUIRED_FUNDING_FLOWS = [
  FUNDING_FLOWS.ONBOARDING_PREMIUM,
  FUNDING_FLOWS.ONBOARDING_ELEVATED_CARD,
  FUNDING_FLOWS.UPGRADE_MEMBERSHIP,
]

const CreateAccountMembershipAddPaymentMethod = () => {
  useFundingFlowRouteGuard({ requiredFundingFlows: REQUIRED_FUNDING_FLOWS })

  const { state = {} } = useLocation()
  const {
    paymentDetails = {},
    product = {},
    isUpgrading = false,
    feeKey = '',
    subscriptionId = '',
    cancelUpgradePathname = '',
  } = state || {}

  const navigate = useNavigate()
  const [showMobileBenefits, setShowMobileBenefits] = useState(false)
  const [paymentSession, setPaymentSession] = useState(paymentDetails)
  const [isPromoCodeFormVisible, setIsPromoCodeFormVisible] = useState(false)

  const options = useMemo(() => {
    return {
      clientSecret: paymentSession?.paymentSessionId,
      appearance: {
        theme: 'night',
        variables: {
          colorDanger: '#dc3519',
          colorPrimary: '#1f9081',
          borderRadius: '12px',
          fontSizeBase: '14px',
          fontLineHeight: '18px',
          spacingUnit: '5px',
          spacingGridRow: '18px',
          spacingGridColumn: '7px',
        },
      },
    }
  }, [paymentSession?.paymentSessionId])

  const stripePrivacyPolicyDetails = getBootstrapSitemapResource(
    BOOTSTRAP_SITEMAP_RESOURCES.STRIPE_PRIVACY_POLICY
  )

  const isMobile = useMediaQuery({ query: `(max-width: ${MAX_WIDTH_BREAKPOINTS.MEDIUM}px)` })

  const isElevateMembership = product?.productType === PRODUCT_TYPES.elevateCard
  const membershipType = isElevateMembership
    ? MEMBERSHIP_CARD_TYPES[PRODUCT_TYPES.elevateCard]
    : MEMBERSHIP_CARD_TYPES[PRODUCT_TYPES.premiumPlus]
  const cardImage = isElevateMembership ? ElevateCard : PlatinumCard
  const feeDetails = product?.feeOptions?.find(option => option.key === feeKey)
  const feeText = `$${feeDetails?.amount?.amount.toLocaleString()}${feeDetails?.frequency}`

  const benefitsHeaderClassNames = classNames(
    isMobile ? styling['benefits-header-mobile'] : styling['benefits-header'],
    { [styling.open]: showMobileBenefits }
  )

  useEffect(() => {
    trackPage({ name: SEGMENT_PAGE_NAMES.SUBSCRIBE(membershipType) })
  }, [membershipType])

  const getFeatures = () => (
    <div className='features show-columns-ml'>
      <div key='invest-feature'>
        {getBenefitIcon('Greenwood Invest')}
        <span className='feature-header'>Greenwood Invest</span>
      </div>
      {product?.benefits?.map((benefit, index) => (
        <div key={index}>
          {getBenefitIcon(benefit.category)}
          <span className='feature-header'>{benefit.category}</span>
        </div>
      ))}
    </div>
  )

  const paymentForm = useMemo(() => {
    if (!options?.clientSecret) {
      return null
    }

    const getPromoCodeForm = () => (
      <>
        {isPromoCodeFormVisible ? (
          <>
            <PromoCodeForm isDarkTheme setPaymentSession={details => setPaymentSession(details)} />
            <div className='payment-info-text-container'>
              <div className='text-line' />
              <span>Payment Information</span>
              <div className='text-line' />
            </div>
          </>
        ) : null}
      </>
    )

    return (
      <>
        {!isUpgrading && getPromoCodeForm()}
        <Elements stripe={stripePromise} options={options} key={options?.clientSecret}>
          <StripePaymentForm
            membershipType={membershipType}
            showStripePrivacyPolicyLink={!isUpgrading}
            sessionType={paymentSession?.paymentSessionType}
            setPaymentFormLoaded={() => setIsPromoCodeFormVisible(true)}
          />
        </Elements>
        {isUpgrading && (
          <div className='buttons-container'>
            <Button onClick={() => navigate(cancelUpgradePathname, { replace: true })} ghost>
              Cancel Upgrade
            </Button>
          </div>
        )}
      </>
    )
  }, [
    options,
    membershipType,
    isUpgrading,
    paymentSession?.paymentSessionType,
    cancelUpgradePathname,
    navigate,
    isPromoCodeFormVisible,
  ])

  const additionalHeadingText = useMemo(() => {
    return !isUpgrading && isMobile ? 'Checkout' : `${membershipType} Card`
  }, [isUpgrading, isMobile, membershipType])

  const feeSubtext = useMemo(() => {
    const amount = feeDetails?.amount?.amount
    if (amount) {
      const frequencyArr = feeDetails?.key.split('_')
      const frequency = frequencyArr[frequencyArr.length - 1]?.toLowerCase()

      return `$${amount} ${frequency}, No Hidden Fees`
    }

    return ''
  }, [feeDetails])

  const innerContainerClasses = classNames(
    'start-flow-black-page-content',
    styling['content-container'],
    { [styling['v2-content-container']]: !isUpgrading }
  )

  return (
    <div
      className={classNames(
        'create-account-black-page',
        styling['create-account-membership-add-payment-method-container']
      )}
    >
      <div className={innerContainerClasses}>
        <div className={styling['left-panel']}>
          <GreenwoodLogo className='logo' />
          <h2>Greenwood {additionalHeadingText}</h2>
          <p>
            Access exclusive benefits for only{' '}
            <span className={styling['subscription-rate']}>{feeText}</span>.
          </p>
          <div className={styling['benefits-container']}>
            <MyCardImage displayUserCard={false} image={cardImage} className={styling.card} />
            {isUpgrading && (
              <>
                <p
                  className={benefitsHeaderClassNames}
                  onClick={() => setShowMobileBenefits(!showMobileBenefits)}
                >
                  Membership Benefits
                  <Chevron className={styling.chevron} />
                </p>
                {(!isMobile || (isMobile && showMobileBenefits)) && getFeatures()}
              </>
            )}
            {!isUpgrading && (
              <>
              {isMobile && (
                <div className={styling['whats-inside-text-container']}>
                  <span>{feeSubtext}</span>
                  <h3>What's Inside</h3>
                  <p>Get exclusive banking<sup>1</sup> services, access to events, members-only workspace, and more.</p>
                </div>
              )}
              <AdditionalBenefitsDropdown collapsible isPaymentScreen />
              </>
            )}
          </div>
        </div>
        <div className={styling['right-panel']}>
          {!isUpgrading || paymentSession?.paymentSessionId ? (
            paymentForm
          ) : (
            <UpdatePaymentMethod
              subscriptionId={subscriptionId}
              isUpgrading={isUpgrading}
              feeKey={feeKey}
              newProduct={product}
            />
          )}
          {isUpgrading && (
            <ExternalLink to={stripePrivacyPolicyDetails.url} className='underlined-link bold'>
              {stripePrivacyPolicyDetails?.title.value}
            </ExternalLink>
          )}
        </div>
      </div>
    </div>
  )
}

export default CreateAccountMembershipAddPaymentMethod
