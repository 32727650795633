import { SET_SEGMENT_TRACKING_EVENT_OCCURRED } from './segmentTrackingActions'

export const segmentTrackingReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case SET_SEGMENT_TRACKING_EVENT_OCCURRED:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
