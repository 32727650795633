import { ADD_ALERT, REMOVE_ALERT, REMOVE_ALERTS } from './alertsActions'

export const alertsReducer = (state = [], action = {}) => {
  switch (action.type) {
    case ADD_ALERT: {
      const newState = state.filter(alert => alert.text !== action.payload.text)
      return [...newState, action.payload]
    }
    case REMOVE_ALERT: {
      return state.filter(a => a.id !== action.payload)
    }
    case REMOVE_ALERTS: {
      if (action.payload?.excludeAutoDismiss) {
        // Clear all alerts which don't have an autoDismissDelay set
        return state.filter(_alert => _alert.autoDismissDelay)
      } else {
        // Clear all alerts
        return []
      }
    }
    default:
      return state
  }
}
