import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { ReactComponent as Chevron } from '@shared/images/icons/chevron.svg'

import styling from './linksTable.module.scss'

const TableRow = ({ rowData }) => {
  const getContent = () => {
    if (rowData.renderContent) {
      const children = rowData.renderContent({
        children: rowText,
        className: styling['link-row-action-item'],
      })
      return <>{children}</>
    } else {
      return rowText
    }
  }

  const rowText = (
    <>
      <p>{rowData.heading}</p>
      {rowData.subtext && <p className={styling.subtext}>{rowData.subtext}</p>}
    </>
  )

  const content = getContent()

  const handleOnClick = () => {
    if (rowData.onClick) {
      rowData.onClick()
    } else {
      // Get the content's attached component to trigger it's click event
      const actionItem = content?._owner?.firstEffect?.stateNode

      // This check negates attempting to click on non-physical items such as <Transition />
      if (actionItem?.classList) {
        actionItem?.click()
      }
    }
  }

  return (
    <tr className='link-row' onClick={handleOnClick} data-cy={rowData.dataCy}>
      <td className={styling['icon-container']}>{rowData.icon}</td>
      <td className={styling['text-container']}>{content}</td>
      <td className='row-chevron'>
        <Chevron />
      </td>
    </tr>
  )
}

const LinksTable = ({ className, rowsData }) => {
  return rowsData ? (
    <table
      className={classNames('simple-table', 'fixed-height', styling['table-links'], className)}
    >
      <tbody>
        {rowsData.map((rowData, index) => (
          <TableRow rowData={rowData} key={index} />
        ))}
      </tbody>
    </table>
  ) : null
}

LinksTable.propTypes = {
  /**
   * Any `classname` library compatible classnames which should be applied to the view's container
   */
  className: PropTypes.any,

  /**
   * The array of row config objects. This prop should be memoized for performance
   * Object.renderContent function expects as a parameter:
   *   PropTypes.shape({
   *     children: PropTypes.node.isRequired,
   *     className: PropTypes.any // Any `classname` library compatible classnames
   *   })
   */
  rowsData: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string.isRequired,
      subtext: PropTypes.string,
      icon: PropTypes.node,
      renderContent: PropTypes.func,
      onClick: PropTypes.func,
      dataCy: PropTypes.string,
    })
  ).isRequired,
}

export default LinksTable
