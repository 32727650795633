const SEGMENT_SOURCE_DETAILS = {
  BANK_APPLICATION: 'Bank Application',
  UNDER_18: 'Under 18',
  REGISTRATION_EMAIL: 'Registration Email',
  WAITLIST_CODE: 'Waitlist Code',
  PASSWORD: 'Password',
  PHONE: 'Phone',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  BIRTHDAY: 'Birthday',
  SOCIAL: 'Social',
  ADDRESS: 'Address',
  ADDRESS_LINE_1: 'Address Line 1',
  ADDRESS_LINE_2: 'Address Line 2',
  CITY: 'City',
  STATE: 'State',
  ZIP: 'Zip',
  EDIT_NAME: 'Edit Name',
  EDIT_DATE_OF_BIRTH: 'Edit Date of Birth',
  EDIT_SOCIAL_SECURITY: 'Edit Social Security',
  EDIT_ADDRESS: 'Edit Address',
  COPY_ROUTING_NUMBER: 'Copy Routing Number',
  COPY_ACCOUNT_NUMBER: 'Copy Account Number',
  MASK_ACCOUNT_NUMBER: 'Mask/unmask Account Number',
  BACK: 'Back',
  CONTINUE: 'Continue',
  PAYMENT_FREQUENCY: 'Payment Frequency',
  ABOUT_MY_MEMBERSHIP: 'About My Membership',
  CHANGE_MY_MEMBERSHIP: 'Change My Membership',
  PAYMENT_METHOD: 'Payment Method',
  AGREE: 'Agree',
  SUBSCRIBE: 'Subscribe',
  STRIPE_PRIVACY_POLICY: 'Stripe Privacy Policy',
  UPDATE_PAYMENT_METHOD: 'Update Payment Method',
  UPGRADE: 'Upgrade',
  DIRECT_DEPOSIT: 'Direct Deposit',
  ADD_MONEY_FROM_OTHER_BANKS: 'Add Money from Other Banks',
  TRANSFER_BETWEEN_ACCOUNTS: 'Transfer Money between Accounts',
  INSTANT_CARD_DEPOSITS: 'Instant Card Deposit',
  WIRE_TRANSFER: 'Wire Transfer',
  PAY_BILLS: 'Pay Bills',
  SEND_DIRECT_DEPOSIT_FORM: 'Send Direct Deposit Form',
  FIND_YOUR_PAYROLL_PROVIDER: 'Find Your Payroll Provider',
  DONE: 'Done',
  TRY_AGAIN: 'Try Again',
  DEPOSIT_AMOUNT: 'Deposit Amount',
  FUND_FROM: 'Fund From',
  ENTER_CVV: 'Enter CVV',
  CONFIRM: 'Confirm',
  TRANSFER_AMOUNT: 'Transfer Amount',
  TRANSFER_FROM: 'Transfer From',
  DEPOSIT_TO: 'Deposit To',
  ADD_NOTE: 'Add Note',
  CANCEL: 'Cancel',
  LEARN_MORE: 'Learn More',
  SCHEDULE_ANOTHER: 'Schedule Another',
  EMAIL_WIRE_TRANSFER_FORM: 'Email Wire Transfer Form',
  CREATE_SAVINGS_ACCOUNT: 'Create Savings Account',
  VIEW_ACCOUNT_DETAILS: 'View Account Details',
  TRANSACTION_DETAILS: 'Transaction Details',
  SAVINGS_ACCOUNT: 'Savings Account',
  ACCOUNT_DETAILS: 'Account Details',
  FUND_YOUR_ACCOUNT: 'Fund Your Account',
  UPGRADE_MEMBERSHIP: 'Upgrade Membership',
  OPEN_SAVINGS_ACCOUNT: 'Open Savings Account',
  DOWNLOAD_APP: 'Download App',
  TOOL_TIP: 'Tool Tip',
  MOBILE_NUMBER: 'Mobile Number',
  EMAIL: 'Email',
  PAYMENT_SUBMITTED: 'Payment Submitted',
  MORE: 'More',
  PROFILE_ICON: 'Profile Icon',
  CONTACT_US: 'Contact us',
  FAQS: 'Faqs',
  CALL_US: 'Call us',
  EMAIL_US: 'Email us',
  DOB: 'DOB',
  SETUP_ACCOUNT: 'Set Up Account',
  PROMO_CODE: 'Promo Code',
  APPLY_PROMO_CODE: 'Apply Promo Code',
}

const SEGMENT_SOURCES = {
  REGISTRATION_FLOW: 'Registration Flow',
  PAY_BILLS: 'Pay Bills',
}

const SEGMENT_PAGE_NAMES = {
  HOME: 'Home',
  SIGN_IN: 'Sign In',
  RESET_PASSWORD: 'Reset Password',
  REGISTRATION_EMAIL: 'Registration Email',
  REGISTRATION_PASSWORD: 'Registration Password',
  REGISTRATION_PHONE: 'Registration Phone',
  REGISTRATION_NAME: 'Registration Name',
  REGISTRATION_BIRTHDAY: 'Registration Birthday',
  REGISTRATION_SOCIAL: 'Registration Social',
  REGISTRATION_ADDRESS_GOOGLE: 'Registration Address Google',
  REGISTRATION_ADDRESS_MANUAL: 'Registration Address Manual',
  REGISTRATION_ADDRESS_CONFIRMATION: 'Registration Address Confirmation',
  REGISTRATION_CONFIRM: 'Registration Confirm',
  BANK_APPLICATION_APPROVED: 'Bank Application Approved',
  BANK_APPLICATION_DECLINED: 'Bank Application Declined',
  BANK_APPLICATION_IN_REVIEW: 'Bank Application In Review',
  PAY_BILLS: 'Pay Bills',
  MFA_HELP: 'MFA Help',
  SELECT_MEMBERSHIP_TYPE: 'Select Membership Type',
  AGREEMENTS: 'Agreements',
  ABOUT_MY_MEMBERSHIP: 'About My Membership',
  MEMBERSHIP_SETTINGS: 'Membership Settings',
  SUBSCRIBE: productType => `${productType} - Subscribe`,
  SUCCESSFUL_MEMBERSHIP: productType => `${productType} - Successful Membership`,
  PAYMENT_METHOD: 'Payment Method',
  CHANGE_MY_MEMBERSHIP: 'Change My Membership',
  STRIPE_PAYMENT: 'Stripe Payment',
  DASHBOARD_HOME: 'Dashboard Home',
  BANK_ACCOUNT_CREATED: 'Bank Account Created',
  MOVE_MONEY: 'Move Money',
  MOVE_MONEY_ADD_MONEY: 'Move Money - Add Money',
  MOVE_MONEY_SEND_MONEY: 'Move Money - Send Money',
  MOVE_MONEY_DIRECT_DEPOSIT: 'Direct Deposit',
  MOVE_MONEY_DIRECT_DEPOSIT_FORM_SENT: 'Direct Deposit Form Sent',
  DIRECT_DEPOSIT_FORM_SENT_FAILED: 'Direct Deposit Form Failed',
  INSTANT_CARD_DEPOSIT: 'Instant Card Deposit',
  INSTANT_CARD_DEPOSIT_CONFIRM: 'Instant Card Deposit Confirm',
  INSTANT_CARD_DEPOSIT_SUCCESS: 'Instant Card Deposit Success',
  TRANSFER_MONEY: 'Transfer Money',
  TRANSFER_MONEY_CONFIRM: 'Transfer Money Confirm',
  TRANSFER_MONEY_PROCESSING: 'Transfer Money Processing',
  TRANSFER_MONEY_COMPLETED: 'Transfer Money Completed',
  WIRE_TRANSFER: 'Wire Transfer',
  WIRE_TRANSFER_FORM_SENT: 'Wire Transfer Form Sent',
  SAVINGS_ACCOUNT_SPLASH_SCREEN: 'Savings Account Splash Screen',
  SAVINGS_ACCOUNT_AGREEMENT: 'Savings Account Agreement',
  SAVINGS_ACCOUNT_SUCCESS: 'Savings Account Success',
  TRANSACTION_HISTORY_SAVINGS: 'Transaction History - Savings',
  TRANSACTION_HISTORY: 'Transaction History',
  TRANSACTION_DETAILS: 'Transaction Details',
  ELEVATE_CONTACT_INFO: 'Elevate Contact Info',
  ELEVATE_PAYMENT: 'Elevate Payment',
  MY_MEMBERSHIP: 'My Membership',
  PERSONAL_INFORMATION: 'Personal Information',
  ELEVATE_CHECKOUT: 'Elevate',
  PROFILE: 'Profile',
}

const BUTTON_CLICKED_EVENT = 'Button Clicked'
const FORM_FIELD_ENTERED_EVENT = 'Form Field Entered'

const SEGMENT_EVENTS = {
  CUSTOMER_ACCOUNT_STARTED: {
    event: 'Customer Account Started',
    properties: { acc_started: true },
  },
  ACCOUNT_CREATED: {
    event: 'Account Created',
    properties: {
      source: SEGMENT_SOURCES.REGISTRATION_FLOW,
      source_detail: SEGMENT_SOURCE_DETAILS.BANK_APPLICATION,
    },
  },
  BANK_APPLICATION_STARTED: {
    event: 'Bank Application Started',
    properties: {
      bankapp_started: true,
      source: SEGMENT_SOURCES.REGISTRATION_FLOW,
      source_detail: SEGMENT_SOURCE_DETAILS.BANK_APPLICATION,
    },
  },
  BANK_APPLICATION_STATUS_TRACK: ({ status }) => ({
    event: 'Bank Application Track',
    properties: {
      source: SEGMENT_SOURCES.REGISTRATION_FLOW,
      source_detail: SEGMENT_SOURCE_DETAILS.BANK_APPLICATION,
      status: 'Bank Application ' + status,
      platform: 'Web',
      account_type: 'Plastic Debit - Inactive',
    },
  }),
  BANK_APPLICATION_COMPLETED: {
    event: 'Bank Application Completed',
    properties: { app_success: true },
  },
  BANK_ACCOUNT_CREATED: {
    event: 'Bank Account Created',
    properties: { app_success: true },
  },
  ELEVATE_REGISTRATION_COMPLETED: {
    event: 'Elevate Registration Completed',
    properties: { app_success: true },
  },
  USER_DISQUALIFIED: {
    event: 'User Disqualified',
    properties: {
      source: SEGMENT_SOURCES.REGISTRATION_FLOW,
      source_detail: SEGMENT_SOURCE_DETAILS.UNDER_18,
    },
  },
  PAY_BILLS_BUTTON_CLICK: sourceDetail => ({
    event: BUTTON_CLICKED_EVENT,
    properties: {
      source: SEGMENT_SOURCES.PAY_BILLS,
      source_detail: sourceDetail,
    },
  }),
  AGREEMENTS_AGREE_BUTTON_CLICK: {
    event: BUTTON_CLICKED_EVENT,
    properties: {
      source: SEGMENT_PAGE_NAMES.AGREEMENTS,
      source_detail: SEGMENT_SOURCE_DETAILS.AGREE,
    },
  },
  membershipBackButtonClicked: ({ source }) => ({
    event: BUTTON_CLICKED_EVENT,
    properties: {
      source,
      source_detail: SEGMENT_SOURCE_DETAILS.BACK,
    },
  }),
  MY_MEMBERSHIP_ABOUT_MY_MEMBERSHIP_BUTTON_CLICK: {
    event: BUTTON_CLICKED_EVENT,
    properties: {
      source: SEGMENT_PAGE_NAMES.MY_MEMBERSHIP,
      source_detail: SEGMENT_SOURCE_DETAILS.ABOUT_MY_MEMBERSHIP,
    },
  },
  MY_MEMBERSHIP_CHANGE_MY_MEMBERSHIP_BUTTON_CLICK: {
    event: BUTTON_CLICKED_EVENT,
    properties: {
      source: SEGMENT_PAGE_NAMES.MY_MEMBERSHIP,
      source_detail: SEGMENT_SOURCE_DETAILS.CHANGE_MY_MEMBERSHIP,
    },
  },
  MY_MEMBERSHIP_PAYMENT_METHOD_BUTTON_CLICK: {
    event: BUTTON_CLICKED_EVENT,
    properties: {
      source: SEGMENT_PAGE_NAMES.MY_MEMBERSHIP,
      source_detail: SEGMENT_SOURCE_DETAILS.PAYMENT_METHOD,
    },
  },
  PAYMENT_METHOD_UPDATE_METHOD_BUTTON_CLICK: {
    event: BUTTON_CLICKED_EVENT,
    properties: {
      source: SEGMENT_PAGE_NAMES.PAYMENT_METHOD,
      source_detail: SEGMENT_SOURCE_DETAILS.UPDATE_PAYMENT_METHOD,
    },
  },
  SELECT_MEMBERSHIP_TYPE_CONTINUE_BUTTON_CLICK: {
    event: BUTTON_CLICKED_EVENT,
    properties: {
      source: SEGMENT_PAGE_NAMES.SELECT_MEMBERSHIP_TYPE,
      source_detail: SEGMENT_SOURCE_DETAILS.CONTINUE,
    },
  },
  SELECT_MEMBERSHIP_TYPE_PAYMENT_FREQUENCY_BUTTON_CLICK: {
    event: BUTTON_CLICKED_EVENT,
    properties: {
      source: SEGMENT_PAGE_NAMES.SELECT_MEMBERSHIP_TYPE,
      source_detail: SEGMENT_SOURCE_DETAILS.PAYMENT_FREQUENCY,
    },
  },
  changeMembershipTypeButtonClicked: ({ sourceDetail }) => ({
    event: BUTTON_CLICKED_EVENT,
    properties: {
      source: SEGMENT_PAGE_NAMES.SELECT_MEMBERSHIP_TYPE,
      source_detail: sourceDetail,
    },
  }),
  registrationFormFieldEntry: ({ sourceDetail }) => ({
    event: FORM_FIELD_ENTERED_EVENT,
    properties: {
      source: SEGMENT_SOURCES.REGISTRATION_FLOW,
      source_detail: sourceDetail,
    },
  }),
  registrationButtonClicked: ({ sourceDetail }) => ({
    event: BUTTON_CLICKED_EVENT,
    properties: {
      source: SEGMENT_SOURCES.REGISTRATION_FLOW,
      source_detail: sourceDetail,
    },
  }),

  verificationCodeSubmissionAttempt: ({ rememberMeId, success, remembered }) => ({
    event: 'MFA Verification',
    properties: {
      success,
      remembermeId: rememberMeId,
      remembered,
    },
  }),
  verificationCodeSelection: ({ rememberMeId, selected }) => ({
    event: 'MFA Selection',
    properties: {
      selected,
      remembermeId: rememberMeId,
    },
  }),
  registrationEmailPrepopulated: ({ email }) => ({
    event: 'Email Prepopulated',
    properties: {
      email,
    },
  }),
  registrationEmailSubmitted: ({ email }) => ({
    event: 'Email Submitted',
    properties: {
      email,
    },
  }),
  specificAgreementButtonClicked: ({ sourceDetail }) => ({
    event: BUTTON_CLICKED_EVENT,
    properties: {
      source: SEGMENT_PAGE_NAMES.AGREEMENTS,
      source_detail: sourceDetail,
    },
  }),
  successfulMembershipContinueButtonClicked: ({ productType }) => ({
    event: BUTTON_CLICKED_EVENT,
    properties: {
      source: SEGMENT_PAGE_NAMES.SUCCESSFUL_MEMBERSHIP(productType),
      source_detail: SEGMENT_SOURCE_DETAILS.CONTINUE,
    },
  }),
  productPaymentSubscribeButtonClicked: ({ productType }) => ({
    event: BUTTON_CLICKED_EVENT,
    properties: {
      source: SEGMENT_PAGE_NAMES.SUBSCRIBE(productType),
      source_detail: SEGMENT_SOURCE_DETAILS.SUBSCRIBE,
    },
  }),
  productPaymentStripePrivacyPolicyButtonClicked: ({ productType, source }) => ({
    event: BUTTON_CLICKED_EVENT,
    properties: {
      source: productType ? SEGMENT_PAGE_NAMES.SUBSCRIBE(productType) : source,
      source_detail: SEGMENT_SOURCE_DETAILS.STRIPE_PRIVACY_POLICY,
    },
  }),
  STRIPE_PAYMENT_UPGRADE_BUTTON_CLICK: {
    event: BUTTON_CLICKED_EVENT,
    properties: {
      source: SEGMENT_PAGE_NAMES.STRIPE_PAYMENT,
      source_detail: SEGMENT_SOURCE_DETAILS.UPGRADE,
    },
  },
  MOVE_MONEY_BUTTON_CLICK: sourceDetail => ({
    event: BUTTON_CLICKED_EVENT,
    properties: {
      source: SEGMENT_PAGE_NAMES.MOVE_MONEY,
      source_detail: sourceDetail,
    },
  }),
  MOVE_MONEY_DIRECT_DEPOSIT_BUTTON_CLICK: sourceDetail => ({
    event: BUTTON_CLICKED_EVENT,
    properties: {
      source: SEGMENT_PAGE_NAMES.MOVE_MONEY_DIRECT_DEPOSIT,
      source_detail: sourceDetail,
    },
  }),
  MOVE_MONEY_DIRECT_DEPOSIT_FORM_SENT_DONE_CLICK: {
    event: BUTTON_CLICKED_EVENT,
    properties: {
      source: SEGMENT_PAGE_NAMES.MOVE_MONEY_DIRECT_DEPOSIT_FORM_SENT,
      source_detail: SEGMENT_SOURCE_DETAILS.DONE,
    },
  },
  MOVE_MONEY_DIRECT_DEPOSIT_FORM_FAILED_BUTTON_CLICK: sourceDetail => ({
    event: BUTTON_CLICKED_EVENT,
    properties: {
      source: SEGMENT_PAGE_NAMES.DIRECT_DEPOSIT_FORM_SENT_FAILED,
      source_detail: sourceDetail,
    },
  }),
  INSTANT_CARD_DEPOSIT_AMOUNT_FIELD_ENTERED: {
    event: FORM_FIELD_ENTERED_EVENT,
    properties: {
      source: SEGMENT_PAGE_NAMES.INSTANT_CARD_DEPOSIT,
      source_detail: SEGMENT_SOURCE_DETAILS.DEPOSIT_AMOUNT,
    },
  },
  INSTANT_CARD_DEPOSIT_BUTTON_CLICK: ({ sourceDetail, ...additionalProperties }) => ({
    event: BUTTON_CLICKED_EVENT,
    properties: {
      source: SEGMENT_PAGE_NAMES.INSTANT_CARD_DEPOSIT,
      source_detail: sourceDetail,
      ...additionalProperties,
    },
  }),
  INSTANT_CARD_DEPOSIT_SUCCESS_BUTTON_CLICK: {
    event: BUTTON_CLICKED_EVENT,
    properties: {
      source: SEGMENT_PAGE_NAMES.INSTANT_CARD_DEPOSIT_SUCCESS,
      source_detail: SEGMENT_SOURCE_DETAILS.DONE,
    },
  },
  TRANSFER_AMOUNT_FIELD_ENTERED: {
    event: FORM_FIELD_ENTERED_EVENT,
    properties: {
      source: SEGMENT_PAGE_NAMES.TRANSFER_MONEY,
      source_detail: SEGMENT_SOURCE_DETAILS.TRANSFER_AMOUNT,
    },
  },
  TRANSFER_MONEY_BUTTON_CLICK: ({ sourceDetail, ...additionalProperties }) => ({
    event: BUTTON_CLICKED_EVENT,
    properties: {
      source: SEGMENT_PAGE_NAMES.TRANSFER_MONEY,
      source_detail: sourceDetail,
      ...additionalProperties,
    },
  }),
  TRANSFER_CONFIRM_BUTTON_CLICK: ({ sourceDetail }) => ({
    event: BUTTON_CLICKED_EVENT,
    properties: {
      source: SEGMENT_PAGE_NAMES.TRANSFER_MONEY_CONFIRM,
      source_detail: sourceDetail,
    },
  }),
  TRANSFER_PROCESSING_BUTTON_CLICK: ({ sourceDetail }) => ({
    event: BUTTON_CLICKED_EVENT,
    properties: {
      source: SEGMENT_PAGE_NAMES.TRANSFER_MONEY_PROCESSING,
      source_detail: sourceDetail,
    },
  }),
  TRANSFER_COMPLETED_DONE_BUTTON_CLICK: {
    event: BUTTON_CLICKED_EVENT,
    properties: {
      source: SEGMENT_PAGE_NAMES.TRANSFER_MONEY_COMPLETED,
      source_detail: SEGMENT_SOURCE_DETAILS.DONE,
    },
  },
  EMAIL_WIRE_TRANSFER_FORM_BUTTON_CLICK: {
    event: BUTTON_CLICKED_EVENT,
    properties: {
      source: SEGMENT_PAGE_NAMES.WIRE_TRANSFER,
      source_detail: SEGMENT_SOURCE_DETAILS.EMAIL_WIRE_TRANSFER_FORM,
    },
  },
  EMAIL_WIRE_TRANSFER_FORM_SENT_BUTTON_CLICK: {
    event: BUTTON_CLICKED_EVENT,
    properties: {
      source: SEGMENT_PAGE_NAMES.WIRE_TRANSFER_FORM_SENT,
      source_detail: SEGMENT_SOURCE_DETAILS.DONE,
    },
  },
  CREATE_SAVINGS_ACCOUNT_CLICK: {
    event: BUTTON_CLICKED_EVENT,
    properties: {
      source: SEGMENT_PAGE_NAMES.SAVINGS_ACCOUNT_SPLASH_SCREEN,
      source_detail: SEGMENT_SOURCE_DETAILS.CREATE_SAVINGS_ACCOUNT,
    },
  },
  SAVINGS_ACCOUNT_AGREEMENT_CLICK: {
    event: BUTTON_CLICKED_EVENT,
    properties: {
      source: SEGMENT_PAGE_NAMES.SAVINGS_ACCOUNT_AGREEMENT,
      source_detail: SEGMENT_SOURCE_DETAILS.AGREE,
    },
  },
  SAVINGS_ACCOUNT_SUCCESS_CLICK: {
    event: BUTTON_CLICKED_EVENT,
    properties: {
      source: SEGMENT_PAGE_NAMES.SAVINGS_ACCOUNT_SUCCESS,
      source_detail: SEGMENT_SOURCE_DETAILS.DONE,
    },
  },
  VIEW_ACCOUNT_DETAILS_CLICK: source => ({
    event: BUTTON_CLICKED_EVENT,
    properties: {
      source,
      source_detail: SEGMENT_SOURCE_DETAILS.VIEW_ACCOUNT_DETAILS,
    },
  }),
  DASHBOARD_BUTTON_CLICK: sourceDetail => ({
    event: BUTTON_CLICKED_EVENT,
    properties: {
      source: SEGMENT_PAGE_NAMES.DASHBOARD_HOME,
      source_detail: sourceDetail,
    },
  }),
  TRANSACTION_DETAILS_CLICK: source => ({
    event: BUTTON_CLICKED_EVENT,
    properties: {
      source,
      source_detail: SEGMENT_SOURCE_DETAILS.TRANSACTION_DETAILS,
    },
  }),
  TRANSFER_MONEY_TOOLTIP_HOVER: source => ({
    event: BUTTON_CLICKED_EVENT,
    properties: {
      source,
      source_detail: SEGMENT_SOURCE_DETAILS.TOOL_TIP,
    },
  }),
  TOOLTIP_HOVER: source => ({
    event: BUTTON_CLICKED_EVENT,
    properties: {
      source,
      source_detail: SEGMENT_SOURCE_DETAILS.TOOL_TIP,
    },
  }),
  nonBankingMembershipFieldEntered: ({ sourceDetail, source }) => ({
    event: FORM_FIELD_ENTERED_EVENT,
    properties: {
      source,
      source_detail: sourceDetail,
    },
  }),
  nonBankingMembershipGetAccessButtonClicked: ({ userEmail, source }) => ({
    event: `${source} Submitted`,
    properties: {
      email: userEmail,
    },
  }),
  nonBankingProductConfirmPaymentButtonClicked: ({ userEmail, source }) => ({
    event: BUTTON_CLICKED_EVENT,
    properties: {
      source,
      source_detail: SEGMENT_SOURCE_DETAILS.PAYMENT_SUBMITTED,
      email: userEmail,
    },
  }),
  ELEVATE_FUNNEL_VISIT: {
    event: 'Elevate Funnel Visit',
  },
  elevateLeadContactInfoSubmitted: email => ({
    event: 'Elevate Lead',
    properties: {
      email,
    },
  }),
  UPGRADE_MEMBERSHIP_BUTTON_CLICK: source => ({
    event: BUTTON_CLICKED_EVENT,
    properties: {
      source,
      source_detail: SEGMENT_SOURCE_DETAILS.UPGRADE_MEMBERSHIP,
    },
  }),
  PERSONAL_INFO_CONTACT_US_BUTTON_CLICK: {
    event: BUTTON_CLICKED_EVENT,
    properties: {
      source: SEGMENT_PAGE_NAMES.PERSONAL_INFORMATION,
      source_detail: SEGMENT_SOURCE_DETAILS.CONTACT_US,
    },
  },
  ABOUT_MY_MEMBERSHIP_BUTTON_CLICK: sourceDetail => ({
    event: BUTTON_CLICKED_EVENT,
    properties: {
      source: SEGMENT_PAGE_NAMES.ABOUT_MY_MEMBERSHIP,
      source_detail: `Access Benefits - ${sourceDetail}`,
    },
  }),
  PROFILE_ICON_DROPDOWN_ITEM_CLICK: title => ({
    event: BUTTON_CLICKED_EVENT,
    properties: {
      source: SEGMENT_PAGE_NAMES.PROFILE,
      source_detail: title,
    },
  }),
  HELP_SUPPORT_BUTTON_CLICK: ({ source, sourceDetail }) => ({
    event: BUTTON_CLICKED_EVENT,
    properties: {
      source,
      source_detail: sourceDetail,
    },
  }),
}

const SEGMENT_IDENTIFIER_TRAITS = {
  registration: ({ email, waitlistCode }) => ({
    email: email || undefined,
    waitlist_id: waitlistCode || undefined,
  }),
  login: ({ email }) => ({
    email: email || undefined,
  }),
}

const trackEvent = ({ event, options, callback, ...additionalProperties }) => {
  try {
    window.analytics.track(
      event.event,
      { ...event.properties, ...additionalProperties },
      options,
      callback
    )
  } catch (err) {
    // Fail silently
  }
}

const trackPage = ({ category, name, properties, options, callback, ...additionalProperties }) => {
  try {
    window.analytics.page(
      category,
      name,
      { ...properties, ...additionalProperties },
      options,
      callback
    )
  } catch (err) {
    // Fail silently
  }
}

const trackIdentity = ({ userId, traits, options, callback, ...additionalTraits }) => {
  // userId is optional. If not provided, it is considered an 'anonymous' identity call
  try {
    window.analytics.identify(
      userId,
      {
        ...traits,
        ...additionalTraits,
      },
      options,
      callback
    )
  } catch (err) {
    // Fail silently
  }
}

const reset = () => {
  try {
    window.analytics.reset()
  } catch (err) {
    // Fail silently
  }
}

const getAnonymousId = () => {
  try {
    return window.analytics?.user().anonymousId()
  } catch (err) {
    // Fail silently
    return null
  }
}

const trackTvPixelEvent = () => {
  try {
    // Track the direct response pixel that was loaded by GTM
    // This visitor pixel along with this function is loaded via GTM.
    // In developer tools, look for 71u in Network tab and a response 200
    // when this form is submitted.
    window.pmpx('event', 'form_fill', {})
  } catch (err) {
    // Fail silently
  }
}

// Re-export for convenience
export {
  trackEvent,
  trackPage,
  trackIdentity,
  reset,
  getAnonymousId,
  trackTvPixelEvent,
  SEGMENT_EVENTS,
  SEGMENT_PAGE_NAMES,
  SEGMENT_SOURCE_DETAILS,
  SEGMENT_IDENTIFIER_TRAITS,
}
