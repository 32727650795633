import React, { useRef, useState, useEffect } from 'react'
import classNames from 'classnames'

import Tooltip from '@shared/components/tooltip/Tooltip'
import Button from '@shared/components/button/Button'
import Card from '@shared/components/card/Card'
import Collapse from '@shared/components/Collapse'

import { ReactComponent as Eye } from '@shared/images/icons/eye.svg'
import { ReactComponent as ClosedEye } from '@shared/images/icons/eye-closed.svg'
import { ReactComponent as QuestionMark } from '@shared/images/icons/question-mark.svg'
import { ReactComponent as Chevron } from '@shared/images/icons/chevron.svg'

import { toLongDateFormat, toDollars, maskString } from '@shared/utils'
import { PRODUCT_TYPES } from '@common/constants'
import { trackEvent, SEGMENT_EVENTS } from '@common/utils'

import styling from './hero.module.scss'

const HeroAccountDetails = ({ account, isMobile, segmentSource }) => {
  const [showCurrentBalanceTooltip, setShowCurrentBalanceTooltip] = useState(false)
  const [expandAccountDetails, setExpandAccountDetails] = useState(false)
  const [showAvailableBalanceTooltip, setShowAvailableBalanceTooltip] = useState(false)
  const [maskAccountNumber, setMaskAccountNumber] = useState(true)
  const [maskRoutingNumber, setMaskRoutingNumber] = useState(true)

  const availableBalanceRef = useRef(null)
  const currentBalanceRef = useRef(null)

  const maskNumber = value =>
    maskString({
      value,
      mask: 'x',
      length: value?.length - 4,
      spaceAfterMask: true,
    })
  const getMaskedValue = (isMasked, value, forceMask = false) =>
    (!isMobile || forceMask) && isMasked ? maskNumber(value) : value
  const getMaskedFieldIcon = isMasked => (isMasked ? <Eye /> : <ClosedEye />)
  const chevronClass = classNames({
    [styling['up-chevron']]: expandAccountDetails,
    [styling['down-chevron']]: !expandAccountDetails,
  })
  const accountDetailsText = `${expandAccountDetails ? 'HIDE' : 'SHOW'} ACCOUNT DETAILS`
  const isSavingsAccount = account?.accountType === PRODUCT_TYPES.savings || false

  const getAccountDetails = () =>
    account ? (
      <div className={styling.details}>
        <div className={styling['detail-col']}>
          <p>
            <span data-cy='current-balance-amount'>
              {toDollars({ value: account.balance.currentBalance.amount })}
            </span>
            <span>
              Current Balance{' '}
              <QuestionMark className={styling['tooltip-trigger']} ref={currentBalanceRef} />
            </span>

            <Tooltip
              placement='auto'
              open={showCurrentBalanceTooltip}
              target={currentBalanceRef}
              toggle={open => {
                setShowCurrentBalanceTooltip(!open)
              }}
              header='Current Balance'
            >
              The total amount of money recorded in your account, including funds not yet available
              for you to use. This includes pending transactions, authorization holds that are not
              yet posted or deposits that have not yet been made available.
            </Tooltip>
          </p>
          {!isSavingsAccount && (
            <p>
              <span data-cy='routing-number-value'>
                {getMaskedValue(maskRoutingNumber, account?.routingNumber)}
              </span>
              <span className={styling['has-toggle']}>
                Routing Number{' '}
                {!isMobile && (
                  <Button
                    data-cy='toggle-routing-number-mask-button'
                    ghost
                    onDark
                    onClick={() => setMaskRoutingNumber(!maskRoutingNumber)}
                    className={styling['show-hide-toggle']}
                  >
                    {getMaskedFieldIcon(maskRoutingNumber)}
                  </Button>
                )}
              </span>
            </p>
          )}
        </div>
        <div className={styling['detail-col']}>
          <p>
            <span data-cy='account-number-value'>
              {getMaskedValue(maskAccountNumber, account?.accountNumber, isSavingsAccount)}
            </span>
            <span className={styling['has-toggle']}>
              Account Number{' '}
              {!isMobile && !isSavingsAccount && (
                <Button
                  data-cy='toggle-account-number-mask-button'
                  ghost
                  onDark
                  onClick={() => setMaskAccountNumber(!maskAccountNumber)}
                  className={styling['show-hide-toggle']}
                >
                  {getMaskedFieldIcon(maskAccountNumber)}
                </Button>
              )}
            </span>
          </p>
          {account?.lastStatementDate && (
            <p>
              <span>{toLongDateFormat(new Date(account.lastStatementDate))}</span>
              <span>Latest Statement Date</span>
            </p>
          )}
        </div>
      </div>
    ) : null

  useEffect(() => {
    if (showAvailableBalanceTooltip && segmentSource) {
      trackEvent({ event: SEGMENT_EVENTS.TOOLTIP_HOVER(segmentSource) })
    }
  }, [showAvailableBalanceTooltip, segmentSource])

  const handleAccountDetailsClick = segmentSource => {
    setExpandAccountDetails(!expandAccountDetails)
    trackEvent({
      event: SEGMENT_EVENTS.VIEW_ACCOUNT_DETAILS_CLICK(segmentSource),
    })
  }

  return (
    <>
      {account?.balance && (
        <div className={styling['account-balance']}>
          <h2>
            Available Balance{' '}
            <QuestionMark className={styling['tooltip-trigger']} ref={availableBalanceRef} />
            <Tooltip
              placement='auto'
              open={showAvailableBalanceTooltip}
              target={availableBalanceRef}
              toggle={open => {
                setShowAvailableBalanceTooltip(!open)
              }}
              header='Available Balance'
            >
              The total amount of money that is available for immediate use. It includes deposits,
              withdrawals, transfers, and any other activity that has already cleared to or from the
              account.
            </Tooltip>
            <span data-cy='available-balance-amount'>
              {toDollars({ value: account.balance.availableBalance.amount })}
            </span>
          </h2>
        </div>
      )}
      {isMobile ? (
        <div className={styling['account-details-expander']}>
          <Button ghost onDark onClick={() => handleAccountDetailsClick(segmentSource)}>
            {accountDetailsText}
            <Chevron className={chevronClass} />
          </Button>

          <Collapse className={styling.expander} open={expandAccountDetails}>
            <Card className={styling['expander-card']}>{getAccountDetails()}</Card>
          </Collapse>
        </div>
      ) : (
        getAccountDetails()
      )}
    </>
  )
}

export default HeroAccountDetails
