import {
  RESET_LAUNCH_DARKLY_CUSTOMER,
  SET_CUSTOMER,
  SET_LAUNCH_DARKLY_CUSTOMER,
} from './customerActions'

const initialState = {}

export const customerReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_CUSTOMER:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export const launchDarklyCustomerReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_LAUNCH_DARKLY_CUSTOMER:
      return {
        ...state,
        ...action.payload,
      }
    case RESET_LAUNCH_DARKLY_CUSTOMER:
      return initialState
    default:
      return state
  }
}
