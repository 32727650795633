import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useLocation } from 'react-router-dom'

import Button from '@shared/components/button/Button'

import { staticRoutes } from '@routing/routes'
import { FUNDING_FLOWS } from '@common/constants'

const UpgradeMembershipButton = ({ buttonText = 'Upgrade', className, clickListener, ...rest }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  return (
    <Button
      className={className}
      onClick={() => {
        clickListener && clickListener()

        navigate(staticRoutes.selectAccountTier.pathname, {
          state: {
            isUpgrading: true,
            fundingFlow: FUNDING_FLOWS.UPGRADE_MEMBERSHIP,
            cancelUpgradePathname: pathname,
          },
        })
      }}
      {...rest}
    >
      {buttonText}
    </Button>
  )
}

UpgradeMembershipButton.propTypes = {
  /* Text to display on upgrade button */
  buttonText: PropTypes.string,
}

export default UpgradeMembershipButton
