import { useNavigate } from 'react-router'
import classNames from 'classnames'

import MyCardImage from '../myCard/MyCardImage'
import { staticRoutes } from '../../routing/routes'
import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'
import Button from '@shared/components/button/Button'

import styling from './replacementCardSent.module.scss'

const ReplacementCardSent = () => {
  const navigate = useNavigate()

  return (
    <div className={classNames('create-account-black-page', styling['replacement-card-sent'])}>
      <GreenwoodLogo className='logo' />
      <h1>
        We’ve Received <br /> Your Replacement Request
      </h1>
      <p>
        A new card will be sent to your mailing address. <br /> It will arrive in the next 5-7 days.
      </p>
      <div className={classNames('card-image-container')}>
        <MyCardImage />
        <p>Your new card will have a different card number.</p>
      </div>
      <div className={classNames('buttons-container')}>
        <Button onDark type='button' onClick={() => navigate(staticRoutes.dashboard.pathname)}>
          DONE
        </Button>
      </div>
    </div>
  )
}

export default ReplacementCardSent
