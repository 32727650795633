import { SEGMENT_API_KEY } from '@common/constants'

/**
 * This source code has been slightly modified from the Segment.js source available here:
 * https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/quickstart/
 */
export const initializeSegmentClient = () => {
  const analytics = (window.analytics = window.analytics || [])

  if (!analytics.initialize) {
    if (analytics.invoked) {
      window.console && console.error && console.error('Segment snippet included twice.')
    } else {
      analytics.invoked = true

      analytics.methods = [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'debug',
        'page',
        'once',
        'off',
        'on',
        'addSourceMiddleware',
        'addIntegrationMiddleware',
        'setAnonymousId',
        'addDestinationMiddleware',
      ]

      analytics.factory = function (e) {
        return function () {
          const t = Array.prototype.slice.call(arguments)
          t.unshift(e)
          analytics.push(t)
          return analytics
        }
      }

      for (let methodIndex = 0; methodIndex < analytics.methods.length; methodIndex++) {
        const key = analytics.methods[methodIndex]
        analytics[key] = analytics.factory(key)
      }

      analytics.load = function (key, e) {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.async = true
        script.src = 'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js'

        const node = document.getElementsByTagName('script')[0]
        node.parentNode.insertBefore(script, node)
        analytics._loadOptions = e
      }

      analytics._writeKey = SEGMENT_API_KEY
      analytics.SNIPPET_VERSION = '4.13.2'
      analytics.load(SEGMENT_API_KEY)
    }
  }
}
