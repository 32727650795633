import React, { useEffect } from 'react'
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store, persistor } from '@redux/store'
import { PersistGate } from 'redux-persist/integration/react'
import { useLDClient, withLDProvider } from 'launchdarkly-react-client-sdk'

// global styles
import '@common/styles/app-global.module.scss'
// additional fonts
import '@common/assets/fonts'

import Loading from '@shared/components/Loading'
import RootContainer from '@common/components/rootContainer/RootContainer'
import ProtectedRoute from '@routing/ProtectedRoute'
import { staticRoutes } from '@routing/routes'
import { initializeSegmentClient } from './services/segmentClient'
import useIdleUserTimeout from '@common/utils/useIdleUserTimeout'
import useFirebase from '@common/utils/useFirebase'
import useReduxStore from '@common/utils/useReduxStore'
import {
  LAUNCH_DARKLY_CLIENT_SIDE_ID,
  HOME_ROUTES,
  SIGN_UP_ROUTES,
  INVEST_ROUTES,
} from '@common/constants'

// views
import Login from './views/login/Login'
import Logout from '@common/components/Logout'
import Home from './views/home/Home'
import MyAccount from './views/myAccount/MyAccount'
import PageNotFound from './views/pageNotFound/PageNotFound'
import CreateAccount from './views/createAccount/CreateAccount'
import CreateAccountSelectTier from './views/createAccount/createAccountSelectTier/CreateAccountSelectTier'
import CreateAccountPremiumAgreements from './views/createAccount/CreateAccountPremiumAgreements'
import CreateAccountMembershipAddPaymentMethod from './views/createAccount/createAccountMembership/CreateAccountMembershipAddPaymentMethod'
import CreateAccountMembershipUpgradeConfirmation from './views/createAccount/createAccountMembership/CreateAccountMembershipUpgradeConfirmation'
import MyCard from './views/myCard/MyCard'
import SignUp from './views/signup/SignUp'
import Settings from './views/settings/Settings'
import Membership from './views/membership/Membership'
import MoveMoney from './views/moveMoney/MoveMoney'
import CardActivated from 'src/views/myCard/CardActivated'
import ReplacementCardSent from './views/replacementCardSent/ReplacementCardSent'
import DirectDepositSetup from './views/directDeposit/DirectDepositSetup'
import DirectDepositSelection from './views/directDeposit/DirectDepositSelection'
import DirectDepositFormSent from 'src/views/directDeposit/DirectDepositFormSent'
import DirectDepositFormSendFailed from 'src/views/directDeposit/DirectDepositFormSendFailed'
import Transfer from '@common/components/transfer/Transfer'
import TransferFailed from '@common/components/transfer/TransferFailed'
import TransferProcessing from '@common/components/transfer/TransferProcessing'
import WireTransferSuccess from './views/transfer/WireTransferSuccess'
import AddMoney from './views/funding/AddMoney'
import AccountsForFunding from './views/funding/AccountsForFunding'
import PremiumPayment from './views/funding/PremiumPayment'
import PremiumCardInbound from './views/funding/PremiumCardInbound'
import BasicCardInbound from './views/funding/BasicCardInbound'
import CreateSavingsAccount from './views/createSavingsAccount/CreateSavingsAccount'
import CreateSavingsAccountAgreements from './views/createSavingsAccount/CreateSavingsAccountAgreements'
import CreateSavingsAccountApproved from './views/createSavingsAccount/CreateSavingsAccountApproved'
import StartGiving from './views/giving/StartGiving'
import Giving from './views/giving/Giving'
import AddDebitCard from './views/addDebitCard/AddDebitCard'
import PayBills from './views/payBills/PayBills'
import WireTransfer from './views//transfer/WireTransfer'
import WireTransferFormSendFailed from './views/transfer/WireTransferFormSendFailed'
import ProvidePhone from '@common/components/ProvidePhone'
import VerifyAccount from '@common/components/verifyAccount/VerifyAccount'
import ProvideEmail from '@common/components/ProvideEmail'
import ProvidePassword from '@common/components/providePassword/ProvidePassword'
import DebitCardDeposit from '@common/components/debitCardDeposit/DebitCardDeposit'
import CoreExchangeLogin from './views/coreExchange/CoreExchangeLogin'
import ChooseVerificationMethod from '@common/components/chooseVerificationMethod/ChooseVerificationMethod'
import SubmitVerificationCode from '@common/components/submitVerificationCode/SubmitVerificationCode'
import AddressResubmission from './views/addressResubmission/AddressResubmission'
import DocumentSubmit from '@common/components/documentSubmit/DocumentSubmit'
import VerifyPassword from 'src/views/verifyPassword/VerifyPassword'
import EditAccountInfo from '@common/components/editAccountInfo/EditAccountInfo'
import Invest from './views/invest/Invest'
import Community from './views/community/Community'

function App () {
  // Use hook to check if user has been idle for too long (15 minutes)
  useIdleUserTimeout({})

  // Initialize Firebase client
  useFirebase()

  // Get current value of email address, firstName and lastName in redux store
  const {
    reduxStore: { launchDarklyCustomer },
  } = useReduxStore()

  // Use LaunchDarkly client
  const client = useLDClient()

  useEffect(() => {
    if (Object.keys(launchDarklyCustomer).length) {
      // Get the current LaunchDarkly user
      const user = client?.getContext()

      // Set the LaunchDarkly user using their saved information, ensuring that their key is the email address
      client?.identify({
        ...user,
        ...launchDarklyCustomer,
        key: launchDarklyCustomer.email || user?.key,
      })
    }
  }, [launchDarklyCustomer, client])

  useEffect(() => {
    // Expose redux store when in Cypress execution context.
    if (window.Cypress) {
      window.store = store
    }

    // Initialize the Segment analytics API
    initializeSegmentClient()
  }, [])

  // Prevent iOS Mobile to auto-zoom on input :focus
  // This happens when input's font-size is less than 16px (the default for Safari)
  useEffect(() => {
    const iOSMobile = navigator.userAgent && /iPad|iPhone|iPod/.test(navigator.userAgent)

    if (iOSMobile) {
      document.head.querySelector('meta[name="viewport"]').content =
        'width=device-width, initial-scale=1, maximum-scale=1'
    }
  }, [])

  return (
    <Provider store={store}>
      <PersistGate loading={<Loading />} persistor={persistor}>
        <BrowserRouter>
          <RootContainer>
            <Routes>
              {/* Authentication */}
              <Route path={staticRoutes.signIn.pathname} Component={Login} />

              <Route
                path={staticRoutes.coreExchangeSignIn.pathname}
                Component={CoreExchangeLogin}
              />

              <Route
                path={staticRoutes.signOut.pathname}
                Component={Logout}
              />

              <Route
                path={staticRoutes.recoverProvideEmail.pathname}
                Component={ProvideEmail}
              />

              <Route
                path={staticRoutes.recoverProvidePassword.pathname}
                Component={ProvidePassword}
              />

              <Route
                path={staticRoutes.chooseVerificationMethod.pathname}
                Component={ChooseVerificationMethod}
              />

              <Route
                path={staticRoutes.submitVerificationCode.pathname}
                Component={SubmitVerificationCode}
              />

              <Route path={staticRoutes.providePhone.pathname} Component={ProvidePhone} />

              {HOME_ROUTES.map((route, index) => (
                <Route
                  path={route}
                  element={<ProtectedRoute component={Home} />}
                  key={`home-route-${index}`}
                />
              ))}

              {/* Credential providers and verification */}
              <Route
                path={staticRoutes.verifyEmail.pathname}
                element={<ProtectedRoute><VerifyAccount type='email' /></ProtectedRoute>}
              />
              <Route
                path={staticRoutes.verifyPhone.pathname}
                element={<ProtectedRoute><VerifyAccount type='phone' /></ProtectedRoute>}
              />

              {/* Sign Up and account creation */}
              {SIGN_UP_ROUTES.map((route, index) => (
                <Route
                  path={route}
                  element={<ProtectedRoute component={SignUp} />}
                  key={`sign-up-route-${index}`}
                />
              ))}

              <Route
                path={staticRoutes.createAccount.pathname}
                element={<ProtectedRoute component={CreateAccount} />}
              />

              {/* Account */}
              <Route
                path={staticRoutes.myAccount.pathname}
                element={<ProtectedRoute component={MyAccount} />}
              />
              <Route
                path={staticRoutes.premiumAgreements.pathname}
                element={<ProtectedRoute component={CreateAccountPremiumAgreements} />}
              />
              <Route
                path={staticRoutes.selectAccountTier.pathname}
                element={<ProtectedRoute component={CreateAccountSelectTier} />}
              />
              <Route
                path={staticRoutes.membershipAddPaymentMethod.pathname}
                element={<ProtectedRoute component={CreateAccountMembershipAddPaymentMethod} />}
              />
              <Route
                path={staticRoutes.membershipUpgradeConfirmation.pathname}
                element={<ProtectedRoute component={CreateAccountMembershipUpgradeConfirmation} />}
              />

              {/* Address Resubmission */}
              <Route path={staticRoutes.addressResubmission.pathname} element={<ProtectedRoute component={AddressResubmission} />} />

              {/* My Card */}
              <Route path={staticRoutes.myCard.pathname} element={<ProtectedRoute component={MyCard} />} />
              <Route path={staticRoutes.cardActivated.pathname} element={<ProtectedRoute component={CardActivated} />} />

              {/* Move Money */}
              <Route path={staticRoutes.moveMoney.pathname} element={<ProtectedRoute component={MoveMoney} />} />

              {/* Pay Bills */}
              <Route path={staticRoutes.payBills.pathname} element={<ProtectedRoute component={PayBills} />} />

              {/* Direct Deposit */}
              <Route
                path={staticRoutes.directDepositSetup.pathname}
                element={<ProtectedRoute component={DirectDepositSetup} />}
              />
              <Route
                path={staticRoutes.directDepositSelection.pathname}
                element={<ProtectedRoute component={DirectDepositSelection} />}
              />
              <Route
                path={staticRoutes.directDepositFormSent.pathname}
                element={<ProtectedRoute component={DirectDepositFormSent} />}
              />
              <Route
                path={staticRoutes.directDepositFormSendFailed.pathname}
                element={<ProtectedRoute component={DirectDepositFormSendFailed} />}
              />

              {/* Transer */}
              <Route
                path={staticRoutes.transfer.pathname}
                element={<ProtectedRoute component={Transfer} />}
              />
              <Route
                path={staticRoutes.transferProcessing.pathname}
                element={<ProtectedRoute component={TransferProcessing} />}
              />
              <Route
                path={staticRoutes.transferFailed.pathname}
                element={<ProtectedRoute component={TransferFailed} />}
              />

              {/* Wire Transfer */}
              <Route
                path={staticRoutes.wireTransfer.pathname}
                element={<ProtectedRoute component={WireTransfer} />}
              />
              <Route
                path={staticRoutes.wireTransferSuccess.pathname}
                element={<ProtectedRoute component={WireTransferSuccess} />}
              />
              <Route
                path={staticRoutes.wireTransferFormSendFailed.pathname}
                element={<ProtectedRoute component={WireTransferFormSendFailed} />}
              />

              {/* Create Savings Account */}
              <Route
                path={staticRoutes.createSavingsAccount.pathname}
                element={<ProtectedRoute component={CreateSavingsAccount} />}
              />
              <Route
                path={staticRoutes.createSavingsAccountAgreements.pathname}
                element={<ProtectedRoute component={CreateSavingsAccountAgreements} />}
              />
              <Route
                path={staticRoutes.createSavingsAccountApproved.pathname}
                element={<ProtectedRoute component={CreateSavingsAccountApproved} />}
              />

              {/* Settings */}
              <Route
                path={staticRoutes.settings.pathname}
                element={<ProtectedRoute component={Settings} />}
              />

              {/* Membership */}
              <Route
                path={staticRoutes.membership.pathname}
                element={<ProtectedRoute component={Membership} />}
              />

              {/* Fund account */}
              <Route
                path={staticRoutes.addMoney.pathname}
                element={<ProtectedRoute component={AddMoney} />}
              />
              <Route
                path={staticRoutes.accountsForFunding.pathname}
                element={<ProtectedRoute component={AccountsForFunding} />}
              />
              <Route
                path={staticRoutes.premiumPayment.pathname}
                element={<ProtectedRoute component={PremiumPayment} />}
              />

              {/* Card Inbound */}
              <Route
                path={staticRoutes.premiumCardInbound.pathname}
                element={<ProtectedRoute component={PremiumCardInbound} />}
              />
              <Route
                path={staticRoutes.basicCardInbound.pathname}
                element={<ProtectedRoute component={BasicCardInbound} />}
              />
              <Route
                path={staticRoutes.replacementCardSent.pathname}
                element={<ProtectedRoute component={ReplacementCardSent} />}
              />

              {/* Giving */}
              <Route
                path={staticRoutes.startGiving.pathname}
                element={<ProtectedRoute component={StartGiving} />}
              />
              <Route
                path={staticRoutes.giving.pathname}
                element={<ProtectedRoute component={Giving} />}
              />

              {/* Add Debit Card */}
              <Route
                path={staticRoutes.addDebitCard.pathname}
                element={<ProtectedRoute component={AddDebitCard} />}
              />

              {/* Debit Card Deposit */}
              <Route
                path={staticRoutes.debitCardDeposit.pathname}
                element={<ProtectedRoute component={DebitCardDeposit} />}
              />

              {/* Document Submit */}
              <Route
                path={staticRoutes.documentSubmit.pathname}
                element={<ProtectedRoute component={DocumentSubmit} />}
              />

              {/* Edit Account */}
              <Route
                path={staticRoutes.verifyPassword.pathname}
                element={<ProtectedRoute component={VerifyPassword} />}
              />
              <Route
                path={staticRoutes.editEmailAddress.pathname}
                element={<ProtectedRoute><EditAccountInfo type='email' /></ProtectedRoute>}
              />
              <Route
                path={staticRoutes.editPhoneNumber.pathname}
                element={<ProtectedRoute><EditAccountInfo type='phone' /></ProtectedRoute>}
              />

              {/* Invest */}
              {INVEST_ROUTES.map((route, index) => (
                <Route
                  path={route}
                  element={<ProtectedRoute component={Invest} />}
                  key={`invest-route-${index}`}
                />
              ))}

              {/* Community */}
              <Route
                path={staticRoutes.community.pathname}
                element={<ProtectedRoute component={Community} />}
              />

              {/* Other */}
              <Route path='*' Component={PageNotFound} />
            </Routes>
          </RootContainer>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  )
}

export default withLDProvider({
  clientSideID: LAUNCH_DARKLY_CLIENT_SIDE_ID,
  user: { key: 'web_user', kind: 'user' },
  options: { allAttributesPrivate: true },
})(App)
