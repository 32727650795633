export const SET_APPLICATION = 'SET_APPLICATION'
export const POLLING_HAS_MAXED_OUT = 'POLLING_HAS_MAXED_OUT'
export const SAVE_LOCATION_STATE = 'SAVE_LOCATION_STATE'
export const RESET_LOCATION_STATE = 'RESET_LOCATION_STATE'
export const SET_ONBOARDING_FLOW_TYPE = 'SET_ONBOARDING_FLOW_TYPE'

export const setApplicationAction = payload => {
  return {
    payload,
    type: SET_APPLICATION,
  }
}

export const setPollingHasMaxedOut = () => {
  return {
    payload: {
      pollingHasMaxedOut: true,
    },
    type: POLLING_HAS_MAXED_OUT,
  }
}

export const setLocationState = payload => {
  return {
    payload,
    type: SAVE_LOCATION_STATE,
  }
}

export const resetLocationState = payload => {
  return {
    payload,
    type: RESET_LOCATION_STATE,
  }
}

export const setOnboardingFlowTypeAction = payload => {
  return {
    payload,
    type: SET_ONBOARDING_FLOW_TYPE,
  }
}
