import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import thunkMiddleware from 'redux-thunk'
import storageSession from 'redux-persist/lib/storage/session'
import { encryptTransform } from 'redux-persist-transform-encrypt'
import { configureStore } from '@reduxjs/toolkit'

import rootReducer from './reducers'
import { reset } from '@common/utils'

const persistConfig = {
  key: 'greenwood_root',
  storage: storageSession,
  transforms: [
    encryptTransform({
      secretKey: 'gbwebdatastore',
    }),
  ],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const purgeStore = ({ callback, redirectPath = '/', timeout = 200 }) => {
  setTimeout(() => {
    persistor.purge()

    // Reset analytics, since we can't assume the same user going forward
    reset()

    if (callback !== undefined) {
      callback()
    } else {
      window.location.href = redirectPath
    }
  }, timeout)
}

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(thunkMiddleware),
})

export const persistor = persistStore(store)
