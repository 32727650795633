import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'

import Loading from '@shared/components/Loading'

import { setPollingHasMaxedOut } from '@redux/application/applicationActions'
import { useCheckpointNavigation } from '@common/utils'
import { staticRoutes } from '@routing/routes'

import styling from './createAccountProcessing.module.scss'

const CreateAccountProcessing = React.memo(
  ({ data, fetchError, refetchApplication }) => {
    const navigate = useNavigate()
    const pollInterval = useRef()
    const maxTimeout = useRef()
    const dispatch = useDispatch()
    const pollingHasMaxedOut = useSelector(state => state?.application?.pollingHasMaxedOut)

    useCheckpointNavigation({
      checkpoints: data?.checkpoints,
      applicationStatus: data?.application?.customerApplicationStatus,
      pollingHasMaxedOut,
    })

    useEffect(() => {
      pollInterval.current = setInterval(() => {
        refetchApplication()
      }, 5000)

      // poll for a max of 60 seconds
      maxTimeout.current = setTimeout(() => {
        dispatch(setPollingHasMaxedOut())
        navigate(staticRoutes.createAccountInReview.pathname)
      }, 60000)

      return () => {
        clearInterval(pollInterval.current)
        clearTimeout(maxTimeout.current)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      if (fetchError) {
        navigate(staticRoutes.createAccountInReview.pathname)
      }
    }, [fetchError, navigate, pollInterval])

    return (
      <div className='white-card-container' data-cy='authenticated-loaded-view'>
        <GreenwoodLogo className='logo' />
        <h1 id='review-step-header'>Sit tight!</h1>
        <div className='create-account-content-wrapper'>
          <p>We're processing your application.</p>
          <Loading
            className={styling['loading-icon']}
          />
        </div>
      </div>
    )
  },
  (prevProps, nextProps) =>
    prevProps?.data?.application?.customerApplicationStatus ===
    nextProps?.data?.application?.customerApplicationStatus
)

CreateAccountProcessing.propTypes = {
  fetchData: PropTypes.object,
  fetchError: PropTypes.bool,
  getApplication: PropTypes.func,
  pollingHasMaxedOut: PropTypes.bool,
}

export default CreateAccountProcessing
