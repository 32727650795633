import { store } from '@redux/store'
import { addAlertAction } from '@redux/alerts/alertsActions'
import {
  DEBIT_CARD_DEPOSIT_STATUS_TYPES,
  DEBIT_CARD_DEPOSIT_3DS_SESSION_TYPES,
} from '@common/constants'
import { DEFAULT_ALERT_DISMISS_DELAY } from '@shared/constants/uiConstants'

export const TABAPAY_3DS_ACTION_CODES = {
  SUCCESS: 'SUCCESS',
  NO_ACTION: 'NOACTION',
  FAILURE: 'FAILURE',
  ERROR: 'ERROR',
  CANCEL: 'CANCEL',
  TIMEOUT: 'TIMEOUT',
}

export const submitDetails = async ({ jwt, submitTransactionDetails, redirectToSuccessPage, cardinal }) => {
  const challengeResponse = await submitTransactionDetails(jwt)

  if (challengeResponse?.data) {
    const {
      linkedCardTransaction: challengeLinkedCardTransaction = {},
      transactionSession: challengeTransactionSession = {},
    } = challengeResponse?.data?.pullFundsFromExistingCardChallenge

    if (
      challengeLinkedCardTransaction?.transactionStatus ===
      DEBIT_CARD_DEPOSIT_STATUS_TYPES.SUCCESS
    ) {
      // if frictionless challenge flow and transaction is successful, then redirect the user to the success page
      redirectToSuccessPage(challengeLinkedCardTransaction?.transactionId)
    } else if (
      challengeTransactionSession?.transactionSessionState ===
      DEBIT_CARD_DEPOSIT_3DS_SESSION_TYPES.CHALLENGE
    ) {
      // handle non-frictionless flows
      const {
        challengeUrl,
        payload,
        transactionId: challengeTransactionId,
      } = challengeTransactionSession

      // Use Cardinal to complete the challenge flow
      cardinal?.continue(
        'cca',
        {
          AcsUrl: challengeUrl,
          Payload: payload,
        },
        {
          OrderDetails: {
            TransactionId: challengeTransactionId,
          },
        },
        jwt
      )
    }
  }
}

export const validatePaymentDetails = async ({
  data,
  newJwt,
  submitTransactionDetails,
  navigateToDepositForm,
  redirectToSuccessPage,
}) => {
  switch (data?.ActionCode) {
    case TABAPAY_3DS_ACTION_CODES.SUCCESS: {
      // Handle successful transaction, send JWT to backend to verify
      const transactionDetails = await submitTransactionDetails(newJwt)

      if (transactionDetails?.data) {
        const {
          linkedCardTransaction = {},
        } = transactionDetails?.data?.pullFundsFromExistingCardChallenge

        if (
          linkedCardTransaction?.transactionStatus === DEBIT_CARD_DEPOSIT_STATUS_TYPES.SUCCESS
        ) {
          // if transaction is successful, then redirect the user to the success page
          redirectToSuccessPage(linkedCardTransaction?.transactionId)
        }
      }
      break
    }
    case TABAPAY_3DS_ACTION_CODES.NO_ACTION:
    case TABAPAY_3DS_ACTION_CODES.FAILURE:
    case TABAPAY_3DS_ACTION_CODES.TIMEOUT:
    case TABAPAY_3DS_ACTION_CODES.ERROR:
    case TABAPAY_3DS_ACTION_CODES.CANCEL: {
      // If the ErrorNumber is not 0: Success, then show returned error description
      // If an error description doesn't exist, show generic error
      if (data?.ErrorNumber !== 0) {
        store.dispatch(
          addAlertAction({
            text: `${data?.ErrorDescription}` || 'Uh oh, something went wrong.',
            subText:
              `(${data?.ErrorNumber})` || "We aren't able to process your request at the moment.",
            autoDismissDelay: DEFAULT_ALERT_DISMISS_DELAY,
            dismissible: false,
          })
        )
      }

      navigateToDepositForm()
      break
    }
    default:
      break
  }
}

export const handleTabapay3DSChallenge = ({
  jwt,
  redirectToSuccessPage,
  submitTransactionDetails,
  navigateToDepositForm,
}) => {
  // Initialize Cardinal
  const cardinal = window?.Cardinal
  cardinal?.configure({
    payment: {
      displayExitButton: true,
      displayLoading: true,
    },
  })
  cardinal?.setup('init', { jwt })

  cardinal?.on('payments.setupComplete', async () => await submitDetails({ jwt, submitTransactionDetails, redirectToSuccessPage, cardinal }))

  cardinal?.on('payments.validated', async (data, newJwt) => await validatePaymentDetails({
    data,
    newJwt,
    submitTransactionDetails,
    navigateToDepositForm,
    redirectToSuccessPage,
  }))
}
