
import React, { useEffect, useState, useRef } from 'react'

import styling from './videoCard.module.scss'

const VideoCard = ({ cardDetails = {}, isSelected }) => {
  const [showDetails, setShowDetails] = useState(true)
  const [hover, setHover] = useState(false)
  const [hasPaused, setHasPaused] = useState(false)

  const videoRef = useRef(null)

  const { influencerVideo = {}, influencerPicture = {} } = cardDetails

  useEffect(() => {
    if (isSelected) {
      // Restart the video from the beginning with the details showing
      videoRef?.current?.load()
      setShowDetails(true)
      setHasPaused(false)

      // Hide the details after 5 seconds
      setTimeout(() => {
        setShowDetails(false)
      }, 5000)
    } else {
      videoRef?.current.pause()
    }
  }, [isSelected])

  return (
    <div
      className={styling['video-card']}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <video
        muted
        playsInline={isSelected}
        autoPlay={isSelected}
        loop={isSelected}
        controls={showDetails || hover}
        width="100%"
        ref={videoRef}
        onPause={() => {
          setShowDetails(true)
          setHasPaused(true)
        }}
        onPlay={() => {
          if (hasPaused) {
            setShowDetails(false)
          }
        }}
      >
        <source src={influencerVideo?.url} />
        Your browser does not support the video tag.
      </video>
      {(showDetails || hover) && (
        <div className={styling['video-details-container']}>
          <h3>{influencerVideo?.title}</h3>
          <div className={styling['influencer-details-container']}>
            <img src={influencerPicture?.url} alt='influencer' />
            <div data-cy='influencer-text-details' className={styling['influencer-text-details']}>
              <span>{influencerPicture?.title}</span>
              <span>{influencerPicture?.description}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default VideoCard
