import { useCallback, useEffect, useMemo, useState } from 'react'
import * as braze from '@braze/web-sdk'

import { BRAZE_API_KEY, BRAZE_SDK_ENDPOINT, DEPLOY_ENV } from '@common/constants'
import { store } from '@redux/store'

const useBraze = isAuthenticated => {
  const initialState = store.getState()

  const [isBrazeInitialized, setIsBrazeInitialized] = useState(false)

  const userData = useMemo(() => {
    const { application = {}, customer = {}, launchDarklyCustomer = {} } = initialState

    const { userId = '' } = application
    const { firstName = '', lastName = '', locality: city = '' } = customer

    const { email = '' } = launchDarklyCustomer

    return {
      userId,
      firstName,
      lastName,
      city,
      email,
    }
  }, [initialState])

  const positionSuccessCallback = position => {
    const coords = position.coords

    braze
      .getUser()
      .setLastKnownLocation(
        coords.latitude,
        coords.longitude,
        coords.accuracy,
        coords.altitude,
        coords.altitudeAccuracy
      )
  }

  const setBrazeUserData = useCallback(
    (type, value) => {
      if (isBrazeInitialized) {
        const brazeUser = braze.getUser()

        // eslint-disable-next-line default-case
        switch (type) {
          case 'firstName':
            brazeUser.setFirstName(value)
            break
          case 'lastName':
            brazeUser.setLastName(value)
            break
          case 'city':
            brazeUser.setHomeCity(value)
            break
          case 'email':
            brazeUser.setEmail(value)
            brazeUser.addAlias(value, 'email')
            break
        }
      }
    },
    [isBrazeInitialized]
  )

  useEffect(() => {
    if (isAuthenticated) {
      const isInitialized = braze.initialize(BRAZE_API_KEY, {
        baseUrl: BRAZE_SDK_ENDPOINT,
        enableLogging: DEPLOY_ENV !== 'production',
        sessionTimeoutInSeconds: 900, // timeout in 15 minutes if user is inactive (same as whole application behavior)
      })

      if (isInitialized) {
        setIsBrazeInitialized(true)

        braze.openSession()
        navigator.geolocation.getCurrentPosition(positionSuccessCallback)
      }
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (userData?.userId && isBrazeInitialized) {
      braze.changeUser(userData.userId)
    }
  }, [userData?.userId, isBrazeInitialized])

  useEffect(() => {
    if (userData?.firstName) {
      setBrazeUserData('firstName', userData.firstName)
    }
  }, [userData?.firstName, setBrazeUserData])

  useEffect(() => {
    if (userData?.lastName) {
      setBrazeUserData('lastName', userData.lastName)
    }
  }, [userData?.lastName, setBrazeUserData])

  useEffect(() => {
    if (userData?.email) {
      setBrazeUserData('email', userData.email)
    }
  }, [userData?.email, setBrazeUserData])

  useEffect(() => {
    if (userData?.city) {
      setBrazeUserData('city', userData.city)
    }
  }, [userData?.city, setBrazeUserData])
}

export default useBraze
