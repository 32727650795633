import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { KEYCODES } from '../../constants/codeConstants'

import styling from './tabs.module.scss'

const Tab = ({ item, isActive, onTabChange, asSubTabs, ...rest }) => {
  const Icon = item.icon

  const tabStyle = classNames(styling.tab, {
    [styling.active]: isActive,
    [styling.disabled]: !item.enabled,
    [styling['sub-tab']]: asSubTabs,
  })

  const handleClick = useCallback(() => {
    onTabChange(item)
  }, [onTabChange])

  const handleKeyPress = useCallback(
    event => {
      if ([KEYCODES.SPACE, KEYCODES.ENTER].includes(event.charCode)) {
        event.preventDefault()
        onTabChange(item)
      }
    },
    [onTabChange]
  )

  return (
    <li
      tabIndex={0}
      data-cy={item?.dataCy && `${item.dataCy}${isActive ? '_active' : ''}`}
      className={tabStyle}
      onClick={handleClick}
      onKeyPress={handleKeyPress}
      active={isActive.toString()}
      {...rest}
    >
      {Icon && <Icon />}
      {item.label}
    </li>
  )
}

export const Tabs = ({
  activeTab,
  dataCy,
  className = '',
  defaultActiveTab,
  onChangeCallback = () => {},
  options = [],
  vertical = false,
  asSubTabs = false,
  ...rest
}) => {
  const [selectedTab, setSelectedTab] = useState(activeTab || defaultActiveTab)

  useEffect(() => {
    const selectedTab = options.find(i => i.label === (activeTab?.label || defaultActiveTab?.label))
    if (selectedTab) {
      setSelectedTab(selectedTab)
    }
  }, [activeTab, defaultActiveTab, options])

  const containerStyle = classNames(className, styling.container, {
    [styling['sub-tabs']]: asSubTabs,
    [styling[`vertical-${vertical}`]]: vertical && typeof vertical === 'string',
    [styling['vertical-xs']]: vertical && typeof vertical !== 'string',
  })

  const handleTabChange = useCallback(
    _item => {
      if (_item.enabled) {
        setSelectedTab(activeTab || _item)
        if (onChangeCallback) {
          onChangeCallback(activeTab || _item)
        }
      }
    },
    [setSelectedTab, onChangeCallback]
  )

  return (
    <div className={containerStyle}>
      <div className={styling.inner}>
        <ul
          className={classNames(styling['tab-list'], { [styling['sub-tabs']]: asSubTabs })}
          data-cy={dataCy}
        >
          {options.map((item, index) => (
            <Tab
              isActive={selectedTab?.label === item?.label}
              asSubTabs={asSubTabs}
              key={index}
              item={item}
              onTabChange={handleTabChange}
              {...rest}
            />
          ))}
        </ul>
      </div>
    </div>
  )
}

Tabs.propTypes = {
  /**
   * Optional property that makes this a controlled component
   */
  activeTab: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

  /**
   * Optional custom class for instance level CSS overrides. String is directly applied and will not be further modularized to this component
   */
  className: PropTypes.string,

  /**
   * Optional property that sets the initial default value, but leaves the component uncontrolled
   */
  defaultActiveTab: PropTypes.object,

  /**
   * Callback function to retrieve the current active tab. It will pass in the name of the current tab as a string.
   */
  onChangeCallback: PropTypes.func,

  /**
   * Array of tab options
   */
  options: PropTypes.array,

  /**
   * Whether or not the tabs display vertically. Can be a boolean for "always" or a string corresponding to a breakpoint key denoting when the mobile-first horizontal layout switches to vertical
   */
  vertical: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

  /**
   * Element identifier to support Cypress testing
   */
  dataCy: PropTypes.string,

  /**
   * Whether or not the tabs have sub-tab styling
   */
  asSubTabs: PropTypes.bool,
}

export default Tabs
