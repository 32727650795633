import React, { useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { useDispatch } from 'react-redux'
import { Link, useLocation, Navigate } from 'react-router-dom'

import Button from '@shared/components/button/Button'

import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'

import { authorize } from '@services/serviceUtils'
import { staticRoutes } from '@routing/routes'
import { removeAlertsAction } from '@redux/alerts/alertsActions'

import FormInput from '@shared/components/formInput/FormInput'
import FormPasswordInput from '@shared/components/FormPasswordInput'
import * as Yup from 'yup'

const CE_REDIRECT_URI_PARAM = 'redirect_uri'
const CE_STATE_PARAM = 'state'
const CE_RESPONSE_TYPE_PARAM = 'response_type'
const CE_CLIENT_ID_PARAM = 'client_id'

const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid Email')
    .required('Required'),
  password: Yup.string().required('Required'),
})
/*
  Read redirectUri, state, responseType and clientId from request query params
*/
const CoreExchangeLogin = () => {
  const location = useLocation()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const dispatch = useDispatch()

  const params = new URLSearchParams(location.search)

  const loginAndRedirect = async values => {
    setIsSubmitting(true)

    const response = await authorize({
      username: values.email,
      password: values.password,
      redirectUri: params.get(CE_REDIRECT_URI_PARAM),
      state: params.get(CE_STATE_PARAM),
      responseType: params.get(CE_RESPONSE_TYPE_PARAM),
      clientId: params.get(CE_CLIENT_ID_PARAM),
      responseFormat: 'JSON_PAYLOAD',
    })

    setIsSubmitting(false)

    if (response) {
      window.location.href = response?.data?.location
    }
  }

  const handleLogin = async values => {
    // Remove any current alerts
    dispatch(removeAlertsAction())

    /* Attach a paralell Promise to delay subsequent form submissions. If the user was allowed to
       attempt login in quick succession, their account might get locked out due to security
       measures in place within the application infrastructure. Forcing a wait between attempts
       prevents this. */
    await Promise.all([loginAndRedirect(values), new Promise(resolve => setTimeout(resolve, 1200))])
  }

  const CESignIn = () => (
    <div className='white-card-container' data-cy='core-exchange-login-container'>
      <Link to={staticRoutes.dashboard.pathname}>
        <GreenwoodLogo className='logo' />
      </Link>
      <h1>Hello! Let’s sign in.</h1>
      <Formik
        validationSchema={loginValidationSchema}
        initialValues={{
          email: '',
          password: '',
        }}
        onSubmit={handleLogin}
      >
        {({ errors, touched }) => (
          <Form data-cy='login-form'>
            <Field
              as={FormInput}
              name='email'
              type='email'
              label='Email Address'
              autoComplete='email'
              required
              invalid={errors.email && touched.email}
              errorText={errors.email}
              disabled={isSubmitting}
            />
            <Field
              as={FormPasswordInput}
              name='password'
              label='Password'
              autoComplete='current-password'
              required
              invalid={errors.password && touched.password}
              errorText={errors.password}
              disabled={isSubmitting}
            />
            <Button type='submit' isLoading={isSubmitting}>
              Next
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  )

  return (
    <div>
      {!params.get(CE_REDIRECT_URI_PARAM) ? (
        <Navigate to={staticRoutes.signIn.pathname} />
      ) : (
        <CESignIn />
      )}
    </div>
  )
}

export default CoreExchangeLogin
