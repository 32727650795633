import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import Alert from '@shared/components/alert/Alert'
import { ReactComponent as Checkmark } from '@shared/images/icons/checkmark.svg'
import { ReactComponent as WarningIcon } from '@shared/images/icons/warning.svg'

import { ALERT_TYPES } from '@shared/constants/uiConstants'
import { removeAlertAction, removeAlertsAction } from '@redux/alerts/alertsActions'

const alertIcons = {
  [ALERT_TYPES.SUCCESS]: Checkmark,
  [ALERT_TYPES.WARNING]: WarningIcon,
}

const AlertsManager = () => {
  const alerts = useSelector(state => state.alerts)
  const alertsStringified = JSON.stringify(alerts)
  const dispatch = useDispatch()

  const removeAlert = id => {
    dispatch(removeAlertAction(id))
  }

  useEffect(() => {
    dispatch(removeAlertsAction())
  }, [dispatch])

  useEffect(() => {
    if (alerts?.length > 0) {
      document.body.scrollTop = document.documentElement.scrollTop = 0
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertsStringified])

  return (
    <div className='alerts-manager'>
      {alerts.map(({ id, dismissible, autoDismissDelay, type, ...rest }) => {
        const IconName = alertIcons[type]

        return (
          <Alert
            key={`alert_${id}`}
            id={id}
            open
            dismissible={dismissible}
            autoDismissDelay={autoDismissDelay}
            styleType={type.toLowerCase()}
            removeHandler={removeAlert}
            {...rest}
          >
            <>{type !== ALERT_TYPES.INFO && IconName ? <IconName /> : []}</>
          </Alert>
        )
      })}
    </div>
  )
}

AlertsManager.propTypes = {
  alerts: PropTypes.array,
  dispatch: PropTypes.func,
}

export default AlertsManager
