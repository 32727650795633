import { isEmpty } from 'lodash'

import { useMutation, gql } from '@services/serviceUtils'

// Plaid returns snake_case properties, but the API expects camelCase properties
const mapPlaidAccountsToApiAccounts = accounts =>
  accounts?.map(
    ({ id, mask, name, subtype: subType, type, verification_status: verificationStatus }) => ({
      id,
      mask,
      name,
      subType,
      type,
      verificationStatus: verificationStatus?.toUpperCase(),
    })
  )

export const capturePlaidLinkEventMutation = gql`
  mutation CapturePlaidLinkEvent($plaidLinkEventDTOInput: PlaidLinkEventDTOInput!) {
    capturePlaidLinkEvent(plaidLinkEventDTOInput: $plaidLinkEventDTOInput) {
      success
    }
  }
`

export const capturePlaidLinkExitMutation = gql`
  mutation CapturePlaidLinkExit($plaidLinkExitDTOInput: PlaidLinkExitDTOInput!) {
    capturePlaidLinkExit(plaidLinkExitDTOInput: $plaidLinkExitDTOInput) {
      success
    }
  }
`

export const captureCreatePlaidLinkSuccessMutation = gql`
  mutation CapturePlaidLinkSuccess($plaidLinkSuccessDTOInput: PlaidLinkSuccessDTOInput!) {
    capturePlaidLinkSuccess(plaidLinkSuccessDTOInput: $plaidLinkSuccessDTOInput) {
      success
    }
  }
`

export const captureUpdatePlaidLinkSuccessMutation = gql`
  mutation CapturePlaidLinkUpdateSuccess(
    $plaidLinkUpdateSuccessDTOInput: PlaidLinkUpdateSuccessDTOInput!
  ) {
    capturePlaidLinkUpdateSuccess(plaidLinkUpdateSuccessDTOInput: $plaidLinkUpdateSuccessDTOInput) {
      success
    }
  }
`

/**
 * Provides `onEvent`, `onExit`, and `onSuccess` with respective error properties
 * for Plaid Link configuration. Accepts optional `onEvent`, `onExit`, and `onSuccess`
 * callbacks to perform additional tasks after each mutation is executed.
 */
const usePlaidLinkConfigCallbacks = ({
  onBeforeSuccess = () => {
    // Intentionally empty default implementation
  },
  onAfterSuccess = () => {
    // Intentionally empty default implementation
  },
} = {}) => {
  const [capturePlaidLinkEvent] = useMutation(capturePlaidLinkEventMutation)

  // Captures Plaid Link event state for the API
  const onEvent = (eventName, metadata) => {
    capturePlaidLinkEvent({
      variables: {
        plaidLinkEventDTOInput: {
          eventName,
          metadata,
        },
      },
    })
  }

  const [capturePlaidLinkExit] = useMutation(capturePlaidLinkExitMutation)

  // Captures Plaid Link exit state for the API
  const onExit = (exitError, metadata) => {
    capturePlaidLinkExit({
      variables: {
        plaidLinkExitDTOInput: {
          error: exitError,
          metadata,
        },
      },
    })
  }

  const [captureCreatePlaidLinkSuccess] = useMutation(captureCreatePlaidLinkSuccessMutation)

  // Captures Plaid Link creation success state for the API
  const onCreateSuccess = async ({ publicToken, accounts, institution, metadata }) => {
    await captureCreatePlaidLinkSuccess({
      variables: {
        plaidLinkSuccessDTOInput: {
          accounts: mapPlaidAccountsToApiAccounts(accounts) || [],
          institution: !isEmpty(institution)
            ? {
                id: institution?.institution_id,
                name: institution?.name,
              }
            : undefined,
          publicToken,
          metadata,
        },
      },
    })
  }

  const [captureUpdatePlaidLinkSuccess] = useMutation(captureUpdatePlaidLinkSuccessMutation)

  // Captures Plaid Link update success state for the API
  const onUpdateSuccess = async ({ accountId, metadata }) => {
    await captureUpdatePlaidLinkSuccess({
      variables: {
        plaidLinkUpdateSuccessDTOInput: {
          accountIdToUpdate: accountId,
          metadata,
        },
      },
    })
  }

  return {
    onEvent,
    onExit,
    onCreateSuccess: async (publicToken, metadata) => {
      onBeforeSuccess()
      await onCreateSuccess({
        publicToken,
        accounts: metadata?.accounts,
        institution: metadata?.institution,
        metadata,
      })
      onAfterSuccess()
    },
    onUpdateSuccess: async (accountId, metadata) => {
      onBeforeSuccess()
      await onUpdateSuccess({ accountId, metadata })
      onAfterSuccess()
    },
  }
}

export default usePlaidLinkConfigCallbacks
