import { SET_EMAIL } from './unauthenticatedUserActions'

const initialState = {
  email: null,
}

export const unauthenticatedUserReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_EMAIL:
      return {
        ...state,
        email: action.payload?.email || null,
        waitlistCode: action.payload?.waitlistCode || null,
      }
    default:
      return state
  }
}
