import React from 'react'
import PropTypes from 'prop-types'

import Modal from '@shared/components/modal/Modal'
import ModalBody from '@shared/components/modal/ModalBody'
import ModalHeader from '@shared/components/modal/ModalHeader'
import Button from '@shared/components/button/Button'

const ExitModal = ({ isOpen, toggleModal, closeModal, onContinueClick }) => {
  return (
    <Modal centered size='md' open={isOpen} modalClassName='exit-modal' toggleModal={toggleModal}>
      <ModalHeader withCloseButton closeModal={closeModal}>
        <h4 data-cy='exit-modal-header'>Are you sure you want to exit?</h4>
      </ModalHeader>
      <ModalBody>
        <div>
          <p>Your changes will not be saved.</p>
          <Button
            data-cy='exit-modal-continue-button'
            className='additional-button'
            onClick={onContinueClick}
          >
            Continue
          </Button>
          <Button className='additional-button' onClick={closeModal} ghost>
            Cancel
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

ExitModal.propTypes = {
  /**
   * Boolean value for whether or not the Modal is open
   */
  isOpen: PropTypes.bool.isRequired,
  /**
   * Function for toggling the modal
   */
  toggleModal: PropTypes.func.isRequired,
  /**
   * Function for closing the modal
   */
  closeModal: PropTypes.func.isRequired,
  /**
   * Function to run when user clicks continue from the Exit Modal
   */
  onContinueClick: PropTypes.func.isRequired,
}

export default ExitModal
