import React from 'react'
import PropTypes from 'prop-types'

import Modal from '@shared/components/modal/Modal'
import ModalBody from '@shared/components/modal/ModalBody'
import ModalHeader from '@shared/components/modal/ModalHeader'
import ExternalLink from '@common/components/link/ExternalLink'

import { getBootstrapValueByKey, BOOTSTRAP_KEYS } from '@common/utils'

import styling from './accountIsLockedModal.module.scss'

const AccountIsLockedModal = ({
  isOpen = false,
  closeModal,
  isFraud = false,
  errorDetails,
}) => {
  const contactOptions = getBootstrapValueByKey(BOOTSTRAP_KEYS.SUPPORT)?.contact
  const phoneContact = contactOptions?.find(option => option.method === 'phone')
  const emailContact = contactOptions?.find(option => option.method === 'email')

  const contactMessage = isFraud ? (
    <p>
      Call{' '}
      <a className='bold' href={`tel:${phoneContact.value}`}>
        {phoneContact.value}
      </a>{' '}
      or email{' '}
      <ExternalLink className='underlined-link bold' to={`mailto:${emailContact?.value}`}>
        {emailContact?.value}
      </ExternalLink>
      .
    </p>
  ) : (
    <p>
      If you are having problems with this account, please contact support at{' '}
      <a className='bold' href={`tel:${phoneContact.value}`}>
        {phoneContact.value}
      </a>{' '}
      or{' '}
      <ExternalLink className='underlined-link bold' to={`mailto:${emailContact?.value}`}>
        {emailContact?.value}
      </ExternalLink>
      .
    </p>
  )

  return (
    <Modal
      className={styling['account-is-locked-modal']}
      centered
      size='md'
      open={isOpen}
      toggleModal={closeModal}
    >
      <ModalHeader withCloseButton closeModal={closeModal}>
        {!isFraud && <h4>Your Account Has Been Locked</h4>}
      </ModalHeader>
      <ModalBody>
        <div>
          {isFraud && (
            <p>Your account has been temporarily restricted due to unusual activity. To ensure your account's security, please contact Customer Support immediately.</p>
          )}
          {contactMessage}
          {isFraud && (
            <>
              <p>We appreciate your understanding and cooperation.</p>
              <p>{`(ID: ${errorDetails?.requestId})`}</p>
            </>
          )}
        </div>
      </ModalBody>
    </Modal>
  )
}

AccountIsLockedModal.propTypes = {
  /**
   * Boolean value for whether or not the Modal is open
   */
  isOpen: PropTypes.bool.isRequired,
  /**
   * Function for closing the modal
   */
  closeModal: PropTypes.func.isRequired,
  /**
   * Boolean value for whether the account is locked due to fraud
   */
  isFraud: PropTypes.bool,
  /**
   * Object that includes the details of the error (used specifically for fraud accounts)
   */
  errorDetails: PropTypes.any,
}

export default AccountIsLockedModal
