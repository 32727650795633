export const SET_TRANSFER_DETAILS = 'SET_TRANSFER_DETAILS'
export const RESET_TRANSFER_DETAILS = 'RESET_TRANSFER_DETAILS'

export const setTransferDetailsAction = payload => {
  return {
    payload,
    type: SET_TRANSFER_DETAILS,
  }
}

export const resetTransferDetailsAction = () => {
  return {
    type: RESET_TRANSFER_DETAILS,
  }
}
