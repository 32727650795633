import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import LoadingContainer from '@shared/components/loadingContainer/LoadingContainer'
import CopyToClipboard from '@common/components/copy/CopyToClipboard'

import { gql, useQuery } from '@services/serviceUtils'
import { PRODUCT_TYPES } from '@common/constants'

import styling from './copyAccountNumbers.module.scss'

const accountsQuery = gql`
  query AccountsAndNumbers {
    accounts {
      accounts {
        accountType
        accountNumber
        routingNumber
      }
    }
  }
`

const CopyAccountNumbers = ({
  className,
  loadingContainerClassName,
  topBorder = false,
  bottomBorder = false,
  accountNumberCopyProps = {},
  routingNumberCopyProps = {},
  accountsQueryOptions,
}) => {
  const { loading, error, data } = useQuery(accountsQuery, accountsQueryOptions)

  const spendingAccount = data?.accounts?.accounts.find(
    acc => acc.accountType !== PRODUCT_TYPES.savings
  )

  return (
    <LoadingContainer
      error={error}
      errorMessage='Error loading account data'
      loading={loading}
      className={loadingContainerClassName}
    >
      <div className={classNames(styling['copy-account-numbers'], className)}>
        {topBorder && <hr />}
        <CopyToClipboard
          label='Routing Number'
          value={spendingAccount?.routingNumber || ''}
          {...routingNumberCopyProps}
        />
        <hr />
        <CopyToClipboard
          label='Account Number'
          value={spendingAccount?.accountNumber || ''}
          obscureToggle
          {...accountNumberCopyProps}
        />
        {bottomBorder && <hr />}
      </div>
    </LoadingContainer>
  )
}

CopyAccountNumbers.propTypes = {
  /**
   * Additional `classname` library compatible classes to pass to the container
   */
  className: PropTypes.any,

  /**
   * Additional className to pass to the loading container component
   */
  loadingContainerClassName: PropTypes.string,

  /**
   * Props specifically for account number CopyToClipboard component
   */
  accountNumberCopyProps: PropTypes.object,

  /**
   * Props specifically for routing number CopyToClipboard component
   */
  routingNumberCopyProps: PropTypes.object,

  /**
   * Whether to display a top border or not
   * Default: false
   */
  topBorder: PropTypes.bool,

  /**
   * Whether to display a bottom border or not
   * Default: false
   */
  bottomBorder: PropTypes.bool,

  /**
   * Any additional options to pass to the underlying account numbers fetch query
   */
  accountsQueryOptions: PropTypes.object,
}

export default CopyAccountNumbers
