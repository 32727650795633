import { useEffect } from 'react'
import { SEGMENT_EVENTS, trackPage, trackEvent, SEGMENT_PAGE_NAMES } from '@common/utils'
import { store } from '@redux/store'
import { setSegmentTrackingEventOccurredAction } from '@redux/segmentTracking/segmentTrackingActions'

const useAccountApprovedTracking = ({ fundingFlow, shouldSetupDirectDeposit }) => {
  const initialState = store.getState()
  const { segmentTracking = {} } = initialState

  useEffect(() => {
    if (
      fundingFlow &&
      shouldSetupDirectDeposit &&
      !segmentTracking[SEGMENT_PAGE_NAMES.BANK_APPLICATION_APPROVED]
    ) {
      // Track application approval page visit
      trackPage({ name: SEGMENT_PAGE_NAMES.BANK_APPLICATION_APPROVED })

      // When a user visits the approved page, track the event
      trackEvent({ event: SEGMENT_EVENTS.BANK_APPLICATION_COMPLETED })

      store.dispatch(
        setSegmentTrackingEventOccurredAction({
          [SEGMENT_PAGE_NAMES.BANK_APPLICATION_APPROVED]: true,
        })
      )
    }
  }, [fundingFlow, shouldSetupDirectDeposit, segmentTracking])
}

export default useAccountApprovedTracking
