import React from 'react'
import classNames from 'classnames'

import { ReactComponent as Chevron } from '@shared/images/icons/chevron.svg'

import { gql, useQuery } from '@services/serviceUtils'
import { getBootstrapSitemapResource, BOOTSTRAP_SITEMAP_RESOURCES } from '@common/utils'
import { getCustomerProductSubscriptions } from '@common/utils/accountUtils'
import { PRODUCT_TYPES } from '@common/constants'
import LoadingContainer from '@shared/components/loadingContainer/LoadingContainer'

import styling from './agreementsDisclosures.module.scss'

const productAgreementsQuery = gql`
  query ProductAgreements {
    products {
      productType
      alreadyAvailableToCustomer
      agreements {
        title
        url
      }
    }
  }
`

/* Reads the general terms from the bootstrap file, and presents it as
   an array usable by an AgreementsSection */
const getGeneralTerms = () => {
  const generalTerms = []

  const termsConditions = getBootstrapSitemapResource(
    BOOTSTRAP_SITEMAP_RESOURCES.TERMS_AND_CONDITIONS
  )

  generalTerms.push({
    title: termsConditions?.title?.value || '',
    url: termsConditions?.url || '',
  })

  const privacyPolicy = getBootstrapSitemapResource(BOOTSTRAP_SITEMAP_RESOURCES.PRIVACY_POLICY)

  generalTerms.push({
    title: privacyPolicy?.title?.value || '',
    url: privacyPolicy?.url || '',
  })

  return generalTerms
}

const getProductAgreementsByType = ({ products = [], productType }) =>
  products.find(product => product.productType === productType)?.agreements || []

/* Either provide `products` and `productType` to filter fetched agreements, or
   a list of `agreements` directly */
const AgreementsSection = ({ header, dataCy, products, productType, agreements }) => {
  const _agreements = agreements || getProductAgreementsByType({ products, productType })

  return (
    <>
      <div className='settings-view-subheader' data-cy={dataCy}>
        <h3>{header}</h3>
      </div>
      <table className={classNames('simple-table', styling['agreements-disclosures-table'])}>
        <tbody>
          {_agreements.map((agreement, agreementIndex) => (
            <tr
              className='link-row'
              key={agreementIndex}
              onClick={() => window.open(agreement.url)}
            >
              <th scope='row' colSpan={2}>
                <p>{agreement.title}</p>
              </th>
              <td className='row-chevron'>
                <Chevron />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

const AgreementsDisclosures = () => {
  const { loading, error, data } = useQuery(productAgreementsQuery)

  const products = data?.products.filter(product => product.productType !== PRODUCT_TYPES.premium)

  return (
    <div className='settings-view-content'>
      <h2>Agreements &amp; Disclosures</h2>
      <p className='subtext'>
        You already agreed with these Agreements and Disclosures. These links will take you to the
        documents to review.
      </p>
      <AgreementsSection
        dataCy='general-terms-section'
        header='General Terms'
        agreements={getGeneralTerms()}
      />
      <LoadingContainer
        loading={loading}
        error={error}
        errorMessage='Error loading account agreements and disclosures.'
      >
        {getCustomerProductSubscriptions(products).map((product, productIndex) => {
          return (
            <AgreementsSection
              dataCy={`${product.displayName.toLowerCase()}-account-section`}
              header={`${product.displayName} Account`}
              products={products}
              productType={product.productType}
              key={productIndex}
            />
          )
        })}
      </LoadingContainer>
    </div>
  )
}

export default AgreementsDisclosures
