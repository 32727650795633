import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Navigation, Pagination, A11y } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useMediaQuery } from 'react-responsive'

import AccountSummaryCard from '@common/components/accountSummary/AccountSummaryCard'
import { obscureAccountNumberShort } from '@shared/utils'
import { ReactComponent as Chevron } from '@shared/images/icons/chevron.svg'

import 'swiper/scss'
import 'swiper/scss/navigation'
import 'swiper/scss/pagination'
import 'swiper/scss/a11y'

import styling from './accountsCardSlider.module.scss'

const AccountsCardSlider = ({ accounts, selectedAccount, setSelectedAccount }) => {
  // store swiper instance
  const [swiper, setSwiper] = useState(null)
  const [activeIndex, setActiveIndex] = useState(0)
  const nextButtonRef = useRef(null)
  const prevButtonRef = useRef(null)
  const paginationRef = useRef(null)
  const isDesktop = useMediaQuery({
    query: '(min-width: 769px)',
  })
  const numAccountsToShow = isDesktop ? 2 : 1

  const getSwiper = () => (
    <Swiper
      modules={[Navigation, Pagination, A11y]}
      breakpoints={{
        576: {
          slidesPerView: 1.75,
          spaceBetween: 24,
        },
        769: {
          slidesPerView: 2.25,
          spaceBetween: 32,
        },
      }}
      navigation={
        accounts?.length > numAccountsToShow && {
          nextEl: nextButtonRef.current,
          prevEl: prevButtonRef.current,
        }
      }
      slidesPerView={1.25}
      spaceBetween={24}
      pagination={
        accounts?.length > numAccountsToShow && {
          clickable: true,
          el: paginationRef.current,
          bulletElement: 'button',
          bulletActiveClass: styling.active,
          bulletClass: styling['pagination-bullet'],
        }
      }
      a11y
      className={styling['account-list']}
      onSwiper={setSwiper}
      onSlideChange={slide => {
        setActiveIndex(slide.activeIndex)
      }}
    >
      {accounts?.map(account => (
        <SwiperSlide key={account.id}>
          <AccountSummaryCard
            active={selectedAccount === account.id}
            // Only show the active border if there is more than one selectable account
            activeBorder={accounts.length > 1}
            title={account.title}
            accountNumber={obscureAccountNumberShort(account.accountNumber)}
            amount={account.balance.amount}
            onClick={
              accounts.length > 1
                ? () => {
                    setSelectedAccount(account.id)
                  }
                : undefined
            }
            data-cy='account-summary-card'
          />
        </SwiperSlide>
      ))}
    </Swiper>
  )

  return (
    <>
      {getSwiper()}
      {accounts?.length > numAccountsToShow && (
        <div className={styling.controls}>
          <button
            className={styling['prev-button']}
            disabled={activeIndex === 0}
            ref={prevButtonRef}
            onClick={() => {
              swiper.slidePrev()
            }}
          >
            <Chevron />
          </button>
          <div className={styling.pagination} ref={paginationRef} />
          <button
            className={styling['next-button']}
            disabled={activeIndex === accounts.length - numAccountsToShow}
            ref={nextButtonRef}
            onClick={() => {
              swiper.slideNext()
            }}
          >
            <Chevron />
          </button>
        </div>
      )}
    </>
  )
}

AccountsCardSlider.propTypes = {
  /* An array of accounts to display in the slider */
  accounts: PropTypes.array,

  /* The id of selected account */
  selectedAccount: PropTypes.string,

  /* Callback function to call when a user clicks on a card in the slider. */
  setSelectedAccount: PropTypes.func,
}

export default AccountsCardSlider
