import { useCallback } from 'react'

/* Store the async function constructor to be able to check for instances
   of it */
const AsyncFunction = async function () {}.constructor

/* Creates a callback with the executing function wrapped in a try-catch
   statement. Exceptions are intentionally suppressed here, as the main
   use case for this hook will be to wrap calls to apolloClient.js and
   auth.js API functions, since errors that occur in those services are
   both bubbled up, and captured by emitting error messages to the user. 
   
   Basically, wrap a code block which you would normally contain in a
   try { ... } block in a function (supports async). This hook will return
   a function which you can execute and pass parameters to like any
   typical function call. The difference is that any errors generated
   during the call will be suppressed.
   
   When dependencies are passed, this hook uses React's `useCallback` hook
   under the hood, passing this dependency list to help optimize renders
   in the parent component. */
const useTryWithoutCatch = (callback, dependencies) => {
  let _callback

  if (callback instanceof AsyncFunction) {
    _callback = async (...params) => {
      try {
        return await callback(...params)
      } catch (err) {
        /* Swallow the exception for now. In the future, a silent client
           logging API integration can be called here */
      }
    }
  } else {
    _callback = (...params) => {
      try {
        return callback(...params)
      } catch (err) {
        /* Swallow the exception for now. In the future, a silent client
           logging API integration can be called here */
      }
    }
  }

  if (Array.isArray(dependencies)) {
    return useCallback(_callback, dependencies)
  } else {
    return _callback
  }
}

export default useTryWithoutCatch
