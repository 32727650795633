import { toMediumDateFormat } from '@shared/utils'

export const getTransactionDate = transaction => {
  if (transaction?.transactionType === 'PENDING') {
    return 'Pending'
  } else if (transaction?.transactionTime) {
    return toMediumDateFormat(transaction.transactionTime)
  } else {
    return ''
  }
}
