import React from 'react'
import { useNavigate } from 'react-router-dom'

import Card from '@shared/components/card/Card'
import { ReactComponent as Chevron } from '@shared/images/icons/chevron.svg'

import { staticRoutes } from '@routing/routes'

import styling from './newUnserInvestCampaignCallout.module.scss'

const NewUserInvestCampaignCallout = () => {
  const navigate = useNavigate()

  return (
    <Card
      className={styling.card}
      size='small'
      data-cy='new-user-invest-upgrade-callout'
      onClick={() => navigate(staticRoutes.invest.pathname)}
    >
      <div className={styling['text-container']}>
        <span>Get $10</span>
        <p className={styling.subtext}>Get $10 by signing up for Greenwood Invest.</p>
        <p className={styling['cta-text']}>
          CLAIM NOW <Chevron />
        </p>
      </div>
    </Card>
  )
}

export default NewUserInvestCampaignCallout
