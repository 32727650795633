import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { gql, useLazyQuery } from '@services/serviceUtils'

import MyGreenwoodCard from '@common/images/greenwood-plastic-card.svg'
import { ReactComponent as GlowingRing } from '@common/images/glowing-ring.svg'

import styling from './myCardImage.module.scss'

const imageAccessTokenQuery = gql`
  query ImageAccessToken {
    imageAccessToken {
      imageUrl
    }
  }
`

/* The image URL is stale almost immediately after it's fetched, so
   this data can't pulled from the cache */
const imageAccessTokenQueryOptions = { fetchPolicy: 'no-cache' }

const MyCardImage = ({
  image = MyGreenwoodCard,
  displayUserCard = true,
  includeGlowingRing = true,
  className,
  onClick,
}) => {
  const [srcLoading, setSrcLoading] = useState(false)
  const [imageSource, setImageSource] = useState(image)

  const [
    getUserCardImage,
    { data: imageAccessTokenData, loading: imageAccessTokenLoading },
  ] = useLazyQuery(imageAccessTokenQuery, imageAccessTokenQueryOptions)

  useEffect(() => {
    setImageSource(image)
  }, [image])

  useEffect(() => {
    if (displayUserCard) {
      getUserCardImage()
    }
  }, [displayUserCard, getUserCardImage])

  useEffect(() => {
    if (imageAccessTokenData?.imageAccessToken?.imageUrl) {
      setSrcLoading(true)
      setImageSource(imageAccessTokenData?.imageAccessToken?.imageUrl)
    }
  }, [imageAccessTokenData])

  return (
    <div
      className={classNames(className, styling['card-image-container'], {
        [styling.loading]: (imageAccessTokenLoading || srcLoading) && displayUserCard,
      })}
      onClick={onClick}
    >
      <img
        src={imageSource}
        alt='My Greenwood Card'
        className={classNames(styling.card, {
          [styling.static]: (imageAccessTokenLoading || srcLoading) && displayUserCard,
        })}
        onLoad={() => {
          setSrcLoading(false)
        }}
        onError={() => {
          setImageSource(image)
        }}
      />
      {includeGlowingRing && <GlowingRing className={styling['glowing-ring']} />}
    </div>
  )
}

MyCardImage.propTypes = {
  /* Card image to display */
  image: PropTypes.any,
  /* Whether or not the user's actual card should be displayed. Set to 'true' by default. */
  displayUserCard: PropTypes.bool,
  /* Whether or not the glowing ring under the card should show. Set to 'true' by default. */
  includeGlowingRing: PropTypes.bool,
  /* Custom classname for the card container */
  className: PropTypes.string,
  /* Function to execute when clicking on image */
  onClick: PropTypes.func,
}

export default MyCardImage
