export const SET_AUTH = 'SET_AUTH'
export const SET_SESSION_ID = 'SET_SESSION_ID'
export const SET_CHALLENGE_TYPE = 'SET_CHALLENGE_TYPE'
export const SET_TOKEN = 'SET_TOKEN'

export const setAuthAction = payload => {
  return {
    payload,
    type: SET_AUTH,
  }
}

export const setTokenAction = payload => {
  return {
    payload,
    type: SET_TOKEN,
  }
}

export const setSessionIdAction = payload => {
  return {
    payload,
    type: SET_SESSION_ID,
  }
}
