import React from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'

import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'

import TransferForm from './TransferForm'
import TransferReview from './TransferReview'
import TransferSuccess from './TransferSuccess'

import ProtectedRoute from '@routing/ProtectedRoute'
import { staticRoutes } from '@routing/routes'
import { FUNDING_FLOWS } from '@common/constants'
import useFundingFlowRouteGuard from '@common/utils/useFundingFlowRouteGuard'

const REQUIRED_FUNDING_FLOWS = [FUNDING_FLOWS.BASIC, FUNDING_FLOWS.TRANSFER]

const Transfer = () => {
  const { state } = useLocation()
  useFundingFlowRouteGuard({ requiredFundingFlows: REQUIRED_FUNDING_FLOWS })

  return (
    <div className='white-card-container'>
      <GreenwoodLogo className='logo' />
      <Routes>
        <Route path={staticRoutes.transferForm.relativePathname} element={<ProtectedRoute component={TransferForm} />} />
        <Route path={staticRoutes.transferReview.relativePathname} element={<ProtectedRoute component={TransferReview} />} />
        <Route path={staticRoutes.transferSuccess.relativePathname} element={<ProtectedRoute component={TransferSuccess} />} />
        <Route
          path='*'
          element={
            <Navigate to={staticRoutes.transferForm.pathname} state={state} />
          }
        />
      </Routes>
    </div>
  )
}

export default Transfer
