import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import Button from '@shared/components/button/Button'
import useFundingFlowRouteGuard from '@common/utils/useFundingFlowRouteGuard'
import { FUNDING_FLOWS, PRODUCT_TYPES } from '@common/constants'
import { staticRoutes } from '@routing/routes'
import { ReactComponent as GreenwoodMetalCard } from '@common/images/greenwood-metal-card.svg'
import CelebrationView from '@common/components/celebrationView/CelebrationView'
import useAccountCreatedTracking from '@common/utils/useAccountCreatedTracking'
import usePreventBrowserBackClick from '@common/utils/usePreventBrowserBackClick'

const REQUIRED_FUNDING_FLOWS = [FUNDING_FLOWS.ONBOARDING_PREMIUM, FUNDING_FLOWS.PREMIUM]

const PremiumCardInbound = () => {
  const navigate = useNavigate()
  const { state } = useLocation()

  usePreventBrowserBackClick()

  useFundingFlowRouteGuard({ requiredFundingFlows: REQUIRED_FUNDING_FLOWS })

  // Now initiate the `BASIC` account funding flow. GWB-2446 onboarding 5-a, cta 4-a
  const handleDoneClick = () => {
    if (state?.fundingFlow === FUNDING_FLOWS.ONBOARDING_PREMIUM) {
      navigate(staticRoutes.addMoney.pathname, {
        state: {
          ...state,
          fundingFlow: FUNDING_FLOWS.BASIC,
        },
      })
    } else {
      navigate(staticRoutes.dashboard.pathname)
    }
  }
  useAccountCreatedTracking({ accountType: PRODUCT_TYPES.premium })

  return (
    <CelebrationView>
      <div className='card-inbound-container'>
        <h1>A new metal card is coming to you</h1>
        <p>It will be sent to your permanent address on file.</p>
        <div className='card-image-container'>
          <GreenwoodMetalCard />
        </div>
        <div className='buttons-container'>
          <Button onDark onClick={handleDoneClick} data-cy='premium-card-inbound-done-button'>
            Done
          </Button>
        </div>
      </div>
    </CelebrationView>
  )
}

export default PremiumCardInbound
