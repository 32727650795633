export const SET_CUSTOMER = 'SET_CUSTOMER'
export const SET_LAUNCH_DARKLY_CUSTOMER = 'SET_LAUNCH_DARKLY_CUSTOMER'
export const RESET_LAUNCH_DARKLY_CUSTOMER = 'RESET_LAUNCH_DARKLY_CUSTOMER'

export const setCustomerAction = payload => {
  return {
    payload,
    type: SET_CUSTOMER,
  }
}

export const setLaunchDarklyCustomerAction = payload => {
  return {
    payload,
    type: SET_LAUNCH_DARKLY_CUSTOMER,
  }
}

export const resetLaunchDarklyCustomerAction = payload => {
  return {
    payload,
    type: RESET_LAUNCH_DARKLY_CUSTOMER,
  }
}
