import { useEffect } from 'react'
import { initializeApp } from 'firebase/app'
import { getAnalytics, isSupported, logEvent } from 'firebase/analytics'
import { getPerformance } from 'firebase/performance'

import {
  FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID,
  FIREBASE_MEASUREMENT_ID,
} from '@common/constants'

const useFirebase = () => {
  useEffect(() => {
    const firebaseConfig = {
      apiKey: FIREBASE_API_KEY,
      authDomain: FIREBASE_AUTH_DOMAIN,
      projectId: FIREBASE_PROJECT_ID,
      storageBucket: FIREBASE_STORAGE_BUCKET,
      messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
      appId: `1:${FIREBASE_MESSAGING_SENDER_ID}:web:${FIREBASE_APP_ID}`,
      measurementId: `G-${FIREBASE_MEASUREMENT_ID}`,
    }

    // Initialize the Firebase application
    const app = initializeApp(firebaseConfig)

    isSupported().then(result => {
      if (result) {
        const analytics = getAnalytics(app)
        logEvent(analytics, 'An event has been logged within the greenwood application')

        // Initialize performance monitoring
        getPerformance(app)
      }
    })
  }, [])
}

export default useFirebase
