import { get } from 'lodash'

import ClientBootstrap from '../../../public/client-bootstrap.json'

export const BOOTSTRAP_SITEMAP_RESOURCES = {
  FAQ: 'faq',
  FAQ_DIRECT_DEPOSIT: 'faq_direct_deposit',
  CONTACT: 'contact',
  TERMS_AND_CONDITIONS: 'terms_and_conditions',
  FAQ_PRIVACY_SECURITY: 'faq_privacy_security_personal_information',
  PRIVACY_POLICY: 'privacy_policy',
  COASTAL_COMMUNITY_PRIVACY_CCPA_NOTICE: 'coastal_community_privacy_ccpa_notice',
  DIRECT_DEPOSIT_CONTEST_RULES: 'direct_deposit_contest_rules',
  STRIPE_PRIVACY_POLICY: 'stripe_privacy_policy',
}

export const BOOTSTRAP_KEYS = {
  SITEMAP: 'sitemap',
  SUPPORT: 'support',
  STATES: 'states',
  DISCLOSURES: 'disclosures',
  FEATURE_FLAGS: 'featureFlags',
}

export const BOOTSTRAP_FEATURE_FLAGS = {
  WAITLIST: 'waitlist',
}

/**
 * Retrieves a client bootstrap value by key name
 * @param {String} key - The specified BOOTSTRAP_KEYS
 * @param {Object} options
 * @param {Object} options.defaultValue - An optional default value if the key
 * was not found
 * @returns The requested bootstrap value, or a default value to fall back on
 * if specified
 */
export const getBootstrapValueByKey = (key, { defaultValue } = {}) =>
  get(ClientBootstrap, key, defaultValue)

/**
 * Retrieves a client bootstrap sitemap resource
 * @param {String} resource - The specified BOOTSTRAP_SITEMAP_RESOURCES to fetch
 * @param {Object} options
 * @param {Object} options.defaultValue - An optional default value if the resource
 * was not found
 * @returns The requested bootstrap sitemap resource, or a default value to fall
 * back on if specified
 */
export const getBootstrapSitemapResource = (resource, { defaultValue } = {}) =>
  getBootstrapValueByKey(BOOTSTRAP_KEYS.SITEMAP, { defaultValue: [] }).find(
    obj => obj.resource === resource
  ) || defaultValue

export const getBootstrapFeatureFlag = (featureFlag, { defaultValue } = {}) => {
  const bootstrapFeatureFlags = getBootstrapValueByKey(BOOTSTRAP_KEYS.FEATURE_FLAGS, {
    defaultValue: {},
  })

  return get(bootstrapFeatureFlags, featureFlag, defaultValue)
}
