import { ReactComponent as DocumentsIcon } from '@common/images/icons/documents.svg'
import { ReactComponent as DocumentsPersonIcon } from '@common/images/icons/documents-person.svg'
import { ReactComponent as SignOutIcon } from '@shared/images/icons/sign-out.svg'
import { ReactComponent as MobileHelpIcon } from '@common/images/icons/mobile-help.svg'
import { ReactComponent as GearsIcon } from '@common/images/icons/gears.svg'
import { ReactComponent as SendMoneyIcon } from '@common/images/icons/money-transfer.svg'
import { ReactComponent as AddMoneyIcon } from '@common/images/icons/add-money.svg'
import { ReactComponent as LinkedAccountsIcon } from '@common/images/icons/link.svg'
import { ReactComponent as MembershipIcon } from '@common/images/icons/membership.svg'
import { ReactComponent as ProfileIcon } from '@common/images/icons/profile.svg'
import { ReactComponent as GivingHeartIcon } from '@common/images/icons/giving-heart.svg'

import { staticRoutes } from '@routing/routes'

export const getMoreSubLinks = ({
  applicationStatus,
  showSignOutLink,
  isStandardUser,
  communityTabEnabled,
  hasSubscription,
}) => {
  const links = [
    {
      toRoute: staticRoutes.settingsPrivacySecurity.pathname,
      title: 'Settings',
      icon: <GearsIcon />,
      isExact: false,
    },
    {
      toRoute: staticRoutes.settingsAgreementsDisclosures.pathname,
      title: 'Agreements & Disclosures',
      icon: <DocumentsPersonIcon />,
    },
    {
      toRoute: staticRoutes.settingsHelp.pathname,
      title: 'Help & Support',
      icon: <MobileHelpIcon />,
    },
  ]

  if (showSignOutLink) {
    links.push({
      toRoute: staticRoutes.signOut.pathname,
      title: 'Sign Out',
      hasTextClassname: false,
      icon: <SignOutIcon />,
    })
  }

  if (applicationStatus === 'PASS') {
    if (!isStandardUser) {
      links.splice(0, 0, {
        toRoute: staticRoutes.giving.pathname,
        title: 'Giving',
        icon: <GivingHeartIcon />,
      })
    }

    links.splice(2, 0, {
      toRoute: staticRoutes.settingsLinkedAccounts.pathname,
      isExact: false,
      title: 'Linked Accounts/Cards',
      icon: <LinkedAccountsIcon />,
    })

    links.splice(3, 0, {
      toRoute: staticRoutes.settingsDocuments.pathname,
      title: 'Statements',
      icon: <DocumentsIcon />,
    })
  }

  if (communityTabEnabled && !isStandardUser) {
    let tabLocation = 1
    if (applicationStatus === 'PASS' || hasSubscription) {
      links.splice(tabLocation, 0, {
        toRoute: staticRoutes.membershipDetails.pathname,
        title: 'Membership',
        icon: <MembershipIcon />,
      })

      tabLocation = tabLocation + 1
    }

    links.splice(tabLocation, 0, {
      toRoute: staticRoutes.membershipAccount.pathname,
      title: 'Personal Information',
      icon: <ProfileIcon />,
    })
  }

  return links
}

export const getMoveMoneySubLinks = () => [
  {
    toRoute: staticRoutes.moveMoneyAddMoney.pathname,
    title: 'Add Money',
    icon: <AddMoneyIcon />,
  },
  {
    toRoute: staticRoutes.moveMoneySendMoney.pathname,
    title: 'Send Money',
    icon: <SendMoneyIcon />,
  },
]

export const getMembershipSubLinks = ({ applicationStatus, hasSubscription }) => {
  const subLinks = []

  if (applicationStatus === 'PASS' || hasSubscription) {
    subLinks.push({
      toRoute: staticRoutes.membershipDetails.pathname,
      title: 'Membership',
      icon: <MembershipIcon />,
    })
  }

  subLinks.push({
    toRoute: staticRoutes.membershipAccount.pathname,
    title: 'Personal Information',
    icon: <ProfileIcon />,
  })

  return subLinks
}
