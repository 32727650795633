import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { staticRoutes } from '@routing/routes'
import { store } from '@redux/store'

/**
 * Route guarding for the funding flow views
 * @param {Object} options
 * @param {String[]} options.requiredFundingFlows Specify one or more funding flows required to be
 * able to visit this route
 * @param {String} options.redirectTo Specify where to redirect to if the route guard checks fail.
 * Default is the dashboard.
 * @param {Boolean} options.redirectWithReplace Instead of `push`, use `replace` to redirect. Useful
 * for allowing the user to navigate backwards after this redirect; otherwise they may be trapped
 * in a redirect loop. Default is false.
 */
const useFundingFlowRouteGuard = ({
  requiredFundingFlows = [],
  redirectTo = staticRoutes.dashboard.pathname,
  redirectWithReplace = false,
} = {}) => {
  const { state } = useLocation()
  const navigate = useNavigate()

  const { savedLocationState } = store?.getState()

  const fundingFlow = state?.fundingFlow || savedLocationState?.fundingFlow

  /* Make sure `fundingFlow` is specified in this routes state, and that it matches at least one of
     the supplied required funding flows */
  useEffect(() => {
    if (!fundingFlow || !requiredFundingFlows.includes(fundingFlow)) {
      if (redirectWithReplace) {
        navigate(redirectTo, { replace: true })
      } else {
        navigate(redirectTo)
      }
    }
  }, [navigate, fundingFlow, redirectTo, requiredFundingFlows, redirectWithReplace])
}

export default useFundingFlowRouteGuard
