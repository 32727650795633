import { ALERT_TYPES } from '@shared/constants/uiConstants'

export const ADD_ALERT = 'ADD_ALERT'
export const REMOVE_ALERT = 'REMOVE_ALERT'
export const REMOVE_ALERTS = 'REMOVE_ALERTS'

export const addAlertAction = payload => {
  /* pass payload with default props and overridden values as desired */
  return {
    type: ADD_ALERT,
    payload: {
      dismissible: true,
      id: Date.now(),
      type: ALERT_TYPES.DANGER,
      ...payload,
    },
  }
}

export const removeAlertAction = payload => {
  return {
    type: REMOVE_ALERT,
    payload,
  }
}

export const removeAlertsAction = payload => {
  return {
    type: REMOVE_ALERTS,
    payload,
  }
}
