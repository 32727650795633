import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { ReactComponent as XIcon } from '../../images/icons/x.svg'

import Fade from '@shared/components/Fade'
import { Transition } from 'react-transition-group'

import { TIMEOUT } from '../../constants'

import styling from './alert.module.scss'

/**
 * The alert component can be used to display contextual user messages.
 */

export const Alert = ({
  autoDismissDelay,
  centered = true,
  children,
  text,
  subText,
  className,
  closeClassName,
  closeAriaLabel = 'Close',
  dismissible,
  fade = true,
  id,
  open = false,
  removeHandler,
  styleType = 'primary',
  tag: Tag = 'div',
  transition = {
    ...Transition.defaultProps,
    appear: true,
    enter: true,
    exit: true,
    in: true,
    timeout: TIMEOUT.FADE,
    unmountOnExit: true,
  },
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(open)
  const dismissTimeout = useRef(null)
  const alertRef = useRef(null)

  const dismiss = () => {
    setIsOpen(false)
    setTimeout(() => {
      removeHandler(id)
    }, TIMEOUT.FADE + 100)
  }

  useEffect(() => {
    return () => {
      if (dismissTimeout.current !== null) {
        clearTimeout(dismissTimeout.current)
      }
    }
  })

  useEffect(() => {
    if (autoDismissDelay && open) {
      if (open && !isOpen) {
        setIsOpen(true)
      }
      dismissTimeout.current = setTimeout(() => {
        dismiss()
        dismissTimeout.current = null
      }, autoDismissDelay)
    } else {
      setIsOpen(open)
    }
  }, [autoDismissDelay, open, removeHandler, id])

  const classes = classNames(
    className,
    styling.alert,
    styling[`alert-${styleType}`],
    centered && styling.centered,
    dismissible && styling['alert-dismissible'],
    styleType === 'warning' && 'warning-alert',
  )

  const closeClasses = classNames(styling.close, closeClassName)

  const alertTransition = {
    ...Transition.defaultProps,
    appear: true,
    enter: true,
    exit: true,
    in: true,
    ...transition,
    baseClass: fade ? transition.baseClass : '',
    timeout: fade ? transition.timeout : 0,
  }

  return (
    <Fade
      {...rest}
      {...alertTransition}
      tag={Tag}
      className={classes}
      in={isOpen}
      role='alert'
      innerRef={alertRef}
    >
      {dismissible ? (
        <button
          type='button'
          className={closeClasses}
          aria-label={closeAriaLabel}
          onClick={dismiss}
        >
          <XIcon className='close-alert-icon' />
        </button>
      ) : null}
      {children}
      <div className={styling['text-content']}>
        {text && <div>{text}</div>}
        {subText && <div className={styling['sub-text']}>{subText}</div>}
      </div>
    </Fade>
  )
}

Alert.propTypes = {
  /**
   * Time in milliseconds the alert should appear for before auto-dismissing itself
   */
  autoDismissDelay: PropTypes.number,
  /**
   * Whether to center align the alert's contents
   */
  centered: PropTypes.bool,
  /**
   * The children nodes.
   */
  children: PropTypes.node,
  /**
   * Main text content.
   */
  text: PropTypes.string,
  /**
   * Sub text content.
   */
  subText: PropTypes.string,
  /**
   * Custom class name.
   */
  className: PropTypes.string,
  /**
   * The close button's class name.
   */
  closeClassName: PropTypes.string,
  /**
   * The close button's aria label.
   */
  closeAriaLabel: PropTypes.string,
  /**
   * The style color.
   */
  styleType: PropTypes.string,
  /**
   * Whether it should fade, or not.
   */
  fade: PropTypes.bool,
  /**
   * Whether it is open, or not.
   */
  open: PropTypes.bool,
  /**
   * Whether the user can dismiss it, or not.
   */
  dismissible: PropTypes.bool,
  /**
   * The function to run after it is dismissed, for cleanup
   */
  removeHandler: PropTypes.func,
  /**
   * The transition config. See `Fade` component for more details.
   */
  transition: PropTypes.shape(Fade.propTypes),
  /**
   * The component tag type.
   */
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  /**
   * A unique ID for the alert
   */
  id: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
}

export default Alert
