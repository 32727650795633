import React, { useState } from 'react'
import classNames from 'classnames'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'
import Button from '@shared/components/button/Button'
import ExternalLink from '@common/components/link/ExternalLink'

import { MFA_CHANNELS } from '@common/constants'
import { ROUTE_USER_FLOW, CHALLENGE_TYPES } from '@shared/constants/uiConstants'

import { verify } from '@services/serviceUtils'
import {
  getRedirectLocationByChallengeTypeAndUserFlow,
  SEGMENT_EVENTS,
  trackEvent,
  getBootstrapSitemapResource,
  BOOTSTRAP_SITEMAP_RESOURCES,
} from '@common/utils'

import styling from './chooseVerificationMethod.module.scss'

const ChooseVerificationMethod = () => {
  const [channel, setChannel] = useState()
  const [isLoading, setIsLoading] = useState()
  const sessionId = useSelector(state => state.auth?.sessionId)

  const navigate = useNavigate()
  const { state } = useLocation()

  const faq = getBootstrapSitemapResource(BOOTSTRAP_SITEMAP_RESOURCES.FAQ)

  const handleChannelClick = async channel => {
    setChannel(channel)
    setIsLoading(true)

    try {
      const response = await verify({ sessionId, value: channel })
      const data = response?.data

      setIsLoading(false)

      trackEvent({
        event: SEGMENT_EVENTS.verificationCodeSelection({
          rememberMeId: window.sessionStorage.getItem('mfaRememberMeId'),
          selected: channel,
        }),
      })

      // if the flow is not provided by the state, likely due to this being a new tab,
      // we can fall back to determining the flow based on the returned challenge type
      const userFlowFlag = state?.userFlow || ROUTE_USER_FLOW[CHALLENGE_TYPES[data?.challengeType]]

      const { pathname: navigatePath, state: navigateState } = getRedirectLocationByChallengeTypeAndUserFlow({
        challengeType: data?.challengeType,
        userFlow: userFlowFlag,
        sessionId: data?.sessionId,
        mfaChannel: data?.mfaChannel,
        displayEmail: state?.displayEmail,
      })

      navigate(navigatePath, { state: navigateState, replace: true })
    } catch (error) {
      setIsLoading(false)
    }
  }

  return (
    <div
      className={classNames(
        'white-card-container',
        styling['choose-verification-method-container']
      )}
    >
      <GreenwoodLogo className='logo' />
      <div className='create-account-content-wrapper'>
        <h1>Let's make sure it's you</h1>
        <p className={styling['choose-verification-method-subtext']}>
          Choose the method in which you want to request a verification code to sign in.
        </p>
        <Button
          type='button'
          data-cy='choose-verification-method-email-code-button'
          className='additional-button'
          isLoading={channel === MFA_CHANNELS.EMAIL && isLoading}
          onClick={() => handleChannelClick(MFA_CHANNELS.EMAIL)}
        >
          Email Code
        </Button>
        <Button
          type='button'
          data-cy='choose-verification-method-text-code-button'
          className='additional-button'
          isLoading={channel === MFA_CHANNELS.SMS && isLoading}
          onClick={() => handleChannelClick(MFA_CHANNELS.SMS)}
        >
          Text Code
        </Button>
        <p className={styling['disclaimer-text']}>
          Message and data rates may apply.{' '}
          <ExternalLink className='underlined-link bold' to={faq.url}>
            Learn More
          </ExternalLink>
        </p>
      </div>
    </div>
  )
}

export default ChooseVerificationMethod
