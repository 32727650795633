import { useState, useEffect, useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { getNextIncompleteCheckpointRoute, isCompleteCheckpointPath } from '@common/utils'
import { staticRoutes } from '@routing/routes'
import { APPLICATION_STATUSES } from '@shared/constants/uiConstants'
import { FUNDING_FLOWS } from '@common/constants'

/**
 * Controls checkpoint based navigation of account creation screens.
 * Pass the current 'data.checkpoints' value, then execute the
 * proceedToNextCheckpoint callback to trigger the navigation.
 */
const useCheckpointNavigation = ({
  checkpoints,
  applicationStatus,
  pollingHasMaxedOut,
  isResubmitting,
}) => {
  const navigate = useNavigate()
  const { pathname, state } = useLocation()
  const [updated, setUpdated] = useState(false)

  /* Make sure that this is the current required step in the account
     creation flow */
  useEffect(() => {
    const nextIncompleteCheckpointPath = getNextIncompleteCheckpointRoute(checkpoints)
    const _isCompleteCheckpointPath = isCompleteCheckpointPath({ checkpoints, pathname })

    const checkAndUpdatePath = (newPathname, state) => {
      if (pathname !== newPathname) {
        navigate(newPathname, { state })
      }
    }

    const hasDocumentRequiredStatus = [
      APPLICATION_STATUSES.DOCUMENT_REQUIRED,
      APPLICATION_STATUSES.DOCUMENT_RESUBMISSION_REQUIRED,
    ].includes(applicationStatus)

    const ignoreCheckpointNavigation =
      ((isResubmitting || state?.isResubmitting) &&
        applicationStatus === APPLICATION_STATUSES.RESUBMISSION_REQUIRED) ||
      (state?.isSubmittingDocuments && hasDocumentRequiredStatus)

    if (!applicationStatus || ignoreCheckpointNavigation) {
      return
    }

    switch (applicationStatus) {
      case APPLICATION_STATUSES.SUBMITTED:
        if (!pollingHasMaxedOut) {
          checkAndUpdatePath(staticRoutes.createAccountProcessing.pathname)
        } else {
          checkAndUpdatePath(staticRoutes.createAccountInReview.pathname)
        }
        break

      case APPLICATION_STATUSES.INITIAL:
        if (!_isCompleteCheckpointPath) {
          checkAndUpdatePath(nextIncompleteCheckpointPath)
        }
        break

      case APPLICATION_STATUSES.REVIEW:
        checkAndUpdatePath(staticRoutes.createAccountInReview.pathname)
        break

      case APPLICATION_STATUSES.PASS:
        checkAndUpdatePath(staticRoutes.selectAccountTier.pathname, {
          fundingFlow: FUNDING_FLOWS.ONBOARDING_PREMIUM,
          shouldSetupDirectDeposit: true,
        })
        break

      case APPLICATION_STATUSES.DECLINED:
      case APPLICATION_STATUSES.DOCUMENT_REQUIRED:
      case APPLICATION_STATUSES.DOCUMENT_RESUBMISSION_REQUIRED:
      case APPLICATION_STATUSES.DOCUMENT_UNDER_REVIEW:
      default:
        checkAndUpdatePath(staticRoutes.dashboard.pathname)
        break
    }
  }, [
    pathname,
    checkpoints,
    applicationStatus,
    navigate,
    pollingHasMaxedOut,
    isResubmitting,
    state?.isResubmitting,
    state?.isSubmittingDocuments,
  ])

  /* After successful update and the new checkpoints have been fetched
    navigate to the next incomplete step in the onboarding process, or
    the review page if all steps are complete  */
  useEffect(() => {
    updated &&
      navigate(getNextIncompleteCheckpointRoute(checkpoints), { state })
  }, [navigate, updated, checkpoints, state])

  const proceedToNextCheckpoint = useCallback(() => {
    setUpdated(true)
  }, [])

  return {
    proceedToNextCheckpoint,
  }
}

export default useCheckpointNavigation
