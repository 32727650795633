import React, { useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { ReactComponent as MoneyTransferIcon } from '@common/images/icons/money-transfer.svg'
import { ReactComponent as BillPayIcon } from '@common/images/icons/bill-pay.svg'
import LinksTable from '@common/components/link/LinksTable'
import TransferMoneyButton from './TransferMoneyButton'
import {
  trackEvent,
  trackPage,
  SEGMENT_PAGE_NAMES,
  SEGMENT_EVENTS,
  SEGMENT_SOURCE_DETAILS,
} from '@common/utils'

import { staticRoutes } from '@routing/routes'
import styling from './moveMoney.module.scss'

const SendMoney = () => {
  const navigate = useNavigate()

  const tableItems = useMemo(
    () => [
      {
        heading: 'Transfer Money between Accounts',
        subtext: 'Available in 5-7 business days',
        icon: <MoneyTransferIcon />,
        renderContent: ({ children, className }) => {
          return <TransferMoneyButton className={className} displayText={children} ghost />
        },
        dataCy: 'move-money-send-money-transfer-list-item',
        onClick: () => {
          trackEvent({
            event: SEGMENT_EVENTS.MOVE_MONEY_BUTTON_CLICK(
              SEGMENT_SOURCE_DETAILS.TRANSFER_BETWEEN_ACCOUNTS
            ),
          })
        },
      },
      {
        heading: 'Pay Bills',
        subtext: 'Use your Greenwood card or account details to pay bills',
        icon: <BillPayIcon />,
        onClick: () => {
          trackEvent({
            event: SEGMENT_EVENTS.MOVE_MONEY_BUTTON_CLICK(SEGMENT_SOURCE_DETAILS.PAY_BILLS),
          })

          navigate(staticRoutes.payBills.pathname)
        },
        dataCy: 'move-money-send-money-pay-bills-list-item',
      },
    ],
    [navigate]
  )

  // Tracks page visit
  useEffect(() => {
    trackPage({ name: SEGMENT_PAGE_NAMES.MOVE_MONEY_SEND_MONEY })
  }, [])

  return (
    <div className={classNames(styling['move-money'], styling['send-money'])}>
      <h2>Send Money</h2>
      <p>Transfer money between accounts or pay bills online.</p>
      <div className={styling['table-container']}>
        <LinksTable rowsData={tableItems} />
      </div>
    </div>
  )
}

export default SendMoney
