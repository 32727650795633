import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useLocation } from 'react-router-dom'

import { gql, useQuery } from '@services/serviceUtils'
import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'
import { ReactComponent as AgreementsIllustration } from '@common/images/illustrations/illustration-legal.svg'
import Button from '@shared/components/button/Button'
import LoadingContainer from '@shared/components/loadingContainer/LoadingContainer'
import { staticRoutes } from '@routing/routes'
import { PRODUCT_TYPES, COASTAL_BANKING_SERVICES } from '@common/constants'
import AgreementsList from '@common/components/agreementsList/AgreementsList'
import { SEGMENT_EVENTS, SEGMENT_PAGE_NAMES, trackEvent, trackPage } from '@common/utils'
import { APPLICATION_STATUSES } from '@shared/constants'

import styling from './createAccountAgreements.module.scss'

const productsQuery = gql`
  query ProductAgreements($legacy: Boolean) {
    products(legacy: $legacy) {
      productType
      agreements {
        id
        title
        url
      }
    }
  }
`

const CreateAccountAgreements = ({ updateApplication, updating, data: applicationData }) => {
  const navigate = useNavigate()
  const { state } = useLocation()

  useEffect(() => {
    if (applicationData?.application?.customerApplicationStatus === APPLICATION_STATUSES.INITIAL) {
      trackPage({ name: SEGMENT_PAGE_NAMES.AGREEMENTS })
    }
  }, [applicationData?.application?.customerApplicationStatus])

  const { loading, error, data } = useQuery(productsQuery, { variables: { legacy: false } })

  const product =
    data?.products?.find(_product => _product.productType === PRODUCT_TYPES.basic) || null

  const allAgreements = []
  const allAvailableProducts = data?.products?.filter(
    product => ![PRODUCT_TYPES.savings, PRODUCT_TYPES.premium].includes(product?.productType)
  )
  allAvailableProducts?.forEach(p => {
    p?.agreements?.forEach(agreement => allAgreements.push(agreement))
  })

  const handleAgreeClick = async () => {
    trackEvent({ event: SEGMENT_EVENTS.AGREEMENTS_AGREE_BUTTON_CLICK })

    const agreements = product?.agreements?.map(agreement => agreement.id) || []

    await updateApplication({
      agreements,
    })

    navigate(staticRoutes.createAccountReview.pathname, { state })
  }

  return (
    <div className='white-card-container' data-cy='authenticated-loaded-view'>
      <GreenwoodLogo className='logo' />

      <div className={styling.eyebrow}></div>
      <AgreementsIllustration />
      <h1 className={styling['no-eyebrow']}>Legal Agreements and Disclosures</h1>

      <LoadingContainer error={error} errorMessage='Error loading agreements.' loading={loading}>
        <div className='create-account-content-wrapper'>
          <p>
            By continuing to open an account through Greenwood, I agree that I have read, understand
            and consent to the following:
          </p>

          <AgreementsList agreements={allAgreements} />

          <Button
            type='submit'
            onClick={handleAgreeClick}
            data-cy='accept-agreements-button'
            isLoading={updating}
          >
            AGREE
          </Button>

          <p className='disclaimer-text'>{COASTAL_BANKING_SERVICES}</p>
        </div>
      </LoadingContainer>
    </div>
  )
}

CreateAccountAgreements.propTypes = {
  /**
   * Function used to update the application object
   */
  updateApplication: PropTypes.func,

  /**
   * Loading indicator when the application data is updating
   */
  updating: PropTypes.bool,
}

export default CreateAccountAgreements
